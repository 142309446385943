
// TODO: Translations

export default {
  show_page:            'Seite ansehen',
  show_pdf:             'PDF ansehen',
  article_not_public:   'Artikel ist nicht öffentlich.',
  status_text_future:   'Dieser Artikel ist nicht öffentlich sichtbar, weil das Startdatum in der Zukunft liegt.',
  status_text_draft:    'Dieser Artikel ist nicht öffentlich sichtbar, weil er nicht den Status "Online-Anzeigen" hat und somit ein Entwurf ist.',
  kleinanzeigen:        'Um diese Funktion nutzen zu können, müssen Sie den "Kleinanzeigen-Manager" buchen',
  call_sales:           'Um diese Funktion nutzen zu können, sprechen Sie bitte mit Ihrem Vertriebspartner.',
  ebay_no_slots:        'Es sind leider keine freien Slots mehr verfügbar.'
}
