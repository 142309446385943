import React from 'react';
import Button from "components/CustomButtons/Button.jsx";
import Check from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import Close from "@material-ui/icons/Close";

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    circularProgress: {
        width: 'unset !important',
        height: 'unset !important',
        color: '#ffffff',
        '& svg': {
            marginRight: 0
        }
    }
});

const LoadingButton = (props) => {
    const { classes, loading, done, failed, children, ...other } = props;

    if (done) {
        return (
            <Button className={classes.button} {...other} disabled>
                {children} &nbsp; <Check />
            </Button>
        );
    } else if (failed) {
        return (
            <Button className={classes.button} {...other} disabled>
                {children} &nbsp; <Close />
            </Button>
        );
    } else if (loading) {
        return (
            <Button className={classes.button} {...other} disabled>
                {children} &nbsp; <CircularProgress className={classes.circularProgress} />
            </Button>
        );
    } else {
        return (
            <Button color="primary" className={classes.button} {...other}>{children}</Button>
        );
    }
}

export default withStyles(styles)(LoadingButton);