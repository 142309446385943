//import DB from 'components/44/Database';
import API from 'components/44/ApiPlanungswelten';

class Statistics {

  async getRemoteStatistics(stat_opts){
    await API.getStatistics(stat_opts).then((json) => {
      Object.keys(json).forEach(function(k){
        localStorage.setItem(k, JSON.stringify(json[k]))
        localStorage.setItem('statistics_last_updated', Date.now())
      });
      localStorage.setItem('stats_start_date', stat_opts.stats_start_date);
      localStorage.setItem('stats_end_date', stat_opts.stats_end_date);
      localStorage.setItem('stats_selected_partner', JSON.stringify(stat_opts.partner_ids));
      return Promise.resolve();
    })
  }

  getStatisticDateLastUpdated(){
    if(typeof localStorage.statistics_last_updated !== 'undefined' && parseInt(localStorage.statistics_last_updated, 10)){
      if(new Date(parseInt(localStorage.statistics_last_updated, 10)).toLocaleDateString() === new Date(Date.now()).toLocaleDateString()){
        // heute
        return 'Heute um '+new Date(parseInt(localStorage.statistics_last_updated, 10)).toLocaleTimeString();
      }else{
        // nicht heute
        return new Date(parseInt(localStorage.statistics_last_updated, 10)).toLocaleString();
      }
    }else{
      return '-'
    }
  }

  async getStatistic(key, cache = 'auto', stat_ops = {}){
    cache = true;
    try {

      if(cache==='auto') {
        // Checks if stat is in Cache/LocalStorage, then use it, otherwise retrieve the stats!
        if(typeof localStorage[key] !== 'undefined' && (localStorage[key] >= 0)){
          // return Promise.resolve(parseFloat(localStorage[key]).toLocaleString('de-DE'))
          return Promise.resolve(localStorage[key])
        }else if(typeof localStorage[key] !== 'undefined' && typeof JSON.parse(localStorage[key]) === 'object'){
          return Promise.resolve(JSON.parse(localStorage[key]));
        }else{
          console.debug('angeforderte Statistik '+key+' existiert im localStorage nicht! Statistik wird abgeholt!', localStorage[key])

          let data_to_return = null
          await API.getStatistics(stat_ops).then((json) => {
            //console.log('statistics', json)
            Object.keys(json).forEach(function(k){
              //console.log(k, JSON.stringify(json[k]));
              localStorage.setItem(k, JSON.stringify(json[k]))
              localStorage.setItem('statistics_last_updated', Date.now())

              if(key === k){
                data_to_return = json[k]
              }
            });
          })
          return Promise.resolve(data_to_return)
        }

      }else if(cache){
        if(typeof localStorage[key] !== 'undefined' && (localStorage[key] >= 0)){
          // return Promise.resolve(parseFloat(localStorage[key]).toLocaleString('de-DE'))
          return Promise.resolve(localStorage[key])
        }else if(typeof localStorage[key] !== 'undefined' && typeof JSON.parse(localStorage[key]) === 'object'){
          //console.log('data return from cache', JSON.parse(localStorage[key]));
          return Promise.resolve(JSON.parse(localStorage[key]));
        }else{
          console.debug('angeforderte Statistik '+key+' existiert im localStorage nicht!', localStorage[key])
          //return Promise.reject('angeforderte Statistik '+key+' existiert im localStorage nicht!')
          return false
        }
      }else{
        /*
         * LIVE-Mode: Aktuell noch nicht benutzbar; Rückgabe muss async und als promise erfolgen
         */
        let data_to_return = null
        await API.getStatistics(stat_ops).then((json) => {
          //console.log('statistics', json)
          Object.keys(json).forEach(function(k){
            //console.log(k, JSON.stringify(json[k]));
            localStorage.setItem(k, JSON.stringify(json[k]))
            localStorage.setItem('statistics_last_updated', Date.now())

            if(key === k){
              data_to_return = json[k]
            }
          });
        })
        return Promise.resolve(data_to_return)
      }

    }catch (e) {
      console.log(e)
      return localStorage[key]
    }
  }


  async getStatisticByCategory(type = 'online_and_deleted_by_cat_level1', cache = 'auto', stat_ops = {}, selected_cat = false){
    try {
      //console.log('-------------'+type+'-------------')
      return await this.getStatistic(type, cache, stat_ops).then((statistics)=>{

        //console.log('statistics', statistics)

        let filtered_statistics = []

        /* Ab level 2 muss eine Auswahl getroffen sein */
        if(type !== 'online_and_deleted_by_cat_level1' && selected_cat){
          if(typeof statistics[selected_cat] !== 'undefined'){
            filtered_statistics = statistics[selected_cat]
          }else{
            return false
          }
        }else{
          filtered_statistics = statistics
        }

        //console.log('type', type)
        //console.log('selected_cat', selected_cat)
        //console.log('filtered_statistics', filtered_statistics)

        return filtered_statistics
      })
    }catch (e) {
      console.log('Error in "getStatisticByCategory" type='+type+' aufgetreten!')
      return false
    }
  }

  async getStatisticData(type = 'deleted_by_month', cache = 'auto', stat_ops = {}){
    try {

      return await this.getStatistic(type, cache, stat_ops).then( (data) => {
        return data
      } )

      //console.log(type, statistics)

      //return await this.getStatistic(type, cache, stat_ops)
      //return Promise.resolve(statistics)
    }catch (e) {
      return Promise.reject(e)
    }
  }

  async getStatisticKeys(type = 'online_and_deleted_by_cat_level1', selected_cat = false, cache = 'auto'){
    try {
      let keys = []
      return await this.getStatistic(type, cache).then((statistics) => {

        if(selected_cat){
          // LEVEL 2,3
          let filtered_statistics = []
          if(typeof statistics[selected_cat] !== 'undefined'){
            filtered_statistics = statistics[selected_cat]
          }else{
            //return false
          }

          keys = Object.entries(filtered_statistics).map(function (item, key) {
            key = item.shift();
            return { name: item[0].name, value: item[0].name }
          });
          return keys
        }else{
          // LEVEL 1
          if(type === 'online_and_deleted_by_cat_level1'){
            keys = Object.entries(statistics).map(function (item, key) {
              key = item.shift();
              return { name: item[0].name, value: item[0].name }
            });
          }else{
            keys = Object.entries(statistics).map(function (item, key) {
              key = item.shift();
              return { name: key, value: key }
            });
          }
          return keys
        }
        //return Promise.resolve(keys)
      })
    }catch (e) {
      return false
    }
  }

  getStatisticThisYear(type = 'deleted_by_month', cache = 'auto'){
    try {
      let statistics = this.getStatistic(type, cache)
      //console.log('statistics', statistics)
      let currentYear = new Date(Date.now()).getFullYear()
      let statisticsCurrentYear = statistics[currentYear]
      //console.log('statisticsCurrentYear', statisticsCurrentYear)
      let values = Object.values(statisticsCurrentYear)

      return {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mai",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ],
        series: (typeof(values[0]) !== 'object' ? [values] : values)
        //series:  values
      }
    }catch (e) {
      return false
    }
  }

};

const Statistic = new Statistics();

export default Statistic;
