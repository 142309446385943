/*global FB*/
import React, { Component } from 'react';

import Button from "components/CustomButtons/Button.jsx";
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import styles from './FacebookPost.module.css';

class FacebookPost extends Component {
  state = {
    isMobile: this.props.isMobile || false,
    loading: false,
    success: false,
    error: false
  }

  handlePostProduct = () => {
    let _this = this;
    const pageToken = this.props.pageToken;
    const pageID = this.props.pageID;
    const { onError, onSuccess } = this.props;
    const { name, preis, images, link } = this.props.postData;


    let photo = {
      caption: name + ' \n\n Jetzt zum Vorteilspreis von ' + preis + ' € \n\n Weitere Informationen erhalten Sie unter: \n ' + link,
      // caption: name + ' \n\n Jetzt zum Vorteilspreis von ' + preis + ' € \n\n Weitere Informationen erhalten Sie unter: \n https://www.bbm-moebel.de/reduziert/?product=wohnwand-34',
    };

    if (typeof images !== 'undefined' && images.length > 0) {

      let urlPosFilename = images[0].thumbnail_url.lastIndexOf('/') + 1
      photo.url = images[0].thumbnail_url.slice(0,urlPosFilename) + images[0].name;

    } else {
      photo.url = null
    }

    this.setState({loading: true})
    //console.debug('access_token', pageToken)

    if (typeof FB != 'undefined' && !link.startsWith('/?')) {
      FB.api(
        '/'+pageID+'/photos',
        'POST',
        {
          access_token: pageToken,
          ...photo
        },
        function (response){
          if (!response.error) {
            onSuccess('Artikel erfolgreich auf Facebook veröffentlicht');
            _this.setState({
              loading: false,
              success: true
            })
          } else {
            _this.setState({
              loading: false,
              error: true
            })
            onError(response.error)
          }
          setTimeout(function(){
            _this.setState({success:false, error:false})
          },3600)
        }
      );
    } else {
      this.setState({
        loading: false,
        error: true
      })
      if(typeof FB == 'undefined'){
        onError({message: 'FB konnte nicht initialisiert werden. Lade die App neu.'})
      }else if(!link.startsWith('/?')) {
        onError({message: 'Keine Marktplatz-URL vorhanden. Bitte ergänze diese in den Partner-Daten.'})
      }

      setTimeout(function(){
        _this.setState({success:false, error:false})
      },3600)
    }
  }

  render() {
    const {
      isMobile,
      loading,
      success,
      error
    } = this.state;

    let btnColor = success ? "success" : error ? "danger" : "indigo";
    let btnContent = success ? <DoneIcon style={{top:-4}} /> : error ? <CloseIcon style={{top:-4}} /> : "f";
    let content = loading ? (
      <div className={styles.spinner}>
        <div className={styles.doubleBounce1}></div>
        <div className={styles.doubleBounce2}></div>
      </div>
    ) : (
      <div className={styles.indigoBtn}>
        {btnContent}
      </div>
    );

    return (
      <Button
        isMobile={isMobile}
        justIcon
        round
        tooltip="facebook-Post erstellen"
        color={btnColor}
        onClick={this.handlePostProduct}
      >
        {content}
      </Button>
    )
  }
}

export default FacebookPost;
