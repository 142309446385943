import React from 'react'
import {withSnackbar} from "notistack";
import {Restore} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import API from "./ApiPlanungswelten";
import SnackbarClose from "../Snackbar/SnackbarClose";

class SessionTimer extends React.Component {
  constructor() {
    super();
    this.state = { time: {}, seconds: this.sessionExpiresAt() };
    this.timer = 0;
    this.expireInfo = false;
    this.startTimer = this.startTimer.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds,
      "ss": seconds < 10 ? `0${seconds}` : seconds
    };
    return obj;
  }

  sessionExpiresAt(token_expires_at=false){
    let currentTimeInSeconds = 0
    let expiresInSeconds = 0

    if(token_expires_at){
      currentTimeInSeconds = parseInt(Date.now()/1000)
      expiresInSeconds = parseInt(token_expires_at) - currentTimeInSeconds
    }else{
      currentTimeInSeconds = parseInt(Date.now()/1000)
      expiresInSeconds = parseInt(localStorage.token_expires_at) - currentTimeInSeconds
    }
    return expiresInSeconds
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar, expireInfo: true });
    this.startTimer()
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }else{
      //console.debug('Session timeout - REDIRECT to login')
      window.location.assign('/logout')
    }
  }

  resetTimer() {
    let _this = this
    API.tokenRefresh().then(
      (result) => {
        //console.debug('tokenRefresh Result', result.token)
        //console.debug('tokenRefresh Result-JSON', result.token_expires_at)
        let seconds = _this.sessionExpiresAt(result.token_expires_at);
        _this.setState({
          time: this.secondsToTime(seconds),
          seconds: seconds
        })
        localStorage.setItem('token', result.token)
        localStorage.setItem('token_expires_at', result.token_expires_at)
      },
      (error) => {
        console.debug('tokenRefresh Error', error)
      }
    ).catch(
      (error) => {
        console.debug('tokenRefresh Error', error)
      }
    )
  }

  countDown() {
    if(navigator.onLine) {
      // Remove one second, set state so a re-render happens.
      let seconds = this.sessionExpiresAt();
      let expireInfo = this.state.expireInfo

      if (seconds < 60 && !expireInfo) {
        expireInfo = true
        expireInfo = this.props.enqueueSnackbar('Achtung: In weniger als einer Minute läuft ihre Sitzung ab!', {persist: true, variant: 'info', action: (<SnackbarClose/>)})
        //this.setState({ expireInfo: expireInfo });
      }
      if (seconds >= 60) {
        this.props.closeSnackbar(expireInfo)
        expireInfo = false
      }

      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
        expireInfo: expireInfo,
      });

      // Check if we're at zero.
      if (seconds <= 0) {
        clearInterval(this.timer);
        //console.debug('Session timeout - REDIRECT to login')
        window.location.assign('/logout')
      }
    }else{
      // console.debug('Client is offline, wait for going online again to refresh timer.')
      if(this.state.expireInfo){
        this.props.closeSnackbar(this.state.expireInfo)
        this.setState({
          expireInfo: false,
        });
      }
    }
  }

  render() {
    return(
      <div>
        <div style={{marginTop: 3, marginRight: 10, lineHeight: 1}}>
          <small>Abmeldung in</small> {this.state.time.m}:{this.state.time.ss}
          <IconButton style={{marginTop: -4}} onClick={this.resetTimer}><Restore/></IconButton>
        </div>
      </div>
    );
  }
}

export default withSnackbar(SessionTimer);
