import React from "react";
//import ReactDOM from 'react-dom';
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomButton from "components/CustomButtons/Button.jsx";
//import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

//import Form from 'material-ui-form-builder';
import FormBuilder from 'components/44/formbuilder';

/*import Upload from 'material-ui-upload/Upload';
import UploadPreview from 'material-ui-upload/UploadPreview';*/

// 44 components
import DB from 'components/44/Database';
import API from 'components/44/ApiPlanungswelten';
import { theme } from "components/44/muiTheme"
import combineStyles from 'components/44/combineStyles';

import defaultImage from "assets/img/default-picture.png";

// import Styles
import productFormStyle from "assets/jss/44/productFormStyle.jsx";
import SnackbarClose from "../../components/Snackbar/SnackbarClose";
import {withSnackbar} from "notistack";
import Txt from "../../variables/texts";

const formData = require("../../json/produkte.json");
const formDataWpFields = require("../../json/produkte_wp-fields.json");
const formFields = [ ...formDataWpFields.fields, ...formData.fields ];

const ansprechpartnerFallback = require("../../json/ansprechpartner.json");
const herstellerFallback = require("../../json/hersteller_production.json");
const kategorienFallback = process.env.NODE_ENV === 'production' ? require("../../json/produkt_kategorien_production.json") : require("../../json/produkt_kategorien.json");


const styles = theme => ({
  root: {
    width: '100%',
    fontFamily: 'Lato'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  textfieldOutlined: {
    flexBasis: 280,
  },
  zeilen: {
    marginTop: 0,
    marginBottom: 15,
    fontWeight: 500
  },
  zeilenSwitch: {
    marginTop: -10,
    marginBottom: 10,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  selectTreeItem: {
    padding: '4px 10px',
    borderBottom: '1px solid #dadada',
    fontWeight: 300
  }
});

class ProductFormRemote extends React.Component {

  constructor(props) {
    super(props);

    const selectedPartnerData = DB.selectedPartnerData()

    this.state = {
      props: props,
      formFields: formFields,
      ai_text_generation_enable: API.userHasGenerateAiTexts(), /* API.userIs44OrT4M() */
      editValues: {
        app_synced: false,
        post_id: false,
        post_status: 'publish',
        produkt_typ: 'moebel',
        partner: selectedPartnerData.partner_id,
        zahlungsbedingungen: selectedPartnerData.partner_zahlungsbedingungen || '',
        lieferbedingungen: selectedPartnerData.partner_lieferbedingungen || '',
        finanzierung: selectedPartnerData.partner_finanzierung || '',
        strasse: selectedPartnerData.partner_street || '',
        plz: selectedPartnerData.partner_zip || '',
        ort: selectedPartnerData.partner_city || '',
        land: selectedPartnerData.partner_country || '',
        menge: 1,
        versand_moeglich: 0,
        cat_level1: 0,
        cat_level2: 0,
        cat_level3: 0
      },
      jsonData: {
        "ansprechpartner": ansprechpartnerFallback,
        "hersteller": herstellerFallback,
        "kochfeld_hersteller": herstellerFallback,
        "herd_backofen_hersteller": herstellerFallback,
        "mikrowelle_hersteller": herstellerFallback,
        "dunstabzug_hersteller": herstellerFallback,
        "kuehlschrank_hersteller": herstellerFallback,
        "gefrierschrank_hersteller": herstellerFallback,
        "geschirrspueler_hersteller": herstellerFallback,
        "kuehl-gefrier-kombi_hersteller": herstellerFallback,
        "produkt_kategorien": kategorienFallback
      },
      validateValues: {},
      pictures: {},
      expanded: null,
      loading: true
    };
    this.formBuilder = React.createRef();
    this.pictureUpload = React.createRef();
  }

  componentDidMount(){
    this.setState({ db: DB })
    let _this = this

    /* JSON-Data (ua. Kategorien, Ansprechpartner, Hersteller-Daten usw) neu laden */
    let kategorien = typeof(localStorage['kategorienData']) !== 'undefined' ? JSON.parse(localStorage['kategorienData']) : kategorienFallback;
    let ansprechpartner = typeof(localStorage['ansprechpartnerData']) !== 'undefined' ? JSON.parse(localStorage['ansprechpartnerData']) : ansprechpartnerFallback;
    let herstellerLieferprogramm = typeof(localStorage['herstellerData']) !== 'undefined' ? JSON.parse(localStorage['herstellerData']) : herstellerFallback;
    let herstellerCategory = typeof(localStorage['herstellerCategoryData']) !== 'undefined' ? JSON.parse(localStorage['herstellerCategoryData']) : herstellerFallback;

    let jsonData = {
      "ansprechpartner": ansprechpartner,
      "hersteller": DB.checkPartnerData('hersteller_lieferprogramm', false) ? herstellerLieferprogramm : herstellerCategory,
      "produkt_kategorien": kategorien,
      "kochfeld_hersteller": herstellerCategory,
      "herd_backofen_hersteller": herstellerCategory,
      "mikrowelle_hersteller": herstellerCategory,
      "dunstabzug_hersteller": herstellerCategory,
      "kuehlschrank_hersteller": herstellerCategory,
      "gefrierschrank_hersteller": herstellerCategory,
      "geschirrspueler_hersteller": herstellerCategory,
      "kuehl-gefrier-kombi_hersteller": herstellerCategory,
    }

    // "Online anzeigen? | Kleinanzeigen | Markt.de" für Ersteller sperren
    if (API.getUserRole() === 'sellanizer_creator') {
      this.state.formFields.forEach( field => {
        if (field.name === 'post_status' || field.name === 'should_ebay' || field.name === 'should_marktde') {
          field.disabled = true
          field.readonly = true
        }
      })
    }

    // Extra-Feature prüfen / sperren
    if(!DB.checkPartnerData('is_ebay_kleinanzeigen')) {
      let search = new RegExp(' - ' + Txt.kleinanzeigen,"g");
      this.state.formFields.forEach( field => {
        if (field.name === 'should_ebay' || field.name === 'should_marktde') {
          field.label = field.label.replace(search, '')
          field.label = field.label + ' - ' + Txt.kleinanzeigen
          field.disabled = true
          field.readonly = true
        }
      })
    }

    this.setState({ jsonData: jsonData })

    if(this.props.match.params.id){
      //console.debug('E D I T   REMOTE');

      //API.getProducts().then((json) => {
      API.getProduct(this.props.match.params.id).then((json) => {
        //_this.changeProduktValues(key, { synced: undefined, app_synced: true, app_sync_error: false, post_id: json.post_id}, false)

        const editData = json[0]

        console.log("editData", editData)

        //console.debug('set editValues', editData)
        _this.setState({editValues: editData})
        _this.setState({loading: false})
        _this.formBuilder.current.setState({values: editData})
        _this.formBuilder.current.setDefaultFields(editData);

        // wird vermutlich nicht mehr benötigt durch nachfolgende neue Funktion check_cl_on_startup:
        // _this.formBuilder.current.handleCondition('field_595cdd3218382', 'produkt_typ', editData.produkt_typ)

        _this.state.formFields.forEach( (field) => {
          // Alle Felder welche als Entscheidungsträger markiert sind, auf Conditions prüfen!
          if(typeof(field.wrapper) !== 'undefined' && field.wrapper.class === 'check_cl_on_startup') {
            //console.debug('handleConditions OF ', field.name);
            let val = editData[field.name] ?? field.default_value;
            _this.formBuilder.current.handleCondition(field.key, field.name, val)
          }
        })

      }).catch(function (err) {
        console.log('Fehler', err);
        //_this.changeProduktValues(key, { app_synced: false, app_sync_error: true }, false)
        //reject(Error('Fehler beim Sync! '+err));
      });

      // Load Product-Data from DB

    }else{
      //console.log('_this.formBuilder', _this.formBuilder);
      _this.setState({loading: false})
      //_this.formBuilder.current.handleCondition('field_595cdd3218382', 'produkt_typ', _this.state.editValues.produkt_typ)
    }
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  /*_onChange = (values) => {
    //console.log('this.formBuilder.current', this.formBuilder.current);
    //console.log('values', this.formBuilder.current.state.values);
  };*/

  _saveFormAndBack = () => {
    let _this = this
    this._saveForm().then(function (r){
      //_this.state.props.history.push('/products/cloud')
      _this.state.props.history.go(-1)
    }).catch(error => {
      console.warn('Error during _saveForm:', error);
    });
  }

  _abortFormAndBack = () => {
    this.state.props.history.go(-1)
    //this.state.props.history.push('/products/cloud')
  }

  /*
   * Bilderupload
   * */

  onFileLoad = (e, file) => console.log(e.target.result, file.name);

  onChange = (pictures) => this.setState({pictures});

  convertImgToBlob(img, callback) {
     var canvas = document.createElement('canvas');
     var context = canvas.getContext('2d');
     context.drawImage(img, 0, 0);

      // Warning: toBlob() isn't supported by every browser.
      // You may want to use blob-util.
     canvas.toBlob(callback, 'image/png');
  }

  setStateFromChildren(state){
    console.log('setStateFromChildren:', state);
    this.setState(...state)
  }


  validateForm() {
    const _this = this
    let errors = this.formBuilder.current.state.errors;
    let errorText = ''

    const formFields = _this.state.formFields

    Object.keys(errors).forEach(function(k){
      let f = errors[k]
      //console.debug('errors.forEach '+k, f);
      if(f.hide){
        // remove hidden fields from validation list
        delete errors[k]
        //console.debug('DELETE '+f.label+' from Validations, coz its hidden!');
      }else{
        let found = false
        // search for label and instructions in formFields for a better validation message
        formFields.forEach(function(ff){
          //console.debug('formFields.forEach', ff);
          if(ff.key === k){
            found = true
            // add error to validation message if its required
            if(ff.required){
              //console.debug('Field '+ff.key+' is required, but missing. Add instructions to validation message!', ff)
              let msg = ff.instructions.length ? ff.instructions : ( errors[ff.key].text.length ? errors[ff.key].text : "Dies ist ein Pflichtfeld!");
              //toValidateLabels[f.key] = {label: f.label, text: msg}
              errorText+= '-'+ff.label+': '+msg+"\n\r"
            }else{
              delete errors[f.key]
            }
          }
        })

        // if field is not in formFields, then add it with default texts
        if(!found){
          //console.debug('Field '+k+' konnte nicht in den formFields gefunden werden!', f)
          errorText+= '-'+f.label+': '+f.text+"\n\r"
        }else{
          //console.debug('Field '+k+' konnte in den formFields gefunden werden!', f)
        }
      }
    })

    if(Object.keys(errors).length > 0){
      //console.warn('errors', errors);
      const msg = 'Bitte überprüfen Sie die rot markierten Felder: \n\n'+errorText
      //alert(msg);
      this.props.enqueueSnackbar(msg, {
        variant: 'error',
        action: <SnackbarClose/>,
        autoHideDuration: 8000,
        style: {whiteSpace: 'pre-wrap', marginTop: 50},
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        },
      });
      return false
    }else{
      return true
    }
  }


  _saveForm = (newValues) => {
    let _this = this

    if(!this.validateForm()){
      return Promise.reject('Bitte überprüfen Sie die rot markierten Felder!')
    }else{
      this.setState({loading: true})

      let values = this.formBuilder.current.state.values;

      //let images = this.formBuilder.current.state.values.images;
      //console.debug('_saveForm.images', images);
      //console.debug('_saveForm.newValues', values)

      //console.log('images', images);
      let key = this.props.match.params.id

      console.log("values", values)

      if(key){
        return API.syncRequest(key, values).then((json) => {
          _this.setState({loading: false})
          //console.debug('Update-Sync für '+key+' Erfolgreich!', json)
        }, (error => {
          alert('Fehler beim Update-Sync! '+error)
        })).catch(function (err) {
          _this.setState({loading: false})
          console.debug('Fehler beim Update-Sync!', err);
          //_this.changeProduktValues(key, { app_synced: false, app_sync_error: true }, false)
          Promise.reject('Fehler beim Update-Sync! '+err);
        });
      }
    }
  };

  render() {
    const {classes} = this.props;

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
          {(!!this.state.loading ?
            <GridContainer style={{paddingTop: 20, height:'100%', minHeight: '500px'}}>
              <div style={{position:'absolute', height:'100%', width:'100%', top:'-10%', justifyContent:'center', display:'flex', alignItems:'center'}}>
                <div style={{textAlign:'center'}}>
                  <div style={{display:'block', clear:'both', color:"#ff412f", marginBottom:10 }}>Wird geladen...</div>
                  <CircularProgress className={classes.progress} style={{color:"#ff412f"}} />
                </div>
              </div>
            </GridContainer> :

            <GridContainer style={{paddingTop: 20}}>
              <GridItem xs={12}>
                <FormBuilder ref={this.formBuilder}
                  classes={classes}
                  defaultImage={defaultImage}
                  fieldContainerStyle={{backgroundColor: '#fefefe'}}
                  //onChange={() => this._onChange(this.state.editValues)}
                  saveForm={this._saveForm}
                  setStateFromChildren={(state) => this.setStateFromChildren(state)}
                  fields={formFields}
                  jsonData={this.state.jsonData}
                  values={this.state.editValues}
                  errors={this.state.validateValues}
                  ai_text_generation_enable={this.state.ai_text_generation_enable}>
                </FormBuilder>
                <br/>
                {process.env.NODE_ENV !== 'production' ?
                  <GridContainer justify="flex-end" style={{paddingTop: 20}}>
                    <GridItem xs={6} sm={4} md={4} dense={1}>
                      <CustomButton icon="save" color="success" onClick={this._saveForm}>Speichern</CustomButton>
                    </GridItem>
                    <GridItem xs={6} sm={4} md={4}>
                      <CustomButton icon="save" color="info" onClick={this._saveFormAndBack}>Speichern & Zurück</CustomButton>
                    </GridItem>
                    <GridItem xs={6} sm={4} md={4} dense={1} style={{textAlign: 'right'}}>
                      <NavLink to="/products/cloud">
                        <CustomButton icon="clear" color="danger">Abbrechen</CustomButton>{/*onClick={this._abortFormAndBack}*/}
                      </NavLink>
                    </GridItem>
                  </GridContainer>
                  :
                  <GridContainer justify="flex-end" style={{paddingTop: 20}}>
                    <GridItem xs={6} sm={6} md={6} dense={1}>
                      <CustomButton icon="save" color="info" onClick={this._saveFormAndBack}>Speichern & Zurück</CustomButton>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6} dense={1} style={{textAlign: 'right'}}>
                      <NavLink to="/products/cloud">
                        <CustomButton icon="clear" color="danger">Abbrechen</CustomButton>{/*onClick={this._abortFormAndBack}*/}
                      </NavLink>
                    </GridItem>
                  </GridContainer>
                }
              </GridItem>
            </GridContainer>
          )}
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withSnackbar(withStyles(combineStyles(styles, productFormStyle))(ProductFormRemote));
