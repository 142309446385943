import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import WifiIcon from '@material-ui/icons/Wifi'; // Connection OK
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff'; // No Connection

// core components
//import HeaderLinks from "./HeaderLinks";
import Button from "components/CustomButtons/Button";

// styles
import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";

// 3.party components
import { Offline, Online } from "react-detect-offline";
import SessionTimer from "../44/SessionTimer";

function Header({ ...props }) {
  function makeBrand() {
    let name = false
    props.routes.map((prop, key) => {
      let routeName = prop.path.replace(/:[\w-]*/, '')
      //console.log('props.routes match: '+props.location.pathname+' => '+(props.location.pathname.indexOf(routeName) > -1), routeName)
      if (!name && props.location.pathname.indexOf(routeName) > -1 ) { // || prop.pathLink === props.location.pathname
        name = prop.navbarName;
      }
      return name
    }
    );
    return name ? name : 'navbarName fehlt';
  }
  const { classes, color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button color="transparent" href="#" className={classes.title}>
            {makeBrand()}
          </Button>
        </div>
        <Online><SessionTimer style={{position:'relative', top: 0}} /></Online>
        <Hidden smDown implementation="css">
          <div className={classes.flex}>
            <div style={{position:'relative', width: 36, right: 0, top: 2}}>
              <Online><WifiIcon/></Online>
              <Offline><SignalWifiOffIcon color="primary"/></Offline>
            </div>
          </div>
          {/*<HeaderLinks />*/}
        </Hidden>
        <Hidden mdUp implementation="css">
          <div className={classes.flex}>
            <div style={{position:'relative', width: 36, right: 0, top: 6}}>
              <Online><WifiIcon /></Online>
              <Offline><SignalWifiOffIcon color="primary" /></Offline>
            </div>
          </div>
          <IconButton
            className={classes.appResponsive}
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(headerStyle)(Header);
