import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "components/Table/Table.jsx";
/*import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";*/

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

// 44 components
import API from 'components/44/ApiPlanungswelten';
import MainContext from 'contexts/MainContext';
import DB from 'components/44/Database';

//import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import RegularButton from "../CustomButtons/Button";

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    marginTop: "0px",
    marginBottom: "0px"
  }
};

class PartnerDaten extends React.Component {

  API_URL = process.env.REACT_APP_API_URL;
  static contextType = MainContext;

  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered

    this.state = {
      props: props,
      token: this.props.token,
      isLoggedIn: this.props.isLoggedIn,
      selectedPartnerData: [],
      partners: [],
      ansprechpartnerData: [["Bitte Partner-Daten neu laden", "0"]],
      herstellerData: [["Bitte Partner-Daten neu laden", "0"]],
      herstellerCategoryData: [["Bitte Partner-Daten neu laden", "0"]]
    };
  }

  componentDidMount(){
    const selectedPartnerData = DB.selectedPartnerData()
    this.parseData('selectedPartnerData', selectedPartnerData)
    this.parseData('partners')
    this.parseData('ansprechpartnerData')
    this.parseData('herstellerData')
    this.parseData('herstellerCategoryData')

    //const context = this.context;
    //console.debug('context', context)
    //context.setPartnerData(this.state.selectedPartnerData)
    //context.setPartner(this.state.selectedPartnerData)
    //It will get the data from context, and put it into the state.
    //this.setState({ selectedPartnerData: context.selectedPartnerData });
  }

  parseData(datakey, parsedData=false){
    if(!parsedData){
      if(typeof(localStorage[datakey])==='undefined')
        return false

      parsedData = JSON.parse(localStorage[datakey]) || []
      //console.debug('parsedData', parsedData)
    }

    parsedData = Object.keys(parsedData).map(function (item, i) {
      if(typeof parsedData[item] === 'object' ){
        // Wenn Inhalt wieder ein Object ist, dann den ersten Key (z.B. 'name') suchen und nur Inhalt von erstem Key zurück geben
        let ii = parsedData[item]
        let key = Object.keys(ii)[0]
        parsedData[item] = ii[key]
      }

      return [item, (parsedData[item] || '').toString()];
    }) || {}

    //console.debug(datakey+' has typeof: '+typeof(parsedData), parsedData)

    this.setState({[datakey]: parsedData})
  }

  /*prepareData(data){
    console.log('data', data);
    //return data;
    if(typeof data === 'object'){
      console.log('is OBJECT', Object.keys(data))
      let key = Object.keys(data)[0]

      let preparedData = Object.keys(data).map(function (item, i) {
                      return [i, item[key]];
                    }) || {}

      return preparedData

    }else if(typeof data === 'array'){
      console.log('is ARRAY')
      data.forEach(function (row) {
        row.forEach(function (item) {
          if(typeof item === 'object'){
            let key = Object.keys(item)[0]

            let preparedData = Object.keys(item).map(function (iitem, i) {
              return iitem[key];
            }) || {}

            return preparedData
          }
        })
      })
      return data
    }
    return data
  }*/

  /*
  partnerDatenZiehen() {
    API.getAnsprechpartnerAndSave()
    API.getHerstellerAndSave()
  }
  */

  async handlePartnerDatenNeuLaden() {
    API.getAnsprechpartnerAndSave().then(()=>{
      this.parseData('ansprechpartnerData')
    })
    API.getHerstellerAndSave().then(()=>{
      this.parseData('herstellerData')
    })
    API.getHerstellerCategoryAndSave().then(()=>{
      this.parseData('herstellerCategoryData')
    })
  }

  async handlePartnerGrunddatenNeuLaden() {
    API.getPartnerDatenAndSave().then(()=>{
      const selectedPartnerData = DB.selectedPartnerData()
      setTimeout(function (){
        this.parseData('selectedPartnerData', selectedPartnerData)
      }, 100);
      this.parseData('partners')
    })
  }


  render() {
    const { classes } = this.props;
    //const context = this.context;

    //console.debug('context', context)
    //console.log('new2 selectedPartnerData', this.state.selectedPartnerData)
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            {API.userIsAdmin() ? (
            <GridItem xs={12} sm={12} md={12}>
              <RegularButton color="success" onClick={() => this.state.props.history.push('/partnerEditRemote/')}>Partner-Daten bearbeiten</RegularButton>
              {/* <RegularButton onClick={() => this.handlePartnerDatenNeuLaden()}>Ansprechpartner-/Hersteller-/Kategoie-Daten neu laden</RegularButton>
              <RegularButton onClick={() => this.handlePartnerGrunddatenNeuLaden()}>Partner-Grunddaten neu laden</RegularButton>
              <div>{ context.selectedPartnerData }</div>*/}
              <br/>
              <br/>
            </GridItem>) : ''}

            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Ansprechpartner</h4>
                  <p className={classes.cardCategoryWhite}>
                    Ihnen fehlt ein Ansprechpartner in der Liste? Melden Sie sich bei uns!
                  </p>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["ID", "Wert"]}
                    tableData={this.state.ansprechpartnerData}
                  />
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>ausgewählter Standort/Partner</h4>
                  <p className={classes.cardCategoryWhite}>
                    Diese Ansicht dient zur Kontrolle der geladenen Daten. Sie haben einen Fehler entdeckt? Melden Sie ihn uns!
                  </p>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["Key", "Wert"]}
                    tableData={this.state.selectedPartnerData}
                  />
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Hersteller (Lieferprogramm)</h4>
                  <p className={classes.cardCategoryWhite}>
                    Ihnen fehlt ein Hersteller in der Liste? Melden Sie sich bei uns!
                  </p>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["ID", "Wert"]}
                    tableData={this.state.herstellerData}
                  />
                </CardBody>
              </Card>
            </GridItem>

            {/*<GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Alle Partner (Filialen)</h4>
                  <p className={classes.cardCategoryWhite}>
                    Diese Ansicht dient zur Kontrolle der geladenen Daten. Sie haben einen Fehler entdeckt? Melden Sie ihn uns!
                  </p>
                </CardHeader>
                <CardBody>
                  {this.state.partners.map((prop, key) => {
                    console.log('key', key)
                    let partner = JSON.parse(prop[1])
                    console.log('prop', partner)
                    return (
                    <Table
                      tableHeaderColor="primary"
                      tableHead={["ID", "Wert"]}
                      tableData={[key, partner]}
                    />
                  )
                  })}
                </CardBody>
              </Card>
            </GridItem>*/}

            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Hersteller (Alle, nach Kategorie filterbar)</h4>
                  <p className={classes.cardCategoryWhite}>
                    Ihnen fehlt ein Hersteller in der Liste? Melden Sie sich bei uns!
                  </p>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["ID", "Wert"]}
                    tableData={this.state.herstellerCategoryData}
                  />
                </CardBody>
              </Card>
            </GridItem>

          </GridContainer>
        </div>
      </div>
    );
  }
}

PartnerDaten.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(PartnerDaten);
