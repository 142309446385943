import React from "react";
//import ReactDOM from 'react-dom';
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';

// @material-ui/icons

// TODO: eigene Styles für Accordion importen
//import accordionStyle from "assets/jss/material-dashboard-pro-react/components/accordionStyle.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomButton from "components/CustomButtons/Button.jsx";
//import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

//import Form from 'material-ui-form-builder';
import FormBuilder from 'components/44/formbuilder';

// 44 components
import DB from 'components/44/Database';
import API from 'components/44/ApiPlanungswelten';
import { theme } from "components/44/muiTheme"
import combineStyles from 'components/44/combineStyles';

import defaultImage from "assets/img/default-picture.png";

// import Styles
import productFormStyle from "assets/jss/44/productFormStyle.jsx";
import Close from "@material-ui/icons/Close";
import {withSnackbar} from "notistack";

const formData = require("../../json/partner.json");
const formDataWpFields = require("../../json/partner_wp-fields.json");
const formFields = [ ...formDataWpFields.fields, ...formData.fields ];

const ansprechpartnerFallback = require("../../json/ansprechpartner.json");
const herstellerFallback = require("../../json/hersteller_production.json");
const kategorienFallback = process.env.NODE_ENV === 'production' ? require("../../json/produkt_kategorien_production.json") : require("../../json/produkt_kategorien.json");


const styles = theme => ({
  root: {
    width: '100%',
    fontFamily: 'Lato'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  textfieldOutlined: {
    flexBasis: 280,
  },
  zeilen: {
    marginTop: 0,
    marginBottom: 15,
    fontWeight: 500,
    display: 'inline-block',
    float: 'left'
  },
  zeilenSwitch: {
    marginTop: -10,
    marginBottom: 10,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  selectTreeItem: {
    padding: '4px 10px',
    borderBottom: '1px solid #dadada',
    fontWeight: 300
  }
});

class PartnerFormRemote extends React.Component {

  constructor(props) {
    super(props);

    const selectedPartnerData = DB.selectedPartnerData()
    console.debug('selectedPartnerData', selectedPartnerData)

    this.state = {
      props: props,
      editValues: {
        app_synced: false,
        post_id: false,
        post_status: 'publish',
        partner: selectedPartnerData.partner_id,
        zahlungsbedingungen: selectedPartnerData.partner_zahlungsbedingungen || '',
        lieferbedingungen: selectedPartnerData.partner_lieferbedingungen || '',
        finanzierung: selectedPartnerData.partner_finanzierung || '',
        strasse: selectedPartnerData.partner_street || '',
        plz: selectedPartnerData.partner_zip || '',
        ort: selectedPartnerData.partner_city || '',
        land: selectedPartnerData.partner_country || '',
        cat_level1: 0,
        cat_level2: 0,
        cat_level3: 0
      },
      jsonData: {
        "ansprechpartner": ansprechpartnerFallback,
        "partner_manufacturer_selection": herstellerFallback,
        "produkt_kategorien": kategorienFallback
      },
      pictures: {},
      expanded: null,
      loading: true
    };
    this.formBuilder = React.createRef();
    this.pictureUpload = React.createRef();
  }

  componentDidMount(){
    this.setState({ db: DB })
    let _this = this

    /* JSON-Data (ua. Kategorien, Ansprechpartner, Hersteller-Daten usw) neu laden */
    let kategorien = typeof(localStorage['kategorienData']) !== 'undefined' ? JSON.parse(localStorage['kategorienData']) : kategorienFallback;
    let ansprechpartner = typeof(localStorage['ansprechpartnerData']) !== 'undefined' ? JSON.parse(localStorage['ansprechpartnerData']) : ansprechpartnerFallback;
    let herstellerLieferprogramm = typeof(localStorage['herstellerData']) !== 'undefined' ? JSON.parse(localStorage['herstellerData']) : herstellerFallback;
    let herstellerCategory = typeof(localStorage['herstellerCategoryData']) !== 'undefined' ? JSON.parse(localStorage['herstellerCategoryData']) : herstellerFallback;

    let jsonData = {
      "ansprechpartner": ansprechpartner,
      "partner_manufacturer_selection": DB.checkPartnerData('hersteller_lieferprogramm', false) ? herstellerLieferprogramm : herstellerCategory,
      "produkt_kategorien": kategorien
    }

    this.setState({ jsonData: jsonData })

    //if(this.props.match.params.id){
      //console.log('E D I T   Partner REMOTE');
      //console.log('this.state.jsonData', this.state.jsonData);

      API.getRemotePartner().then((json) => {

        json = this.decryptPasswords(json)

        // remove this hidden selection, coz otherwise post data to API is too long AND actually form builder can not handle multi select!
        delete json['partner_manufacturer_selection']

        //console.debug('Get RemotePartner', json)

        const editData = json
        //console.debug('set editValues', editData)
        _this.setState({editValues: editData})
        _this.setState({loading: false})
        _this.formBuilder.current.setState({values: editData})

        // check conditional_logic on startup
        formData.fields.forEach(function(field, key) {
          if(field.type==='true_false'){
            _this.formBuilder.current.handleCondition(field.key, field.name, editData[field.name])
          }
        });

        _this.props.enqueueSnackbar('Partner-Daten erfolgreich geladen!', { variant: 'success', autoHideDuration: 3000})

      }, (error) => {
        _this.props.enqueueSnackbar('Beim Laden der Partner-Daten ist ein Fehler aufgetreten!', { variant: 'error', action: <Close/>, persist: true})
        console.log('Fehler', error);
      });
  }


  decryptPasswords(json){
    // Decrypt passwords

    if(json.accounts_ebay_kleinanzeigen){
      json.accounts_ebay_kleinanzeigen.forEach(function(account, index, params) {
        if(account.passwort.length > 80){
          //console.log('decrypt passwort ebaykl bevor: ', account.passwort);
          let passwort_encrypt = API.decrypt(account.passwort, 'H1dd3nPa$§w0rt:')
          json.accounts_ebay_kleinanzeigen[index].passwort = passwort_encrypt
          //console.log('decrypt passwort ebaykl after: ', passwort_encrypt);
        }else{
          console.log('ATTATION! decrypt not needed, passwort is plain!')
        }
      })
    }

    if(json.accounts_marktde){
      json.accounts_marktde.forEach(function(account, index, params) {
        if(account.passwort.length > 80) {
          let passwort_encrypt = API.decrypt(account.passwort, 'H1dd3nPa$§w0rt:')
          json.accounts_marktde[index].passwort = passwort_encrypt
          //console.log('decrypt passwort marktde: '+account.passwort, passwort_encrypt);
        }else{
          console.log('ATTATION! decrypt not needed, passwort is plain!')
        }
      })
    }

    return json;
  }


  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  /*_onChange = (values) => {
    //console.log('this.formBuilder.current', this.formBuilder.current);
    //console.log('values', this.formBuilder.current.state.values);
  };*/

  _saveFormAndBack = () => {
    let _this = this
    this._saveForm().then(function (r){
      _this.state.props.history.push('/partnerdaten')
    })
  }

  _abortFormAndBack = () => {
    //this.state.props.history.back
    this.state.props.history.push('/partnerdaten')
  }

  /*
   * Bilderupload
   * */

  onFileLoad = (e, file) => console.log(e.target.result, file.name);

  onChange = (pictures) => this.setState({pictures});

  convertImgToBlob(img, callback) {
     const canvas = document.createElement('canvas');
     let context = canvas.getContext('2d');
     context.drawImage(img, 0, 0);

      // Warning: toBlob() isn't supported by every browser.
      // You may want to use blob-util.
     canvas.toBlob(callback, 'image/png');
  }

  setStateFromChildren(state){
    console.log('setStateFromChildren:', state);
    this.setState(...state)
  }

  _saveForm = (newValues) => {
    let _this = this
    this.setState({loading: true})

    let values = this.formBuilder.current.state.values;
    //let values = newValues || this.state.editValues

    let images = this.formBuilder.current.state.values.images;
    console.log('_saveForm.images', images);

    console.log('_saveForm.newValues', values)

    //console.log('images', images);
    let key = API.getSelectedPartnerApiKey()

    if(key){
      return API.updatePartner(values).then((json) => {
        _this.setState({loading: false})

        json = this.decryptPasswords(json)

        const editData = json
        console.log('set editValues', editData)
        _this.setState({editValues: editData})

        console.log('Partner-Update für '+key+' Erfolgreich!', json)
        API.getPartnerDatenAndSave()
      }).catch(function (err) {
        _this.setState({loading: false})
        console.log('Fehler beim Partner-Update!', err);
        //_this.changeProduktValues(key, { app_synced: false, app_sync_error: true }, false)
        Promise.reject('Fehler beim Partner-Update! '+err);
      });
    }else{
      console.log('key fehlt')
    }
  };

  render() {
    const {classes} = this.props;
    //console.log('classes', classes);

    // for expansionPanel - which Panel is currently open
    //const expanded = this.state.expanded || false;
    //let loading = this.state.loading;

    // TODO: eigene Styles für Accordion importen; style-Tag entfernen
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
          {(!!this.state.loading ?
            <GridContainer style={{paddingTop: 20, height:'100%', minHeight: '500px'}}>
              <div style={{position:'absolute', height:'100%', width:'100%', top:'-10%', justifyContent:'center', display:'flex', alignItems:'center'}}>
                <div style={{textAlign:'center'}}>
                  <div style={{display:'block', clear:'both', color:"#ff412f", marginBottom:10 }}>Wird geladen...</div>
                  <CircularProgress className={classes.progress} style={{color:"#ff412f"}} />
                </div>
              </div>
            </GridContainer> :

            <GridContainer style={{paddingTop: 20}}>
              <GridItem xs={12}>
                <FormBuilder ref={this.formBuilder}
                  classes={classes}
                  defaultImage={defaultImage}
                  fieldContainerStyle={{backgroundColor: '#fefefe'}}
                  //onChange={() => this._onChange(this.state.editValues)}
                  saveForm={this._saveForm}
                  setStateFromChildren={(state) => this.setStateFromChildren(state)}
                  fields={formFields}
                  jsonData={this.state.jsonData}
                  values={this.state.editValues}
                  errors={{Preis: 'This field is required.'}}>
                </FormBuilder>
                <br/>
                {process.env.NODE_ENV !== 'production' ?
                  <GridContainer justify="flex-end" style={{paddingTop: 20}}>
                    <GridItem xs={6} sm={4} md={4} dense={1}>
                      <CustomButton icon="save" color="success" onClick={this._saveForm}>Speichern</CustomButton>
                    </GridItem>
                    <GridItem xs={6} sm={4} md={4}>
                      <CustomButton icon="save" color="info" onClick={this._saveFormAndBack}>Speichern & Zurück</CustomButton>
                    </GridItem>
                    <GridItem xs={6} sm={4} md={4} dense={1} style={{textAlign: 'right'}}>
                      <NavLink to="/partnerdaten">
                        <CustomButton icon="clear" color="danger">Abbrechen</CustomButton>{/*onClick={this._abortFormAndBack}*/}
                      </NavLink>
                    </GridItem>
                  </GridContainer>
                  :
                  <GridContainer justify="flex-end" style={{paddingTop: 20}}>
                    <GridItem xs={6} sm={6} md={6} dense={1}>
                      <CustomButton icon="save" color="info" onClick={this._saveFormAndBack}>Speichern & Zurück</CustomButton>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6} dense={1} style={{textAlign: 'right'}}>
                      <NavLink to="/partnerdaten">
                        <CustomButton icon="clear" color="danger">Abbrechen</CustomButton>{/*onClick={this._abortFormAndBack}*/}
                      </NavLink>
                    </GridItem>
                  </GridContainer>
                }
              </GridItem>
            </GridContainer>
          )}
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withSnackbar(withStyles(combineStyles(styles, productFormStyle))(PartnerFormRemote));
