import React from "react";
import Close from "@material-ui/icons/Close";

const snackbarCloseStyle = {
  position: "absolute",
  marginTop: 5,
  right: 10,
  bottom: 10
}

export default function(){ return(<Close className="snackClose" style={snackbarCloseStyle}/>) }
