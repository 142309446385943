// Für Material-UI < 1.4
import getMuiTheme from 'material-ui/styles/getMuiTheme';
// Für Material-UI > 1.4
import { createMuiTheme } from "@material-ui/core/styles/index";

const theme = createMuiTheme({
  typography: {
    // fallback, coz in newer version of material-ui are more variant's
    useNextVariants: true,
    // Use the system font instead of the default Lato font.
    fontFamily: 'Lato',
    fontWeightMedium: 500,
    body1: {
      fontWeight: 500,
    },
    subheading: {
      fontSize: 14,
    },
    button: {
      textTransform: 'none !important'
    },
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        fontWeight: 300,
      },
    },
    MuiTypography: {
      root: {
        fontFamily: 'Lato',
        fontWeight: 400, /* 400 700 */
      },
      body1: {
        fontFamily: 'Lato',
        fontWeight: 400, /* 400 700 */
      },
      subheading: {
        //color: grey[100],
        fontFamily: 'Lato',
        fontWeight: 400, /* 400 700 */
      },
      headline: {
        fontFamily: 'Poppins',
        fontWeight: 400, /* 300 400 700 900 */
      },
      title: {
        fontFamily: 'Poppins',
        fontWeight: 400,
      }
    },
    MuiList: {
      root: {
        padding: '10px 0'
      }
    },
    MuiListItem: {
      gutters: {
        padding: 3
      },
    },
  },
  palette: {
    // Sellanizer Farben
    primary: {
      light: '#ff412f',
      main: '#e5362e', /* alter: #e6301f */
      dark: '#c32213',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#6dff73',
      main: '#4caf50',
      dark: '#37783b',
      contrastText: '#000000',
    },
    secondaryGray: {
      light: '#b7b7b7',
      main: '#898989',
      dark: '#717171',
      contrastText: '#ffffff',
    }
  },
  menuItem: {
    '&:focus': {
      backgroundColor: 'blue',
      '& $primary, & $icon': {
        color: 'white' //this.palette.primary.contrastText,
      },
    },
  },
});

const muiTheme1 = getMuiTheme({
  typography: {
    // fallback, coz in newer version of material-ui are more variant's
    useNextVariants: true,
  },
  //fontFamily: 'Roboto, sans-serif',
  fontFamily: '"Lato", sans-serif',
  fontWeight: 300,
  menuItem: {
      '&:focus': {
        backgroundColor: 'blue', //muiTheme14.palette.primary.main,
        '& $primary, & $icon': {
          color: 'white' //muiTheme14.palette.primary.contrastText,
        },
      },
    },
  palette: {
    // Sellanizer Farben

    // Für Material-UI 0.2
    colorPrimary: '#ff412f',
    primary1Color: '#ff412f',
    primary2Color: '#ff412f',
    primary3Color: '#ff412f',

    accent1Color: '#e5362e',
    accent2Color: '#e5362e',
    accent3Color: '#e5362e',
    textColor: '#000000',
    //alternateTextColor: white,
    //canvasColor: white,
    //borderColor: grey300,
    //disabledColor: fade(darkBlack, 0.3),
    //pickerHeaderColor: cyan500,
    //clockCircleColor: fade(darkBlack, 0.07),
    //shadowColor: fullBlack,

    // Für Material-UI 1.4

    primary: {
      colorPrimary: '#ff412f',
      primary1Color: '#ff412f',
      light: '#ff412f',
      main: '#e5362e', /* alter: #e6301f */
      main_old: '#e6301f',
      dark: '#c32213',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#e8e8e8',
      contrastText: '#000000',
    }
  },
});

export { muiTheme1, theme }