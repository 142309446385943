import React from 'react';
//import Button from "../../components/CustomButtons/Button";
import Button from "@material-ui/core/Button";
import API from 'components/44/ApiPlanungswelten';
import Txt from 'variables/texts';
import { withSnackbar } from 'notistack';
import SnackbarClose from "../Snackbar/SnackbarClose";

class OpenAiTextgenerator44 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      description: ''
    }

    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleFeatureEnableMessage = this.handleFeatureEnableMessage.bind(this);
  }

  infoMessage(msg = 'bla') {
    this.props.enqueueSnackbar(msg, {
      variant: 'info',
      action: <SnackbarClose/>,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    });
  }

  handleFeatureEnableMessage() {
    this.infoMessage(Txt.call_sales);
  }

  handleDescriptionChange(){
    let data = this.props.data;

    const zuLoeschendeKeys = ['images', '*energiepass'];

    // Funktion zum Überprüfen, ob ein Schlüssel gelöscht werden sollte
    const shouldDeleteKey = (key) => {
      return zuLoeschendeKeys.some(pattern => {
        if (pattern.startsWith('*') && pattern.endsWith('*')) {
          return key.includes(pattern.substring(1, pattern.length - 1));
        } else if (pattern.startsWith('*')) {
          return key.endsWith(pattern.substring(1));
        } else if (pattern.endsWith('*')) {
          return key.startsWith(pattern.substring(0, pattern.length - 1));
        } else {
          return key === pattern;
        }
      });
    };

    // Erstellen eines neuen bereinigten Datenobjekts
    const bereinigtesData = {};
    for (const key in data) {
      if (!shouldDeleteKey(key)) {
        if(key === 'material_auswahl'){
          console.debug('material_auswahl', data[key])
          console.debug('material_auswahl: typeof: ', typeof data[key])
          console.debug('material_auswahl', Object.keys(data[key])[0])
        }
        /*
         * Wert umformen, weil manche Keys wie material_auswahl liefern ein object mit einem Wert
         * wie z.B.: data[materialauswahl][Aluminium]=true
         */
        if(typeof data[key] === 'object') {
          bereinigtesData[key] = Object.keys(data[key])[0] || ''
        }else{
          bereinigtesData[key] = data[key];
        }
      }
    }

    data = bereinigtesData;

    // console.log('handleDescriptionChange: props.data', data);

    const generateDescription = async () => {

      try {
        const response = await API.generateAiText(data);
        //console.log('response', response);
        this.setDescription(response);

      } catch (error) {
        console.error('Fehler beim Generieren der Beschreibung:', error);
        this.setDescription('Fehler beim Generieren der Beschreibung!');
      }
    };

    generateDescription();
  }

  setDescription(text){
    this.setState({ description: text });
    if (this.props.onChange) {
      this.props.onChange(text);
    }
  }

  render() {
    const { variant, style, children } = this.props;
    const ai_text_generation_enable = this.props.ai_text_generation_enable

    const text = ai_text_generation_enable ? 'Beachten Sie, dass das Ergebnis umso besser wird, je mehr Attribute (Kategorie, Name, Hersteller, Modell, Art, Extras, Farbe, Maße, Material) gefüllt sind.' : 'Dieses Feature ist für ihren Account momentan nicht freigeschaltet.'

    return (
      <div>
        <Button variant={variant} style={style} onClick={ai_text_generation_enable ? this.handleDescriptionChange : this.handleFeatureEnableMessage}>{children}</Button>
        <small style={{paddingLeft:10, marginTop: 6, position:'absolute'}}>{text}</small>
      </div>
    );
  }

}

export default withSnackbar(OpenAiTextgenerator44);
