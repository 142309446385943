import React from 'react';

const setSidebar = (props) => {
  //this.setState({ sidebar: props });
};

const defaultVal = {
  setSidebar: setSidebar, dashboardRoutes: {}, logoText: '', logo: null, image: null, partnerLogo: '', partnerName: '', handleDrawerToggle: null, mobileOpen: null
} //Insert the default value here.

const SidebarContext = React.createContext(defaultVal);

export default SidebarContext;
