import React, {Fragment} from 'react';
import 'babel-polyfill'; // Wird für älterer Browser wie IE verwendet um fehlende Libs nachzurüsten
import {Router, Switch} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import { createBrowserHistory } from "history";
import {SnackbarProvider} from "notistack";
import Session from "./components/44/Session";
import LogoutPage from "./components/44/LogoutPage";
import indexRoutes from "./routes";
import PropsRoute from "./components/44/PropsRoute";
import LoginPage from "./components/44/LoginPage";
import Loading from "@material-ui/icons/Cached";
import Info from "@material-ui/icons/Info";
import Warning from "@material-ui/icons/Warning";
import Error from "@material-ui/icons/Error";
import Success from "@material-ui/icons/DoneAll";
// import { debug } from "helpers/44Helper";

import snackbarNotistackStyle from "assets/jss/material-dashboard-pro-react/components/snackbarNotistackStyle.jsx";

import MainContext from 'contexts/MainContext';
import ErrorBoundary from "./components/ErrorBoundary";
// Airbrake Error-Log Example: window.onerror("TestError: This is a test", "path/to/file.js", 123);

const hist = createBrowserHistory();
//const sidebarContext=React.createContext();

class App extends React.Component {
  //sidebarContext=React.createContext();

  state = {
    partnerData: {
      initialdata: 'empty'
    },
    setPartnerData: (data) => {
      this.setState({ data });
    },
    setPartner: (data) => {
      this.setState({ data });
    }
  }

  setPartnerData = (data) => {
    this.setState({ data });
  }

  render() {
    const { classes } = this.props;
    //const sidebarContext=React.createContext();
    const sidebarContext={};

    //console.debug('this.state', this.state)

    return (
      <ErrorBoundary>
        <Router history={hist}>
          <Switch>
            <Fragment>
              <MainContext.Provider value={this.state}>
                <SnackbarProvider maxSnack={10}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  iconVariant={{
                    success: <Success className={classes.customIcon}/>,
                    error: <Error className={classes.customIcon}/>,
                    warning: <Warning className={classes.customIcon}/>, //'⚠️',
                    info: <Info className={classes.customIcon}/>,
                    default: <Loading className={classes.customIcon +' '+ classes.spin}/> // default = loading
                  }}
                  classes={{
                    root: classes.root,
                    variantSuccess: classes.success,
                    variantError: classes.error,
                    variantWarning: classes.warning,
                    variantInfo: classes.info,
                  }}
                >
                  <Session>
                    {
                      ({ isLoggedIn, token, handleSetState, isMobile }) => {
                        return (
                          isLoggedIn && token ?
                            hist.location.pathname === '/logout' ?
                              <LogoutPage isLoggedIn={isLoggedIn} token={token} isMobile={isMobile} handleSetState={handleSetState} history={hist} /> :
                              (indexRoutes.map((prop, key) => {
                                return <PropsRoute path={prop.path}
                                                   component={prop.component} key={key} isLoggedIn={isLoggedIn} isMobile={isMobile} token={token} handleSetState={handleSetState} history={hist} sidebarContext={sidebarContext} />
                              })) : <LoginPage isLoggedIn={isLoggedIn} token={token} isMobile={isMobile} handleSetState={handleSetState} />
                        )
                      }
                    }
                  </Session>
                </SnackbarProvider>
              </MainContext.Provider>
            </Fragment>
          </Switch>
        </Router>
      </ErrorBoundary>
    );
  }
}

export default withStyles(snackbarNotistackStyle)(App);
