import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "components/Table/Table.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

// 44 components

let pjson = require("./../../../package.json")

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    marginTop: "0px",
    marginBottom: "0px"
  }
};

class Versionen extends React.Component {

  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered

    this.state = {
      versionen: []
    };

  }

  componentDidMount(){
    let versionen = []
    for(let i in pjson.changeslog)
        versionen.push([i, pjson.changeslog[i]]);

    //console.log('versionen', versionen)
    this.setState({versionen: versionen})
  }

  parseData(data){
    if(typeof(localStorage[data])==='undefined')
      return false

    let parsedData = JSON.parse(localStorage[data]) || []
    console.log('parsedData', parsedData)

    parsedData = Object.keys(parsedData).map(function (item, i) {
                return [item, parsedData[item].toString()];
              }) || {}

    this.setState({[data]: parsedData})
  }


  render() {
    const { classes } = this.props;
    //console.log('new2 partnerData', this.state.partnerData)
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">

            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Changeslog</h4>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["Version", "Beschreibung"]}
                    tableData={this.state.versionen}
                  />
                </CardBody>
              </Card>
            </GridItem>

          </GridContainer>
        </div>
      </div>
    );
  }
}

Versionen.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(Versionen);
