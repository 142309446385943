import obj2fd from "obj2fd/es5";
import DB from 'components/44/Database';

//let pjson = require("./../../../package.json")
let CryptoJS = require("crypto-js")

let CryptoJSAesJson = {
  stringify: function (cipherParams) {
      var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
      if (cipherParams.iv) j.iv = cipherParams.iv.toString();
      if (cipherParams.salt) j.s = cipherParams.salt.toString();
      return JSON.stringify(j);
  },
  parse: function (jsonStr) {
      var j = JSON.parse(jsonStr);
      var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
      if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
      if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
      return cipherParams;
  }
}

class ApiPlanungswelten {

  API_URL = process.env.REACT_APP_API_URL;
  AUTH_URL = process.env.REACT_APP_API_AUTH_URL;
  BASIC_AUTH = process.env.REACT_APP_BASIC_AUTH || false;
  DEBUG = process.env.REACT_APP_API_DEBUG && (process.env.REACT_APP_API_DEBUG==='true' || process.env.REACT_APP_API_DEBUG==='1') ? 1 : 0;


  // It's only working on Desktop
  sendBrowserNotification(text = 'Willkommen beim Sellanizer!', img = '/img/Icon-144.png', intervalMs = 2000){
    if (window.Notification && Notification.permission === "granted") {
      //new Notification("22222", { body: text, icon: img });

      // Using an interval cause some browsers (including Firefox) are blocking notifications if there are too much in a certain time.
      var interval = window.setInterval(function () {
        new Notification(text, {tag: Date.now(), icon: img});
        window.clearInterval(interval);
      }, intervalMs);

    }

    // If the user hasn't told if he wants to be notified or not
    // Note: because of Chrome, we are not sure the permission property
    // is set, therefore it's unsafe to check for the "default" value.
    else if (window.Notification && Notification.permission !== "denied") {
      console.log('Notification.permission is DENIED');
      Notification.requestPermission(function (status) {
        // If the user said okay
        if (status === "granted") {
          var interval = window.setInterval(function () {
            new Notification(text, {tag: Date.now(), icon: img});
            window.clearInterval(interval);
          }, intervalMs);
        }

        // Otherwise, we can fallback to a regular modal alert
        else {
          alert(text);
        }
      });
    }

    // If the user refuses to get notified
    else {
      // We can fallback to a regular modal alert
      alert(text);
    }
  }

  getUserRole(user_role_encrypt = false, user_id = false){
    let user_role = 'none'

    if(user_role_encrypt && user_id){
      user_role = JSON.parse(CryptoJS.AES.decrypt(localStorage.user_role_encrypt, "user_id#"+user_id, {format: CryptoJSAesJson}).toString(CryptoJS.enc.Utf8));
    }else if(localStorage.user_role && !localStorage.user_role!=='false' && localStorage.user_id){
      let user_id = JSON.parse(localStorage.user_id);
      user_role = JSON.parse(CryptoJS.AES.decrypt(localStorage.user_role, "user_id#"+user_id, {format: CryptoJSAesJson}).toString(CryptoJS.enc.Utf8));
    }

    return user_role
  }

  userIsAdmin(){
    return this.getUserRole() === 'sellanizer_admin'
  }

  userHasGenerateAiTexts() {
    let user_id = localStorage.user_id;
    let user_has_generate_ai_texts = JSON.parse(CryptoJS.AES.decrypt(localStorage.user_has_generate_ai_texts, "user_id#"+user_id, {format: CryptoJSAesJson}).toString(CryptoJS.enc.Utf8));
    return user_has_generate_ai_texts==='true' || user_has_generate_ai_texts===true ? true : false
  }

  userIs44OrT4M(){
    /*
      Sellanizer-Admin=80, Alex-Schilling=44, Mathias=45, Roman=69, Noah=971,972, Dennis=969,909, Lukas=924
      Sellanizer-Muster=164, Lena Pauken=977
     */
    const whitelist_user_ids = [80, 44, 45, 69, 164, 971, 972, 969, 909, 924, 977]
    const user_id = parseInt(localStorage.user_id, 10);

    if (!isNaN(user_id) && whitelist_user_ids.includes(user_id)) {
      return true;
    }else{
      return false;
    }
  }

  async insert_update(key, values){
    let _this = this
    let route = 'sellanizer/produkte/'
    let url = _this.API_URL + route
    let headersData = new Headers()

    if (_this.BASIC_AUTH){
      headersData.set('Authorization', 'Basic ' + Buffer.from(_this.BASIC_AUTH).toString('base64'));
    }

    // Not working/not allowed: Set Sellanizer as additional User-Agent
    //headersData.set('User-Agent', 'Sellanizer v'+pjson.version + '/' + navigator.appCodeName+'/'+navigator.appVersion);

    let params = { ...{ debug: this.DEBUG, user_id: localStorage.user_id, api_key: this.getSelectedPartnerApiKey() }, ...values }

    let bodyData = obj2fd(params);

    await fetch(url, {
      method: "POST",
      headers: headersData,
      body: bodyData,
      //mode: "no-cors",
      cache: "no-cache",
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer"
    })
    /*.then(function(response) {
      try {
        //out = response.json()
        //let json = JSON.parse(response);
        return true
      }catch (e) {
        console.log('No JSON valid response:', response)
        // throw ['No JSON valid response', response]
      }
    })*/
    .then(function(params) {
      console.log('SYNC-Response:', params);
      _this.changeProduktValue(key, 'app_synced', true, false)
      _this.changeProduktValue(key, 'post_id', params.post_id, false)
    }, (error) => {
      console.log('Error SYNC-Response', error)
    })
    .catch((e) => {
      _this.changeProduktValue(key, 'app_synced', true, false)
      _this.changeProduktValue(key, 'app_sync_error', true, false)
      console.log('Error SYNC-Response', e)
    });
  }

  apiFetchSmall(url = '', options ={}){
    return fetch(url, options)
    /*.then(function(response) {
      try {
        //out = response.json()
        //let json = JSON.parse(response);
        return true
      }catch (e) {
        console.log('No JSON valid response:', response)
        // throw ['No JSON valid response', response]
      }
    })*/
    .then(function(params) {
      console.debug('SYNC-Response:', params);
    })
    .catch((e) => {
      console.debug('Error SYNC-Response', e)
    });
  }

  async apiFetch(url = '', options = {}){
    return new Promise((resolve, reject) => {
      const token = localStorage.token
      const withoutAuth = options.withoutAuth || false

      // delete internal options from fetch options
      delete options.withoutAuth

      let headersData = new Headers()

      // Not working/not allowed: Set Sellanizer as additional User-Agent
      //headersData.set('User-Agent', 'Sellanizer v'+pjson.version + '/' + navigator.appCodeName+'/'+navigator.appVersion);

      if (this.BASIC_AUTH){
        console.debug('Basic-Auth activated', Buffer.from(this.BASIC_AUTH).toString('base64'));
        headersData.set('Authorization', 'Basic ' + Buffer.from(this.BASIC_AUTH).toString('base64'));
        options.headers = headersData;
      }

      // default is with Bearer-Token (=withoutAuth===false)
      if(withoutAuth===false){
        if(typeof token !== 'undefined' && token.length > 30 && this.getUserRole() !== 'none'){
          // console.debug('Bearer-Auth aktivated', token);
          headersData.set('Authorization', 'Bearer ' + token);
          options.headers = headersData;

        }else{
          // redirect zum Login ??
          console.debug('Token undefined - REDIRECT to login (1)')
          window.location.assign('/logout')
        }
      }else{
        // exception request without Bearer-Token
      }

      //console.debug('options', options);

      fetch(url, options)
      .then(response => {
        console.debug('Response:', response);
        let errorMessage = false;

        // OK / 200
        if(response.status && response.status === 200) {
          return response.json().catch(error => {
            // return Promise.reject('Invalid JSON: ' + error.message);
            reject('Invalid JSON: ' + error.message);
          })

        // Authorisation / 403
        }else if(response.status && response.status === 403){
          // only on Login
          errorMessage = 'Bitte überprüfen Sie Ihre eingegebenen Zugangsdaten!'
          reject(errorMessage);

        // All other Errors, e.g. 400 or 5xx
        }else{
          response.json().then(error => {
            //return Promise.reject(error.message + " - " + response.status + " : " + response.statusText);

            if(error.message === 'Expired token'){
              // redirect zum Login
              console.debug('Token expired - REDIRECT to login (3)')
              window.location.assign('/logout')
            }else{
              // alert('Fehler: '+error);
              console.debug('API error:', error)
              // other error
            }
            // return Promise.reject(error);
            errorMessage = typeof error.message !== 'undefined' ? error.message : error
            return reject(errorMessage)
            //return reject(error)
          }).catch((error) => {
            console.debug('catched error:', error)
          })

          let errorMessage = response.message

          if(errorMessage){
            return Promise.reject(errorMessage);
          }

          return Promise.reject(errorMessage + response.status + " : " + response.statusText);
        }
      })
      .then(json => {
        if(typeof json !== 'undefined' && json.data && json.data.status && !(json.data.status >= 200 && json.data.status < 403)){
          //return Promise.reject(json.code + " : " + json.message);
          reject(json.code + " : " + json.message);
        }else{
          // return new Promise.resolve(json);
          resolve(json)
        }
      })
      .catch(error => {
        //return new Promise.reject(error);
        reject(error)
      });

    })
  }

  /*
   * Die Methode obj2fd löscht Key/Value-Pairs mit leere Arrays [] raus. Um das zu verhindern setzt man eine 0 ein.
   */
  formDataFix(params) {

    Object.keys(params).forEach(function(key) {
      if(typeof params[key] === 'object' && params[key].length === 0){
        params[key] = [false]
      }
    });

    return params;
  }

  // Sync product
  async syncRequest(key, values){
    let _this = this
    let route = 'sellanizer/produkte/'
    let url = _this.API_URL + route
    //console.debug('API-DEBUG-Mode?', this.DEBUG);

    let params = { ...{ debug: this.DEBUG, user_id: localStorage.user_id, api_key: this.getSelectedPartnerApiKey() }, ...values  }

    let formData = obj2fd(this.formDataFix(params));

    let options = {
      method: "POST",
      body: formData,
      //mode: "no-cors",
      cache: "no-cache",
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer"
    }

    return await this.apiFetch(url, options)
  }

  // generateOpenAiText
  async generateAiText(data){
    let _this = this
    let route = 'sellanizer/generate_ai_text/'
    let url = _this.API_URL + route
    //console.debug('API-DEBUG-Mode?', this.DEBUG);
    let params = { ...{ debug: this.DEBUG, user_id: localStorage.user_id, api_key: this.getSelectedPartnerApiKey(), ...{"data": data}}}

    let formData = obj2fd(this.formDataFix(params));

    let options = {
      method: "POST",
      body: formData,
      //mode: "no-cors",
      cache: "no-cache",
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer"
    }

    return await this.apiFetch(url, options)
  }

  encrypt(val, passphrase='H1dd3nPa$§w0rt:'){
    return CryptoJS.AES.encrypt(JSON.stringify(val), passphrase, {format: CryptoJSAesJson}).toString();
  }

  decrypt(val, passphrase='H1dd3nPa$§w0rt:'){
    // return ************ if decryption
    try {
      JSON.parse(val);
      return JSON.parse(CryptoJS.AES.decrypt(val, passphrase, {format: CryptoJSAesJson}).toString(CryptoJS.enc.Utf8));
    } catch (e) {
      return '******error******'
    }
  }

  // Update partner data
  async updatePartner(values){
    let _this = this
    let route = 'sellanizer/partner/'
    let url = _this.API_URL + route

    //let params = { ...{ user_id: localStorage.user_id, partner: this.getSelectedPartnerID() }, ...values  }
    let params = { ...{ user_id: localStorage.user_id, api_key: this.getSelectedPartnerApiKey() }, ...values  }

    // Verschlüsseln der Passwörter vor der Datenübertragung
    if(params.accounts_ebay_kleinanzeigen){
      params.accounts_ebay_kleinanzeigen.forEach(function(account, index, params) {
        let passwort_encrypt = API.encrypt(account.passwort);
        account.passwort = passwort_encrypt
      })
    }
    if(params.accounts_marktde){
      params.accounts_marktde.forEach(function(account, index, params) {
        let passwort_encrypt = API.encrypt(account.passwort);
        account.passwort = passwort_encrypt
      })
    }

    let formData = obj2fd(params);

    let options = {
      method: "POST",
      body: formData,
      //mode: "no-cors",
      cache: "no-cache",
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer"
    }

    return await this.apiFetch(url, options)
  }

  getPartners(ids_only=false){
    let partners = false
    let partner_ids = []

    try {
      if(localStorage.getItem('partners')){
        partners = JSON.parse(localStorage.partners)
        partner_ids = partners.map(function (item) {
          //console.log(item);
          if(ids_only){
            return item.id
          }else{
            return { name: item.partner_city, value: item.id };
          }
        });
      }
      return partner_ids.sort(function(a,b){
        return a.name.localeCompare(b.name);
      })
    }catch(e){
      console.log(e)
    }
  }

  async getStatistics(stat_opts){
    let _this = this
    /*
      set API Statistic Options
     */
    let partners = false
    let partner_api_keys = []
    let partner_misses_api_key = []
    let partner_ids = stat_opts && stat_opts.partner_ids ? stat_opts.partner_ids : false

    let start_year  = stat_opts && stat_opts.start_year ? stat_opts.start_year : false
    let start_month = stat_opts && stat_opts.start_month ? stat_opts.start_month : false

    let end_year    = stat_opts && stat_opts.end_year ? stat_opts.end_year : false
    let end_month   = stat_opts && stat_opts.end_month ? stat_opts.end_month : false

    let version = stat_opts && stat_opts.version ? parseInt(stat_opts.version, 10) : 2
    let route = version===1 ? 'sellanizer/statistik/' : 'sellanizer/statistik_v'+version+'/'
    let demo = stat_opts && stat_opts.demo ? '&demo' : ''

    try {
      // if not set, get all partner_ids
      if(!partner_ids && localStorage.getItem('partners')){
        partners = JSON.parse(localStorage.partners)
        partner_ids = partners.map(function (item) {
          return item.id;
        });
      }

      // get all api_keys of the given partners
      if(partner_ids){
        partners = JSON.parse(localStorage.partners)
        partners.map(function (item) {
          if(partner_ids.includes(item.id)){
            if(typeof item.api_key !== 'undefined'){
              partner_api_keys.push(item.api_key)
            }else{
              partner_misses_api_key.push(item.id)
            }
          }
          return true
        });
        //console.debug('partner_ids', partner_ids);
        //console.debug('partner_api_keys', partner_api_keys);
        if(partner_misses_api_key.length){
          console.log('Bitte prüfen: Partner ohne API-Key', partner_misses_api_key);
        }
      }

    }catch(e){
      console.debug(e)
    }

    // Add Partner & Demo Param to API-URL
    // route+='?partner='+partner_ids+demo
    route+='?api_key='+partner_api_keys.join(',')+demo

    // Add Date Params to API-URL
    if(start_year){ route+='&start_year='+start_year }
    if(start_month){ route+='&start_month='+start_month }
    if(end_year){ route+='&end_year='+end_year }
    if(end_month){ route+='&end_month='+end_month }

    let url = _this.API_URL + route

    let options = {
      method: "GET",
      cache: "no-cache",
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer"
    }

    return await this.apiFetch(url, options)
  }

  // get the Partner-Id of the selected or the first partner
  getPartnerId(){
    try {
      if(localStorage.getItem('partners')) {
        let partnerData = DB.selectedPartnerData()
        return partnerData.id
      }else{
        const partners = JSON.parse(localStorage.getItem('partners'));
        return partners[0].id;
      }
    }catch(e){
      console.log(e)
      return false
    }
  }

  getSelectedPartnerID(){
    try {
      if(localStorage.getItem('selected_partner_id')){
        return JSON.parse(localStorage.selected_partner_id)

      }else{
        // Return Mutteraccount-ID
        return this.getPartnerId()
      }
    }catch(e){
      console.log(e)
    }
  }

  getSelectedPartnerApiKey(selected_partner_id = false){
    try {
      //let partnerData = DB.selectedPartnerData()
      const api_key = DB.getPartnerData('api_key')

      if(typeof api_key !== 'undefined' && api_key.length){
        return api_key
      }else{
        if(!selected_partner_id) {
          selected_partner_id = localStorage.getItem('selected_partner_id') ? localStorage.getItem('selected_partner_id') : false
        }
        alert(`Für den Partner mit der ID ${selected_partner_id} ist kein API-Key hinterlegt. Bitte wenden Sie sich an Ihren Sellanizer-Ansprechpartner.`);
        return false
      }
    }catch(e){
      console.log(e)
    }
  }

  getSelectedPartnerData(selected_partner_id = false){
    let partners = false
    let partner_data = []

    if(!selected_partner_id){
      selected_partner_id = this.getSelectedPartnerID()
    }

    try {
      if(localStorage.getItem('partners')){
        partners = JSON.parse(localStorage.partners)
        partner_data = partners.map(function (item) {
          //console.log(item);
          if(item.id === selected_partner_id){
            return item
          }
          return false
        });
      }
      return partner_data.filter(Boolean).shift()
    }catch(e){
      console.log(e)
    }
  }


  async getProducts(){
    let route = 'sellanizer/produkte/'

    if(!this.getPartnerId()){
      return false
    }

    let params = { api_key: this.getSelectedPartnerApiKey() }

    let esc = encodeURIComponent;
    let query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');

    let url = this.API_URL + route + '?' + query;

    let options = {
      method: "GET",
      cache: "no-cache",
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer"
    }

    return await this.apiFetch(url, options)
  }


  async getProduct(post_id){
    let _this = this
    let route = 'sellanizer/produkte/'

    let params = { api_key: this.getSelectedPartnerApiKey(), post_id: post_id }

    let esc = encodeURIComponent;
    let query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');

    let url = _this.API_URL + route + '?' + query;

    //let formData = obj2fd(params);

    let options = {
      method: "GET",
      cache: "no-cache",
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer"
    }

    return await this.apiFetch(url, options)
  }


  async getRemotePartner(post_id){
    let _this = this
    let route = 'sellanizer/partner/'

    //route+='?partner='+this.getSelectedPartnerID()
    route+='?api_key='+this.getSelectedPartnerApiKey()

    let url = _this.API_URL + route

    //let formData = obj2fd(params);

    let options = {
      method: "GET",
      cache: "no-cache",
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer"
    }

    return await this.apiFetch(url, options)
  }


  async loginAndSavePartnerDaten(params) {
    let esc = encodeURIComponent;
    let query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');

    let url = this.AUTH_URL + '?' + query;

    let options = {
      method: "POST",
      cache: "no-cache",
      withoutAuth: true
    }

    return await this.apiFetch(url, options)
  }


  async tokenRefresh(params) {
    let url = this.AUTH_URL + '/refresh';

    let options = {
      method: "POST",
      cache: "no-cache"
    }

    return await this.apiFetch(url, options)
  }


  resetTimer(){
    this.tokenRefresh().then(
      (result) => {
        localStorage.setItem('token', result.token)
        localStorage.setItem('token_expires_at', result.token_expires_at)
      },
      (error) => {
        console.debug('tokenRefresh Error', error)
      }
    ).catch(
      (error) => {
        console.debug('tokenRefresh Error', error)
      }
    )
  }


  async getPartnerDatenAndSave() {
    return await this.getRemotePartner()
      .then(
        (result) => {
          //console.log('set PartnerDaten', result )
          //localStorage.setItem('PartnerDaten', JSON.stringify(result));

          if(typeof result.api_key !== 'undefined'){
            DB.updateSelectedPartnerData(result);
          }
          return Promise.resolve(result);
        },
        (error) => {
          console.debug('Error getPartnerDatenAndSave:', error);
          return Promise.reject('Error getPartnerDatenAndSave: ' + error);
        }
    )
  }

  async getKategorienAndSave() {
    return await this.getKategorien()
      .then(
        (result) => {
          //console.log('set kategorienData', result )
          localStorage.setItem('kategorienData', JSON.stringify(result));
          return Promise.resolve(result);
        },
        (error) => {
          //console.log('Error getKategorienAndSave:', error);
          return Promise.reject('Error getAnsprechpartnerAndSave: ' + error);
        }
    )
  }


  async getKategorien() {
    let route = 'sellanizer/category-tree/'
    let params = { api_key: this.getSelectedPartnerApiKey() }

    let esc = encodeURIComponent;
    let query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');

    let url = this.API_URL + route + '?' + query;

    let options = {
      method: "GET",
      cache: "no-cache",
    }

    return await this.apiFetch(url, options)
  }


  async getAnsprechpartner() {
    let route = 'sellanizer/ansprechpartner/'
    let params = { api_key: this.getSelectedPartnerApiKey() }

    let esc = encodeURIComponent;
    let query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');

    let url = this.API_URL + route + '?' + query;

    let options = {
      method: "GET",
      cache: "no-cache",
    }

    return await this.apiFetch(url, options)
  }


  async getAnsprechpartnerAndSave() {
    return await this.getAnsprechpartner()
      .then(
        (result) => {
          console.debug('set ansprechpartnerData', result )
          localStorage.setItem('ansprechpartnerData', JSON.stringify(result));
          return Promise.resolve(result);
        },
        (error) => {
          console.log('Error getAnsprechpartnerAndSet:', error);
          return Promise.reject('Error getAnsprechpartnerAndSave: ' + error);
        }
    )
  }


  async getHersteller() {
    let route = 'sellanizer/hersteller/'
    let params = { api_key: this.getSelectedPartnerApiKey() }

    let esc = encodeURIComponent;
    let query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');

    let url = this.API_URL + route + '?' + query;

    let options = {
      method: "GET",
      cache: "no-cache",
    }

    return await this.apiFetch(url, options)
  }


  async getHerstellerCategory() {
    let route = 'sellanizer/hersteller_category/'

    let url = this.API_URL + route;

    let options = {
      method: "GET",
      cache: "no-cache",
    }

    return await this.apiFetch(url, options)
  }


  async getHerstellerAndSave() {
    return await this.getHersteller()
      .then(
        (result) => {
          //console.log('set herstellerData', result )
          localStorage.setItem('herstellerData', JSON.stringify(result));
          return Promise.resolve(result);
        },
        (error) => {
          //console.log('Error herstellerDataAndSave:', error);
          return Promise.reject('Error herstellerDataAndSave: ' + error);
        }
    )
  }


  async getHerstellerCategoryAndSave() {
    return await this.getHerstellerCategory()
      .then(
        (result) => {
          //console.log('set herstellerCategoryData', result )
          localStorage.setItem('herstellerCategoryData', JSON.stringify(result));
          return Promise.resolve(result);
        },
        (error) => {
          //console.log('Error herstellerCategoryDataAndSave:', error);
          return Promise.reject('Error herstellerCategoryDataAndSave: ' + error);
        }
    )
  }


  async parseData(data){
    if(typeof(localStorage[data])==='undefined')
      return false

    let parsedData = JSON.parse(localStorage[data]) || []
    //console.log('parsedData', parsedData)

    parsedData = Object.keys(parsedData).map(function (item, i) {
      if(typeof parsedData[item] === 'object' ){
        // Wenn Inhalt wieder ein Object ist, dann den ersten Key (z.B. 'name') suchen und nur Inhalt von erstem Key zurück geben
        let ii = parsedData[item]
        let key = Object.keys(ii)[0]
        parsedData[item] = ii[key]
      }

      return [item, parsedData[item].toString()];
    }) || {}

    //this.setState({[data]: parsedData})
    //return parsedData
    return Promise.resolve(parsedData);
  }


  async delete(post_id = false, reason = false){
    let _this = this
    let route = 'sellanizer/produktDelete/'
    let url = _this.API_URL + route

    if(!post_id){
      throw Promise.reject('Param post_id cannot be empty!');
      //return false
    }

    let params = { post_id: post_id, delete_reason: reason, api_key: this.getSelectedPartnerApiKey() }
    let formData = obj2fd(params);

    let options = {
      method: "POST",
      body: formData,
      //mode: "no-cors",
      cache: "no-cache",
      redirect: "follow",
      referrer: "no-referrer",
      /*headers: {
          "Host": "pw-relaunch.san",
          "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
        },*/
    }

    return await this.apiFetch(url, options)
  }

};

const API = new ApiPlanungswelten();

export default API;
