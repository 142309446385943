import React, {Fragment} from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import EuroSymbol from "@material-ui/icons/EuroSymbol";
import Verkaufte from "@material-ui/icons/Gavel";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import Home from "@material-ui/icons/Home";
import Close from "@material-ui/icons/Cancel";
import SnackbarClose from "components/Snackbar/SnackbarClose";
import FileCopy from "@material-ui/icons/FileCopy";
import InfoOutlined from "@material-ui/icons/InfoOutlined";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// 3.party components
import { Offline, Online } from "react-detect-offline";

// 44 components
//import Quota from "../components/44/Quota"
import DB from 'components/44/Database';
import API from 'components/44/ApiPlanungswelten';
import ATH from 'components/44/AddToHomescreen';
import Statistic from 'components/44/Statistic';
import CustomSelect from "components/CustomDropdown/CustomSelect.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import RegularButton from "../../components/CustomButtons/Button";

import { NavLink } from "react-router-dom";
//import Notifications from "../Components/Notifications";
//import PerfectScrollbar from "perfect-scrollbar";
import { withSnackbar } from 'notistack';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../../components/CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
//import CustomButton from "../../components/CustomButtons/Button";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_partner: API.getSelectedPartnerID(),
      mobileOpen: false,
      value: 0,
      countLocalProducts: 0,
      warenwertRemoteProducts: 0,
      spaceUsed: 0,
      spaceGranted: 0,
      spaceRemaining: 0,
      deleted_current_month: '-',
      deleted_last_month: '-',
      showInstallMessage: false,
      ebayDialog: false,
      partnersWrongEbayCredentials: [],
      hasWrongEbayCredentialsPartnerAsSelected: false
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }else{
      this.setState({ mobileOpen: true });
    }
  }

  componentDidMount(){
    ATH({
      //debug: 'ios',           // activate debug mode in ios emulation
      skipFirstVisit: false,	// show at first access
      startDelay: 0,          // display the message right away
      lifespan: 0,            // do not automatically kill the call out
      displayPace: 0,         // do not obey the display pace
      privateModeOverride: true,	// show the message in private mode
      maxDisplayCount: 10      // do not obey the max display count
    })

    if(localStorage.getItem('partners') != null && localStorage.getItem('partnersWrongEbayCredentials') != null) {

      let partnersWithWrongCredentials = [];

      // eslint-disable-next-line
      JSON.parse(localStorage.getItem('partnersWrongEbayCredentials')).map(partnerId => {
        // eslint-disable-next-line
        JSON.parse(localStorage.getItem('partners')).map(partner => {
          if(partnerId === partner.id) {
            partnersWithWrongCredentials.push({ id: partner.id, name: partner.partner_shortname });
          }
        })
      })

      // Check if the current selected partner has wrong Kleinanzeigen credentials
      const selectedPartnerId = localStorage.getItem('selected_partner_id');

      if(partnersWithWrongCredentials.length > 0) {
        if(partnersWithWrongCredentials.length === 1 && selectedPartnerId != null){
          if(partnersWithWrongCredentials[0].id === parseInt(selectedPartnerId)) {
            this.setState({ hasWrongEbayCredentialsPartnerAsSelected: true })
          }
        }
        this.setState({partnersWrongEbayCredentials: partnersWithWrongCredentials});
        this.handleOpenEbayDialog();
      }
    }

    this.checkIsUpdateAvailable()
    this.showAddToHomescreen()
    // this.showCurrentNews()

    if(!localStorage.getItem('ansprechpartnerData') || !localStorage.getItem('herstellerData')){
      this.refreshPartnerRelevantData()
    }

    this.setCountLocalProducts()
    this.setWarenwertRemoteProducts()

    let _this = this

    //Statistic.getRemoteStatistics({version:2, demo:true}).then( (data) => {
      Statistic.getStatistic('deleted_current_month').then( (data) => {
        _this.setState({deleted_current_month: data})

        Statistic.getStatistic('deleted_last_month').then( (data) => {
          _this.setState({deleted_last_month: data})
        })
      })

    //})

    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }else{
      this.setState({ mobileOpen: true });
    }
    window.addEventListener("resize", this.resizeFunction);

    this.setState({componentDidMountForTheFirstTime: true})
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  componentWillUnmount() {
    // auskommentiert weil: index.js:1 Warning: Can't call setState (or forceUpdate) on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.
    //     in Dashboard (created by WithStyles(Dashboard))
    // window.removeEventListener("resize", this.resizeFunction);
  }

  refreshPartnerRelevantData(selected_partner_id = false){

    if(!selected_partner_id){
      selected_partner_id = API.getSelectedPartnerID()
      //console.debug('selected_partner_id', selected_partner_id);
    }

    // Reload partner relevant data after partner was changing
    this.setState({ selected_partner: selected_partner_id}, function(){

      localStorage.setItem('selected_partner_id', selected_partner_id);

      DB.selectedPartnerData();

      //console.debug('refreshing PartnerData')
      //console.debug('selected_partner_id', this.state.selected_partner)

      this.setCountLocalProducts()
      this.setWarenwertRemoteProducts()

      const kategorienDataNoti = this.props.enqueueSnackbar('Kategorie-Daten werden abgeholt und eingelesen...', { persist:true })
      API.getKategorienAndSave().then(()=>{
        API.parseData('kategorienData').then(
        (result) => {
          // CLOSE Notification
          this.props.closeSnackbar(kategorienDataNoti)
          this.props.enqueueSnackbar('Kategorie-Daten fertig geladen!', { variant: 'success'})
        },
        (error) => {
          console.debug('Fehler beim Laden der Kategorie-Daten!', error)
          this.props.closeSnackbar(kategorienDataNoti)
          this.props.enqueueSnackbar('Fehler beim Laden der Kategorie-Daten! Bitte wiederholen sie den Vorgang oder laden sie die App neu!', { persist:true, variant: 'error', action: (<SnackbarClose/>)})
        })
      })

      const ansprechpartnerDataNoti = this.props.enqueueSnackbar('Ansprechpartner-Daten werden abgeholt und eingelesen...', { persist:true })
      API.getAnsprechpartnerAndSave().then(()=>{
        API.parseData('ansprechpartnerData').then(
        (result) => {
          // CLOSE Notification
          this.props.closeSnackbar(ansprechpartnerDataNoti)
          this.props.enqueueSnackbar('Ansprechpartner-Daten fertig geladen!', { variant: 'success'})
        },
        (error) => {
          this.props.closeSnackbar(ansprechpartnerDataNoti)
          this.props.enqueueSnackbar('Fehler beim Laden der Ansprechpartner-Daten! Bitte wiederholen sie den Vorgang oder laden sie die App neu!', { persist:true, variant: 'error', action: (<SnackbarClose/>)})
        })
      })

      const herstellerDataNoti = this.props.enqueueSnackbar('Lieferprogramm-Daten werden abgeholt und eingelesen...', { persist:true })
      API.getHerstellerAndSave().then(()=>{
        API.parseData('herstellerData').then(
        (result)=>{
          this.props.closeSnackbar(herstellerDataNoti)
          this.props.enqueueSnackbar('Lieferprogramm-Daten fertig geladen!', { variant: 'success'})
        },
        (error) => {
          this.props.closeSnackbar(herstellerDataNoti)
          this.props.enqueueSnackbar('Fehler beim Laden der Lieferprogramm-Daten! Bitte wiederholen sie den Vorgang oder laden sie die App neu!', { persist:true, variant: 'error', action: (<SnackbarClose/>)})
        })
      })

      const herstellerCategoryDataNoti = this.props.enqueueSnackbar('Hersteller-Daten werden abgeholt und eingelesen...', { persist:true })
      API.getHerstellerCategoryAndSave().then(()=>{
        API.parseData('herstellerCategoryData').then(
        (data)=>{
          //console.debug('data', data);
          this.props.closeSnackbar(herstellerCategoryDataNoti)
          this.props.enqueueSnackbar('Hersteller-Daten fertig geladen!', { variant: 'success'})
        },
        (error) => {
          this.props.closeSnackbar(herstellerCategoryDataNoti)
          this.props.enqueueSnackbar('Fehler beim Laden der Hersteller-Daten! Bitte wiederholen sie den Vorgang oder laden sie die App neu!', { persist:true, variant: 'error', action: (<SnackbarClose/>)})
        })
      })

    });
  }

  getQuota(){
    let _this = this

    this.storageEstimateWrapper().then((data) => {
      // Wenn nicht ermittelbar (bei iOS), dann auch 50MB setzen
      if(isNaN(data.quota)){
        data.quota = 52428800
      }

      let usedProductsBytes = parseInt(_this.state.usedProductsBytes, 10)
      let usedLs = Object.keys(window.localStorage).map(function(key) { return localStorage[key].length;}).reduce(function(a,b) { return a+b;});
      let usedLsByte = (usedLs * 1024 ).toFixed(0)
      data.usage = parseInt(usedLsByte, 10)+parseInt(usedProductsBytes, 10)

      let remaining = data.quota - data.usage

      let usedMb = (data.usage / 1024 / 1024).toFixed(0)
      let grantedMb = (data.quota / 1024 / 1024).toFixed(0)
      let remainingMb = (remaining / 1024 / 1024).toFixed(0)

      data = {spaceUsed: usedMb, spaceGranted: grantedMb, spaceRemaining: remainingMb}
      _this.setState(data)
    })

/*
    let data = {}
    let _this = this
    navigator.webkitTemporaryStorage.queryUsageAndQuota(
      function (used, granted) {
        console.log('we are using ', used, ' of ', granted, 'bytes');
        let remaining = granted - used

        let usedMb = (used / 1024 / 1024).toFixed(0)
        let grantedMb = (granted / 1024 / 1024).toFixed(0)
        let remainingMb = (remaining / 1024 / 1024).toFixed(0)

        data = {spaceUsed: usedMb, spaceGranted: grantedMb, spaceRemaining: remainingMb}
        _this.setState(data)
      },
      function (e) {
        console.log('Error', e);
      }
    );*/
  }


  storageEstimateWrapper() {
    if ('storage' in navigator && 'estimate' in navigator.storage) {
      // We've got the real thing! Return its response.
      return navigator.storage.estimate();
    }

    if ('webkitTemporaryStorage' in navigator &&
        'queryUsageAndQuota' in navigator.webkitTemporaryStorage) {
      // Return a promise-based wrapper that will follow the expected interface.
      return new Promise(function(resolve, reject) {
        navigator.webkitTemporaryStorage.queryUsageAndQuota(
          function(usage, quota) {resolve({usage: usage, quota: quota})},
          reject
        );
      });
    }

    // If we can't estimate the values, return a Promise that resolves with NaN.
    return Promise.resolve({usage: 'n.e.', quota: 'n.e.'});
  }

  setCountLocalProducts(){
    let _this = this
    DB.getAllProducts()
      .then(result => {
        let usedProductsBytes = JSON.stringify(result).length
        // console.debug('usedProductsBytes', usedProductsBytes);
        _this.setState({ countLocalProducts: result.length, usedProductsBytes: usedProductsBytes}, _this.getQuota())
      }
    );
  }

  setWarenwertRemoteProducts(){
    let _this = this
    API.getProducts().then((json) => {
      let sum = 0.0
      if(typeof json !== 'undefined' && json.length > 0){
        json.forEach((item, key) => {
          if(parseFloat(item.preis) > 0){
            if(parseFloat(item.menge) > 1){
              sum+= parseFloat(item.menge) * parseFloat(item.preis)
            }else{
              sum+= parseFloat(item.preis)
            }
          }
        })
      }
      _this.setState({ warenwertRemoteProducts: sum.toFixed(0)})
      localStorage.setItem('warenwertRemoteProducts', sum.toFixed(0))
    })
  }

  getWarenwertRemoteProducts(){
    if(typeof this.state.warenwertRemoteProducts !== 'undefined' && this.state.warenwertRemoteProducts > 0){
      return parseFloat(this.state.warenwertRemoteProducts).toLocaleString('de-DE')
    }else if(typeof localStorage.warenwertRemoteProducts !== 'undefined' && localStorage.warenwertRemoteProducts > 0){
      return parseFloat(localStorage.warenwertRemoteProducts).toLocaleString('de-DE')
    }else{
      return '-'
    }
  }


  checkIsUpdateAvailable(){
    //const pjson = require("./../../../package.json")
  }

  showAddToHomescreen(){
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test( userAgent );
    }
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      this.setState({ showInstallMessage: true });
    }
  }

  showCurrentNews(){
    if(!localStorage.getItem('news_shown')){
      localStorage.setItem('news_shown', true);
      /*const message = '<strong>50,- EUR Amazon Gutschein gewinnen!</strong>\n\nLiebe User, '+
        'beantworten Sie uns bis zum 3.9.2021 ein paar Fragen wie zufrieden Sie mit unserem Sellanizer sind und gewinnen Sie mit etwas Glück einen 50,- EUR-Gutschein von Amazon.\n'+
        'Nehmen Sie hier <a href="https://sellanizer.net/gewinnspiel" target="_blank">https://sellanizer.net/gewinnspiel</a> am Gewinnspiel teil.';*/
      const message = 'Wir haben das technische Problem der Kleinanzeigen Schnittstelle gelöst. Sie können diese nun wie gewohnt wieder verwenden. ';

      this.props.enqueueSnackbar(<div dangerouslySetInnerHTML={{__html: message}} />, { className: 'white-space-pre-wrap', variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, persist: true,
        /*action: (<Close style={{marginTop: 0}}/>)})*/
        action: (
            <Fragment>
              <Close style={{marginLeft: 4, right: 8, top: 8, position: 'absolute'}}/>
              {/*<small style={{display: 'block', textAlign: 'left', paddingRight: 40, float: 'left'}}>{'Meldung nicht wieder anzeigen'}</small>
              <CustomButton style={{padding: '5px 8px', marginLeft: 4, right: 8, top: 8, position: 'absolute'}} size="sm">{'Ok'}</CustomButton>*/}
            </Fragment>
          )
        })
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  handleCloseEbayDialog = () => {
    this.setState({ ebayDialog: false })
    localStorage.removeItem('partnersWrongEbayCredentials');
  }

  handleOpenEbayDialog = () => {
    this.setState({ ebayDialog: true })
  }

  handleAcceptEbayDialog = () => {
    this.handleCloseEbayDialog();
    localStorage.removeItem('partnersWrongEbayCredentials');
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          {this.state.mobileOpen ?
            <React.Fragment>
          <GridItem xs={false} sm={4} md={6}>

          </GridItem>

          <GridItem xs={12} sm={8} md={6}>
            <Card>
              <CardHeader color="warning" stats icon>
                <p className={classes.cardCategory}>Die wichtigsten Funtionen für Ihren Start</p>
                <h3 className={classes.cardTitle}>
                  Quick-Links
                </h3>
                {/*<Quicklinks
                  routes={dashboardRoutes}
                  color="red"
                />*/}

                <NavLink to={'products/local'} activeClassName="active">
                  <RegularButton>Artikel verwalten</RegularButton>
                </NavLink>
                <NavLink to={'productNew'} activeClassName="active">
                  <RegularButton color="primary">Neuen Artikel anlegen</RegularButton>
                </NavLink>

              </CardHeader>
              <CardFooter stats>
              </CardFooter>
            </Card>
          </GridItem></React.Fragment> : ''}

          {API.userIsAdmin() ? (
            <GridItem xs={12} sm={6} md={6}>
              <Card>
                <CardHeader color="info" stats icon>
                  <CardIcon color="info">
                    <Home/>
                  </CardIcon>
                  <p className={classes.cardCategory}>Aktiven Standort auswählen</p>
                </CardHeader>
                <CardBody>
                  <CustomSelect
                    buttonText="Standort auswählen"
                    onChange={(v) => { this.refreshPartnerRelevantData(v) }}
                    dropdownList={API.getPartners()}
                    value={this.state.selected_partner}
                    values={this.state.selected_partner}
                    style={{padding: 0}}
                  />
                </CardBody>
              </Card>
            </GridItem>) : ''}


          <GridItem xs={12} sm={6} md={6}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <EuroSymbol />
                </CardIcon>
                <p className={classes.cardCategory}>kumulierter Warenwert</p>
                <h3 className={classes.cardTitle}>
                  <Online>{this.getWarenwertRemoteProducts()} €</Online>
                  <Offline>{this.getWarenwertRemoteProducts()} €</Offline>
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <LocalOffer/> aller online verfügbaren Artikel
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Verkaufte />
                </CardIcon>
                <p className={classes.cardCategory}>Verkaufte diesen/letzten Monat</p>
                <h3 className={classes.cardTitle}>{this.state.deleted_current_month} / {this.state.deleted_last_month}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update /> zuletzt aktualisiert: {Statistic.getStatisticDateLastUpdated()} Uhr
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <InfoOutlined/>
                </CardIcon>
                <p className={classes.cardCategory}>Nicht synchronisierte Artikel</p>
                <h3 className={classes.cardTitle}>{this.state.countLocalProducts}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <LocalOffer /> bitte täglich synchronisieren
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <FileCopy/>
                </CardIcon>
                <p className={classes.cardCategory}>Offline Speicher</p>
                <h3 className={classes.cardTitle}>
                  {this.state.spaceUsed}/{this.state.spaceGranted} <small>MB</small>
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <LocalOffer />
                  In Benutzung / Verfügbar (n.e. = nicht ermittelbar)
                </div>
                {/*<div className={classes.stats}>*/}
                  {/*<Danger>*/}
                    {/*<Warning />*/}
                  {/*</Danger>*/}
                  {/*<a href="#pablo" onClick={e => e.preventDefault()}>*/}
                    {/*Get more space*/}
                  {/*</a>*/}
                {/*</div>*/}
              </CardFooter>
            </Card>
          </GridItem>

          <Dialog
            open={this.state.ebayDialog}
            onClose={this.handleCloseEbayDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" style={{ paddingBottom: '15px' }}>Kleinanzeigen Zugangsdaten fehlerhaft</DialogTitle>
            <DialogContent style={{ paddingBottom: 0 }}>
              <DialogContentText id="alert-dialog-description">
                {this.state.hasWrongEbayCredentialsPartnerAsSelected ? (
                  <p>Bitte korrigieren Sie ihre Kleinanzeigen Zugangsdaten bei Gelegenheit!</p>
                ) : (
                  <>
                    <span>Bei folgenden Partnern müssen diese korrigiert werden:</span>
                    <ul>
                      {this.state.partnersWrongEbayCredentials.map(partner => {
                        return (
                          <li><b>{partner.name}</b></li>
                        )
                      })}
                    </ul>
                    <p>Bitte korrigieren Sie die Kleinanzeigen Zugangsdaten bei Gelegenheit!</p>
                  </>
                )}
                <br />
                <span><b>Wie ändere ich die Einstellungen?</b></span>
                <ol>
                  <li>Betroffenen <b>Standort</b> im Dashboard auswählen</li>
                  <li>Im Menü auf "<b>Partner-Daten</b>" und dann "<b>Partner-Daten bearbeiten</b>"</li>
                  <li>In den "<b>Marktplatz-Einstellungen</b>" die Kleinanzeigen-Zugangsdaten eintragen.</li>
                </ol>
                <span><b>Hinweis:</b> Haben Sie nicht die Berechtigung diese Daten zu bearbeiten, wenden Sie sich an den Admin</span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleAcceptEbayDialog} color="info">
                Verstanden
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
              open={this.state.ebayDialog}
              onClose={this.handleCloseEbayDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" style={{ paddingBottom: '15px' }}>Kleinanzeigen Zugangsdaten fehlerhaft</DialogTitle>
            <DialogContent style={{ paddingBottom: 0 }}>
              <DialogContentText id="alert-dialog-description">
                {this.state.hasWrongEbayCredentialsPartnerAsSelected ? (
                    <p>Bitte korrigieren Sie ihre Kleinanzeigen Zugangsdaten bei Gelegenheit!</p>
                ) : (
                    <>
                      <span>Bei folgenden Partnern müssen diese korrigiert werden:</span>
                      <ul>
                        {this.state.partnersWrongEbayCredentials.map(partner => {
                          return (
                              <li><b>{partner.name}</b></li>
                          )
                        })}
                      </ul>
                      <p>Bitte korrigieren Sie die Kleinanzeigen Zugangsdaten bei Gelegenheit!</p>
                    </>
                )}
                <br />
                <span><b>Wie ändere ich die Einstellungen?</b></span>
                <ol>
                  <li>Betroffenen <b>Standort</b> im Dashboard auswählen</li>
                  <li>Im Menü auf "<b>Partner-Daten</b>" und dann "<b>Partner-Daten bearbeiten</b>"</li>
                  <li>In den "<b>Marktplatz-Einstellungen</b>" die Kleinanzeigen-Zugangsdaten eintragen.</li>
                </ol>
                <span><b>Hinweis:</b> Haben Sie nicht die Berechtigung diese Daten zu bearbeiten, wenden Sie sich an den Admin</span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleAcceptEbayDialog} color="info">
                Verstanden
              </Button>
            </DialogActions>
          </Dialog>

          {API.userIsAdmin() ? (
          <React.Fragment>
            {/*<GridItem xs={12} sm={12} md={12} lg={6}>
            <Card chart>
              <CardHeader color="info">
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={Statistic.getStatisticThisYear('deleted_by_month')}
                  type="Bar"
                  options={simpleBarChart.options}
                  responsiveOptions={simpleBarChart.responsiveOptions}
                  listener={simpleBarChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Verkaufte Artikel pro Monat</h4>
                <p className={classes.cardCategory}>Dieses Jahr - 2018</p>
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update /> zuletzt aktualisiert: {Statistic.getStatisticDateLastUpdated()} Uhr
                </div>
              </CardFooter>
            </Card>
          </GridItem>*/}
          </React.Fragment>) : ''}
          {/*<GridItem xs={12} sm={6} md={6}>
            <Card>
              <CardBanner>Demo - In Kürze verfügbar</CardBanner>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Accessibility />
                </CardIcon>
                <p className={classes.cardCategory}>Besucher heute</p>
                <h3 className={classes.cardTitle}>+245</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update />
                  zuletzt aktualisiert: 13:15 Uhr
                </div>
              </CardFooter>
            </Card>
          </GridItem>*/}
        </GridContainer>
        {/*<GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardBanner chart>Demo - In Kürze verfügbar</CardBanner>
              <CardHeader color="success">
                <ChartistGraph
                  className="ct-chart"
                  data={dailySalesChart.data}
                  type="Line"
                  options={dailySalesChart.options}
                  listener={dailySalesChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Verkaufte / Monat</h4>
                <p className={classes.cardCategory}>
                  <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                  </span>{" "}
                  mehr in diesem Monat
                </p>
              </CardBody>
              <CardFooter chart>
                <CardBanner chart>Demo - In Kürze verfügbar</CardBanner>
                <div className={classes.stats}>
                  <AccessTime /> zuletzt aktualisiert: 13:15 Uhr
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardBanner chart>Demo - In Kürze verfügbar</CardBanner>
              <CardHeader color="warning">
                <ChartistGraph
                  className="ct-chart"
                  data={emailsSubscriptionChart.data}
                  type="Bar"
                  options={emailsSubscriptionChart.options}
                  responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                  listener={emailsSubscriptionChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Anzahl Views</h4>
                <p className={classes.cardCategory}>
                  aller Artikel auf ihrem Marktplatz
                </p>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> zuletzt aktualisiert: 13:15 Uhr
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardBanner chart>Demo - In Kürze verfügbar</CardBanner>
              <CardHeader color="danger">
                <ChartistGraph
                  className="ct-chart"
                  data={completedTasksChart.data}
                  type="Line"
                  options={completedTasksChart.options}
                  listener={completedTasksChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Completed Tasks</h4>
                <p className={classes.cardCategory}>
                  Last Campaign Performance
                </p>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> campaign sent 2 days ago
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>*/}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(dashboardStyle)(Dashboard));
