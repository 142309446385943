import React from 'react';

let icon = (props) => (
  <svg id="eEWsMezXAkf1" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 -10 217 186">
    <g transform="matrix(.1 0 0-.1 0 186)">
      <path
        d="M535,1781c-73-9-100-23-119-59-25-49-82-224-111-340-15-63-33-118-39-123s-31-25-57-43c-30-23-60-60-92-115-26-44-47-85-47-90c0-6,12-17,27-25c26-13,30-12,78,29c27,23,73,56,100,73c184,111,442,99,623-28c86-61,134-115,178-205c50-103,65-174,62-290l-2-100l32-3c31-3,32-2,32,35c1,123,60,261,150,354c98,99,196,139,345,139c128,0,237-40,328-120l48-43-6,54c-17,142-61,222-142,257-60,26-197,54-405,83l-156,22-7,56c-13,107-34,189-86,330-53,144-68,163-98,127-10-11-7-25,12-63c51-101,103-276,121-405c4-36,3-38-22-38-26,0-28,4-56,112-35,134-98,320-121,355-33,50-81,61-305,66-113,3-232,2-265-2Zm475-60c44-9,50-21,96-197c31-119,37-160,42-281l5-143h-86-86l-78,51c-43,27-115,69-160,91-61,30-82,46-79,57c3,9,24,111,47,228l42,212l111-5c61-4,127-9,146-13Zm-320-3c0-7-19-106-42-220l-43-208-97-2c-54-1-108-2-120-2-34-1-34,48,1,186c32,127,75,233,100,248s201,13,201-2Z"
        fill="#FFF"/>
    </g>
    <g>
      <path d="M-51.911401,0c0-28.669875,23.241526-51.911401,51.911401-51.911401s51.911401,23.241526,51.911401,51.911401-23.241526,51.911401-51.911401,51.911401-51.911401-23.241526-51.911401-51.911401Z" transform="matrix(.782844 0 0 0.782844 56.5886 125.628012)" fill="none"
            stroke="#FFF" strokeWidth="20"/>
      <path d="M-51.911401,0c0-28.669875,23.241526-51.911401,51.911401-51.911401s51.911401,23.241526,51.911401,51.911401-23.241526,51.911401-51.911401,51.911401-51.911401-23.241526-51.911401-51.911401Z" transform="matrix(.645709 0 0 0.645709 169.5886 134.292263)" fill="none"
            stroke="#FFF" strokeWidth="22"/>
    </g>
  </svg>
);
//icon = pure(EbayIcon);
icon.displayName = 'Traktorpool';
icon.muiName = 'SvgIcon';

export default icon;