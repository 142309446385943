/*global FB*/
const FBinit = (_this) => {
  const script = document.createElement("script");
  script.async = true;
  script.src = "https://connect.facebook.net/de_DE/sdk.js";
  document.body.appendChild(script);

  return (
    window.fbAsyncInit = function() {
      FB.init({
        appId            : _this.state.appData.appID,
        autoLogAppEvents : true,
        cookie           : true,
        xfbml            : true,
        version          : 'v20.0'
      });
      //console.debug('FB init');
      _this.checkToken()
    }
  );
}

export default FBinit;
