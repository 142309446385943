import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

export default class BulkEditDeleteDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteType: '-',
      errorText: ''
    }
  }

  handleConfirm = () => {
    if(!this.props.remote) {
      this.props.onClose();
      this.props.onConfirm(this.state.deleteType);
      this.resetStates();
      return;
    }

    if(this.state.deleteType && this.state.deleteType.length > 2) {
      this.props.onClose();
      this.props.onConfirm(this.state.deleteType);
      this.resetStates();
    } else {
      this.setState({ errorText: 'Bitte Grund des Löschens angeben!' })
    }
  }

  resetStates() {
    this.setState({ errorText: '', deleteType: '-' })
  }

  handleClose() {
    this.props.onClose();
    this.setState({ errorText: '', deleteType: '-' })
  }

  handleSellType(e) {
    this.setState({ deleteType: e.target.value });

    if(e.target.value !== '') {
      this.setState({ errorText: '' });
    }
  }

  render() {
    const isRemote = this.props.remote;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">{this.props.amount} Artikel löschen</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Möchten Sie wirklich {this.props.amount} Artikel löschen?
          </DialogContentText>
          {isRemote ? (
            <FormControl>
              <InputLabel htmlFor="sell-reason">Pflichtfeld</InputLabel>
              <Select
                id="sell-reason"
                autoFocus
                value={this.state.deleteType}
                onChange={(e) => this.handleSellType(e)}
                required={true}
              >
                <MenuItem value="-">- bitte wählen -</MenuItem>
                <MenuItem value="verkauft_online">Artikel wurde durch Online-Anfrage verkauft</MenuItem>
                <MenuItem value="verkauft_offline">Artikel wurde stationär verkauft</MenuItem>
                <MenuItem value="test_fehler_duplikat">Artikel ist ein Duplikat, Test oder Fehlerhaft</MenuItem>
                <MenuItem value="sonstiges">Artikel soll aus anderen Gründen nicht mehr angeboten werden.</MenuItem>
              </Select>
              {(this.state.errorText && this.state.errorText.length > 1) ? (
                <p className="alert-dialog-error">{this.state.errorText}</p>
              ) : '' }
            </FormControl>
          ) : ''}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleConfirm} color="primary">
            Löschen bestätigen
          </Button>
          <Button onClick={this.handleClose} autoFocus>
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}