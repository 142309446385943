import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
//import Session from "components/44/Session"

class LogoutPage extends React.Component {

  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered

    this.state = {
      token: false,
      isLoggedIn: false
    };
  }

  componentDidMount(){
    //var session = Session.new()
    this.logout()
    // Parent Session probs setzen
    //console.log('LogoutPage.props',this.props)
  }

  logout = () => {
    this.setState({token: false, isLoggedIn: false})
    if(this.props.handleSetState)
      this.props.handleSetState({isLoggedIn: false, token: false})
    this.props.history.push('/')

    localStorage.token = false
    sessionStorage.token = false
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');


    // Kompletten Cache löschen, aber last Credentials wieder einsetzen
    let user = localStorage.getItem('username')
    let pass = localStorage.getItem('password')
    localStorage.clear()
    sessionStorage.clear()
    localStorage.setItem('username', user)
    localStorage.setItem('password', pass)
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.content} style={{backgroundColor:'black'}}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form>
                <Card className={classes[this.state.cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="danger"
                  >
                    <h4 className={classes.cardTitle}>Logout</h4>
                  </CardHeader>
                  <CardBody>
                    <h2>Sie werden jetzt abgemeldet...!</h2>
                  </CardBody>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

LogoutPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LogoutPage);
