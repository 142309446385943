import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
//import Button from '@material-ui/core/Button';
import Button from "components/CustomButtons/Button.jsx";
import CheckIcon from '@material-ui/icons/Check';
//import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from "@material-ui/icons/Backup";
import ErrorIcon from "@material-ui/icons/Error";

//import { muiTheme, muiTheme14 } from "components/44/muiTheme"
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const styles = muiTheme => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: 0, //muiTheme.spacing.unit,
    position: 'relative',
  },
  button: {
    backgroundColor: green[100],
    '&:hover': {
      backgroundColor: green[100],
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[500],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: 0,
    left: -4,
    zIndex: 1,
  }
});

class CircularIntegration extends React.Component {
  timer = null;

  state = {
    loading: false,
    success: false,
    error: false,
  };

  componentDidMount() {
    // Wird benötigt damit die Startwerte gesetzt werden
    this.setState({ success: this.props.synced, error: this.props.sync_error })
  }

  componentWillReceiveProps(changedProps) {
    //console.log('changedProps', changedProps)
    //this.handleButtonClick(this.props.onClick)
    // Wird benötigt damit Button sich aktualisiert wenn sich eine Änderung durch die anderen Actions-Buttons ergibt ohne das die gesamte View neu gerendert wird.
    this.setState({ success: this.props.synced, error: this.props.sync_error })
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleButtonClick = (callback) => {
    let _this = this

    //console.log('handleButtonClick.callback()')
    //console.log('handleButtonClick.callback()', callback)

    if (!this.state.loading) {
      this.setState(
        {
          success: false,
          loading: true,
          error: false,
        },
        () => {
          //callback().then(console.log('ok'), console.log('error'));
          callback().then((message) => {
            //console.log('callback.then von ProgressButton.onClick', message)
            _this.setState({
              loading: false,
              success: true,
              error: false,
            });
          }).catch((error) => {
            //console.log('callback.catch von ProgressButton.onClick', error)
            _this.setState({
              loading: false,
              success: false,
              error: true,
            });
          })

          /* Nur für Demo neutzbar - Callback sendet sofort true. abwohl request noch läuft, State wird später gesetzt.
          this.timer = setTimeout(() => {
            if (callback()) {
              this.setState({
                loading: false,
                success: true,
                error: false,
              });
            } else {
              this.setState({
                loading: false,
                success: false,
                error: true,
              });
            }
          }, 2000)*/
          /*this.setState({
            loading: false,
            success: true,
            error: false,
          });*/
        }
      );
    }
  };

  render() {
    const { loading, error, success } = this.state;
    const { classes, children, tooltip, onClick, isMobile, id } = this.props; // synced
    const buttonClassname = classNames({
      [classes.buttonSuccess]: classes,
    });

    //console.log('this.props', this.props)

    const btn = <Button
                  id={id}
                  style={{marginRight: 4}}
                  justIcon
                  isMobile={isMobile}
                  round
                  color={success ? 'success' : 'info'}
                  className={buttonClassname}
                  onClick={success ? undefined : () => this.handleButtonClick(onClick)}
                >
                  {loading ? <SyncIcon /> : success ? <CheckIcon /> : error ? <ErrorIcon /> : <SyncIcon/>}
                  {children}
                </Button>

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          {tooltip ?
            <Tooltip title={tooltip} placement="top-start">
              <>{btn}</>
            </Tooltip> :
            <>{btn}</>
          }
          {loading && <CircularProgress size={isMobile ? 41 : 51} className={classes.fabProgress} />}
        </div>
      </div>
    );
  }
}

CircularIntegration.propTypes = {
  classes: PropTypes.object.isRequired,
  success: PropTypes.bool
};

export default withStyles(styles)(CircularIntegration);
