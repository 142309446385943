import React, {Fragment} from 'react'
import {withSnackbar} from "notistack";

import CustomButton from "components/CustomButtons/Button.jsx";
//import Button from '@material-ui/core/Button';
import {unregister} from "../../registerServiceWorker";

const pjson = require("./../../../package.json")

class Session extends React.Component {

  state = {
    isLoggedIn: (localStorage.token || '').length > 1 ? true : false,
    token: localStorage.token || false,
    isMobile: window.innerWidth >= 520 ? false : true,
  }

  constructor(props) {
    super(props);
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  componentDidMount() {
    if (window.innerWidth >= 960) {
      this.setState({ isMobile: false });
    }else{
      this.setState({ isMobile: true });
    }
    window.addEventListener("resize", this.resizeFunction);

    this.checkIfUpdateAvailable();
  }

  refreshApp(){
    unregister()
    window.location.reload(true)
  }

  checkIfUpdateAvailable(){
    let v = pjson.version
    let vInfo = pjson.changeslog[v]
    let lv = localStorage.getItem('version')

    localStorage.setItem('version', v)

    if(lv && lv.length > 1 && lv !== v){
      // Info to successfully reloaded
      this.props.enqueueSnackbar("Die neue Version "+v+" wurde geladen!", { action: (
        <Fragment>
          <small style={{display: 'block', textAlign: 'left', paddingRight: 40, float: 'left'}}>{vInfo}</small>
          <CustomButton style={{padding: '5px 8px', marginLeft: 4, right: 8, top: 8, position: 'absolute'}} size="sm">{'Ok'}</CustomButton>
        </Fragment>
      ), variant: "success", persist: true})
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ isMobile: false });
    }else{
      this.setState({ isMobile: true });
    }
  }

  handleSetState = someProps => {
    this.setState(someProps);
  }

  logout() {
    this.setState({ token: false, isLoggedIn: false})
  }

  logout = () => {
    this.setState({ token: false, isLoggedIn: false})
  }

  render() {
    //return this.props.children({isLoggedIn: this.state.isLoggedIn})
    const { isLoggedIn, token, isMobile } = this.state;
    return this.props.children({isLoggedIn, token, isMobile, handleSetState: this.handleSetState});
  }
}

export default withSnackbar(Session);