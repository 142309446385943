// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import GridOn from "@material-ui/icons/GridOn";
import Timeline from "@material-ui/icons/Timeline";
import Lock from "@material-ui/icons/Lock";
import PartnerDataIcon from "@material-ui/icons/HowToReg";
import Unarchive from "@material-ui/icons/Unarchive";
import SettingsIcon from '@material-ui/icons/Settings';
// core components/views
//import DashboardPage from "views/Dashboard/DashboardNew.jsx";
import DashboardPage from "views/Dashboard/Dashboard.jsx";
//import Charts from "views/Charts/Charts.jsx";
import AmCharts from "views/Charts/AmCharts.jsx";
import AmCharts2021 from "views/Charts/AmCharts2021.jsx";
// 44 components
import ProductForm from "../views/Product/ProductForm";
import ProductFormRemote from "../views/Product/ProductFormRemote";
import PartnerFormRemote from "../views/Partner/PartnerFormRemote";
import ProductTables from "../views/Product/ProductTables";
//import Notifications from "../views/Components/Notifications"
// 44 components
import PartnerDaten from "../components/44/PartnerDaten"
import LogoutPage from "../components/44/LogoutPage";
import Versionen from "../components/44/Versionen";
import Settings from "components/44/Settings";
import API from "../components/44/ApiPlanungswelten";

function resetTimer() {
  API.resetTimer()
}

const dashboardRoutes = [
  {
    path: "/dashboard",
    sidebarName: "Dashboard",
    navbarName: "Dashboard",
    icon: Dashboard,
    style: { paddingTop: 10, width: '100%' },
    component: DashboardPage
  },
  {
    path: "/products/:tab",
    pathLink: "/products/local",
    sidebarName: "Artikel verwalten",
    navbarName: "Artikel verwalten",
    icon: GridOn,
    component: ProductTables
  },
  {
    path: "/productNew",
    sidebarName: "Artikel anlegen",
    navbarName: "Artikel anlegen",
    icon: Unarchive,
    component: ProductForm,
    onClick: resetTimer
  },
  {
    path: "/productEdit/:id",
    //pathLink: "/productFormEdit",
    sidebarName: "Artikel bearbeiten",
    navbarName: "Artikel bearbeiten",
    invisible: true,
    icon: Unarchive,
    component: ProductForm,
    onClick: resetTimer
  },
  {
    path: "/productEditRemote/:id",
    //pathLink: "/productFormEdit",
    sidebarName: "Artikel bearbeiten",
    navbarName: "Artikel bearbeiten",
    invisible: true,
    icon: Unarchive,
    component: ProductFormRemote,
    onClick: resetTimer
  },
  {
    path: "/statistics/",
    sidebarName: "Statistiken",
    navbarName: "Statistiken",
    icon: Timeline,
    component: AmCharts,
    role: 'sellanizer_admin',
    onClick: resetTimer
  },
  {
    path: "/statistics-v3",
    sidebarName: "Statistiken Beta-v3",
    navbarName: "Statistiken Beta-v3",
    icon: Timeline,
    component: AmCharts2021,
    role: 'sellanizer_admin',
    invisible: true,
    onClick: resetTimer
  },
  {
    path: "/partnerdaten",
    sidebarName: "Partner-Daten",
    navbarName: "Partner-Daten",
    icon: PartnerDataIcon,
    //onClick: function(){ console.log('How to call Session.logout() of Session-Component?') },
    component: PartnerDaten,
    onClick: resetTimer
  },
  {
    path: "/partnerEditRemote",
    sidebarName: "Partner-Daten bearbeiten",
    navbarName: "Partner-Daten bearbeiten",
    invisible: true,
    icon: Unarchive,
    component: PartnerFormRemote,
    role: 'sellanizer_admin',
    onClick: resetTimer
  },
  {
    path: "/settings",
    sidebarName: "Einstellungen",
    navbarName: "Einstellungen",
    icon: SettingsIcon,
    component: Settings,
    role: 'sellanizer_admin',
    onClick: resetTimer
  },
  /*{
    path: "/notifications",
    sidebarName: "Notifications",
    navbarName: "Notifications",
    icon: PartnerDataIcon,
    //onClick: function(){ console.log('How to call Session.logout() of Session-Component?') },
    component: Notifications,
    //action: Session.logout()
  },*/
  {
    path: "/pages/versions",
    //pathTo: "/pages/versions",
    sidebarName: "Versionen - Historie",
    navbarName: "Versionen - Historie",
    invisible: true,
    component: Versionen
  },
  {
    path: "/logout",
    sidebarName: "Abmelden",
    navbarName: "Abmelden",
    //style: { top: 30, paddingBottom: 20, marginBottom: 20, width: '100%' },
    style: { paddingBottom: 30 },
    classNames: 'logout',
    icon: Lock,
    //onClick: function(){ console.log('How to call Session.logout() of Session-Component?') },
    component: LogoutPage,
    //action: Session.logout()
  },
  { redirect: true, path: "/", to: "/dashboard", navbarName: "Dashboard" }
];

export default dashboardRoutes;
