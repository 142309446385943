import React from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
// core components

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx"

class CustomSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      values: this.props.value ? this.props.value : this.props.multiple ? [] : '',
    };
  }

  // Use this for simple single mode (no multipe values)
  handleSimple = event => {
    this.setState({ values: event.target.value }, function () {
      if(this.props && this.props.onChange) {
        this.props.onChange(this.state.values);
      }
    });
  };

  // Use this for multiple in combination with onClose
  handleMultiple = event => {
    this.setState({ values: event.target.value });
  };

  handleOnClose = (event) => {
    this.setState({ open: false });

    if(this.props && this.props.onChange && this.props.multiple){
      this.props.onChange(this.state.values);
    }
  };

  handleOnOpen = () => {
    this.setState({ open: true });
  };


  /*handleMultiple = event => {
    this.setState({ multipleSelect: event.target.value });
    if(this.props && this.props.onClick){
      this.props.onClick(event.target.value);
    }
  };*/

  render() {
    const {
      classes,
      buttonText,
      dropdownList,
    } = this.props;

    return (
          <FormControl
            fullWidth
            className={classes.selectFormControl}
          >
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabel}
            >
              {buttonText !== undefined ? buttonText : null}
            </InputLabel>
            <Select
              multiple={this.props.multiple}
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.values}
              open={this.state.open}
              onClose={this.handleOnClose}
              onOpen={this.handleOnOpen}
              onChange={this.props.multiple ? this.handleMultiple : this.handleSimple}
              inputProps={{
                name: this.props.multiple ? "multipleSelect" : "simpleSelect",
                id: this.props.multiple ? "multiple-select" : "simple-select"
              }}
            >
              {typeof dropdownList === 'object' ? dropdownList.map((prop, key) => {
                let value = typeof prop.value !== 'undefined' ? prop.value : prop
                let name = typeof prop.name !== 'undefined' ? prop.name : prop

                  if (prop.divider) {
                    return (
                      <Divider
                        key={key}
                        onClick={() => this.handleCloseMenu("divider")}
                        className={classes.dropdownDividerItem}
                      />
                    );
                  }
                  return (
                    <MenuItem
                      key={key}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: this.props.multiple ? classes.selectMenuItemSelectedMultiple : classes.selectMenuItemSelected
                      }}
                      value={value}
                    >
                      {name}
                    </MenuItem>
                  );
                }) : ''}
            </Select>
          </FormControl>
    );
  }
}

CustomSelect.defaultProps = {
  multiple: false
};

CustomSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonText: PropTypes.node,
  multiple: PropTypes.bool,
  value: PropTypes.any,
  dropdownList: PropTypes.array,
  dropdownHeader: PropTypes.node,
  // This is a function that returns the clicked menu item
  onClick: PropTypes.func,
  onChange: PropTypes.func
};

export default withStyles(customSelectStyle)(CustomSelect);
