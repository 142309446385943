import React from 'react';
import { Notifier } from '@airbrake/browser';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    if(process.env.NODE_ENV === 'production') {
      this.airbrake = new Notifier({
        projectId: 335352,
        projectKey: '3cc1c4147223259f269ba34704721f7a'
      });
    }
    console.debug('NODE_ENV', process.env.NODE_ENV);
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });

    const excludedErrors = [
      'NetworkError when attempting to fetch resource',
      'Die Netzwerkverbindung wurde unterbrochen',
      'Failed to execute \'transaction\' on \'IDBDatabase\': The database connection is closing'
    ];

    for (let item of excludedErrors) {
      if(error.message.toLowerCase().includes(item.toLowerCase())) {
        return;
      }
    }

    if(process.env.NODE_ENV === 'production'){
      // Send error to Airbrake
      if(typeof error != 'boolean'){ // unknown Airbrake errors with only text 'true' shouldn't come anymore
        this.airbrake.notify({
          error: error,
          params: {info: info}
        });
      }
    } else {
      console.error('In production we would send a Airbrake now. Please improve the code!');
      console.debug(error, info);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
