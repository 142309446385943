import buttonGroupStyle from "assets/jss/material-dashboard-pro-react/buttonGroupStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import purple from "@material-ui/core/colors/purple";

const sellanizerProductTablesStyle = theme => ({
  // Button
  ...customCheckboxRadioSwitch,
  ...buttonGroupStyle,
  right: {
    textAlign: "right"
  },
  center: {
    textAlign: "center"
  },
  actionButton: {
    margin: "0 0 0 5px",
    padding: "5px",
    "& svg": {
      marginRight: "0px"
    }
  },
  icon: {
    verticalAlign: "middle",
    width: "17px",
    height: "17px",
    top: "-1px",
    position: "relative"
  },
  customFont: {
    fontSize: "16px !important"
  },
  actionButtonRound: {
    width: "auto",
    height: "auto",
    minWidth: "auto"
  },
  // Tabelle von Stefan
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  kleinanzeigenIcon: {
    marginTop: -3,
    width: '24px',
    height: '24px'
  },
  marktdeIcon: {
    marginTop: -3,
    fontSize: '24px',
    overflow: 'visible',
    width: '24px',
    height: '24px'
  },
  traktorpoolIcon: {
    marginTop: -3,
    width: '24px',
    height: '24px'
  },
  pdfIcon: {
    marginLeft: 0,
    marginTop: -1,
    height: 20,
    width: 20
  },
  facebookDeactivated: {
    fontSize: '24px',
    lineHeight: '18px',
    fontWeight: 'bold'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 5,
    right: theme.spacing.unit * 2.2,
  },
  // Button loading
  wrapper: {
    // margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonSuccess: {
    // width: '20px',
    // height: '20px',
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  },
  fabProgress: {
    color: purple[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: `#b1184c !important`,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -20,
    marginLeft: -20,
  },
});

export default sellanizerProductTablesStyle;
