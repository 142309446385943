import React from "react";

// react plugin for creating charts
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4lang_de_DE from "@amcharts/amcharts4/lang/de_DE";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
//import ShowChart from "@material-ui/icons/ShowChart";
import BarChart from "@material-ui/icons/BarChart";
import PieChart from "@material-ui/icons/PieChart";
import EuroSymbol from "@material-ui/icons/EuroSymbol";
import Update from "@material-ui/icons/Update";
import ScreenRotation from "@material-ui/icons/ScreenRotation";

// core components
//import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomDropdown/CustomSelect.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// official 3rd-party for material-ui
import DateFnsUtils from '@date-io/date-fns'
import deLocale from 'date-fns/locale/de';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import { withSnackbar } from 'notistack';

import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import mainStyle from "assets/jss/44/main.jsx";

// 44 components
import Statistic from 'components/44/Statistic';
import API from 'components/44/ApiPlanungswelten';
import DB from 'components/44/Database';
import combineStyles from 'components/44/combineStyles';
import Close from "@material-ui/icons/Close";
import SnackbarClose from 'components/Snackbar/SnackbarClose';
import CustomButton from "components/CustomButtons/Button.jsx";

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_material);

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true

class AmCharts2021 extends React.Component {

  constructor(props) {

    let selected_partner = localStorage.getItem('stats_selected_partner') || false
    if(selected_partner && selected_partner!=='undefined' && selected_partner.length > 5){
      selected_partner = JSON.parse(selected_partner)
    }else{
      selected_partner = API.getPartners(true)
    }

    let start_date = localStorage.getItem('stats_start_date') || false
    if(start_date && typeof start_date !== 'undefined' && start_date.length > 10){
      start_date = new Date(start_date)
    }else{
      start_date = new Date();
      start_date.setMonth(start_date.getMonth() - 13);
    }

    let end_date = localStorage.getItem('stats_end_date') || false
    if(end_date && typeof end_date !== 'undefined' && end_date.length > 10){
      end_date = new Date(end_date)
    }else{
      end_date = new Date();
      end_date.setMonth(end_date.getMonth() - 1);
    }

    super(props);
    this.state = {
      locale: deLocale,
      all_partner: API.getPartners(),
      selected_partner: selected_partner,
      selected_start_date: start_date,
      selected_end_date: end_date,
      min_date: new Date('2018-10-01'), // Start of Tracking
      max_date: new Date(), // today
      //selected_daterange: 2019,
      categories_level1: [],
      categories_level2: [],
      selected_cat_level_2: false,
      selected_cat_level_3: false,
      selected_deleted_cat_level_2: false,
      selected_deleted_cat_level_3: false,
      loader: false,
    };
  }

  statisticOptions(){
    return {
      version: 3,
      demo: DB.should_demo_daten() ? true : false,
      partner_ids: this.state.selected_partner,
      stats_start_date: this.state.selected_start_date,
      stats_end_date: this.state.selected_end_date,
      start_year: this.state.selected_start_date.getFullYear(),
      start_month: this.state.selected_start_date.getMonth()+1,
      end_year: this.state.selected_end_date.getFullYear(),
      end_month: this.state.selected_end_date.getMonth()+1,
    }
  }

  refreshStatistics(callerInfo){
    // console.debug('called by '+callerInfo);
    let _this = this
    const statistic_keys = ['deleted_all', 'sold_all', 'sold_online_all', 'sold_offline_all',
      'online_all', 'deleted_ww_all', 'sold_ww_all', 'sold_online_ww_all', 'sold_offline_ww_all', 'online_ww_all']

    Statistic.getRemoteStatistics(
      this.statisticOptions()
    ).then(() => {

      statistic_keys.forEach(function (key){
        let obj  = {}
        let obj2  = {}

        Statistic.getStatistic(key, 'auto').then( (data) => {
          obj[key] = data
          _this.setState(obj)
        })

        obj2[key] = localStorage[key]
        _this.setState(obj2)
      })

      this.stackedBarChartUmsatz()
      this.stackedBarChartVerkaeufe()
      this.barChartAngelegteVerkaufteGesamt()
      this.barChartVerkaufteWarenwert()
      this.barChartVerkaufte()
      this.barChartAngelegteWarenwert()
      this.barChartAngelegte()
      this.pieChartAngelegteVerkaufteByKategorie(1)
      this.pieChartAngelegteVerkaufteByKategorie(2)
      this.pieChartAngelegteVerkaufteByKategorie(3)
      //this.barChartAngelegteVerkaufteGesamtWarenwert()

      // console.debug('Statistiken aktualisiert!')
    }).catch(() => {
      _this.props.closeSnackbar(_this.state.loader)
      _this.props.enqueueSnackbar('Fehler beim Laden der Statistik-Daten! Bitte wiederholen sie den Vorgang oder laden sie die App neu!', { persist:true, variant: 'error', action: (<SnackbarClose/>)})
    })
  }

  componentDidMount() {
    let _this = this

    const statistic_keys = ['deleted_all', 'sold_all', 'sold_online_all', 'sold_offline_all',
          'online_all', 'deleted_ww_all', 'sold_ww_all', 'sold_online_ww_all', 'sold_offline_ww_all', 'online_ww_all']

    statistic_keys.forEach(function (key){
      let obj  = {}
      let obj2  = {}

      Statistic.getStatistic(key, 'auto').then( (data) => {
        obj[key] = data
        _this.setState(obj)
      })

      obj2[key] = localStorage[key]
      _this.setState(obj2)
    })


    Statistic.getStatisticKeys('online_and_deleted_by_cat_level1').then((keys)=>{
      if(typeof keys !== 'undefined'){
        _this.setState({categories_level1: keys})
      }
    })


    /*this.setState({loader: this.props.enqueueSnackbar('Statistiken werden geladen...', { persist:true, variant: 'default', action: <Close/> })},function () {
      setTimeout(function(){
        if(!localStorage.getItem('stats_selected_partner')){
          _this.refreshStatistics('componentDidMount')
        }else{
          _this.barChartAngelegteVerkaufteGesamt()
          _this.barChartVerkaufteWarenwert()
          _this.barChartVerkaufte()
          _this.barChartAngelegteWarenwert()
          _this.barChartAngelegte()
          _this.stackedBarChartUmsatz();
          _this.stackedBarChartVerkaeufe();
          _this.pieChartAngelegteVerkaufteByKategorie(1)
          _this.pieChartAngelegteVerkaufteByKategorie(2)
          _this.pieChartAngelegteVerkaufteByKategorie(3)
          //_this.barChartAngelegteVerkaufteGesamtWarenwert()
        }
      }, 1)
    });*/

  } // ComponentDidMount

  parseFloatAvg(val1, val2) {
    try {
      if(!parseFloat(val1) || !parseFloat(val2)){
        return 0
      }
      // val1 = val1.replaceAll('.','').replaceAll(',','.') // remove german thousand's point; commas replace to points
      // val2 = val2.replaceAll('.','').replaceAll(',','.') // remove german thousand's point; commas replace to points

      let avg = (parseFloat(val1)/parseFloat(val2));
      avg = parseFloat(avg.toFixed(2)) // only two digits after comma
      avg = avg.toLocaleString('de'); // transform to german format
      return avg
    } catch (e) {
      return 0
    }
  }

  parseFloat(val){
    val = parseFloat(val)
    val = val.toFixed(2)
    val = parseFloat(val).toLocaleString('de');
    return val;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  formatYearMonth(date) {
    return date.getFullYear() +'-'+ date.getMonth();
  }

  handleStandorteChange = v => {
    let _this = this
    if(this.state.selected_partner !== v){
      //this.setState({loader: this.props.enqueueSnackbar('Statistiken werden geladen...', { persist:true, variant: 'default', action: <Close/> })},function () {
        setTimeout(function(){
          _this.setState({ selected_partner: v});
          //_this.refreshStatistics('handleStandorteChange')
        }, 1)
      //});
    }
  }

  handleStartDateChange = date => {
    let _this = this
    let selected_year_month = this.formatYearMonth(this.state.selected_start_date)
    let new_selected_year_month = this.formatYearMonth(date)

    if(selected_year_month !== new_selected_year_month){
      //this.setState({loader: this.props.enqueueSnackbar('Statistiken werden geladen...', { persist:true, variant: 'default', action: <Close/> })},function () {
        setTimeout(function(){
          _this.setState({ selected_start_date: date }, function () {
            //_this.refreshStatistics('handleStartDateChange')
          });
        }, 1)
      //});
    }
  };

  handleEndDateChange = date => {
    let _this = this
    let selected_end_date = this.formatYearMonth(this.state.selected_end_date)
    let new_selected_year_month = this.formatYearMonth(date)

    if(selected_end_date !== new_selected_year_month) {
      //this.setState({loader: this.props.enqueueSnackbar('Statistiken werden geladen...', { persist:true, variant: 'default', action: <Close/> })},function () {
        setTimeout(function(){
          _this.setState({selected_end_date: date}, function () {
            //_this.refreshStatistics('handleEndDateChange')
          });
        }, 1)
      //});
    }

    //this.props.closeSnackbar(loader)
  };

  handleRefreshStatistics = () => {
    let _this = this

    this.setState({loader: this.props.enqueueSnackbar('Statistiken werden geladen...', { persist:true, variant: 'default', action: <Close/> })},function () {
      setTimeout(function(){
        _this.refreshStatistics('handleRefreshStatistics')
      }, 1)
    });

    //this.props.closeSnackbar(loader)
  };

  barChartAngelegteVerkaufteGesamt(){
    return this.barChartCombinedDefault({
      name: "barChartAngelegteGeloeschteGesamt",
      type: "online_and_deleted_by_partner",
      legende1: "Angelegte",
      legende2: "Gelöschte",
      subtype1: "online",
      subtype2: "deleted",
      filePrefix: "Angelegte_Geloeschte_Artikel_Gesamt",
      titleYAxis: "Anzahl angelegte/gelöschte Artikel Gesamt",
      tooltip1: "[bold]{valueY}[/] angelegt",
      tooltip2: "[bold]{valueY}[/] gelöscht"
    })
  }

  barChartVerkaufteWarenwert(){ // Umsatz der Verkäufe
    return this.barChartDefault({
      name: "barChartVerkaufteWarenwert",
      type: "online_and_deleted_by_month_and_partner",
      subtype: "sold_ww",
      filePrefix: "Verkaufte_Artikel_pro_Monat_Warenwert",
      titleYAxis: "Warenwert in € verkaufter Artikel",
      tooltip: "{name}: [bold]{valueY} €[/]"
    })
  }

  barChartVerkaufteWarenwert_todo(){ // Umsatz der Verkäufe
    return this.barChartCombinedDefault({
      name: "barChartVerkaufteWarenwert",
      type: "online_and_deleted_by_month_and_partner",
      legende1: "Verkäufe Online",
      legende2: "Verkäufe Stationär",
      subtype1: "sold_online_ww",
      subtype2: "sold_offline_ww",
      filePrefix: "Verkaufte_Artikel_pro_Monat_Warenwert",
      titleYAxis: "Warenwert in € verkaufter Artikel",
      tooltip1: "{name}: [bold]{valueY} €[/]",
      tooltip2: "{name}: [bold]{valueY} €[/]"
    })
  }

  barChartAngelegteWarenwert(){
    return this.barChartDefault({
      name: "barChartAngelegteWarenwert",
      type: "online_and_deleted_by_month_and_partner",
      subtype: "online_ww",
      filePrefix: "Angelete_Artikel_pro_Monat_Warenwert",
      titleYAxis: "Warenwert in € angelegter Artikel",
      tooltip: "{name}: [bold]{valueY} €[/]"
    })
  }

  barChartVerkaufte(){ // Anzahl Verkäufe
    return this.barChartDefault({
      name: "barChartVerkaufte",
      type: "online_and_deleted_by_month_and_partner",
      subtype: "sold",
      filePrefix: "Verkaufte_Artikel_pro_Monat",
      titleYAxis: "Anzahl verkaufte Artikel",
      tooltip: "{name}: [bold]{valueY} Verkäufe[/]"
    })
  }

  barChartVerkaufte_todo(){ // Anzahl Verkäufe
    return this.barChartCombinedDefault({
      name: "barChartVerkaufte",
      type: "online_and_deleted_by_month_and_partner",
      legende1: "Verkäufe Online",
      legende2: "Verkäufe Stationär",
      subtype1: "sold",
      subtype2: "sold",
      filePrefix: "Verkaufte_Artikel_pro_Monat",
      titleYAxis: "Anzahl verkaufte Artikel",
      tooltip1: "{name}: [bold]{valueY} Verkäufe[/]",
      tooltip2: "{name}: [bold]{valueY} Verkäufe[/]"
    })
  }

  barChartAngelegte(){
    return this.barChartDefault({
      name: "barChartAngelegte",
      type: "online_and_deleted_by_month_and_partner",
      subtype: "online",
      filePrefix: "Angelete_Artikel_pro_Monat",
      titleYAxis: "Anzahl angelegte Artikel",
      tooltip: "{name}: [bold]{valueY} Stück[/]"
    })
  }

  stackedBarChartUmsatz(){
    return this.barChartDefault({
      name: "stackedBarChartUmsatz",
      type: "online_and_deleted_by_month_and_partner",
      subtype: ["sold_online_ww", "sold_offline_ww", "deleted_ww"],
      subtypeLabels: ["online", "stationär", "gelöschte"],
      filePrefix: "Umsatz_nach_Art_pro_Monat",
      titleYAxis: "Umsatz nach Art in €",
      tooltip: "{name}: [bold]{valueY} €[/]"
    })
  }

  stackedBarChartVerkaeufe(){
    return this.barChartDefault({
      name: "stackedBarChartVerkaeufe",
      type: "online_and_deleted_by_month_and_partner",
      subtype: ["sold_online", "sold_offline", "deleted_others"],
      subtypeLabels: ["online", "stationär", "sonstige"],
      filePrefix: "Verkaeufe_nach_Art_pro_Monat",
      titleYAxis: "Anzahl Verkäufe nach Art",
      tooltip: "{name}: [bold]{valueY} Verkäufe[/]"
    })
  }

  /*barChartAngelegteVerkaufteGesamtWarenwert(){
    return this.barChartCombinedDefault({
      name: "barChartAngelegterGeloeschterGesamtWarenwert",
      type: "online_and_deleted_by_partner",
      legende1: "Angelegte",
      legende2: "Verkaufte",
      subtype1: "online_ww",
      subtype2: "deleted_ww",
      filePrefix: "Warenwert_angelegter_geloeschter_Artikel_Gesamt",
      titleYAxis: "Warenwert angelegter/gelöschter Artikel Gesamt",
      tooltip1: "[bold]{valueY} €[/] angelegt",
      tooltip2: "[bold]{valueY} €[/] verkauft"
    })
  }*/


  barChartDefault(args){
    /* snackbar.variant = loading wirft einen Fehler in der Konsole, weil es custom erweitert wurde mit einem Ladeicon. Funktioniert aber */
    //args.notify = this.props.enqueueSnackbar('Statistik "'+args.titleYAxis+'" wird geladen...', { persist:true, variant: 'default', action: <Close/> })
    Statistic.getStatistic(args.type, 'auto', this.statisticOptions()).then( (data) => {
      //console.log('barChartDefault '+args.name+' LOADED', data);

      let chart = am4core.create(args.name, am4charts.XYChart);
      chart.language.locale = am4lang_de_DE;
      chart.numberFormatter.numberFormat = "#,###.##";
      chart.exporting.menu = new am4core.ExportMenu();
      chart.padding(0, 16, 20, 0);

      let filePrefix = args.filePrefix;

      let subtype = args.subtype
      let subtypeLabels = args.subtypeLabels || ['','','','','']
      let stacked = false

      console.debug('subtype', subtype);
      console.debug('is_array subtype', Array.isArray(subtype));
      if(Array.isArray(subtype)){
        stacked = true
      }

      chart.data = data

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "month";
      categoryAxis.title.text = "Monate";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 20;
      categoryAxis.renderer.cellStartLocation = 0.1;
      categoryAxis.renderer.cellEndLocation = 0.9;

      var  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = args.titleYAxis;

      // Create series
      function createSeries(field, name, stacked) {
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "month";
        series.name = name;
        series.columns.template.tooltipText = args.tooltip;
        series.stacked = stacked;
        series.columns.template.width = am4core.percent(95);
      }

      let _this = this

      let selected_partner_names = []

      Object.keys(_this.state.all_partner).forEach(function(key) {
        let partner = _this.state.all_partner[key]

        if(_this.state.selected_partner.includes(partner.value)){
          selected_partner_names.push(partner.name)
          //console.log("createSeries", [partner.value + '_' + subtype, partner.name]);

          if(stacked){
            subtype.forEach(function(k, i) {
              createSeries(partner.value + '_' + k, partner.name + ' ' + subtypeLabels[i], i!==0);
            })
          }else{
            createSeries(partner.value + '_' + subtype, partner.name, false);
          }
        }
      });

      chart.exporting.filePrefix = filePrefix+"--"+selected_partner_names.join('_').replace(' ', '-');

      // Add legend
      chart.legend = new am4charts.Legend();

      // Add Scrollbar
      chart.scrollbarX = new am4core.Scrollbar();

      // ... chart code ends here ...

      this.chart = chart;
      this.props.closeSnackbar(args.notify)
      //this.props.enqueueSnackbar('Statistik "'+args.titleYAxis+'"  fertig!', { variant: 'success'})
    })
  }


  barChartCombinedDefault(args){
    //args.notify = this.props.enqueueSnackbar('Statistik "'+args.titleYAxis+'" wird geladen...', { persist:true, variant: 'default', action: <Close/> })
    Statistic.getStatistic(args.type, 'auto', this.statisticOptions()).then( (data) => {
      //console.log('barChartDefault '+args.name+' LOADED', data);

      let chart = am4core.create(args.name, am4charts.XYChart);
      chart.language.locale = am4lang_de_DE;
      chart.numberFormatter.numberFormat = "#,###.##";
      chart.exporting.menu = new am4core.ExportMenu();
      chart.padding(0, 16, 20, 0);
      chart.colors.list = [
        //am4core.color("#66bb6a"),
        //am4core.color("#f44336"),
        am4core.color("#bbbbb5"),
        am4core.color("#72f4b2"),
      ];

      let filePrefix = args.filePrefix;
      let name1 = args.legende1
      let name2 = args.legende2
      let subtype1 = args.subtype1
      let subtype2 = args.subtype2
      let tooltip1 = args.tooltip1
      let tooltip2 = args.tooltip2

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "name";
      categoryAxis.title.text = "Standorte";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = args.titleYAxis;

      // Create series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name1;
      series.dataFields.valueY = subtype1;
      series.dataFields.categoryX = "name";
      series.clustered = false;
      series.tooltipText = tooltip1;

      var series2 = chart.series.push(new am4charts.ColumnSeries());
      series2.name = name2;
      series2.dataFields.valueY = subtype2;
      series2.dataFields.categoryX = "name";
      series2.clustered = false;
      series2.columns.template.width = am4core.percent(50);
      series2.tooltipText = tooltip2;

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineX.disabled = true;
      chart.cursor.lineY.disabled = true;

      // Create series
      /*
      function createSeries(field, name, stacked) {
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "name";
        series.name = name;
        series.columns.template.tooltipText = args.tooltip;
        series.stacked = stacked;
        series.columns.template.width = am4core.percent(95);
      }*/

      let _this = this

      let selected_partner_names = []
      let data_filtered = []

      Object.keys(_this.state.all_partner).forEach(function(key) {
        let partner = _this.state.all_partner[key]

        if(_this.state.selected_partner.includes(partner.value)){
          selected_partner_names.push(partner.name)

          if(data[partner.value]){
            data_filtered.push(data[partner.value])
          }
        }
      });

      chart.data = data_filtered

      chart.exporting.filePrefix = filePrefix+"--"+selected_partner_names.join('_').replace(' ', '-');

      // Add legend
      chart.legend = new am4charts.Legend();

      // Add Scrollbar
      chart.scrollbarX = new am4core.Scrollbar();

      this.chart = chart;
      //this.props.closeSnackbar(args.notify)
    })
  }


  pieChartAngelegteVerkaufteByKategorie(level=1, selected_cat = false){
    let _this = this
    if(level === 1 || (level > 1 && selected_cat)){
      Statistic.getStatisticByCategory('online_and_deleted_by_cat_level'+level, 'auto', _this.statisticOptions(), selected_cat).then( (data) => {
        let chart = am4core.create("pieChartAngelegteVerkaufteByKategorie"+level, am4charts.PieChart);
        chart.language.locale = am4lang_de_DE;
        chart.exporting.menu = new am4core.ExportMenu();

        chart.padding(0, 0, 0, 0);

        // Add legend
        chart.legend = new am4charts.Legend();
        chart.legend.position = "bottom";

        chart.events.on("datavalidated", resizeLegend);
        //chart.events.on("maxsizechanged", resizeLegend(this.props));
        //chart.legend.events.on("datavalidated", resizeLegend(this.props));
        //chart.legend.events.on("maxsizechanged", resizeLegend(this.props));
        function resizeLegend(e) {
          document.getElementById("pieLegendAngelegteVerkaufteByKategorie"+level).style.height = chart.legend.contentHeight + "px";

          //_this.props.enqueueSnackbar('Statistiken wurden geladen...', { persist:true, variant: 'default', action: <Close/> })
          _this.props.closeSnackbar(_this.state.loader)
        }

        let legendContainer = am4core.create("pieLegendAngelegteVerkaufteByKategorie"+level, am4core.Container);
        legendContainer.width = am4core.percent(100);
        legendContainer.height = am4core.percent(100);
        chart.legend.parent = legendContainer;

        // Let's cut a hole in our Pie chart the size of 40% the radius
        chart.innerRadius = am4core.percent(40);

        chart.data = data

        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "deleted";
        pieSeries.dataFields.category = "name_deleted";
        pieSeries.dataFields.hidden = "hidden_deleted";
        pieSeries.slices.template.tooltipText = "[bold]{name_deleted}[/]: {deleted} verkauft - {value.percent.formatNumber('#.0')}%".replace(' (verk.)', '').replace(' (ang.)', '');
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;


        // Disabling labels and ticks on inner circle
        pieSeries.labels.template.fontSize = 10;
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;

        // Disable sliding out of slices
        pieSeries.slices.template.states.getKey("hover").properties.shiftRadius = 0;
        pieSeries.slices.template.states.getKey("hover").properties.scale = 0.9;

        // Add second series
        var pieSeries2 = chart.series.push(new am4charts.PieSeries());
        pieSeries2.dataFields.value = "online";
        pieSeries2.dataFields.category = "name";
        pieSeries2.dataFields.hidden = "hidden_online";
        pieSeries2.slices.template.stroke = am4core.color("#fff");
        pieSeries2.slices.template.tooltipText = "[bold]{name}[/]: {online} angelegt - {value.percent.formatNumber('#.0')}%";
        pieSeries2.slices.template.strokeWidth = 2;
        pieSeries2.slices.template.strokeOpacity = 1;
        pieSeries2.slices.template.states.getKey("hover").properties.shiftRadius = 0;
        pieSeries2.slices.template.states.getKey("hover").properties.scale = 1.1;


        if(data===false){
          chart.data = [{
            "category": 1,
            "name": "Keine Daten vorhanden",
            "deleted": 1,
            "disabled": true,
          }];

          // Add and configure Series
          pieSeries.dataFields.value = "deleted";
          pieSeries.dataFields.category = "name";
          pieSeries.dataFields.hidden = "hidden_deleted";
          pieSeries.slices.template.tooltipText = "Keine Daten für diese Kategorie vorhanden!";
          pieSeries.slices.template.stroke = am4core.color("#dadada");
          pieSeries.slices.template.fill = am4core.color("#dadada");
          pieSeries.slices.template.fillOpacity = 0.3;
          pieSeries.slices.template.strokeWidth = 2;
          pieSeries.slices.template.strokeOpacity = 1;
          pieSeries.slices.template.strokeDasharray = "4,4";

          pieSeries2.slices.template.tooltipText = "Keine Daten für diese Kategorie vorhanden!";
          pieSeries2.slices.template.stroke = am4core.color("#dadada");
          pieSeries2.slices.template.fill = am4core.color("#dadada");
          pieSeries2.slices.template.fillOpacity = 0.3;

          pieSeries2.labels.template.disabled = true;
          pieSeries2.labels.template.fontSize = 5;
          pieSeries2.ticks.template.disabled = true;

        }

      })
    }
  }


  render() {
    const {classes} = this.props;
    return (
      <div>
        {/*<Heading
          textAlign="center"
          title="Statistiken"
          category={
            <span></span>
          }
        />*/}
        {window.innerHeight > window.innerWidth && window.innerWidth < 600 ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <div>
              <h3>
                <ScreenRotation /> Bitte drehen Sie ihr Gerät ins Querformat
              </h3>
            </div>
          </GridItem>
        </GridContainer>) : ''}

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12} dense={0}>

              <Card chart>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={6} dense={0}>

                      <CustomSelect
                        multiple={true}
                        buttonText="Standorte auswählen"
                        onChange={this.handleStandorteChange}
                        dropdownList={API.getPartners()}
                        value={this.state.selected_partner}
                        values={this.state.selected_partner}
                      />

                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={6} dense={0}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={this.state.locale}>
                        <DatePicker
                          className={classes.datepickerStart}
                          views={['year', 'month']}
                          openTo="year"
                          label="Start Jahr/Monat"
                          //helperText="Start Jahr/Monat wählen"
                          value={this.state.selected_start_date}
                          onChange={this.handleStartDateChange}
                          minDate={this.state.min_date}
                          maxDate={this.state.max_date}
                          cancelLabel={'Abbrechen'}
                          autoOk={false}
                          style={{/*minWidth: '25%', marginRight: 30, marginTop: 20, marginBottom: 20*/}}
                        />
                        <DatePicker
                          className={classes.datepickerEnd}
                          views={['year', 'month']}
                          openTo="year"
                          label="End Jahr/Monat"
                          //helperText="End Jahr/Monat wählen"
                          value={this.state.selected_end_date}
                          onChange={this.handleEndDateChange}
                          minDate={this.state.min_date}
                          maxDate={this.state.max_date}
                          cancelLabel={'Abbrechen'}
                          autoOk={false}
                          style={{/*minWidth: '25%', marginTop: 20, marginBottom: 20*/}}
                        />
                      </MuiPickersUtilsProvider>

                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={6} dense={0}>
                      <h5>Anzahl Angelegte Gesamt*: <strong>{this.state.online_all}</strong></h5>
                      <h5>Anzahl Gelöschte Gesamt*: <strong>{this.state.deleted_all}</strong></h5>
                      <h5>Anzahl Verkäufe Gesamt*: <strong>{this.state.sold_all}</strong></h5>
                      <h5>Anzahl online Verkäufe*: <strong>{this.state.sold_online_all}</strong></h5>
                      <h5>Anzahl stationäre Verkäufe*: <strong>{this.state.sold_offline_all}</strong></h5>
                      <small>* im gewählten Zeitraum</small>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={6} dense={0}>
                      {this.state.online_ww_all ? (
                      <h5>Warenwert Angelegte Gesamt*: <strong>{this.parseFloat(this.state.online_ww_all)} &euro;</strong></h5>
                      ) : ''}
                      {this.state.online_all && this.state.online_ww_all ? (
                      <h5>Durchschnittlicher Ww. Angelegte*: <strong>{this.parseFloatAvg(this.state.online_ww_all, this.state.online_all)} &euro;</strong></h5>
                      ) : ''}
                      <h5>Umsatz Gesamt*: <strong>{this.parseFloat(this.state.sold_ww_all || 0)} &euro;</strong></h5>
                      <h5>Umsatz online Verkäufe*: <strong>{this.parseFloat(this.state.sold_online_ww_all || 0)} &euro;</strong></h5>
                      <h5>Umsatz stationäre Verkäufe*: <strong>{this.parseFloat(this.state.sold_offline_ww_all || 0)} &euro;</strong></h5>
                      {this.state.sold_all && this.state.sold_ww_all ? (
                      <h5>Durchschnittlicher Ww. pro Verkauf*: <strong>{this.parseFloatAvg(this.state.sold_ww_all, this.state.sold_all)} &euro;</strong></h5>
                      ) : ''}
                      <small>* im gewählten Zeitraum</small>
                    </GridItem>
                    <GridItem xs={12} dense={1} style={{textAlign: 'right' }}>
                      <CustomButton icon="refresh" color="info" onClick={this.handleRefreshStatistics}>Statistiken aktualisieren</CustomButton>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>

          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12} dense={0}>
            <h4>Legende / Begriffserklärungen</h4>
            <ul>
              <li><small>Anzahl Verkäufe = Anzahl der Transaktionen. Pro Transaktion geht es um einen Artikel, unabhängig von der verkauften Stückzahl/Menge.</small></li>
              <li><small>Gelöschte Artikel = Es geht um aus dem System gelöschte Artikel. Diese können verkauft, nicht verkauft, aus Fehler- oder andern Gründen gelöscht worden sein.</small></li>
            </ul>
          </GridItem>
        </GridContainer>

        <GridContainer>

          <GridItem xs={12} sm={12} md={12} lg={12} dense={0}>
            <Card chart>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <EuroSymbol/>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Umsatz durch Verkäufe - <small>pro Monat / pro Partner</small>
                </h4>
              </CardHeader>

              <CardBody>
                {this.state.selected_partner ? (
                  <div id="barChartVerkaufteWarenwert" style={{ width: "100%", height: "500px" }}></div>
                ) : null }Angelegte Artikel & Anzahl Verkäufe
              </CardBody>

              <CardFooter stats>
                <div className={classes.stats}>
                  <Update/>zuletzt aktualisiert: {Statistic.getStatisticDateLastUpdated()} Uhr
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12} dense={0}>
            <Card chart>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <Timeline />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Anzahl Verkäufe - <small>pro Monat / pro Partner</small>
                </h4>
              </CardHeader>

              <CardBody>
                {this.state.selected_partner ? (
                  <div id="barChartVerkaufte" style={{ width: "100%", height: "500px" }}></div>
                ) : null }
              </CardBody>

              <CardFooter stats>
                <div className={classes.stats}>
                  <Update/>zuletzt aktualisiert: {Statistic.getStatisticDateLastUpdated()} Uhr
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12} dense={0}>
            <Card chart>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <EuroSymbol />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Umsatz nach Art <small>pro Monat / pro Partner</small>
                </h4>
              </CardHeader>
              <CardBody>
                {this.state.selected_partner && Statistic.getStatisticData('online_and_deleted_by_month_and_partner') ? (
                  <div id="stackedBarChartUmsatz" style={{ width: "100%", height: "500px" }}></div>
                ) : null }
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update/>zuletzt aktualisiert: {Statistic.getStatisticDateLastUpdated()} Uhr
                </div>
              </CardFooter>
            </Card>
          </GridItem>


          <GridItem xs={12} sm={12} md={12} lg={12} dense={0}>
            <Card chart>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <BarChart />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Anzahl Verkäufe nach Art <small>pro Monat / pro Partner</small>
                </h4>
              </CardHeader>
              <CardBody>
                {this.state.selected_partner && Statistic.getStatisticData('online_and_deleted_by_month_and_partner') ? (
                  <div id="stackedBarChartVerkaeufe" style={{ width: "100%", height: "500px" }}></div>
                ) : null }
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update/>zuletzt aktualisiert: {Statistic.getStatisticDateLastUpdated()} Uhr
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12} dense={0}>
            <Card chart>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <BarChart/>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Warenwert angelegter Artikel - <small>pro Monat / pro Partner</small>
                </h4>
              </CardHeader>

              <CardBody>
                {this.state.selected_partner ? (
                  <div id="barChartAngelegteWarenwert" style={{ width: "100%", height: "500px" }}></div>
                ) : null }
              </CardBody>

              <CardFooter stats>
                <div className={classes.stats}>
                  <Update/>zuletzt aktualisiert: {Statistic.getStatisticDateLastUpdated()} Uhr
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12} dense={0}>
            <Card chart>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <BarChart/>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Anzahl angelegte Artikel - <small>pro Monat / pro Partner</small>
                </h4>
              </CardHeader>
              <CardBody>
                {this.state.selected_partner && Statistic.getStatisticData('online_and_deleted_by_month_and_partner') ? (
                  <div id="barChartAngelegte" style={{ width: "100%", height: "500px" }}></div>
                ) : null }
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update/>zuletzt aktualisiert: {Statistic.getStatisticDateLastUpdated()} Uhr
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12} dense={0}>
            <Card chart>
              <CardHeader color="danger" icon>
                <CardIcon color="danger">
                  <BarChart />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Anzahl angelegte/gelöschte Artikel - <small>Gesamt / pro Partner</small>
                </h4>
              </CardHeader>

              <CardBody>
                {this.state.selected_partner ? (
                  <div id="barChartAngelegteGeloeschteGesamt" style={{ width: "100%", height: "500px" }}></div>
                ) : null }
              </CardBody>

              <CardFooter stats>
                <div className={classes.stats}>
                  <Update/>zuletzt aktualisiert: {Statistic.getStatisticDateLastUpdated()} Uhr
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12} dense={0}>
            <Card chart>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <PieChart />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Angelegte Artikel & Anzahl Verkäufe pro Hauptkategorie</h4>
              </CardHeader>
              <CardBody>
                <div id="pieChartAngelegteVerkaufteByKategorie1" style={{ width: "100%", height: "700px" }}></div>
                <div id="pieLegendAngelegteVerkaufteByKategorie1" style={{ width: "100%", height: "300px" }}></div>
              </CardBody>
              <CardFooter stats className={classes.cardFooter}>
                <div className={classes.stats}>
                  <Update/>zuletzt aktualisiert: {Statistic.getStatisticDateLastUpdated()} Uhr
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12} dense={0}>
            <Card chart>
              <CardHeader color="info" icon>
                <GridContainer>
                  <CardIcon color="info" style={{height:"100%"}}>
                    <PieChart />
                  </CardIcon>
                  <GridItem xs={12} sm={6} md={6} lg={6}>
                    <h4 className={classes.cardIconTitle}>Angelegte Artikel & Anzahl Verkäufe pro Unterkategorie</h4>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={4}>
                    <CustomSelect
                      buttonText="Hauptkategorie auswählen"
                      onChange={(v) => {
                        this.setState({selected_deleted_cat_level_2: v}, this.pieChartAngelegteVerkaufteByKategorie(2, v) )

                        let _this = this
                        Statistic.getStatisticKeys('online_and_deleted_by_cat_level2', v).then((keys)=>{
                          if(typeof keys !== 'undefined'){
                            _this.setState({categories_level2: keys})
                          }
                        })

                      }}
                      dropdownList={this.state.categories_level1}
                    />
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
              {this.state.selected_deleted_cat_level_2 ? (
                <React.Fragment>
                  <div id="pieChartAngelegteVerkaufteByKategorie2" style={{ width: "100%", height: "500px" }}></div>
                  <div id="pieLegendAngelegteVerkaufteByKategorie2" style={{ width: "100%", height: "200px" }}></div>
                  <CustomSelect
                    buttonText="Unterkategorie auswählen"
                    onChange={(v) => { this.setState({selected_deleted_cat_level_3: v}, this.pieChartAngelegteVerkaufteByKategorie(3, v) )}}
                    dropPlacement="top"
                    dropdownList={this.state.categories_level2}
                  />
                </React.Fragment>) : null }
              {this.state.selected_deleted_cat_level_3 && this.pieChartAngelegteVerkaufteByKategorie(3, this.state.selected_deleted_cat_level_3) !== false ? (
                <React.Fragment>
                  <div id="pieChartAngelegteVerkaufteByKategorie3" style={{ width: "100%", height: "500px" }}></div>
                  <div id="pieLegendAngelegteVerkaufteByKategorie3" style={{ width: "100%", height: "200px" }}></div>
                </React.Fragment>) : <h5>In dieser Kategorie sind keine Artikel-Daten verfügbar</h5> }
              </CardBody>
              <CardFooter stats className={classes.cardFooter}>
                <div className={classes.stats}>
                  <Update/>zuletzt aktualisiert: {Statistic.getStatisticDateLastUpdated()} Uhr
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          {/*<GridItem xs={12} sm={12} md={12} lg={6} dense={0}>
            <Card chart>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <BarChart />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Warenwert angelegter/gelöschter Artikel - <small>Gesamt / pro Partner</small>
                </h4>
              </CardHeader>

              <CardBody>
                {this.state.selected_partner ? (
                  <div id="barChartAngelegterGeloeschterGesamtWarenwert" style={{ width: "100%", height: "500px" }}></div>
                ) : null }
              </CardBody>

              <CardFooter stats>
                <div className={classes.stats}>
                  <Update/>zuletzt aktualisiert: {Statistic.getStatisticDateLastUpdated()} Uhr
                </div>
              </CardFooter>
            </Card>
          </GridItem>*/}

        </GridContainer>

        {DB.is_pw_account() ? (
          <GridContainer>
          </GridContainer>) : ''}
      </div>
    );
  }
}

export default withSnackbar(withStyles(combineStyles(mainStyle, chartsStyle))(AmCharts2021));
