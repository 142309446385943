import GridItem from "../Grid/GridItem";
// eslint-disable-next-line
import { debug } from "helpers/44Helper";
var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');
var classNames = require('classnames');
var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');
var _lodash2 = _interopRequireDefault(_lodash);

var _ExpansionPanel = require('@material-ui/core/ExpansionPanel/index.js');
var _ExpansionPanel2 = _interopRequireDefault(_ExpansionPanel);

var _ExpansionPanelSummary = require('@material-ui/core/ExpansionPanelSummary/index.js');
var _ExpansionPanelSummary2 = _interopRequireDefault(_ExpansionPanelSummary);

var _ExpansionPanelDetails = require('@material-ui/core/ExpansionPanelDetails/index.js');
var _ExpansionPanelDetails2 = _interopRequireDefault(_ExpansionPanelDetails);

var _ExpandMoreIcon = require('@material-ui/icons/ExpandMore');

//var _TextField = require('material-ui/TextField');
//var _TextField2 = _interopRequireDefault(_TextField);

var TextField14 = require('@material-ui/core/TextField/index.js');
var _TextField14 = _interopRequireDefault(TextField14);

//var _FlatButton = require('material-ui/FlatButton');
//var _FlatButton2 = _interopRequireDefault(_FlatButton);

//var _Checkbox = require('material-ui/Checkbox');
//var _Checkbox2 = _interopRequireDefault(_Checkbox);

var FormControl14 = require('@material-ui/core/FormControl');
var _FormControl14 = _interopRequireDefault(FormControl14);

var FormLabelControl14 = require('@material-ui/core/FormControlLabel');
var _FormLabelControl14 = _interopRequireDefault(FormLabelControl14);

var Checkbox14 = require('@material-ui/core/Checkbox');
var _Checkbox14 = _interopRequireDefault(Checkbox14);

var Switch14 = require('@material-ui/core/Switch');
var _Switch14 = _interopRequireDefault(Switch14);

var _reactLayoutComponents = require('react-layout-components');
var _reactLayoutComponents2 = _interopRequireDefault(_reactLayoutComponents);

//var _materialUiChipInput = require('material-ui-chip-input');
//var _materialUiChipInput2 = _interopRequireDefault(_materialUiChipInput);

//var _SelectField = require('material-ui/SelectField');
//var _SelectField2 = _interopRequireDefault(_SelectField);

var SelectField14 = require('@material-ui/core/Select/index.js');
var _SelectField14 = _interopRequireDefault(SelectField14);

//var Input14 = require('@material-ui/core/Input');
//var _Input14 = _interopRequireDefault(Input14);

var InputLabel14 = require('@material-ui/core/InputLabel');
var _InputLabel14 = _interopRequireDefault(InputLabel14);

var InputAdornment14 = require('@material-ui/core/InputAdornment');
var _InputAdornment14 = _interopRequireDefault(InputAdornment14);

var _RaisedButton = require('material-ui/RaisedButton');
var _RaisedButton2 = _interopRequireDefault(_RaisedButton);

var _Button = require('@material-ui/core/Button');
var _Button2 = _interopRequireDefault(_Button);

var _fineUploaderWrappers = require('fine-uploader-wrappers');
var _fineUploaderWrappers2 = _interopRequireDefault(_fineUploaderWrappers);

var _MenuItem = require('material-ui/MenuItem');
var _MenuItem2 = _interopRequireDefault(_MenuItem);

var MenuList14 = require('@material-ui/core/MenuList');
var _MenuList14 = _interopRequireDefault(MenuList14);

var MenuItem14 = require('@material-ui/core/MenuItem');
var _MenuItem14 = _interopRequireDefault(MenuItem14);

//var List14 = require('@material-ui/core/List');
//var _List14 = _interopRequireDefault(List14);

//var ListItem14 = require('@material-ui/core/ListItem');
//var _ListItem14 = _interopRequireDefault(ListItem14);

var PictureUpload = require("components/CustomUpload/PictureUpload.jsx");
var _PictureUpload = _interopRequireDefault(PictureUpload);

var FileUpload = require("components/CustomUpload/FileUpload.jsx");
var _FileUpload = _interopRequireDefault(FileUpload);

var Repeater = require("components/44/Repeater.jsx");
var _Repeater = _interopRequireDefault(Repeater);

var OpenAiTextgenerator = require("components/44/OpenAiTextgenerator44.jsx");
var _OpenAiTextgenerator = _interopRequireDefault(OpenAiTextgenerator);

//var GridItem = require("components/Grid/GridItem.jsx");
//var _GridItem = _interopRequireDefault(GridItem);


//var _materialUiDatetimepicker = require('material-ui-datetimepicker');

//var _materialUiDatetimepicker2 = _interopRequireDefault(_materialUiDatetimepicker);

//var _DatePickerDialog = require('material-ui/DatePicker/DatePickerDialog');
//var _DatePickerDialog2 = _interopRequireDefault(_DatePickerDialog);

//var _TimePickerDialog = require('material-ui/TimePicker/TimePickerDialog');
//var _TimePickerDialog2 = _interopRequireDefault(_TimePickerDialog);

// Custom Components
//var PictureUpload = require("../CustomUpload/PictureUpload.jsx");
//var _PictureUpload = _interopRequireDefault(PictureUpload);


function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }


const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    flexBasis: 200
  },
  primary: {},
  icon: {},
});

// eslint-disable-next-line
let CatsSelectedAndisLastLevelChild = false;

var Form = function (_React$Component) {
  _inherits(Form, _React$Component);

  function Form(props) {
    _classCallCheck(this, Form);

    var _this = _possibleConstructorReturn(this, (Form.__proto__ || Object.getPrototypeOf(Form)).call(this, props));

    const {classes} = this.props;
    //console.debug('classes', classes);

    _this.state = {
      timeout: null,
      values: {}
    };
    _this.style = {
      width: '100%'
    };
    _this.displayFields = {}
    _this.conditionalLogics = []
    _this.DEFAULT_IMG = _this.props.defaultImage || 'http://via.placeholder.com/50x50';
    _this.underlineFocusStyle = _this.props.focusStyle || {};
    _this.floatingLabelFocusStyle = _this.props.focusStyle || {};

    _this.handleChange = function (field, value, callback) {
      /*
       * NOT WORKING, need SOLUTION :(
      if(field==='motorleistung_ps'){
        _this.handleChangeDirect('motorleistung_kw', value*0.73)
      }*/
      //_this.setChanges(field, value, callback);
      if (_this.state.timeout) {
        clearTimeout(_this.state.timeout);
        _this.setState({ timeout: null }, function () {
          return _this.setChanges(field, value, callback);
        });
      } else {
        _this.setChanges(field, value, callback);
      }
    };

    _this.handleChangeDirect = function (field, value, callback) {
      _this.setChanges(field, value, callback);
    };

    _this.handleConditions = function () {
      _this.props.fields.map(function (field, index) {
        if(typeof(field.wrapper) !== 'undefined' && field.wrapper.class === 'check_cl_on_startup') {
          //console.debug('handleConditions: handleConditions OF ', field.name);
          let val = field.value ?? field.default_value;
          _this.handleCondition(field.key, field.name, val)
        }
        return true;
      });
    };

    _this.handleCondition = function (key_changed_field, name, value_changed_field) {
      /*
        Aufbau Logics:
        ------------------------------
        field: "field_595cdd3218382"
        operator: "=="                (Mögliche Operatoren "==", "!=", "==contains")
        value: "moebel"
       */

      let errors = _this.state.errors;

      _this.displayFields = {}
      _this.conditionalLogics.forEach(function(cLogic){
        //console.debug('1 check Logic: ', cLogic);

        cLogic.logic.forEach(function(ccLogic){
          let key_to_hide = cLogic.key

          ccLogic.forEach(function(ccLogic){
            //ccLogic = ccLogic[0]

            // TODO: Nicht nur ccLogic[0] sondern auch die Anderen abfragen und je nach UND oder ODER-Verknüpfung
            // die hide-Flags setzen....!

            //console.debug('-- 2 check Logic: ', ccLogic);
            //console.debug('ccLogic.field == key', ccLogic.field+' == '+key_changed_field);
            if(ccLogic.field === key_changed_field) {
              //console.debug('field '+ccLogic.field+' has changed. check conditions!')

              // condition field passt, prüfung der condition fällig
              //console.debug('value_logic_field', ccLogic.value);
              //console.debug('value_changed_field', value_changed_field);
              //console.debug('operator_logic_field', ccLogic.operator);

              // eslint-disable-next-line
              if((ccLogic.value == value_changed_field && ccLogic.operator === '==') ||        // "=="-condition trifft zu
                ((typeof value_changed_field == "string") && value_changed_field.indexOf(ccLogic.value) > -1 && ccLogic.operator === '==contains')   // "==contains"-condition trifft zu
              ) {
                // condition trifft zu
                //console.debug('condition trifft zu', (ccLogic.operator === '==' ? 'SHOW ' : 'HIDE ') + key_to_hide);
                _this.displayFields['hide_' + key_to_hide] = false;

                // for not skipping the validation
                if(typeof _this.state.errors[key_to_hide] !== 'undefined'){
                  _this.state.errors[key_to_hide]['hide']=false
                }

                if(_this.displayFields.hasOwnProperty('hide_' + key_to_hide)){

                }else{
                  _this.displayFields['hide_' + key_to_hide] = false
                  /*if(key_to_hide==='field_5f3d197db3db3'){ // Ausgabe als Test nur beim Beispiel: field_5f3d197db3db3=fahrrad-typ
                    console.debug('Funktion SET HIDE=false === '+ccLogic.value)
                  }*/
                }

                //console.debug('condition ('+ccLogic.value+ccLogic.operator+value_changed_field+') trifft zu', 'SHOW: ' + key_to_hide);

              // eslint-disable-next-line
              }else if(ccLogic.value != value_changed_field && ccLogic.operator === '!='){ // condition trifft zu
                //console.debug('negative condition trifft zu', (ccLogic.operator === '!=' ? 'SHOW ' : 'HIDE ') + key_to_hide);
                _this.displayFields['hide_' + key_to_hide] = false;

                // for not skipping the validation
                if(typeof _this.state.errors[key_to_hide] !== 'undefined'){
                  _this.state.errors[key_to_hide]['hide']=false
                }

                if(_this.displayFields.hasOwnProperty('hide_' + key_to_hide)){

                }else{
                  _this.displayFields['hide_' + key_to_hide] = false

                  // for not skipping the validation
                  if(typeof _this.state.errors[key_to_hide] !== 'undefined'){
                    _this.state.errors[key_to_hide]['hide']=false
                  }

                  /*if(key_to_hide==='field_5ac38f6b66806'){ // Ausgabe als Test nur beim Beispiel: field_59ba3784ee91c=Funktion
                    console.debug('Funktion SET HIDE=false COZ !== '+ccLogic.value)
                  }*/
                }
                //console.debug('condition ('+ccLogic.value+ccLogic.operator+value_changed_field+') trifft zu', 'SHOW: ' + key_to_hide);

              /*}else if(ccLogic.value === value_changed_field && ccLogic.operator === '!='){ // condition trifft NICHT zu
                obj['hide_' + key_to_hide] = true
                if(key_to_hide==='field_59ba3784ee91c'){
                  console.debug('Funktion SET HIDE=true COZ === '+ccLogic.value)
                }
                //console.debug('condition ('+ccLogic.value+ccLogic.operator+value_changed_field+') trifft NICHT zu', 'HIDE: ' + key_to_hide);

              }else if(ccLogic.value !== value_changed_field && ccLogic.operator === '=='){ // condition trifft NICHT zu
                obj['hide_' + key_to_hide] = true
                if(key_to_hide==='field_59ba3784ee91c'){
                  console.debug('Funktion SET HIDE=true COZ !== '+ccLogic.value)
                }
                //console.debug('condition trifft NICHT zu', 'HIDE ' + key_to_hide);*/

              }else{
                // obj['hide_' + key_to_hide] = true
                //console.debug('ACHTUNG: CONDITION ELSE nicht abgedeckt.')
                // condition trifft nicht zu

                // for skipping the validation
                if(typeof _this.state.errors[key_to_hide] !== 'undefined'){
                  _this.state.errors[key_to_hide]['hide']=true
                }
                //console.debug('_this.state.errors', _this.state.errors[key_to_hide]);

                //obj['hide_' + key_to_hide] = (ccLogic.operator === '==' ? false : true)
                _this.displayFields['hide_' + key_to_hide] = true
              }
            }
          })
        })
        //console.debug('Ende der logic prüfungen');
      })
      if(Object.keys(_this.displayFields).length > 0){
        _this.setState(_this.displayFields);
      }
      _this.setState({ errors: errors })
      //console.debug('errors', errors);
    };


    _this.setChanges = function (field, value, callback = undefined) {
      //console.debug('_this.props', _this.props)
      //console.debug('setCHANGE', field+' : '+value);

      let values = null;

      //debug('_this.props', _this.props)
      //_this.setState({ values: _this.props.values });

      /*if (_this.props.handleChange) {
        _this.props.handleChange(field, value);
      }*/

      let property = _defineProperty({}, field, value);

      // für verschachtelte Attribute, wie Repeater
      let matches = field.match(/[0-9]\]\[[(a-z)]+/gi);
      if(!!matches){ // check for example for field[0][subfield] is in the field name
        field = field.split('[')[0]
        //debug('field', field);
        //debug('matches', matches);

        let property = _lodash2.default.get(_this.props.values, field);
        //console.debug('property from props', property);

        let split = matches[0].split('][')
        let index = split[0]
        let subfield = split[1]

        if(typeof property[index] === 'undefined'){
          property[index] = _defineProperty({}, subfield, value)
        }else{
          property[index][subfield] = value;
        }
        // In diesem Fall wird über das vorhandene Referenz-Objekt gearbeitet. Das hinzufügen der neuen Werte (property über _extends) ist nicht notwendig

        values = _extends({}, _this.props.values, _this.state.values, property);
      }else{
        values = _extends({}, _this.props.values, _this.state.values, property);
      }

      //console.debug('values are changed to', { 'property': property, 'values': values, '_this.props.values': _this.props.values, '_this.state.values': _this.state.values })

      _this.setState({ values: values }, function () {
        if(typeof callback === 'function'){
          callback.call()
        }
        if (!_this.props.delayTime || !_this.props.onDelayedChange) {
          return false;
        }
        var time = 0;
        if (_lodash2.default.includes(_this.props.delayTriggers, field)) {
          time = _this.props.delayTime;
        }
        var timeout = setTimeout(function () {
          _this.props.onDelayedChange(values);
        }, time);
        _this.setState({ timeout: timeout });
      });

      if (_this.props.onChange) {
        _this.props.onChange(values);
      }
    };

    _this.getProperty = function (key, defaultValue, formDefault, key_of_sub=false, sub_index=false, multiple=false) {
      let componentDefault = _lodash2.default.get(_this.props.values, key);
      let _return = _lodash2.default.get(_this.state.values, key, componentDefault || defaultValue || formDefault);
      //console.debug('All the values of getProperty1', _return);

      // für verschachtelte Attribute, wie Repeater
      /*if(key_of_sub !== false && sub_index !== false){
        let key_sub_ary = key_of_sub.split('-');
        let key_ary = key.split('-');
        let nested_key = key_sub_ary[0]
        let nested_index = key_sub_ary[1]
        _return = _lodash2.default.get(_this.state.values, nested_key, componentDefault || defaultValue || formDefault);
        _return = _lodash2.default.get(_return[nested_index], key_ary[0], componentDefault || defaultValue || formDefault);
      }*/

      /*let matches = key.match(/[0-9]\]\[[(a-z)]+/gi);
      if(!!matches){ // check for example for field[0][subfield] is in the field name
        key = key.split('[')[0]
        //debug('getProperty:field', key);
        //debug('getProperty:matches', matches);
        console.debug('All the values of getProperty2', _return);
        let split = matches[0].split('][')
        let nested_index = split[0]
        let nested_key = split[1]

        _return = _lodash2.default.get(_this.state.values, key, componentDefault || defaultValue || formDefault);
        _return = _lodash2.default.get(_return[nested_index], nested_key, componentDefault || defaultValue || formDefault);
      }*/

      //console.debug('getProperty key', key);

      if(key === 'partner_manufacturer_selection'){
        console.debug('_return', _return);
        _return = multiple ? [_return] : _return;
      }

      if((key === 'farbe_auswahl' || key === 'material_auswahl') && typeof _return === 'object'){
        _return = Object.keys(_return)[0] || ''
      }
      return _return
    };

    _this.getName = function (name) {
      return _lodash2.default.replace(name, /\./g, ' ');
    };

    _this.getPrice = function (value) {
      return (typeof(value) !== 'undefined' && value.length > 0) ? parseFloat(value) : 0
    }


    _this.getItems = function (items, parentKey) {
      if(items[0].value !== 0){
        items.unshift({value: 0, title: '-'});
      }
      var values = items.map(function (item) {
        return item.value;
      });
      return items.map(function (item) {
        var key = item.key || item.value || 'key';
        key = parentKey + '-' + key;
        return _react2.default.createElement(_MenuItem2.default, {
          value: item.value, key: key, primaryText: item.title,
          checked: values && values.indexOf('name') > -1
        });
      });
    };


    _this.getChoices = function (items, parentKey) {
      var values = Object.keys(items).map(function (item) {
        return item;
      });
      var entries = Object.entries(items).map(function (item, key) {
        key = item.shift();
        var value = item.pop();
        var label = value || ' - ';
        label = label.replace('&amp;', '&')
        //var save_key = parentKey + '-' + key;
        return _react2.default.createElement(_MenuItem2.default, {
          value: key, key: key, primaryText: label,
          checked: values && values.indexOf('name') > -1 // TODO: check if this is working
        });
      });
      return entries;
    };


    // select tree / menu tree
    _this.getChoices14_orig = function (items, parentKey) {
      var values = Object.keys(items).map(function (item) {
        return item;
      });
      var entries = Object.entries(items).map(function (item, key) {
        key = item.shift();
        var value = item.pop();
        var label = value || ' - ';
        label = label.replace('&amp;', '&')
        //var save_key = parentKey + '-' + key;
        return _react2.default.createElement(_MenuItem14.default, {
          className: classes.menuItem,
          dense: true,
          value: key, key: key,
          checked: values && values.indexOf('name') > -1 // TODO: check if this is working
        }, _react2.default.createElement('div', {}, label));
      });
      return entries;
    };


    // select tree / menu tree
    _this.getChoices14 = function (items, parentKey) {
      //console.debug('parentKey', parentKey);

      var values = Object.keys(items).map(function (item) {
        return item;
      });
      var entries = Object.entries(items).map(function (item, key) {
        key = item.shift();
        var value = item.pop();
        if(typeof value === 'string'){
          // Normalfall: Auswahl
          //console.debug('Normalfall: Auswahl', items);

          let label = value || ' - ';
          label = label.replace('&amp;', '&')

          return _react2.default.createElement(_MenuItem14.default, {
            className: classes.menuItem,
            dense: true,
            value: key,
            label: label,
            key: key,
            checked: values && values.indexOf('name') > -1 // TODO: check if this is working
          }, _react2.default.createElement('div', {}, label));
        }else{
          //console.debug('Fall Hersteller-Auswahl', items);
          // Fall: Hersteller-Auswahl, welche nach der gewählten Kategorie gefiltert wird
          // Ausnahme field_57da562cb2fee = Hersteller-Auswahl für Partner-Edit => Dort immer alle anzeigen
          if(parentKey==='field_57da562cb2fee' || (_this.state.values['cat_level1'] > 0 && value['categories'].indexOf(_this.state.values['cat_level1']) > -1) ||
            (_this.state.values['cat_level2'] > 0 && value['categories'].indexOf(_this.state.values['cat_level2']) > -1) ||
            (_this.state.values['cat_level3'] > 0 && value['categories'].indexOf(_this.state.values['cat_level3']) > -1) ) {
            let label = value['name']
            let id = value['id'] || key

            if(typeof label != 'undefined') {
              label = label.replace('&amp;', '&')
              //debug('adde:', label)
            }

            return _react2.default.createElement(_MenuItem14.default, {
              className: classes.menuItem,
              dense: true,
              value: id, key: id,
              checked: values && values.indexOf('name') > -1 // TODO: check if this is working
            }, _react2.default.createElement('div', {}, label));
          }else{
            return []
          }
        }
      });
      return entries;
    };


    _this.getDisplay = function (field, defaultValue) {

      // Check current Condition

      // TEST Kochfeld Modellkennung: _this.state['hide_field_5ac38f6b66806'] = true

      //console.debug(field.name+' display?', !_this.state['hide_'+field.key])
      let display = !_this.state['hide_'+field.key] && !_this.displayFields['hide_'+field.key]
      //console.debug('_this.displayFields '+field.key, _this.displayFields);
      return {display: display ? (defaultValue || 'block') : 'none' }
    }

    _this.getMenuDisplay = function (level, id, parentId, defaultValue) {
      //console.debug('CHECK Level: '+level+' tax: '+parentId+'/'+id)

      let check = false;

      if(level === 3){
        check = _this.state.values['cat_level2'] === id
      }else{
        check = _this.getMenuSelected(id)
      }

      let styles = {display: check ? (defaultValue || 'block') : 'none' }

      //if(_this.getMenuSelected(id) || _this.getMenuSelected(parentId))
      //  console.debug('styles: ', styles)
      return styles
    }

    _this.getMenuSelected = function (id = 0) {
      //console.debug('_this.state.values', _this.state.values);
      let sel = (_this.state.values['cat_level1'] === id || _this.state.values['cat_level2'] === id || _this.state.values['cat_level3'] === id)
      //if(sel)
      //console.debug('getMenuSelected: '+id+', select: ', sel);

      return sel
    }

    // select tree / menu tree
    _this.getMenuList = function (items, parentId = undefined, level=1, parent_produkt_typ = 'moebel') {
      Object.keys(items).map(function (item) {
        return item;
      });

      let child_entries = []
      let entries = Object.entries(items).map(function (item, i) {
        item = item.pop();
        //console.debug('item', item);
        let key = 'tax-'+item.term_taxonomy_id
        let id = item.term_taxonomy_id
        let value = item.term_taxonomy_id
        let label = item.name || ' - ';
        label = label.replace('&amp;', '&')
        let childs = null;
        let current_produkt_typ = item.produkt_typ ? item.produkt_typ : (level === 1 ? 'moebel' : parent_produkt_typ)

        if(item.childs && item.childs.length){
          let next_level = level + 1
          //console.debug('next_level for '+value, next_level);
          childs = _this.getMenuList(item.childs, value, next_level, current_produkt_typ)
          //console.debug('childs', childs);

          child_entries.push(_react2.default.createElement(_MenuList14.default, {
            id: 'list-'+level+'-'+i,
            key: 'list-'+level+'-'+i,
            style: { ...{width:'100%', padding: '0 4px 0 4px'}, ..._this.getMenuDisplay(next_level, id, parentId, 'inline-flex')}}, childs))
        }else{
          //console.debug('Ich habe gar keine Kinder');
        }

        let isLastLevelChild = !(item.childs && item.childs && item.childs.length > 0)

        // solve category validation on startup
        if(_this.getMenuSelected(value) && isLastLevelChild){
          //TODO: SOLVE
          //console.debug('PRÜFUNG CatsSelectedAndisLastLevelChild=true value: '+value+' coz', item.childs)
          //debug('CatsSelectedAndisLastLevelChild=true value: '+value+' coz', item.childs)
          CatsSelectedAndisLastLevelChild = true
          //_this.handleValidation('cat_level3', 'cat_level3', 0)
        }else{
          //CatsSelectedAndisLastLevelChild = false
          //_this.handleValidation('cat_level3', 'cat_level3', 0)
          //console.debug('PRÜFUNG CatsSelectedAndisLastLevelChild=false value: '+value+' coz', item.childs)
        }

        return( _react2.default.createElement(_MenuItem14.default, {
          className: classes.selectTreeItem,
          //className: classes.menuItem,
          style: _this.getMenuSelected(value) && isLastLevelChild ? {backgroundColor: 'rgba(76, 175, 80, 0.8)'} : {},
          dense: true,
          value: value,
          key: key,
          selected: _this.getMenuSelected(value),
          onClick: function onChange(e) {
            CatsSelectedAndisLastLevelChild = false
            let callback = undefined
            let callback2 = undefined
            let nextlevel = 0
            let values = _this.state.values

            // Letzte Ebene dieses Asts: add Choose-Cat-Funktion
            if(isLastLevelChild){

              // Wenn Kategorien geändert werden und neue Auswahl nicht bis Level 3 geht, müssen die weitere wieder auf 0 gesetzt werden.
              if (level !== 3) {

                // TODO: Leider kann die nachfolgende saubere Lösung nicht benutzt werden da wir mehrer handleChange nicht nach Gang aufrufen können.
                // TODO: Deswegen muss dringend ein Umbau auf Promise gemacht werden: _this.handleChange(...).then(() => { _this.handleChange(...) })
                /*
                let nextlevel = level
                while (nextlevel < 3) {
                  nextlevel++
                  console.debug('>clear cat_level' + nextlevel.toString(), 0)
                  _this.handleChangeDirect('cat_level' + nextlevel.toString(), 0)
                }*/

                callback2 = function(){
                  // produkt_typ setzen / Model wählen
                  //console.debug('produkt_typ setzen / Model wählen');
                  _this.handleChange('produkt_typ', current_produkt_typ)
                  _this.handleConditions()
                  _this.handleCondition('field_595cdd3218382', 'produkt_typ', current_produkt_typ)
                  //_this.handleValidation('category', 'Kategorie-Auswahl', 0)

                  console.debug('handleValidation cat_level3', 1)
                  _this.handleValidation('cat_level3', 'Kategorie-Level '+ level.toString()+ ' Auswahl', 1, true)
                }

                callback = function(){
                  //console.debug('>clear cat_level3', 0)
                  _this.handleChange('cat_level3', 0, callback2)
                }

              }else{

                callback = function(){
                  //console.debug('produkt_typ setzen / Model wählen');
                  // produkt_typ setzen / Model wählen
                  _this.handleChange('produkt_typ', current_produkt_typ)
                  _this.handleConditions()
                  _this.handleCondition('field_595cdd3218382', 'produkt_typ', current_produkt_typ)
                }

              }
            }else{
              if (level !== 3) {

                // TODO: Leider kann die nachfolgende saubere Lösung nicht benutzt werden da wir mehrere handleChange nicht nach Gang aufrufen können.
                // TODO: Deswegen muss dringend ein Umbau auf Promise gemacht werden: _this.handleChange(...).then(() => { _this.handleChange(...) })

                // Nachfolgender Code funktioniert nicht. Änderungen werden nicht übernommen bzw. wieder durch alte Werte überschrieben?!
                nextlevel = level
                while (nextlevel < 3 && nextlevel > 0) {
                  nextlevel++
                  console.debug('>clear cat_level' + nextlevel.toString(), 0)
                  values['cat_level' + nextlevel.toString()] = 0
                  //_this.handleChange('cat_level' + nextlevel.toString(), 0)
                  //console.debug('>DONE clear cat_level' + nextlevel.toString())
                }
              }
            }

            const current_value = e.target.value

            values['cat_level' + nextlevel.toString()] = e.target.value
            _this.setState({values: values},
              function() {
                console.debug('>set cat_level' + level.toString(), current_value)
                _this.handleChange('cat_level' + level.toString(), current_value, callback)

                console.debug('handleValidation cat_level' + level.toString(), current_value)
                _this.handleValidation('cat_level' + level.toString(), 'Kategorie-Level '+ level.toString()+ ' Auswahl', current_value, true)
              }
            )
          },
          //onChange: () => alert('change'),
        }, label)
        )
      });

      return (_react2.default.createElement('div', { style: {width:'100%'}},
        _react2.default.createElement('div', { style: {width: _this.getWidth(level, 1), float:'left'}},
          _react2.default.createElement(_MenuList14.default, {
            key: 'mainlist', style: {width:'100%'} }, entries),
        ),
        _react2.default.createElement('div', { style: {width: _this.getWidth(level, 2), float:'left'}},
          _react2.default.createElement('div', {width:'100%'}, child_entries)
        )
        )
      )
    };

    _this.getWidth = function(level, col){
      if(level===1) {
        if(col===1){
          return '34%'
        }else{
          return '66%'
        }
      }else if(level===2){
        if(col===1){
          return '50%'
        }else{
          return '50%'
        }
      }else if(level===3){
        if(col===1){
          return '100%'
        }else{
          return '0%'
        }
      }
    }

    _this.handleValidation = function (key_changed_field, name, value_changed_field = undefined, required = false) {
      let errors = this.state.errors

      if(errors[key_changed_field]){
        // TODO: Die Logik hinter dem Code erschließt sich mir nicht!? Prüfen!
        if(!(typeof value_changed_field === 'undefined' || value_changed_field.length === 0)){
          // console.debug('WRONG VALIDATION for '+key_changed_field+' with:', value_changed_field)
          // errors = _lodash.omit(errors, key_changed_field)
        }else{
          // console.debug('SOLVED VALIDATION for '+key_changed_field+' with:', value_changed_field)
          // errors = _lodash.omit(errors, key_changed_field)
        }

      }


      /*
       * VALIDATION
       */
      let filled = false
      let _value = value_changed_field

      //console.debug('typeof '+name, typeof _value)

      switch(typeof _value) {
        case 'undefined':
          filled = false
          //console.debug('typeof undefined', name)
          break
        case 'string':
          filled = _value.length > 0
          //console.debug('typeof string filled?', filled)
          break
        case 'number':
          filled = _value > 0
          break
        case 'object':
          filled =  Object.keys(_value).length > 0
          //console.debug('typeof object', name)
          break
        case 'array':
          filled = _value.length > 0
          //console.debug('typeof array', name)
          //console.debug('available ITEMS', items14)
          break
        default:
          filled = false
          break
      }
      //console.debug('FILLED ('+key_changed_field+'): '+filled+ ' with:', _value)

      // Wenn Pflichtfeld && laut Condtions nicht ausgeblendet && Wert nicht gefüllt
      /*
        Fehlende Funktionalität:
        Bei der Neuanlage sind Required-Felder, welche nicht aktiv sind, aber als Fehler drin.
      */
      // TODO: Falls Eltern Tab ausgeblendet ist, muss es auch nicht required sein. Muss noch abgefragt werden...
      //console.debug('required', required)
      //console.debug('!_this.state[hide_+key_changed_field]', !_this.state['hide_'+key_changed_field])

      if(required && !_this.state['hide_'+key_changed_field] && !filled){
        let errorText = 'Dies ist ein Pflichtfeld!'


        //console.debug(name, _value)
        // _value
        /*if (_lodash2.default.get(errors, key)) {
          errorText = errors[key];
        }*/

        //errors[key_changed_field] = errorText
        errors[key_changed_field] = {label: name, text: errorText}
        console.debug('WRONG VALIDATION for '+key_changed_field+' ('+errorText+') with:', value_changed_field)
      }else{
        errors = _lodash.omit(errors, key_changed_field)
        console.debug('SOLVED VALIDATION for '+key_changed_field+' with:', value_changed_field)
      }

      this.setState({ errors: errors })
      console.debug('errors', errors);
    }

    _this.getField = function (field, key_of_sub = false, index_of_sub = false) {
      var key = field.key || field.name.toLowerCase().replace(" ", "_") || (new Date().getTime());
      var type = field.type || 'text';
      var append = field.append || false;
      var prepend = field.prepend || false;
      var defaultValue = field.default || field.default_value || '';
      var name = field.name || 'name';
      var label = field.label || field.name || '';
      var date_picker_format = field.display_format || 'yyyy-MM-dd hh';
      var date_picker_type = ((field.display_format && field.display_format.includes('h')) || field.name==='startdatum' ) ? 'datetime-local' : 'date';


      // für Repeater-Feld, damit ID's durchgezählt werden und nicht gleich sind
      if(index_of_sub!== false && (index_of_sub===0 || index_of_sub>0)){
        key = key_of_sub;
        name = key_of_sub;
      }

      //var inputKey = 'form-' + key;
      //var items = {}; items = field.items ? _this.getItems(field.items || [], key) : field.choices ? _this.getItems(field.choices || [], key) : {};

      let items = {};
      let items14 = {};

      if(type === 'post_object' || type === 'relationship'){
        items14 = typeof(_this.props.jsonData[name]) !== 'undefined' ? _this.getChoices14(_this.props.jsonData[name] || [], key) : {};
        //console.debug('items14', _this.props.jsonData[name])
      }else{
        items14 = typeof(field.choices) !== 'undefined' ? _this.getChoices14(field.choices || [], key) : {};
      }
      if(type === 'select_tree'){
        items = typeof(_this.props.jsonData[name]) !== 'undefined' ? _this.getMenuList(_this.props.jsonData[name] || []) : {}
        //console.debug('items key'+key, items);
      }

      let errors = _this.props.errors;
      //console.debug('props.errors', errors);
      let disabled = !!field.disabled || false;
      //var empty = !!field.empty || false;

      // New ACF-Fields
      let helperText = field.instructions || '';
      let placeholder = field.placeholder || '';

      // Hide Fields (Nick :D) - for intern fields, don't give sellanizer-customer accesss to change
      if(field.wrapper && field.wrapper.class && field.wrapper.class==='hide_for_customer'){
        return ''       // for delete from form
      }
      if(field.wrapper && field.wrapper.class && field.wrapper.class==='readonly_for_customer'){
        disabled = true // for gray out
        helperText = ''
        placeholder = ''
      }

      //var prepend = field.prepend || '';
      //var append = field.append || '';
      //var maxlength = field.maxlength || '';
      let required = field.required ? true : false;
      //var children = field.choices || {};

      //var multiple = !!field.multiple || false;
      let errorText = field.instructions || 'Dies ist ein Pflichtfeld!';

      let conditional_logic = !!field.conditional_logic ? field.conditional_logic : false;
      if(conditional_logic) {
        let clKeys = _this.conditionalLogics.map(function (item) {
          return item.key;
        })
        if(!clKeys.includes(field.key)){
          //console.debug('conditional_logic for '+field.name, conditional_logic);
          _this.conditionalLogics.push({key: field.key, logic: conditional_logic})

          //TODO: Hier muss ich den field name und den Wert raussuchen um die Condition zu prüfen...!!!!!

          //_this.handleCondition(conditional_logic[0][0].field, conditional_logic[0][0].field, _this.props.fields[conditional_logic[0][0].field].value)
          //_this.handleCondition('field_5e7b6af20a6cb', 'versand_moeglich', 1)
        }
        /*if(field.key==='field_5ac38f6b66806'){
          console.warn('_this.props.fields', _this.props.fields);
          console.warn('meine CL', conditional_logic);
          console.warn('Prüfe CL für Kochfeld-Modellkennung', clKeys);
        }*/
      }

      /*
       * VALIDATION set Errors on Load
       */
      let filled = false
      let _value = _this.getProperty(name, defaultValue, '', key_of_sub, index_of_sub)

      switch(typeof _value) {
        case 'undefined':
          filled = false
          //console.debug('typeof undefined', name)
          break
        case 'string':
          filled = _value.length > 0
          //console.debug('typeof string', name)
          break
        case 'number':
          filled = _value > 0
          break
        case 'object':
          if(_value===null){
            filled = false;
          }else{
            filled = Object.keys(_value).length > 0
          }
          //debug('typeof object', _value)
          break
        case 'array':
          filled = _value.length > 0
          //debug('typeof array', name)
          //debug('available ITEMS', items14)
          break
        default:
          filled = false
          //debug('typeof default', name)
          break
      }

      /* Aktuell nicht in Verwendung
      // Leider ist diese Ausnahme notwendig, da kein dynmaischer Weg gefunden wurde
      let isEditAndCatLevel3 = false
      if(key === 'cat_level1' || key === 'cat_level2' || key === 'cat_level3'){
        // bedeutet: Wenn es sich um keine Neuanlage handelt && cat_level 3 angeklickt wurde
        isEditAndCatLevel3 = (typeof _this.state.values._id !== 'undefined') && key === 'cat_level3'
        //console.debug('isEditAndCatLevel3', isEditAndCatLevel3);
      }else{
        isEditAndCatLevel3 = false
      }*/

      //TODO: Das hier scheint alles richtig zu laufen. Allerdings hat die äußere Validierung noch die alten Daten.
      // Mögliche Lösung: da wo die conditional_locics gepushed werden dieses hier abfragen und dann eine pseudo-logic pushen welche dann abgefragt wird,
      // anstelle von ausblenden, soll dann nur nicht validiert werden via hide=1?!

      //debug('CatsSelectedAndisLastLevelChild: ', CatsSelectedAndisLastLevelChild);
      //console.debug('######################### field', field);

      //TODO: Conditionbedingte-Validierung ggf. wieder aktivieren, aber Bug bei Modell/Hersteller beheben
      /*if(field.wrapper && field.wrapper.class && field.wrapper.class.match('required_if')) {
        //console.debug('check if this is required', field.name);
        //console.debug('######################### produkt_typ value', this.state.values.produkt_typ);

        const field_value_to_check = this.state.values.produkt_typ
        const value_to_expected = 'kuechenelektrogeraete'
        //console.debug('######################### value_to_expected', value_to_expected);

        required = field_value_to_check === value_to_expected;
        if(required){
          //console.debug('OK, IST Pflichtfeld: '+field.name, _this.state)

          if(typeof(_this.state.errors[field.key])==='undefined'){
            _this.state.errors[key] = {label: field.name, text: field.instructions}
          }

          if(typeof(_this.state['hide_'+key])!=='undefined'){
            _this.state['hide_'+key] = false
          }

        }else{
          /*console.debug('OK, kein Pflichtfeld: '+field.name, _this.state)

          let errors = this.state.errors
          errors = _lodash.omit(errors, field.key)
          //_this.state[field.key] = {hide: true}

          //this.setState({ errors: errors })
          //console.debug('errors nach SA', errors);
           *
        }
      }*/


      // Add field to errors if it's required, not hidden and not filled out
      if(required && (!_this.state['hide_'+key] || typeof(_this.state['hide_'+key]) === 'undefined') && !filled){ //  && !CatsSelectedAndisLastLevelChild
        //console.debug('Add field to errors '+key, _value)
        //console.warn('i have this field as required: '+name);

        if(key==='cat_level3'){
          if(!CatsSelectedAndisLastLevelChild){
            console.debug('PRÜFUNG cat_level3 ADD Error', CatsSelectedAndisLastLevelChild)
            errors[key] = {label: label, text: errorText}
          }else{
            // Werfe kein Fehler, wenn es sich um die Prüfung der lezten Kategorie-Ebene handelt, wir aber nur eine Zweistufige an der Stelle haben!
            console.debug('PRÜFUNG cat_level3 KEIN Fehler werfen, weil es ist die letzte Ebene!', CatsSelectedAndisLastLevelChild)
          }
        }else{
          errors[key] = {label: label, text: errorText}
        }

        /*
        if(typeof(_this.state.errors[field.key])==='undefined'){
          _this.state.errors[key] = {label: field.name, text: field.instructions}
        }

        if(typeof(_this.state[key])==='undefined'){
          _this.state[key] = {hide: true}
        }else{
          _this.state[key].hide = true
        }
        */
      }else{
        // Remove field from errors
        //console.warn('i have this field NOT as required: '+name);
        errors = _lodash.omit(errors, key)

        /*if(typeof(_this.state.errors[field.key])==='undefined'){
        }else{
          _this.state.errors[field.key] = _lodash.omit(_this.state.errors, key)
        }

        if(typeof(_this.state[field.key])==='undefined'){
          //_this.state[field.key] = {hide: true}
        }else{
          _this.state[field.key].hide = true
        }*/


      }

      //TODO: Das hier wäre eine Lösung, allerdings läuft die App dann in einer Endlosschleife (durch change?!) rein.
      //TODO: Grundsätzlich ist get_field aber auch die falsche Stelle um errors zu prüfen und zu ändern!

      //this.setState({ errors: errors });
      //console.debug('Errors Ramazotti after '+field.name, errors)

      if(field.wrapper && field.wrapper.class && field.wrapper.class==='hide_but_validate_for_customer'){
        return ''       // for delete from Form
      }

      if (type === 'text' || type === 'url' || type === 'link' || type === 'email') {

        let value = _this.getProperty(name, defaultValue, '', key_of_sub, index_of_sub);

        // TODO: Bei Typ Link wird aktuell die Angabe des Labels und des Targets nicht unterstützt
        if(type==='link'){
          value = value['url']
        }

        //sub_name = (index_of_sub!== false && (index_of_sub===0 || index_of_sub>0)) ? key_of_sub+'['+index_of_sub+']['+name+']' : name
        //let sub_key = (index_of_sub!== false && index_of_sub===0 || index_of_sub>0) ? key+'-'+index_of_sub : key
        return _react2.default.createElement(_TextField14.default, {
          id: key,
          key: key,
          name: name,
          label: label, // +' '+Date.now(),
          type: type,
          helperText: helperText,
          placeholder: placeholder,
          style: {...{width: '100%'}, ..._this.getDisplay(field, 'inline-flex')},
          className: classNames(classes.zeilen, styles.textField),
          margin: 'dense',
          inputProps: {
            maxLength: field.maxlength || -1
          },
          InputLabelProps: {
            /*shrink: true,*/
          },
          value: value,
          onChange: function onChange(event) {
            //console.debug('event.target', event.target)
            _this.handleValidation(key, name, event.target.value, required);
            return _this.handleChange(name, event.target.value);
          },
          error: typeof (errors[key]) !== 'undefined',
          disabled: disabled,
          required: required,
          readOnly: disabled
        });
      } else if (type === 'number') {
        //let prefix = name.includes('preis') ? {startAdornment: _react2.default.createElement(_InputAdornment14.default, {position: 'start', children: '€'})} : {}
        let prependE = prepend ? {startAdornment: _react2.default.createElement(_InputAdornment14.default, {position: 'start', children: prepend})} : {}
        let appendE = append ? {endAdornment: _react2.default.createElement(_InputAdornment14.default, {position: 'end', children: append})} : {}
        return _react2.default.createElement(_TextField14.default, {
          id: key,
          key: key,
          name: name,
          label: label,
          type: 'number',
          step: '0.01',
          helperText: helperText,
          placeholder: placeholder,
          style: {...{width: '100%'}, ..._this.getDisplay(field, 'inline-flex')},
          //style: { ..._this.getDisplay(field, 'inline-flex'), ..._this.style, ...{width: '100%', /*marginTop: 15*/} },
          className: classNames(classes.zeilen, styles.textField),
          margin: 'dense',
          //variant:'filled', // not working?!
          //variant:'outlined', // not working?!
          InputProps: {
            ...prependE,
            ...appendE,
            style: {},
            step: '0.01'
          },
          InputLabelProps: {
            /*shrink: true,*/
          },
          value: _this.getProperty(name, defaultValue, '', key_of_sub, index_of_sub),
          // TODO: onchange verursacht in Verbindung mit prefix folgenden Fehler: index.js:2178 Warning: A component is changing an uncontrolled input of type number to be controlle
          onChange: function onChange(event) {
            _this.handleValidation(key, name, event.target.value, required);
            return _this.handleChange(name, event.target.value); //_this.getPrice(event.target.value)
          },
          error: typeof (errors[key]) !== 'undefined',
          disabled: disabled,
          required: required,
        });
      } else if (type === 'date' || type === 'date_picker') {
        //var sub_name;
        defaultValue = null;
        return _react2.default.createElement(_TextField14.default, {
          id: key,
          key: key,
          name: name,
          label: label,
          type: date_picker_type,
          format: date_picker_format,
          value: _this.getProperty(name, defaultValue, key_of_sub, index_of_sub),
          className: classNames(classes.zeilen),
          style: {...{width: '100%'}, ..._this.getDisplay(field)},
          InputProps: {
            style: {minWidth: 120}
          },
          InputLabelProps: {
            shrink: true,
          },
          onChange: function onChange(event) {
            _this.handleChange(name, event.target.value); // value.format('yyyy-MM-dd')
          },
          required: required,
          //errorText: errorText,
          disabled: disabled
        });
      } else if (type === 'password') {
        //sub_name = (index_of_sub!== false && (index_of_sub===0 || index_of_sub>0)) ? name+'_'+index_of_sub : name;
        return _react2.default.createElement(_TextField14.default, {
          id: key,
          key: key,
          name: name,
          label: label,
          helperText: helperText,
          type: 'password',
          placeholder: placeholder,
          style: {...{width: '100%'}, ..._this.getDisplay(field, 'inline-flex')},
          className: classNames(classes.zeilen),
          InputProps: {
            style: {},
          },
          InputLabelProps: {
            shrink: true,
          },
          value: _this.getProperty(name, defaultValue, '', key_of_sub, index_of_sub),
          onChange: function onChange(event) {
            _this.handleValidation(key, name, event.target.value, required);
            return _this.handleChange(name, event.target.value);
          },
          error: typeof (errors[key]) !== 'undefined',
          readOnly: disabled,
          disabled: disabled,
          required: required,
        });
      } else if (type === 'textarea' || type === 'multiLineText') {

        let extra = []

        if(name === 'bemerkung') {
          extra = _react2.default.createElement(_OpenAiTextgenerator.default, {
            variant: (_this.props.ai_text_generation_enable ? 'contained' : 'outlined'),
            style: { marginBottom:15 },
            children: 'KI Beschreibung einfügen',
            color: 'default',
            ai_text_generation_enable: _this.props.ai_text_generation_enable,
            data: _this.state.values,
            onChange: function onChange(value) {
              //_this.handleValidation(key, name, value, required);
              return _this.handleChange(name, value);
            }
          })
          if (_this.props.ai_text_generation_enable){
            // label+= 'Dieses Feature ist für ihren Account momentan nicht freigeschaltet.'
          }
        }

        return (_react2.default.createElement(_FormControl14.default, {
            style: {
              ..._this.getDisplay(field, 'inline-flex'), ...{
                width: '100%'
              }
            }
          },
          _react2.default.createElement(_TextField14.default, {
          id: key,
          key: key,
          name: name,
          label: label,
          //hintText: _this.getName(name),
          helperText: helperText,
          placeholder: placeholder,
          //floatingLabelText: _this.getName(name),
          style: {
            ..._this.getDisplay(field, 'inline-flex'), ...{
              width: '100%'
            }
          },
          //underlineFocusStyle: _this.underlineFocusStyle,
          //floatingLabelFocusStyle: _this.floatingLabelFocusStyle,
          //inputStyle: { marginTop: 3 },
          //variant:'filled',
          className: classNames(classes.zeilen),
          margin: 'dense',
          InputProps: {
            /*style: {marginTop: 10},*/
          },
          InputLabelProps: {
            shrink: true,
          },
          //floatingLabelStyle: { top: 40 },
          value: _this.getProperty(name, defaultValue, ''),
          onChange: function onChange(event) {
            _this.handleValidation(key, name, event.target.value, required);
            return _this.handleChange(name, event.target.value);
          },
          multiline: true,
          rows: 1, //field.rows || 2,
          rowsMax: field.rowsMax || (field.rows || 2) * 2,
          error: typeof (errors[key]) !== 'undefined',
          disabled: disabled,
          required: required,
        }), extra
        ));
      } else if (type === 'repeater') {
        //let count = _this.getProperty(name, []).length
        let subfields = []
        let updated_at = Date.now();
        let index = 0;

        (_this.getProperty(name, []) || []).map(function (_field, index) {
          //debug('index', index);
          updated_at = Date.now();
          return _react2.default.createElement(
            'div',
            {key: 'form-field-container-' + index, style: _this.props.inputContainerStyle, classes: 'updated-at-' + updated_at},
            subfields.push(_this.getSubFields(field.sub_fields, field, index))
          );
        });

        for (let i = subfields.length; i < (field.max+1 || 10); i++) {
          // Add Blueprint/Skeleton
          let bluprint = _this.getSubFields(field.sub_fields, field, index + i)
          bluprint.disabled = true;
          subfields.push(bluprint)
        }

        //console.debug('subfields2', subfields);
        //console.debug(name + ' has ' + count + ' rows.');

        return _react2.default.createElement('div', {
          children: (
            _react2.default.createElement(_Repeater.default, {
              rows: _this.getProperty(name, []),
              subfields: subfields,
              updated_at: updated_at,
              helperText: helperText,
              min: field.min || 1,
              max: field.max || 10,
              layout: field.layout,
              button_label: field.button_label,
              id: key,
              key: key,
              name: name,
              style: { ..._this.getDisplay(field) },

              onChange: function onChange(value) {
                //console.debug('subfields.onChange', subfields[0][0].props.children.props.onChange)

                /*
                debug('repeater.onChange', value);

                value.forEach(function(val, key) {
                  if(val.disabled===true){
                    delete value[key];
                  }
                });

                debug('repeater.onChange afterDeletion', value);*/
                // return _this.handleChange(name, value);
                //return subfields[0][0].props.children.props.onChange(event);
              },
            })),
          //label: label
        })
      } else if (type === 'post_object' || type === 'relationship' || type === 'select') {
        /* Muss händisch gemacht werden, da es seitens Material-UI fehlt */
        if(required){
          label+= " *";
        }
        if (!items14) {
          return name + ': (Daten für Type "' + name + '" sind nicht verfügbar)';
        }

        // Hidden fields
        if (name === 'partner' || name === 'produkt_typ') {
          return _react2.default.createElement('input', {id: key, key: key, name: name, type: 'hidden'})
        }
        // Select-Type Textbausteine noch ausblenden
        if (name.includes('textbausteine')) {
          return ''
        }

          let extra = []
          if(name === 'hersteller') {
            extra = _react2.default.createElement(_Button2.default, {
              variant: 'contained',
              style: { marginBottom:15, maxWidth:200 },
              children: 'Hersteller anfragen',
              color: 'default',
              href: 'mailto:support@sellanizer.net?subject=Sellanizer - Neuer Hersteller&body=' +
                encodeURIComponent("Guten Tag,\n\nwir benötigen folgende neue Hersteller in der/den Kategorie(n):\n<KATEGORIE(N) EINTRAGEN> : <HERSTELLER EINTRAGEN>\n\n\nViele Grüße,\n <ABSENDER EINTRAGEN>")
            })
          }

        if(name === 'energieklasse' && helperText.length > 1){
          label+=' ('+helperText+')';
        }

        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
            },
          },
        };

        var multiple = field.max === '' || field.max > 1;

        return (
          _react2.default.createElement(_FormControl14.default, {
              style: {
                ..._this.getDisplay(field, 'inline-flex'), ...{
                  width: '100%'
                }
              }
            },
            _react2.default.createElement(_InputLabel14.default, {htmlFor: 'input-' + key, shrink: true}, label),
            _react2.default.createElement(_SelectField14.default, {
                id: key,
                key: key,
                name: 'sel' + name,
                displayEmpty: true,
                label: label,
                native: false,
                className: classNames(classes.zeilen, classes.selectEmpty),
                MenuProps: MenuProps,
                inputProps: {
                  name: 'input' + name,
                  id: 'input-' + key,
                },
                //style: { ..._this.getDisplay(field), ..._this.style },
                value: _this.getProperty(name, defaultValue, '', false, false, multiple),
                onChange: function onChange(event, index, value) {
                  value = event.target.value
                  //console.debug('onChange', [event, index, value]);
                  //_this.setState({ [name]: event.target.value });
                  //console.debug('name', name);
                  //console.debug('event.target.value', event.target.value);
                  //console.debug('index', index);
                  //console.debug('event', event);
                  //console.debug('value', value);
                  _this.handleCondition(key, name, value);
                  _this.handleValidation(key, name, value, required);
                  return _this.handleChange(name, value);
                },
                error: typeof (errors[key]) !== 'undefined',
                disabled: disabled,
                required: required,
                multiple: multiple,
              },
              _react2.default.createElement(_MenuItem14.default, {value: '', disabled: false}, _react2.default.createElement('em', {}, 'bitte wählen')),
              items14
            ), extra
          )
        );
      } else if (type === 'select_tree') {
        //debug('produkt_kategorien', items)
        return items
      } else if (type === 'true_false') {
        let value = _this.getProperty(name, defaultValue, false)
        let checked = (value === 'publish' ? true : (value === 'draft' ? false : (value === 1 || value === true))); // if publish then true; if draft then false; if 1 or true then true otherwise false!
        return _react2.default.createElement(_FormLabelControl14.default, {
          control: (
            _react2.default.createElement(_Switch14.default, {
              key: key,
              name: name,
              checked: checked,
              onChange: function onChange(event, isChecked) {
                _this.handleCondition(key, name, isChecked);
                return _this.handleChange(name, isChecked);
              },
              disabled: disabled,
              readOnly: disabled,
              color: 'secondary',
              classes: {
                switchBase: classes.colorSwitchBase,
                checked: classes.colorSwitchChecked,
              }
            })),
          id: key,
          label: label,
          className: classNames(classes.zeilenSwitch),
          style: {..._this.getDisplay(field, 'inline-flex'), ...{}} // TODO: not working: Überschreibt default-Styles?!
        })
      } else if (type === 'checkbox') {//console.debug('items14', items14);
        //console.debug('items', items);
        //console.debug('name', name);
        let checkboxes = [];
        items14.forEach((item) => {
          let props = item.props
          //console.debug('item', item)
          let ikey = item.key
          let iname = name + '[' + props.value + ']'
          let ary_value = _this.getProperty('brillen_eigenschaften', defaultValue, false)
          //debug('ary_value', ary_value);
          checkboxes.push(
            _react2.default.createElement(_FormLabelControl14.default, {
              control: (
                _react2.default.createElement(_Checkbox14.default, {
                  id: ikey,
                  key: ikey,
                  name: iname,
                  style: {..._this.getDisplay(field)},
                  defaultChecked: ary_value[props.value],
                  onChange: function onChange(event, isChecked) {
                    _this.handleCondition(ikey, iname, isChecked);
                    return _this.handleChange(iname, isChecked);
                  },
                  disabled: disabled,
                })
              ),
              key: 'form_' + ikey,
              label: props.label
            })
          )
        });
        //console.debug('items', items14);
        return _react2.default.createElement(_FormControl14.default, {
            style: {
              ..._this.getDisplay(field, 'inline-flex'), ...{
                width: '100%'
              }
            }
          },
          _react2.default.createElement(_InputLabel14.default, {htmlFor: 'input-' + key, shrink: true}, label),
          checkboxes
        )
      } else if (type === 'image' || type === 'gallery') {
      return _react2.default.createElement('div', {
        children: (
          _react2.default.createElement(_PictureUpload.default, {
            images: _this.getProperty('images', []),
            label: field.label,
            helperText: helperText,
            max: field.max || 1,
            required: required,
            //id: key,
            //key: key,
            //name: name,
            //style: { ..._this.getDisplay(field) },
            onChange: function onChange(value) {
              //debug('gallery.onChange', value);
              return _this.handleChangeDirect('images', value);
            },
          })),
        //label: label
      })
      } else if (type === 'file') {
        return _react2.default.createElement('div', {
          style: { ..._this.getDisplay(field) },
          children: (
              _react2.default.createElement(_FileUpload.default, {
                files: _this.getProperty(name, []),
                helperText: helperText,
                label: field.label,
                mimeTypes: field.mime_types,
                max: field.max || 1,
                required: required,
                //id: key,
                //key: key,
                //name: name,
                style: { ..._this.getDisplay(field) },
                onChange: function onChange(value) {
                  //debug('gallery.onChange', value);
                  _this.handleValidation(key, name, value, required);
                  return _this.handleChangeDirect(name, value);
                },
              })),
          //label: label
        })
      } else if (type === 'google_map') {
          return ''
      } else if (type === '_image_not_working') {
          var defaultImg = defaultValue || _this.DEFAULT_IMG;
          var options = {
            baseUrl: field.resource,
            fileFieldName: 'files',
            uploadSuccess: field.uploadSuccess,
            chooseAndUpload: true
          };
          var imageStyle = {
            fontSize: 16,
            lineHeight: '24px',
            width: '100%',
            display: 'inline-block',
            position: 'relative',
            backgroundColor: 'transparent',
            fontFamily: 'Lato, sans-serif',
            transition: 'height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
            cursor: 'text',
            marginTop: 14,
            color: 'rgba(0, 0, 0, 0.3)'
          };
          return _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              'label',
              {
                style: imageStyle
              },
              _this.getName(name)
            ),
            _react2.default.createElement(
              'div',
              {style: {cursor: 'pointer'}},
              _react2.default.createElement(
                FileUpload,
                {options: options},
                _react2.default.createElement('img', {
                  //ref: 'chooseAndUpload',
                  src: _this.getProperty(key, defaultImg, '') + '?' + new Date().getTime(),
                  onError: function onError(e) {
                    e.target.src = _this.DEFAULT_IMG;
                  },
                  style: {width: 50, height: 50}
                })
              )
            )
          );
      } else if (type === '_image2_not_working') {
          var _defaultImg = defaultValue || _this.DEFAULT_IMG;
          var imageLabelStyle = {
            fontSize: 16,
            lineHeight: '24px',
            width: '100%',
            display: 'inline-block',
            position: 'relative',
            backgroundColor: 'transparent',
            fontFamily: 'Lato, sans-serif',
            transition: 'height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
            cursor: 'text',
            marginTop: 14,
            color: 'rgba(0, 0, 0, 0.3)'
          };

          var _imageStyle = {
            display: 'block',
            marginBottom: 10,
            width: 50,
            height: 50
          };
          if (_lodash2.default.get(field, 'position') === 'flex') {
            _imageStyle.width = '100%';
            _imageStyle.height = 150;
            _imageStyle.objectFit = 'cover';
          }

          var uploader = new _fineUploaderWrappers2.default({
            options: {
              request: {
                endpoint: _lodash2.default.get(field, 'resource'),
                multiple: false,
                inputName: 'file'
              },
              callbacks: {
                onComplete: function onComplete(id, name, response) {
                  if (response.success) {
                    //_this.handleChange(key, _lodash2.default.get(field, 'prefix') + name);
                    return _lodash2.default.get(field, 'onSuccess', function () {
                    })(name, true);
                  }
                  return _lodash2.default.get(field, 'onError', function () {
                  })(name, response);
                }
              }
            }
          });

          return _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              'label',
              {
                style: imageLabelStyle
              },
              _this.getName(name)
            ),
            _react2.default.createElement(
              'div',
              {style: {cursor: 'pointer', width: '100%'}},
              _react2.default.createElement('img', {
                src: _this.getProperty(key, _defaultImg, '') + '?' + new Date().getTime(),
                onError: function onError(e) {
                  e.target.src = _this.DEFAULT_IMG;
                },
                style: _imageStyle
              }),
              _react2.default.createElement(
                _RaisedButton2.default,
                {
                  containerElement: 'label',
                  label: 'upload',
                  primary: true
                },
                _react2.default.createElement('input', {
                  type: 'file',
                  style: {display: 'none'},
                  accept: 'image/*',
                  onChange: function onChange(onChangeEvent) {
                    uploader.methods.addFiles(onChangeEvent.target);
                  }
                })
              )
            )
          );
      } else {
        return name + ': (Type "' + type + '" ist noch nicht verfügbar)';
      }
    };

    _this.handleAddChip = function (key, value, chip) {
      _this.handleChange(key, _lodash2.default.concat(value, chip));
    };

    _this.handleDeleteChip = function (key, value, chip) {
      _this.handleChange(key, _lodash2.default.without(value, chip));
    };

    _this.rescue = function (rescue_value, return_value, check_value=true) {
      if(typeof return_value == 'undefined' || typeof check_value == 'undefined'){
        return rescue_value
      }else{
        return return_value
      }
    };

    _this.getFields = function () {
      return _this.props.fields.map(function (field, index) {
        return _react2.default.createElement(
          'div',
          { key: 'form-field-container-' + index, style: _this.props.inputContainerStyle },
          _this.getField(field)
        );
      });
    };

    _this.getSubFields = function (sub_fields, sub_parent_field, index_of_subrow) {
      let key_of_sub = sub_parent_field.name
      let layout = sub_parent_field.layout || 'block' // block,row,table
      let gridSize = layout==='table' ? 5 : 12
      return sub_fields.map(function (field, index_of_subelem) {
        /*debug('key_of_sub', key_of_sub);
        debug('index_of_sub', index_of_subrow); // Index der Zeile
        debug('field', field);
        debug('index', index_of_subelem); // Index innerhalb einer Zeile unterhalb der Elemente*/
        return _react2.default.createElement(
          GridItem, /*(layout==='table' ? GridItem : 'div'),*/
          { xs: gridSize, sm: gridSize, md: gridSize, key: 'form-field-container-' + index_of_subelem, style: _this.props.inputContainerStyle },
          //_this.getField(field, key_of_sub+'-'+index_of_subrow+'-'+index_of_subelem, index_of_subrow)
          _this.getField(field, key_of_sub+'['+index_of_subrow+']['+field.name+']', index_of_subrow)
        );
      });
    };

    _this.getFieldsTab = function () {
      let tab_open = false;
      let tab_start = false;
      let tab_stop = false;
      let tab_content = [];
      let after_content = [];
      let tab_name = '';
      let tab_obj = null;
      let fieldnode = null;

      return _this.props.fields.map(function (field, index) {

        /***********************
         *  T A B
         ***********************/
        if(field.type==='tab' || (_this.props.fields.length === index+1 && tab_open)){

          if(field.wrapper && field.wrapper.class && field.wrapper.class==='hide_for_customer'){
            return '' // for delete from form
          }

          if(_this.props.fields.length === index+1){
            if(tab_open){
              // save the current last fields in tab_content
              fieldnode = _react2.default.createElement(
                'div',
                { key: 'form-field-container-' + index, style: _this.props.inputContainerStyle },
                _this.getField(field)
              );
              tab_content.push(fieldnode);

              //console.debug('check after_content for: '+field.key, after_content)
              // check after_content
              if(after_content[field.key]){
                after_content[field.key].forEach(function(f){
                  //console.debug('push after_content into tab_content', f)
                  tab_content.push(f)
                })
              }
            }

            // last field, close tab
            tab_start = false;
            tab_stop = true;
            tab_open = false;
          }else{
            tab_start = !tab_open;
            tab_stop = tab_open;
            tab_open = true;
          }

          if(tab_start){
            // save the tab field
            tab_name = field.label || field.name || '';
            tab_obj = field;
            // save following fields in tab_content

            //console.debug('check after_content for: '+field.key, after_content)
            // check after_content
            if(after_content[field.key]){
              after_content[field.key].forEach(function(f){
                //console.debug('push after_content into tab_content', f)
                tab_content.push(f)
              })
            }
          }
          if(tab_stop){
            //console.debug('TAB STOP');
            // create tab-holder and paste tab_content into

            //console.debug('tab_content', tab_content);

            if(!!tab_content.length){
              var FieldContainer = _reactLayoutComponents.VBox;
              if (_this.props.orientation === 'horizontal') {
                FieldContainer = _reactLayoutComponents2.default;
              }
              //console.debug('expansionPanel:', tab_obj.key);

              // Conditional Logic für Tab/Panel speichern
              var conditional_logic = !!tab_obj.conditional_logic ? tab_obj.conditional_logic : false;
              if(conditional_logic) {
                let clKeys = _this.conditionalLogics.map(function (item) {
                  return item.key;
                })
                if(!clKeys.includes(tab_obj.key)){
                  //console.debug('conditional_logic for '+tab_obj.key, conditional_logic);
                  _this.conditionalLogics.push({key: tab_obj.key, logic: conditional_logic})
                }
              }

              fieldnode = _react2.default.createElement(_ExpansionPanel2.default,
                {
                  id: tab_obj.key,
                  key: 'expansion-container-' + index,
                  expanded: _this.props.expanded,
                  style: {display: _this.state['hide_'+tab_obj.key] ? 'none' : 'block' },
                  onChange: function handleChange(e){
                    //console.debug('expansionPanel:handleChange', e);
                  }
                },
                _react2.default.createElement(_ExpansionPanelSummary2.default,{ classes: { root: classes.expansionPanelSummary, expanded: classes.expansionPanelSummaryExpanded }, expandIcon: _react2.default.createElement(_ExpandMoreIcon.default) }, tab_name),
                _react2.default.createElement(_ExpansionPanelDetails2.default,{},
                  //_react2.default.createElement( _reactLayoutComponents.VBox,
                  //  { style: _this.style },
                    _react2.default.createElement( FieldContainer,
                      { style: _extends({ overflow: 'visible' }, _this.props.fieldContainerStyle), flex: 1, wrap: _this.props.wrap },
                      tab_content
                    )
                  //)
                )
              );
            }else{
              fieldnode = null;
            }

            // clear tab_content
            tab_content = [];
            //tab_content.push(_react2.default.createElement('div', {key: 'div-'+index}));
            //console.debug('clear tab_content', tab_content);

            // save the new tab field
            tab_name = field.label || field.name || '';
            tab_obj = field;

            return fieldnode || null;
          }

        }else{
          /***********************
           *  FIELDS
           ***********************/
          let formfield = _react2.default.createElement(
                        'div',
                        { key: 'form-field-container-' + index, style: _this.props.inputContainerStyle },
                        _this.getField(field)
                      );

          if(tab_open){
            // save following fields in tab_content
            tab_content.push(formfield);

            // check after_content
            if(after_content[field.key]){
              after_content[field.key].forEach(function(f){
                //console.debug('push after_content into tab_content', f)
                tab_content.push(f)
              })
            }
          }else{
            if(field.after){
              // Save Field and get back later
              if(!after_content[field.after]){
                after_content[field.after] = []
              }
              after_content[field.after].push(formfield)
              //console.debug('after_content', after_content)
            }else{
              //console.debug('return field: ', field.name)
              // no tab - normal way, so return directly

              let returnFields = formfield

              // check after_content
              if(after_content[field.key]){
                after_content[field.key].forEach(function(f){
                  //console.debug('push after_content into tab_content', f)
                  returnFields += f
                })
              }

              return returnFields;
            }
          }
        }
        return true
      });
    };
    //console.debug('this.props.values', this.props.values)

    _this.setDefaultFields = function (editValues) {
      let values = editValues || _this.props.values
      let default_values = []

      // Add fields to the state.values who has default value, otherwise they will not be saved if the default value is not changed (onChange will not be triggered and this adds the values)
      _this.props.fields.map(function (field, index) {
        if (typeof field.default_value != 'undefined' && (!!field.default_value.length || [0,1].includes(field.default_value))) {
          default_values.push([field.name,field.default_value])
          if(typeof values[field.name] == 'undefined'){
            values = _extends({}, values, _defineProperty({}, field.name, field.default_value));
          }
        }
        return true
      })
      _this.setState({ values: values });
      //console.debug('Set default value fields: ', default_values)
      //console.debug('values', values)
    };

    // HIDE some fields on startup
    // TODO: Formbuilder erweitern, damit dies dynmisch gemacht wird.

    var hideShippingTab = typeof(_this.props.values['versand_moeglich']) === 'undefined' ? true : !_this.props.values['versand_moeglich'];

    _this.state = {
      values: _this.props.values,
      errors: _this.props.errors,
      expanded: _this.props.expanded,
      hide_field_5ea1bcb0cb554: hideShippingTab, // Versandkosten
      hide_field_62260bd38f6c9: true, // Tab Maschinen/Fahrzeuge Spezifiktionen
      hide_field_5964f6326820c: true, // Tab Küchenspezifikationen
      hide_field_5ac3a972484c0: true, // Tab Küchengeräte Kochen
      hide_field_5ac3a911484bf: true, // Tab Küchengeräte Kühlen
      hide_field_5ac3a9a7484c1: true, // Tab Küchengeräte Spülen
      hide_field_5ac3a9c0484c2: true, // Tab Sonstige Küchengeräte
      hide_field_5964f6ba6820d: true, // Tab Elektrogeräte-Spezifikationen
      hide_field_5ac3aad2c1232: true, // Tab Farben und Materialien
      hide_field_57bc5af69251d: true, // Tab Ansprechpartner (unter Partner-Daten bearbeiten)
    };

    /*_this.setState({ values: values }, function () {
      if (!_this.props.delayTime || !_this.props.onDelayedChange) {
        return false;
      }
      var time = 0;
      var timeout = setTimeout(function () {
        _this.props.onDelayedChange(values);
      }, time);
      _this.setState({ timeout: timeout });
    });*/

    return _this;
  }

  _createClass(Form, [{
    key: 'render',
    value: function render() {
      /*var _this2 = this;

      var FieldContainer = _reactLayoutComponents.VBox;
      if (this.props.orientation === 'horizontal') {
        FieldContainer = _reactLayoutComponents2.default;
      }
      return _react2.default.createElement(
        _reactLayoutComponents.VBox,
        { style: this.style },
        _react2.default.createElement(
          FieldContainer,
          { style: _extends({ overflow: 'auto' }, this.props.fieldContainerStyle), flex: 1, wrap: this.props.wrap },
          this.getFieldsTab()
        ),
        _react2.default.createElement(
          _reactLayoutComponents2.default,
          { style: _extends({ justifyContent: 'center' }, this.props.actionContainerStyle) },
          this.props.saveForm && _react2.default.createElement(_FlatButton2.default, { secondary: false, label: 'Speichern', onClick: function onClick() {
              return _this2.props.saveForm(_this2.state.values);
            } }),
          this.props.deleteItem && _react2.default.createElement(_FlatButton2.default, { label: 'Delete', secondary: true, onClick: this.props.deleteItem })
        )
      );*/
      //this.setDefaultFields();
      return this.getFieldsTab();
    }
  }]);

  return Form;
}(_react2.default.Component);

export default Form;
