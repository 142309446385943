import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default class BulkEditActionSelect extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    const disableCloud = this.props.disableCloud === undefined ? false : this.props.disableCloud;
    const disableMarktde = this.props.disableMarktde === undefined ? false : this.props.disableMarktde;
    const disableEbay = this.props.disableEbay === undefined ? false : this.props.disableEbay;
    return (
      <Select
        value={this.props.selectBulkAction}
        onChange={(e) => this.props.onChange(e)}
        name="age"
        displayEmpty
        style={{ marginRight: '20px' }}
      >
        <MenuItem value="" disabled>
          Mehrfachaktionen
        </MenuItem>
        {disableCloud ? ("") : (<MenuItem value="SET_CLOUD">Cloud hochladen</MenuItem>)}
        <MenuItem value="STATUS_SOLD">Auf verkauft stellen ja/nein</MenuItem>
        <MenuItem value="SET_DRAFT">Auf Entwurf setzen ja/nein</MenuItem>
        {disableEbay ? ("") : (<MenuItem value="SET_EBAYKL">Auf Kleinanzeigen setzen ja/nein</MenuItem>)}
        {disableMarktde ? ("") : (<MenuItem value="SET_MARKTDE">Auf Markt.de setzen ja/nein</MenuItem>)}
        <MenuItem value="DELETE">Löschen</MenuItem>
        {/*<MenuItem value="CREATE">Produkt erstellen</MenuItem>*/}
      </Select>
    );
  }
}
