import PouchDB from "pouchdb"

class Database {
  constructor(name){
    /*
     Aktuell gibt es einen Bug:
     https://github.com/pouchdb/pouchdb/issues/7098
     */
    this.db = new PouchDB('products', {revs_limit: 1, auto_compaction: false, size: 100 })
    this.db.compact(function (err, result) {
      if (err) { return console.debug('Error', err); }
      if (result) { /*console.debug('OK', result);*/ }
    });
    this.db.viewCleanup(function (err, result) {
      if (err) { return console.debug(err); }
      if (result) { /*console.debug('OK', result);*/ }
    });
  }

  clearDB(){
    let activeDB = this.db;
    let cleanedDB = new PouchDB('products_cleaned', {revs_limit: 1, auto_compaction: false, size: 100 });
    // eslint-disable-next-line
    let originalTableSize;
    // eslint-disable-next-line
    let cleanedTableSize;

    activeDB.info().then((result)=>{
      console.debug('Start cleaning the DB...');
      originalTableSize = result.doc_count;
      //console.debug('originalTableSize', originalTableSize);
      activeDB.replicate.to(cleanedDB, {filter : function(doc) { if( doc._deleted ) { return false; } else { return doc; } }}).on('complete', function (result) {
        //console.debug('on complete replicate');
        cleanedDB.info().then((cleanedResult)=>{
          cleanedTableSize = cleanedResult.doc_count;
          //console.debug('cleanedTableSize', cleanedTableSize);
          if(true) { // skip tableSize check: cleanedTableSize == originalTableSize
            //console.debug('well since things match destroy old');
            activeDB.destroy().then(()=>{
              //console.debug('now that old is destroyed copy it back to active');
              activeDB = new PouchDB('products', {revs_limit: 1, auto_compaction: false, size: 100 });
              cleanedDB.replicate.to(activeDB, {filter : function(doc) { if( doc._deleted ) { return false; } else { return doc; } }}).on('complete', function (result) {
                //console.debug('we copied clean back to active');
                //console.debug('destroy clean');
                cleanedDB.destroy().then(()=>{
                  //console.debug('we deleted the clean');
                  //console.debug('now we will kick off the real sync between pouch and couch');
                });
              });
            })
          }else{
            console.debug('originalTableSize and cleanedTableSize are different, clearDB ist stopped!')
          }
        });
      });
      console.debug('Finished cleaning the DB!');
    });
  }

  async getAllData(){
    //Retrieving all the documents in PouchDB
    this.db.allDocs({include_docs: true}, function(err, docs) {
       if (err) {
          return console.debug(err);
       } else {
          console.debug (docs.rows);
       }
    });
  }

  async update(key, values){
    let _this = this
    return await this.db.get(key, function(err, doc) {

      /*console.debug(doc);
      console.debug('values', doc.values);
      console.debug('values: {...values, ...doc}', {...values, ...doc})*/

      if (err) { return console.debug(err); }

      _this.db.put({ ...doc, ...values}, function(err, response) {
        if (err) { return console.debug(err); }
        // handle response
        return false
      });
      return true;
    });
  }

  async delete(key, callback = undefined){
    let _this = this
    this.db.get(key, function(err, doc) {
      if (err) { return console.debug(err); }
      _this.db.remove(doc, function(err, response) {
        if (err) { return console.debug(err); }
        // handle response
        if (!err && typeof callback === "function") { callback.call() }
      });
    });
  }

  async getAllProducts() {
    //let _this = this
    let allProducts = await this.db.allDocs({include_docs: true});
    let products = [];
    let index = 0;

    allProducts.rows.forEach(function(r){

      if(r.doc && !r.doc.name){
        r.doc.name = '- Name fehlt -'

        if(!r.doc.rev){
          console.debug('SKIP - Leeres/Defektes Doc (lokale DB):', r)
          return
        }
      }

      // Leere indexDB Values ausschließen
      if(r.doc && r.doc.values) {
        products[index] = r.doc.values
        products[index].rev = r.doc._rev
        products[index].id = r.key
        index++
      }else if(r.doc && r.doc.name) {
        products[index] = r.doc
        products[index].rev = r.doc._rev
        products[index].id = r.key
        index++
      }else if(r._doc_id_rev && r.doc.name) {
        products[index] = r.doc
        products[index].rev = r.doc._doc_id_rev
        products[index].id = r._doc_id_rev
        index++
      /*}else if(r.id && r.key) {
        products[index] = r.doc
        products[index].rev = r.id
        products[index].id = r.id
        index++*/
      }else{
        console.debug('Leeres/Defektes Doc:', r)
        /*
        // Leere Dokumente löschen
        _this.db.remove(r, function(err, response) {
          if (err) { return console.debug('Doc konnte nicht gelöscht werden:', err); }
          // handle response
          if (!err) { console.debug('Erfolgreich gelöscht:', response) }
        });
        */
      }

    });

    //console.debug('allProducts', allProducts);
    //console.debug('Database.getAllProducts', products);

    return products;
  }

  /*checkPartnerData = (key, fallback=false) => {
    let data = 'partnerData'
    if(typeof(localStorage[data])==='undefined')
      return fallback

    let parsedData = []

    try {
      parsedData = JSON.parse(localStorage[data]) || []
    }catch (e) {
      console.debug('ERROR on parsing localStorage[data]')
    }
    //console.debug('parsedData', parsedData)
    //console.debug(key, parsedData[key])
    return parsedData[key] ? true : false
  }*/

  getPartnerData = (key, fallback=false) => {
    const parsedData = this.selectedPartnerData()
    if(typeof(parsedData[key])==='undefined')
      return fallback

    return parsedData[key]
  }

  checkPartnerData = (key, fallback=false) => {
    const parsedData = this.selectedPartnerData()
    if(typeof(parsedData[key])==='undefined')
      return fallback

    return parsedData[key] ? true : false
  }

  /*changePartnerData = (key, value=undefined) => {
    const parsedData = this.selectedPartnerData()

    if(key && typeof value !== 'undefined'){
      parsedData[key] = value;
      return true;
    }else{
      return false;
    }
  }*/

  selectedPartnerData(){
    //OLD: const partnerData = JSON.parse(localStorage.partnerData);
    let partnersData = []
    let selectedPartnerData = {}
    let selectedPartnerId = localStorage.getItem('selected_partner_id') ? localStorage.getItem('selected_partner_id') : false

    try {
      partnersData = JSON.parse(localStorage['partners']) || []
    }catch (e) {
      console.log('ERROR on parsing localStorage[data]')
    }

    partnersData.forEach(function(partner){
      // eslint-disable-next-line
      if(partner.id == selectedPartnerId){
        selectedPartnerData = partner
      }
    })

    return selectedPartnerData;
  }

  updateSelectedPartnerData(newDataObj){
    let partnersData = []
    let selectedPartnerId = localStorage.getItem('selected_partner_id') ? localStorage.getItem('selected_partner_id') : false

    try {
      partnersData = JSON.parse(localStorage['partners']) || []
    }catch (e) {
      console.log('ERROR on parsing localStorage[data]')
    }

    partnersData.forEach(function(partner, index){
      // eslint-disable-next-line
      if(partner.id == selectedPartnerId){
        Object.keys(newDataObj).map(function (key, i) {
          //console.log(i, key);
          partnersData[index][key] = newDataObj[key];
          return true;
        });
      }
    })

    //console.debug('partner update data', partnersData)
    return localStorage.setItem('partners', JSON.stringify(partnersData));
  }

  is_pw_account() {
    const partnerData = this.selectedPartnerData()
    return (partnerData.title === 'Sellanizer Muster' || partnerData.title === 'Planungswelten Möbelhaus')
  }

  should_demo_daten() {
    const username = localStorage.username;
    return (username === 'Sellanizer-Muster')
  }

};

const DB = new Database();

export default DB;
