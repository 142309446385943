
const productFormStyle = theme => ({
  root: {
    width: '100%',
    fontFamily: 'Lato'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  textfieldOutlined: {
    flexBasis: 280,
  },
  zeilen: {
    marginTop: 0,
    marginBottom: 15,
    fontWeight: 500
  },
  zeilenSwitch: {
    marginTop: -10,
    marginBottom: 10,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  selectTreeItem: {
    padding: '4px 10px',
    borderBottom: '1px solid #dadada',
    fontWeight: 300,
    overflowX: 'auto',
    fontSize: 'calc(10px + .3vw)',
    height: 'auto'
  },
  expansionPanelSummaryExpanded: {
    backgroundColor: '#f44336',
    color: 'white'
  },
  /*colorSwitchBase: {
    color: theme.palette.primary.main,
    '&$colorChecked': {
      color: purple[500],
      '& + $colorBar': {
        backgroundColor: purple[500],
      },
    },
  },
  colorSwitchChecked: {
    color: '#000000'
  },
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
      /*'& $primary, & $icon': {
        color: theme.palette.common.white,
      },*
    },
  },*/
});

export default productFormStyle;
