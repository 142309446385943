import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
//import classNames from "classnames";
import './ProductTables.css';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {MuiThemeProvider, Typography} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import _ from 'lodash';

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Copy from "@material-ui/icons/FileCopy";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import ShoppingCartOff from "@material-ui/icons/RemoveShoppingCart";
import OpenInNew from "@material-ui/icons/OpenInNew";
import Cloud from "@material-ui/icons/Cloud";
import LokalIcon from "@material-ui/icons/Devices";
import MenuIcon from "@material-ui/icons/MoreVert";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";
import AddIcon from "@material-ui/icons/Add";
import CloudOff from "@material-ui/icons/CloudOff";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
import Feedback from "@material-ui/icons/Feedback";
import SnackbarClose from "components/Snackbar/SnackbarClose";
import kleinanzeigenIcon from "assets/img/logo_kleinanzeigen.svg";
import marktdeIcon from "assets/img/logo_marktde.svg";
import traktorpoolIcon from "assets/img/logo_traktorpool.svg";
import pdfIcon from "assets/img/pdf.svg";

//import EbayIcon from "components/44/EbayIcon";
import KleinanzeigenIcon from "components/44/KleinanzeigenIcon";
import MarktdeIcon from "components/44/MarktdeIcon";
import TraktorpoolIcon from "components/44/TraktorpoolIcon";
import Button from "components/CustomButtons/Button.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import {withSnackbar} from 'notistack';

// 3.party components
import {Offline, Online} from "react-detect-offline";
//import obj2fd from 'obj2fd/es5'
// 44 components
import {theme} from "components/44/muiTheme"
import DB from 'components/44/Database';
import API from 'components/44/ApiPlanungswelten';
import ProgressButton from 'components/44/CircularIntegration'
import FacebookPost from 'components/44/FacebookPost/FacebookPost'
import FBinit from 'components/44/FBinit'
import Txt from 'variables/texts';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import LoadingText from "../../components/44/LoadingText";
import PlaceholderImage from '../../assets/img/placeholder_image.png';
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";

import SellanizerProductTablesStyle from "assets/jss/material-dashboard-pro-react/views/sellanizerProductTables.jsx";
import CustomButton from "../../components/CustomButtons/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import BulkEditDeleteDialog from "../../components/44/BulkEditDeleteDialog";
import BulkEditActionSelect from "../../components/44/BulkEditActionSelect";
import BulkEditSellDialog from "../../components/44/BulkEditSellDialog";

class ProductTables extends React.Component {

  API_URL   = process.env.REACT_APP_API_URL;
  PW_DOMAIN = process.env.REACT_APP_PW_DOMAIN;
  FB_APP_ID = process.env.REACT_APP_FB_APP_ID;

  constructor(props) {
    super(props);
    this.state = {
      drawerBottom: false,
      props: props,
      dataLocal: undefined,
      dataRemote: undefined,
      tempRemote: undefined,
      draftToggle: false,
      publishedToggle: false,
      dataEbay: undefined,
      hasError: false,
      activeTab: 'local',
      dialogOpen: false,
      dialogSellOpen: false,
      salesQuantity: 0,
      dialogProductQuantity: 0,
      dialogDisableActionButton: false,
      sellType: '-',
      dialogText: '',
      dialogProductId: false,
      dialogProductName: '',
      productIsRemote: false,
      toDeleteReason: '-',
      callButtonId: 0,
      progress: {
        loading: false,
        success: false,
      },
      appData: {
        appID: this.FB_APP_ID
      },
      data: {
        pageToken: null,
        pageID: null
      },
      selectedPartnerData: DB.selectedPartnerData(),
      loading: true,
      selectAll: false,
      checked: {},
      selectBulkAction: '',
      bulkEditDialog: false,
      productBulk: [],
      productBulkFailed: [],
      productBulkUploaded: [],
      bulkProgress: 0,
      bulkEditDeleteDialog: false,
      bulkEditSellDialog: false,
      bulkEditSellProductsWithAmount: [],
      duplicationQueue: [],
      kleinanzeigenQueue: [],
    }
  }

  componentDidMount(){
    if (!window.fbAsyncInit) {
      // init fb-sdk
      FBinit(this);
    } else {
      // check wether token is valid
      this.checkToken();
    }

    this.loadLocalData();
    this.loadRemoteData();

    //this.showCurrentNews();
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true, dialogOpen: true });
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  getCategoryBreadcrumbByCategoryIds(ids) {
    const categoryFallback = process.env.NODE_ENV === 'production' ? require("../../json/produkt_kategorien_production.json") : require("../../json/produkt_kategorien.json");
    let categories = typeof(localStorage['kategorienData']) !== 'undefined' ? JSON.parse(localStorage['kategorienData']) : categoryFallback;

    let foundCategories = { levelOne: undefined, levelTwo: undefined, levelThree: undefined };

    // eslint-disable-next-line
    Object.values(categories).map(levelOneKey => {
      if(levelOneKey.term_id === ids.levelOne) {
        foundCategories.levelOne = levelOneKey;
      }

      // eslint-disable-next-line
      if(ids.levelTwo === undefined) return;

      // eslint-disable-next-line
      Object.values((levelOneKey.childs ? levelOneKey.childs : {})).map(levelTwoKey => {
        if(levelTwoKey.term_id === ids.levelTwo && foundCategories.levelOne.term_id === levelTwoKey.parent) {
          foundCategories.levelTwo = levelTwoKey;
        }

        // eslint-disable-next-line
        if(ids.levelThree === undefined) return;

        // eslint-disable-next-line
        Object.values((levelTwoKey.childs ? levelTwoKey.childs : {})).map(levelThreeKey => {
          if(levelThreeKey.term_id === ids.levelThree && foundCategories.levelTwo.term_id === levelThreeKey.parent) {
            foundCategories.levelThree = levelThreeKey;
          }
        });
      });
    });

    const levelOneString = foundCategories.levelOne !== undefined ? foundCategories.levelOne.name : "";
    const levelTwoString = foundCategories.levelTwo !== undefined ? " > " + foundCategories.levelTwo.name : "";
    const levelThreeString = foundCategories.levelThree !== undefined ? " > " + foundCategories.levelThree.name : "";

    const breadcrumbString = levelOneString + levelTwoString + levelThreeString;
    return breadcrumbString.replace("&amp;", "&");
  }

  checkToken() {
    if (this.getPartnerData('fb_page_token') && this.getPartnerData('fb_page_token') !== 'false') {
      console.debug('FB settoken')
      this.setState({
        data: {
          pageToken: this.getPartnerData('fb_page_token'),
          pageID: this.getPartnerData('fb_page_id')
        }
      })
    }
  }

  showCurrentNews(){
    this.props.enqueueSnackbar('Lieber Sellanizer-Kunde,\n'+
      'aufgrund COVID-19 haben wir aktuell eine Instabilität der Kleinanzeigen-Schnittstelle zu verzeichnen. Wir arbeiten daran, Ihnen diese schnellstmöglich wieder zur Verfügung zu stellen. Wir bitten um ihr Verständnis.',
      { className: 'white-space-pre-wrap', variant: 'info', persist: false, action: (<SnackbarClose/>)})
  }

  handleClickNewProduct = () => {
    API.resetTimer();
    this.state.props.history.push('/productNew/')
  }

  handleClickOpenDialog = (type, prop, is_remote = false) => {
    if(type==='delete'){
      this.setState({
        dialogOpen: true,
        dialogProductId: prop.id,
        dialogProductName: prop.name,
        productIsRemote: is_remote
      });
    }else if(type==='sell'){
      this.setState({
        dialogSellOpen: true,
        dialogProductId: prop.id,
        dialogProductName: prop.name,
        dialogProductQuantity: prop.menge,
        productIsRemote: is_remote
      });
    }
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false, dialogSellOpen: false, dialogProductId: false })
    this.setState({ sellType: '-', toDeleteReason: '-', dialogText: '', salesQuantity: 0 })
  };

  handleDeleteReason(e) {
    // TODO: Prüfen warum useState trotz React > 16.8 nicht funktioniert
    //const [toDeleteReason, setDeleteReason] = useState(false);
    //this.setDeleteReason(e.target.value)
    this.setState({ toDeleteReason: e.target.value, dialogText: '' });
    //console.debug('toDeleteReason', e.target.value);
  };

  handleSellType(e) {
    // TODO: Prüfen warum useState trotz React > 16.8 nicht funktioniert
    //const [toDeleteReason, setDeleteReason] = useState(false);
    //this.setDeleteReason(e.target.value)
    this.setState({ sellType: e.target.value, dialogText: '' });
    //console.debug('sellType', e.target.value);
  };

  handleDeleteAndCloseDialog = () => {
    let _this = this
    //console.debug('this.state.toDeleteReason', this.state.toDeleteReason)
    if(this.state.productIsRemote){
      if(this.state.toDeleteReason && this.state.toDeleteReason.length > 2){
        this.setState({dialogDisableActionButton: true})
        this.deleteProductRemote(this.state.dialogProductId, this.state.toDeleteReason).then((key) => {
          this.setState({
            dialogOpen: false,
            dialogProductId: false,
            productIsRemote: false,
            toDeleteReason: '-',
            dialogText: '',
            dialogDisableActionButton: false
          });
          _this.loadRemoteData()
        })
      }else{
        this.setState({ dialogText: 'Bitte Grund des Löschens angeben!' })
      }
    }else{
      this.deleteProductLocal(this.state.dialogProductId).then((key) => {
        this.setState({ dialogOpen: false })
      })
    }
  };

  handleSellAndCloseDialog = (prop) => {
    const id = this.state.dialogProductId
    const productQuantity = parseInt(this.state.dialogProductQuantity)
    const salesQuantity = parseInt(this.state.salesQuantity)
    const sellType = this.state.sellType
    const menge = productQuantity - salesQuantity
    if(this.state.sellType && this.state.sellType.length > 2){
      if(this.state.sellType.includes('teilverkauf')){
        //if(salesQuantity >= productQuantity) {
        //  this.setState({dialogText: "Die neue Menge muss niedriger \r\n als die verfügbare Menge sein!"})
        //}else{
          if(salesQuantity > 0){
            if(salesQuantity > productQuantity){
              this.setState({dialogText: "Die Verkaufs-Menge kann maximal \r\n gleich der verfügbaren Menge sein!"})
            }else{
              // Menge reduzieren
              this.setState({dialogDisableActionButton: true})
              this.changeProductRemoteValues(id, {'menge': menge, 'verkaufsmenge': salesQuantity, 'verkaufsart': sellType, 'is_verkauft': (menge===0) })
              //this.changeProduktValues(id,{'is_verkauft' : !prop.is_verkauft, 'menge' : menge})
              this.setState({ dialogSellOpen: false, dialogProductId: false, sellType: '-', productIsRemote: false, dialogText: '', salesQuantity: 0, dialogDisableActionButton: false })
            }
          }else{
            this.setState({ dialogText: 'Bitte Menge angeben!' })
          }
        //}
      }else{
        //this.changeProductRemoteValue(id, 'is_verkauft', true)
        this.setState({dialogDisableActionButton: true})
        this.changeProductRemoteValues(id, {'menge': menge, 'verkaufsmenge': salesQuantity, 'verkaufsart': sellType, 'is_verkauft': true }).then()
        this.setState({ dialogSellOpen: false, dialogProductId: false, sellType: '-', productIsRemote: false, dialogText: '', salesQuantity: 0, dialogDisableActionButton: false })
      }
    }else{
      this.setState({ dialogText: 'Bitte Art des Verkaufs angeben!' })
    }
  }

  loadLocalData = () => {
    let _this = this
    DB.getAllProducts()
      .then(result => {
        return result.map((item, key) => {
          return {
            id: item.id,
            name: item.name,
            post_id: item.post_id ? item.post_id : false,
            link: this.buildLink(item),
            artikelnr: item.article_nr,
            images: item.images || {},
            preis: _.replace(item.preis, /\./g, ',') + ' €',
            category: this.getCategoryBreadcrumbByCategoryIds({ levelOne: item.cat_level1, levelTwo: item.cat_level2, levelThree: item.cat_level3 }),
            is_verkauft: item.is_verkauft,
            should_marktde: item.should_marktde,
            app_synced: item.app_synced,
            app_sync_error: item.app_sync_error,
            actions: this.props.isMobile ? this.buildActionsMobile(item, key) : this.buildActions(item, key),
            key: key
          }}
        )
    }).then((newData) => {
      //console.debug('set new Data', newData)
      var checkedCopy = { ...this.state.checked };
      var selectAll = this.state.selectAll;

      newData.forEach(function(e, index) {
        if (checkedCopy[String(e.id)] === undefined) checkedCopy[String(e.id)] = selectAll;
      });

      _this.setState({
        dataLocal: newData,
        checked: checkedCopy,
        selectAll: selectAll
      })
    })
  }

  loadRemoteData = () => {
    let _this = this

    API.getProducts().then((json) => {
      //console.log('Get Products', json)
      //_this.changeProduktValues(key, { synced: undefined, app_synced: true, app_sync_error: false, post_id: json.post_id}, false)

      return json.map((item, key) => {
        item.id = item.id || item.post_id
        const post_status = (item.post_status === 'private') ? ' (privat)' : ''
        return {
          id: item.post_id || key,
          name: item.name+post_status,
          post_id: item.post_id ? item.post_id : false,
          link: this.buildLink(item),
          permalink: item.permalink,
          artikelnr: item.article_nr,
          images: item.images,
          preis: _.replace(item.preis, /\./g, ',') + ' €',
          category: item.categories_breadcrumb.replace('&amp;', '&'),
          app_synced: true,
          app_sync_error: false,
          is_verkauft:  item.is_verkauft,
          menge:  item.menge,
          // Kleinanzeigen
          should_ebay: item.should_ebay,
          is_on_ebay: item.is_on_ebay,
          ebay_ad_id: item.ebay_ad_id,
          has_ebay_error: item.has_ebay_error,
          ebay_error_info: item.ebay_error_info,
          // Markt.de
          should_marktde: item.should_marktde,
          is_on_marktde: item.is_on_marktde,
          marktde_ad_id: item.marktde_ad_id,
          // Traktorpool
          should_traktorpool: item.should_traktorpool,
          is_on_traktorpool: item.is_on_traktorpool,
          traktorpool_ad_id: item.traktorpool_ad_id,
          has_traktorpool_error: item.has_traktorpool_error,
          traktorpool_error_info: item.traktorpool_error_info,
          //
          actions: this.buildRemoteActions(item, key),
          key: key,
					post_status: item.post_status
        }
        }
      )
    }).then((editData) => {
      //console.log('set dataRemote', editData)
      _this.setState({ dataRemote: editData })

      let ebayListe = []
      let marktdeListe = []
      let traktorpoolListe = []

      //console.log('editData', editData)
      editData.forEach(function(item, key) {
        // Kleinanzeigen-Liste
        if (item.should_ebay || item.is_on_ebay) {
          let ebayItem = _.clone(item)
          ebayItem['actions'] = _this.buildEbayActions(ebayItem, key)
          ebayListe.push(ebayItem)
        }
        // Markt.de-Liste
        if (item.should_marktde || item.is_on_marktde) {
          let marktdeItem = _.clone(item)
          marktdeItem['actions'] = _this.buildMarktdeActions(marktdeItem, key)
          marktdeListe.push(marktdeItem)
        }
        // Traktorpool-Liste
        if (item.should_traktorpool || item.is_on_traktorpool) {
          let traktorpoolItem = _.clone(item)
          traktorpoolItem['actions'] = _this.buildTraktorpoolActions(traktorpoolItem, key)
          traktorpoolListe.push(traktorpoolItem)
        }
      })

      var checkedCopy = { ...this.state.checked };
      var selectAll = this.state.selectAll;

      editData.forEach(function(e, index) {
        if (checkedCopy[String(e.id)] === undefined) checkedCopy[String(e.id)] = selectAll;
      });

      _this.setState({ dataEbay: ebayListe, checked: checkedCopy, dataMarktde: marktdeListe, dataTraktorpool: traktorpoolListe })
      //Loading animation for the table
      _this.setState({ loading: false });
    }).catch(function (err) {
      console.debug('Fehler beim Sync!', err);
      _this.props.enqueueSnackbar('Fehler beim Laden der Produkt-Daten! Bitte wiederholen sie den Vorgang oder laden sie die App neu!', { persist:true, variant: 'error', action: (<SnackbarClose/>)})
      //_this.changeProduktValues(key, { app_synced: false, app_sync_error: true }, false)
      //reject(Error('Fehler beim Sync! '+err));
    });

  }

  async syncProductPromiseBased(key, reload = true) {
    let _this = this
    return new Promise((resolve, reject) => {
      // Load Product-Data from local DB
      DB.db.get(key, {attachments: true}).then(function (doc) {
        let docValues = {}
        if (doc.values) {
          docValues = doc.values
        } else if (doc.name) {
          docValues = doc
        }

        API.syncRequest(key, docValues).then((json) => {
          if(json[0] && json[0] === 'Error') {
            alert(json[1])
            reject(json[1])
          } else {
            if(reload) _this.loadRemoteData();

            // DEBUG: Delete Produkt kann zum Testen auskommentiert werden
            _this.changeProduktValues(key, { synced: true, app_synced: true, app_sync_error: false, post_id: json.post_id }, false, false).then(()=>{
              DB.delete(key).then(() => {
                console.debug('Deleted from LocalDB:', key);

                let data = _this.state.dataLocal;
                data.find((o, i) => {
                  if (o.id === key) {
                    data.splice(i, 1);
                    return true;
                  }
                  return false;
                });


                _this.setState({ dataLocal: data }, () => {
                  resolve('Artikel vollständig gelöscht!')
                });
              })
            })
          }
        }).then(()=>{
          resolve('Sync für ' + key + ' Erfolgreich!');
        }).catch(function (err) {
          console.log('Fehler beim Sync!', err);
          reject(Error('Fehler beim Sync! '+err));
        });

      }).catch(function (err) {
        console.log('Error at loading Product-Data from local DB', err);
        reject(Error('Error at loading Product-Data from local DB: '+err));
      });
    })
  }

  async changeProductValuePromiseBased(key, attr, value, resync = true, reload = true){
    let _this = this
    let values = resync ? { app_synced: false } : {}
    values[attr] = value
    return new Promise((resolve, reject) => {
      DB.update(key, values)
        .then(res => {
          if(reload) _this.loadLocalData();
          resolve(res);
        }).catch(err => {
          reject(err);
        })
    });
  }

  async changeProductRemoteValuePromiseBased(key, attr, value, reSync = true, reload = true) {
    let _this = this
    let values = { post_id: key, ...(reSync ? { app_synced: false } : {}) }
    values[attr] = value

    return new Promise((resolve, reject) => {
      API.syncRequest(key, values)
        .then(res => {
          if(reload) _this.loadRemoteData();
          resolve(res);
        }).catch(err => {
          reject(err);
        });
    });
  }

  async changeProductRemoteValuesPromiseBased(id, values, reload = true){
    let _this = this
    values = { ...{ post_id: id }, ...values }

    return new Promise((resolve, reject) => {
      API.syncRequest(id, values)
        .then(res => {
          if(reload) _this.loadRemoteData();
          resolve(res);
        }).catch(err => {
        reject(err);
      });
    });
  }

  async deleteProductRemotePromiseBased(id, reason = false, reload = true) {
    const _this = this;
    return new Promise((resolve, reject) => {
      API.delete(id, reason).then(() => {
        let data = _this.state.dataRemote;
        data.find((o, i) => {
          if (o.id === id) {
            data.splice(i, 1);
            return true;
          }
          return false;
        });

        _this.setState({ dataRemote: data }, () => {
          if(reload) _this.loadRemoteData();
          resolve('Produkt vollständig gelöscht!')
        });
      }).catch(() => {
        console.log('Error on try to delete from RemoteDB', id);
        reject();
      })
    });
  }

  handleCheckboxToggle(id) {
    const checkedCopy = { ...this.state.checked };

    checkedCopy[String(id)] = !this.state.checked[String(id)];

    if (checkedCopy[String(id)] === false) {
      this.setState({ selectAll: false });
    }

    this.setState({
      checked: checkedCopy
    });
  }

  handleCheckboxToggleAll(data, remote = true) {
    const checkedCopy = { ...this.state.checked };
    const selectAll = !this.state.selectAll;

    data.forEach(function(e) {
      checkedCopy[String(e._original.id)] = selectAll;
    });

    this.setState({
      selectAll: selectAll,
      checked: checkedCopy
    });
  }

  handleSelectBulkAction(e) {
    this.setState({
      selectBulkAction: e.target.value
    });
  }

  getBulkEditType() {
    switch (this.state.selectBulkAction) {
      case "STATUS_SOLD":
        return "Verkauft Status ändern";
      case "SET_DRAFT":
        return "Produkt Status ändern";
      case "SET_EBAYKL":
        return "Kleinanzeigen Status ändern";
      case "SET_MARKTDE":
        return "Markt.de Status ändern";
      case "DELETE":
        return "Löschen";
      default:
        return "Hochladen";
    }
  }

  getCheckedProducts() {
    const products = [];

    // eslint-disable-next-line
    Object.entries(this.state.checked).map(item => {
      if(item[1] === true) {
        products.push(item[0]);
      }
    })

    return products;
  }

  resetBulkActionSelect() {
    this.setState({ selectBulkAction: '' });
  }

  resetCheckedProductsList() {
    const checkedCopy = { ...this.state.checked };

    Object.entries(checkedCopy).forEach(function(e, index) {
      checkedCopy[e[0]] = false;
    });

    this.setState({ checked: checkedCopy, selectAll: false });
  }

  changeProductCheckedState(id, checked = false) {
    let checkedCopy = { ...this.state.checked };
    checkedCopy[id] = checked;
    this.setState({ checked: checkedCopy });
  }
  removeProductFromCheckedState(id) {
    let checkedCopy = { ...this.state.checked };
    delete checkedCopy[id];
    this.setState({ checked: checkedCopy });
  }

  addProductToBulkUploaded(id) {
    let bulkUploadedCopy = [ ...this.state.productBulkUploaded ];
    bulkUploadedCopy.push(id);
    this.setState({ productBulkUploaded: bulkUploadedCopy });
  }

  addProductToBulkFailed(id) {
    let bulkFailedCopy = [ ...this.state.productBulkFailed ];
    bulkFailedCopy.push(id);
    this.setState({ productBulkFailed: bulkFailedCopy });
  }

  handleBulkActionConfirmation(remote = true) {
    switch (this.state.selectBulkAction) {
      case "SET_CLOUD":
        this.handleBulkDialogOpen();
        this.syncProduct();
        break;
      case "SET_DRAFT":
        this.handleBulkDialogOpen();
        this.bulkEditSetDraft(remote);

        if (this.state.draftToggle) {
            this.setState({ draftToggle: false });
        }
        break;
      case "STATUS_SOLD":
        let foundProducts = [];

        this.getCheckedProducts().forEach((id) => {
          const productData = remote ? this.state.dataRemote : this.state.dataLocal;
          const productInfo = productData.filter(item => String(item.id) === String(id)).shift();
          if(productInfo?.menge > 1 && productInfo?.is_verkauft === false) {
            foundProducts.push(productInfo.name);
          }
        });
        this.setState({ bulkEditSellProductsWithAmount: foundProducts });
        this.handleBulkEditSellDialogOpen();
        break;
      case "SET_EBAYKL":
        if(this.checkPartnerData('is_ebay_kleinanzeigen')) {
          let currentCount = this.state && this.state.dataEbay ? this.state.dataEbay.length : 0
          if(this.getPartnerData('ebay_max_slots') <= currentCount) {
            this.message(Txt.ebay_no_slots)
          } else {
            this.handleBulkDialogOpen();
            this.bulkEditSetEbay(remote);
          }
        } else {
          this.message(Txt.kleinanzeigen)
        }
        break;
      case "SET_MARKTDE":
        if(this.checkPartnerData('is_marktde')) {
          this.handleBulkDialogOpen();
          this.bulkEditSetMarktde(remote);
        } else {
          this.message(Txt.kleinanzeigen)
        }
        break;
      case "DELETE":
        this.handleBulkEditDeleteDialogOpen(remote);
        break;
      case "CREATE":
        let newId = this.guid();
        DB.db.put(
          {
            ...{_id: newId},
            app_synced: false,
            artikelart: "austellungsware",
            cat_level1: 5495,
            cat_level2: 5496,
            cat_level3: 5497,
            einheit: "moebel",
            energielabel_2021: 0,
            field_wordpress_date: "2022-12-20T12:26",
            finanzierung: "",
            has_traktorpool_error: 0,
            images: [],
            is_neuware: 0,
            is_on_traktorpool: 0,
            is_verkauft: 0,
            land: "Deutschland",
            lieferbedingungen: "Abholung",
            menge: 1,
            menge_auf_anfrage: 0,
            ort: "Koblenz",
            partner: undefined,
            paypal_logo_anzeigen: 0,
            plz: "56070",
            post_id: false,
            post_status: "publish",
            preis_ist_vb: 0,
            produkt_typ: "moebel",
            should_ebay: 0,
            should_marktde: 0,
            should_traktorpool: 0,
            startdatum: "2022-12-20T12:26",
            strasse: "Ferdinand Nebel Str. 7",
            versand_moeglich: 0,
            zahlungsbedingungen: "Barzahlung",
            ansprechpartner: "67861",
            preis: 555,
            name: this.s4() + '-' + this.s4(),
            farbe_auswahl: "anthrazit"
          }).then(function (response) {
          console.log("added item to local db");
        }).catch(function (err) {
          console.log('Error on saving doc:', err);
          return false;
        });
        this.loadLocalData();
        break;
      default:
        break;
    }

  }

  syncProduct() {
    let products = this.getCheckedProducts();

    if(products.length <= 0) {
      this.setState({ selectAll: false });
      return true;
    }

    let id = products.shift();

    this.syncProductPromiseBased(id, products.length <= 0)
      .catch(() => {
        this.addProductToBulkFailed(id);
      })
      .then(() => {
        this.removeProductFromCheckedState(id);
        this.addProductToBulkUploaded(id);
        return this.syncProduct();
      });
  }

  bulkEditSetDraft(remote = true) {
    let products = this.getCheckedProducts();

    if(products.length <= 0) {
      this.setState({ selectAll: false });
      return true;
    }

    let id = products.shift();
    const productData = remote ? this.state.dataRemote : this.state.dataLocal;
    const productInfo = productData?.filter(item => String(item.id) === String(id)).shift();

    let changeProductValueFunction;

    if (remote) {
      changeProductValueFunction = this.changeProductRemoteValuePromiseBased(id, 'post_status', productInfo.post_status === 'publish' ? 'draft' : 'publish', false, products.length <= 0);
    } else {
      changeProductValueFunction = this.changeProductValuePromiseBased(id, 'post_status', productInfo.post_status === 'publish' ? 'draft' : 'publish', false, products.length <= 0);
    }

    changeProductValueFunction
        .catch(() => {
          this.addProductToBulkFailed(id);
        })
        .then((res) => {
          this.changeProductCheckedState(id);
          this.addProductToBulkUploaded(id);
          return this.bulkEditSetDraft(remote);
        });
  }

  bulkEditSetSold(remote = true, reason = '') {
    let products = this.getCheckedProducts();

    if(products.length <= 0) {
      this.setState({ selectAll: false });
      return true;
    }

    let id = products.shift();
    const productData = remote ? this.state.dataRemote : this.state.dataLocal;
    const productInfo = productData.filter(item => String(item.id) === String(id)).shift();

    let changeProductValueFunction;

    if (remote) {
      changeProductValueFunction = this.changeProductRemoteValuesPromiseBased(id, { 'verkaufsart': productInfo.is_verkauft === false ? reason : "", 'is_verkauft': !productInfo.is_verkauft }, products.length <= 0);
    } else {
      changeProductValueFunction = this.changeProductValuePromiseBased(id, 'is_verkauft', !productInfo.is_verkauft, false, products.length <= 0);
    }

    changeProductValueFunction
      .catch(() => {
        this.addProductToBulkFailed(id);
      })
      .then(() => {
          this.changeProductCheckedState(id);
          this.addProductToBulkUploaded(id);
          return this.bulkEditSetSold(remote, reason);
      });
  }

  bulkEditSetMarktde(remote = true) {
    let products = this.getCheckedProducts();

    if(products.length <= 0) {
      this.setState({ selectAll: false });
      return true;
    }

    let id = products.shift();
    const productData = remote ? this.state.dataRemote : this.state.dataLocal;
    const productInfo = productData.filter(item => String(item.id) === String(id)).shift();

    let changeProductValueFunction;

    if (remote) {
      changeProductValueFunction = this.changeProductRemoteValuePromiseBased(id, 'should_marktde', !productInfo.should_marktde, false, products.length <= 0);
    } else {
      changeProductValueFunction = this.changeProductValuePromiseBased(id, 'should_marktde', !productInfo.should_marktde, false, products.length <= 0);
    }

    changeProductValueFunction
      .catch(() => {
        this.addProductToBulkFailed(id);
      })
      .then(() => {
        this.changeProductCheckedState(id);
        this.addProductToBulkUploaded(id);
        return this.bulkEditSetMarktde(remote);
      });
  }

  bulkEditSetEbay(remote = true) {
    let products = this.getCheckedProducts();

    if(products.length <= 0) {
      this.setState({ selectAll: false });
      return true;
    }

    let id = products.shift();
    const productData = remote ? this.state.dataRemote : this.state.dataLocal;
    const productInfo = productData.filter(item => String(item.id) === String(id)).shift();

    let changeProductValueFunction;

    if (remote) {
      changeProductValueFunction = this.changeProductRemoteValuePromiseBased(id, 'should_ebay', !productInfo.should_ebay, false, products.length <= 0);
    } else {
      changeProductValueFunction = this.changeProductValuePromiseBased(id, 'should_ebay', !productInfo.should_ebay, false, products.length <= 0);
    }

    changeProductValueFunction
      .catch(() => {
        this.addProductToBulkFailed(id);
      })
      .then(() => {
        this.changeProductCheckedState(id);
        this.addProductToBulkUploaded(id);
        return this.bulkEditSetEbay(remote);
      });
  }

  bulkEditDelete(remote = true, reason = '') {
    let products = this.getCheckedProducts();

    if(products.length <= 0) {
      this.setState({ selectAll: false });
      return true;
    }

    let id = products.shift();

    let changeProductValueFunction;

    if (remote) {
      changeProductValueFunction = this.deleteProductRemotePromiseBased(id, reason, products.length <= 0);
    } else {
      changeProductValueFunction = this.deleteProductLocal(id);
    }

    changeProductValueFunction
      .catch(() => {
        this.addProductToBulkFailed(id);
      })
      .then(() => {
        this.changeProductCheckedState(id);
        this.removeProductFromCheckedState(id);
        this.addProductToBulkUploaded(id);
        return this.bulkEditDelete(remote, reason);
      });
  }

  handleBulkDialogClose = () => {
    this.setState({ bulkEditDialog: false });
    setTimeout(() => {
      this.setState({ selectBulkAction: '' });
    }, 150)
  }

  handleBulkDialogOpen = () => {
    this.setState({ bulkEditDialog: true, productBulkUploaded: [], productBulkFailed: [], productBulk: this.getCheckedProducts() })
  }

  guid() {
    return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
      this.s4() + '-' + this.s4() + this.s4() + this.s4();
  }

  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  async syncProdukt(key) {
    let _this = this
    return new Promise((resolve, reject) => {
      //console.log('Starte Sync für ', key);

      // Load Product-Data from local DB
      DB.db.get(key, {attachments: true}).then(function (doc) {
        //console.log(doc);
        let docValues = {}
        if (doc.values) {
          docValues = doc.values
        } else if (doc.name) {
          docValues = doc
        }

        API.syncRequest(key, docValues).then((json) => {
          if(json[0] && json[0] === 'Error'){
            alert(json[1])
            reject(json[1])
          }else{
            _this.loadRemoteData()

            // DEBUG: Delete Produkt kann zum Testen auskommentiert werden
            _this.changeProduktValues(key, { synced: true, app_synced: true, app_sync_error: false, post_id: json.post_id }, false, false).then(()=>{
              DB.delete(key).then(() => {
                console.log('Deleted from LocalDB:', key);

                let data = _this.state.dataLocal;
                data.find((o, i) => {
                  if (o.id === key) {
                    data.splice(i, 1);
                    return true;
                  }
                  return false;
                });

                //_this.changeProduktValue(key, 'app_synced', true, false)
                //_this.changeProduktValue(key, 'post_id', params.post_id, false)

                _this.setState({ dataLocal: data }, () => {
                  resolve('Artikel vollständig gelöscht!')
                });
              })
              //console.log('changeProduktValues erfolgreich!');
            })
          }

        }).then(()=>{
          resolve('Sync für '+key+' Erfolgreich!');
        }).catch(function (err) {
          console.log('Fehler beim Sync!', err);
          //_this.changeProduktValues(key, { app_synced: false, app_sync_error: true }, false)
          reject(Error('Fehler beim Sync! '+err));
        });

      }).catch(function (err) {
        console.log('Error at loading Product-Data from local DB', err);
        reject(Error('Error at loading Product-Data from local DB: '+err));
      });
    })
  }


  async deleteProductLocal(key){
    let _this = this

    return new Promise((resolve, reject) => {
      DB.delete(key).then(() => {
        console.log('Deleted from LocalDB:', key);

        let data = _this.state.dataLocal;
        data.find((o, i) => {
          if (o.id === key) {
            data.splice(i, 1);
            return true;
          }
          return false;
        });

        _this.setState({ dataLocal: data }, () => {
          resolve('Produkt vollständig gelöscht!')
        });
      }).catch((err)=>{
        reject(Error('Produkt konnte nicht gelöscht werden! ' +err))
      })
    })
  }


  async deleteProductRemote(key_post_id, reason = false){
    return API.delete(key_post_id, reason).then(() => {
      console.log('Deleted from RemoteDB:', key_post_id);
    }).catch(() => {
      console.log('Error on try to delete from RemoteDB', key_post_id);
    })
  }


  // Wird aktuell nicht benötigt, da gesyncte Artikel sofort gelöscht werden
  async deleteProductLocalRemote(key){
    let _this = this
    return new Promise((resolve, reject) => {
      DB.db.get(key).then(function (doc) {
        let post_id = doc.post_id

        if(post_id){
          // Erst via API aus RemoteDB löschen, bei Erfolg erst dann aus lokaler DB
          console.log('Try to delete from RemoteDB:', key);

          API.delete(key, post_id).then(() => {
            console.log('Deleted from RemoteDB:', key);
            console.log('Try to delete from LocalDB:', key);

            DB.delete(key).then(() => {
              console.log('Deleted from LocalDB:', key);

              let data = _this.state.dataLocal;
              data.find((o, i) => {
                if (o.id === key) {
                  data.splice(i, 1);
                  return true;
                }
                return false;
              });

              //_this.changeProduktValue(key, 'app_synced', true, false)
              //_this.changeProduktValue(key, 'post_id', params.post_id, false)

              _this.setState({ dataLocal: data }, () => {
                resolve('Artikel vollständig gelöscht!')
              });
            })

          })
          .catch(() => {
            //_this.changeProduktValue(key, 'app_synced', true, false)
            //_this.changeProduktValue(key, 'app_sync_error', true, false)
          })

        }else{
          // Artikel existiert nicht in RemoteDB, direkt aus lokaler DB löschen
          console.log('Try to delete from LocalDB:', key);

          DB.delete(key).then(() => {
            console.log('Deleted from LocalDB:', key);

             let data = _this.state.dataLocal;
             data.find((o, i) => {
               if (o.id === key) {
                 data.splice(i, 1);
                 return true;
               }
               return false;
             });
             _this.setState({ dataLocal: data }, () => {
                resolve('Artikel lokal gelöscht!')
              });

           })
        }

      })
    })
  }


  /*
   * key: = doc-id e.g.: 0dd2221b-b775-4613-97e7-8afeae6ea188
   * attr: 'name'
   * value: 'my new name'
   * resync: boolean, default: true => the mostly changes needs a resync, so set app_synced to false
   */
  async changeProduktValue(key, attr, value, resync = true, reload = true){
    let _this = this
    console.log('changeProduktValue: key: '+key+', attr: '+attr+', value: '+value);
    let values = resync ? { app_synced: false } : {}
    values[attr] = value

    Promise.reject();

    return DB.update(key, values).then(result => {
      if(reload) {
        console.log('changeProduktValue: then.loadLocalData', result);
        _this.loadLocalData();
      }
      Promise.resolve()
    })
  }

  async changeProduktValues(key, values = {}, resync = true, reload = true){
    let _this = this
    values = { ...values, ...(resync ? { app_synced: false } : {}) }
    //console.debug('changeProduktValues for '+key+':', values);

    return DB.update(key, values).then(result => {
      if(reload){
        //console.debug('changeProduktValues: then.loadLocalData', result);
        _this.loadLocalData();
      }
      Promise.resolve()
    }).catch(function (err) {
      _this.message('Fehler beim Ändern der Produkt-Daten oin der Cloud! ' + err);
    });
  }

  async changeProductRemoteValue(key, attr, value) {
    let _this = this
    console.debug('changeProductRemoteValue: key: '+key+', attr: '+attr+', value: '+value);
    let values = { post_id: key }
    values[attr] = value

    return API.syncRequest(key, values).then((json) => {
      console.debug('Sync für '+key+' Erfolgreich!', json)
      _this.loadRemoteData();
    }).catch(function (err) {
      _this.message('Fehler beim Ändern der Produkt-Daten oin der Cloud! ' + err);
      console.debug('Fehler beim Ändern der Produkt-Daten oin der Cloud!', err);
      //_this.changeProduktValues(key, { app_synced: false, app_sync_error: true }, false)
      //Promise.reject(Error('Fehler beim Sync! '+err));
    });
  }

  async changeProductRemoteValues(post_id, values){
    let _this = this
    values = { ...{ post_id: post_id }, ...values }
    console.debug('changeProductRemoteValues:', values);

    return API.syncRequest(post_id, values).then((json) => {
      console.debug('Sync für '+post_id+' Erfolgreich!', json)
      _this.loadRemoteData()
    }).catch(function (err) {
      _this.message('Fehler beim Sync! ' + err);
      console.debug('Fehler beim Sync!', err);
    });
  }


  getArtikelName() {
    if(this.state.dialogProductId) {
      let key = this.state.dialogProductId
      let obj = this.state.dataLocal.find(o => o.id === key)
      return obj.name
    }
  }

  buildLink = (item, onlyUrl=true) => {
    let name = item.name
    let link = item.post_id ? '?post_type=produkt&p='+item.post_id+'&preview=true' : false

    return (
      onlyUrl ? this.PW_DOMAIN+link : <a href={this.PW_DOMAIN+link} target="_blank" rel="noopener noreferrer">{name}</a>
    )
  }

  buildDeeplink = (item) => {
    let permalink = item.permalink ? item.permalink.replace(/\/$/, '') : ''
    let name = permalink.substring(permalink.lastIndexOf('/') +1)
    let url = this.getPartnerMarktplatzUrl();
    // let link = (url.endsWith('/') ? url : url + '/') + '?product=' + name;
    let link = url + '?t4m_product=' + name;
    return link;
  }

  buildPdfLink = (item) => {
    let link = item.permalink ? item.permalink+'pdf/' : false

    if(link){
      return link.startsWith('http') ? link : this.PW_DOMAIN+link
    }else{
      return 'about:blank'
    }
  }

  buildEbayLink = (item) => {
    let link = (item.ebay_ad_id && item.ebay_ad_id > 0) ? 'https://www.kleinanzeigen.de/s-anzeige/'+item.ebay_ad_id : false
    return link
  }

  buildMarktdeLink = (item) => {
    let link = false

    if(item.is_on_marktde){
      if(item.marktde_link){
        link = item.marktde_link
      }else if(item.marktde_ad_id.length > 5) {
        link = 'https://www.markt.de/suche.htm?keywords='+item.marktde_ad_id;
      }else{
        link = 'https://www.markt.de/suche.htm?keywords='+item.name;
      }
    }

    return link
  }

  buildTraktorpoolLink = (item) => {
    let link = false

    if(item.has_traktorpool_error===true){
      return false;
    }

    if(item.traktorpool_ad_id && item.traktorpool_ad_id.length > 5) {
      link = 'https://www.traktorpool.de/details/'+item.traktorpool_ad_id;
    }else{
      link = 'https://www.traktorpool.de/gebraucht/?q='+item.name+'&machine_world_id=0';
    }

    return link
  }

  getPartnerData = (key, fallback=false) => {
    const parsedData = this.state.selectedPartnerData

    if(typeof(parsedData[key])==='undefined')
      return fallback

    return parsedData[key]
  }

  checkPartnerData = (key, fallback=false) => {
    const parsedData = this.state.selectedPartnerData

    if(typeof(parsedData[key])==='undefined')
      return fallback

    return parsedData[key] ? true : false
  }

  getPartnerMarktplatzUrl() {
    let parsedData = this.state.selectedPartnerData
    return parsedData.partner_marktplatz_url
  }

  message = (type, as_popup = true) => {
    let msg

    switch (type) {
      case 'kleinanzeigen_manager':
        msg = <div>Um diese Funktion nutzen zu können, buchen Sie den "Kleinanzeigen-Manager" für nur 49,- €/Mnt. <a href="mailto:sellanizer@trendview.de">Kontakt hier</a></div>
      break;
      case 'kleinanzeigen_manager_text':
        msg = Txt.kleinanzeigen
      break;
      default:
        msg = type
    }
    if(as_popup){
      this.infoMessage(msg);
    }else{
      return msg
    }
  }

  infoMessage = (msg) => {
    //alert(msg)
    this.props.enqueueSnackbar(msg, {
      variant: 'info',
      action: <SnackbarClose/>,
      anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
      },
    });
  }

  toggleDrawer = (side, open, key) => () => {
    this.setState({
      [side]: open,
    });
    console.log('get state this.state[side]', this.state[side])
    //this.setState({ dataLocal[key]['actions'],  })
    this.loadLocalData()
  };

  simulateClick(e) {
      e.click()
    }

  secureFilename(key, i, file, productName){
    let filename = file.name || key
    // image.jpg is a default iOS Name, we have to rename it
    if(filename === 'image.jpg') {
      filename = this.filenameByName(key, i, file, productName)
    }
    // Remove invalid trailing _
    if(filename.charAt(0)==='_'){
      filename = filename.substr(1);
    }
    //console.log('secureFilename', filename)
    return filename
  }

  filenameByName(key, i, file, productName){
    let type = (file.type || file.content_type).replace('jpeg', 'jpg')

    // Originalfilename und Fallback Filename mit Timestamp
    let filename = file.name || key || ('img-'+Date.now()+'.'+type.split('/').pop())

    // Filename by Artikelname deaktviert, da in der API aktuell nicht überprüft wird ob unter diesem Namen schon ein Bild existiert, wenn ja wird dieser genommen und überschrieben?!
    if(false || productName.length > 2){
      // Filename by Artikelname
      let secName = productName.replace(' ', '-').replace(/(?!\.[^.]+$)\.|[^\w-]+/g, '')
      filename = 'img'+i+'-'+secName+'.'+type.split('/').pop()
    }else{
      // Uniq Filename by Timestamp
      filename = 'img'+i+'-'+Date.now()+'.'+type.split('/').pop()
    }
    if(filename.charAt(0)==='_'){
      filename = filename.substr(1);
    }
    return filename
  }

  formatFilesObject(files_ary, name) {
    let attachments = {}
    let file
    let _this = this

    if(typeof files_ary !== 'undefined' && Object.keys(files_ary).length){
      let i = 0
      Object.keys(files_ary).forEach(function(key) {
        file = files_ary[key]
        //console.log('images.forEach file=', file);

        if(typeof file !== "undefined"){
          let filename = _this.secureFilename(key, i, file, name)
          file.name = filename

          if(attachments[filename]){
            let filename = _this.filenameByName(key, i, file, name)
            file.name = filename
            // Index existiert schon, ändern
            //console.log('Attachment-Index exitiert schon, rename to:', filename)
            attachments[filename] = file
          }else{
            attachments[filename] = file
          }


        }
        i++;
      });
    }
    return attachments;
  }

  buildActionsMobile = (prop, key) => {
    const { classes } = this.props;
    var editRef = React.createRef();
    var syncRef = React.createRef();
    //console.log('classes', classes);
    return (
      <div className="actions-right">
        <Button
          justIcon
          round
          isMobile={this.props.isMobile}
          onClick={this.toggleDrawer('drawerBottom_'+key, true)}
          className="like"
        >
          <MenuIcon/>
        </Button>
        <SwipeableDrawer
          id={'drawer-'+key}
          anchor="bottom"
          open={!!this.state['drawerBottom_'+key]}
          onClose={this.toggleDrawer('drawerBottom_'+key, false)}
          onOpen={this.toggleDrawer('drawerBottom_'+key, true)}
        >
          <div
            tabIndex={0}
            role="button"

            onKeyDown={this.toggleDrawer('drawerBottom_'+key, false)}
          >
            <div className={classes.root}>
              <List component="nav" disablePadding={true}>
                <ListItem
                  button
                  onClick={() => { console.log('syncbutton', syncRef);
                    prop.app_synced = true
                    prop.doit = 1
                    this.setState({callButtonId: prop.id})
                  }}>
                  {/* ------------------------------- Sync ------------------------------- */}
                  <ProgressButton
                    id={prop.id}
                    ref={syncRef}
                    doit={this.state.callButtonId}
                    synced={prop.app_synced}
                    sync_error={prop.app_sync_error}
                    onClick={() => this.syncProdukt(prop.id)}
                  >
                  </ProgressButton>
                  <ListItemText inset={true}>Synchronisieren</ListItemText>
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    //let obj = this.state.dataLocal.find(o => o.id === prop.id);
                    this.changeProduktValue(prop.id, 'is_verkauft', !prop.is_verkauft)
                  }}>
                  {/* ------------------------------- verkauft/nicht verkauft ------------------------------- */}
                  <Button
                    justIcon
                    round
                    color={ prop.is_verkauft ? 'danger' : 'success'}
                    className="like"
                  >
                    { !prop.is_verkauft ? <ShoppingCart /> : <ShoppingCartOff />}
                  </Button>
                  <ListItemText>{ !prop.is_verkauft ? 'Auf verkauft stellen' : 'Auf NICHT verkauft stellen'}</ListItemText>
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    if(this.checkPartnerData('is_ebay_kleinanzeigen')){
                      this.changeProduktValue(prop.id, 'should_ebay', !prop.should_ebay)
                    }else{
                      this.message(Txt.kleinanzeigen)
                    }
                  }}>
                  {/* ------------------------------- Kleinanzeigen ja/nein ------------------------------- */}
                  <Button
                    justIcon
                    round
                    color={ prop.should_ebay ? 'success' : 'danger'}
                    className="like"
                  >
                    <img src={kleinanzeigenIcon} className={classes.kleinanzeigenIcon} alt="Kleinanzeigen"/>
                  </Button><ListItemText>{ prop.should_ebay ? 'Von Kleinanzeigen streichen' : 'Auf Kleinanzeigen setzen'}</ListItemText>
                </ListItem>
                <ListItem
                  button
                  onClick={() => { (editRef.current.props.onClick()) }}>
                  {/* ------------------------------- bearbeiten ------------------------------- */}
                  <Button
                    ref={editRef}
                    justIcon
                    round
                    color="warning"
                    className="edit"
                    onClick={() => { this.state.props.history.push('/productEdit/'+prop.id) }}
                  >
                    <Edit />
                  </Button><ListItemText>Bearbeiten</ListItemText>
                </ListItem>
                <ListItem
                  button
                  onClick={ () => { this.handleClickOpenDialog('delete', prop) }}>
                  {/* ------------------------------- löschen -------------------------------*/}
                  <Button
                    justIcon
                    round
                    color="primary"
                    className="remove"
                  >
                    <Delete />
                  </Button><ListItemText>Löschen</ListItemText>
                </ListItem>
              </List>
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    )
  }

  buildActions = (prop, key) => {
    const { classes } = this.props;
    return (
      // we've added some custom button actions
      <div className="actions-right cloud">
        {/* ------------------------------- Sync ------------------------------- */}
        <Online>
          <ProgressButton
            id="selenium-local-sync"
            isMobile={this.props.isMobile}
            tooltip="Artikel synchronisieren"
            synced={prop.app_synced}
            sync_error={prop.app_sync_error}
            onClick={() => this.syncProdukt(prop.id)}
          >
          </ProgressButton>
        </Online>
        <Offline>
          <Tooltip title="Stellen Sie eine Internet-Verbindung her" placement="top">
          <Button
            justIcon
            isMobile={this.props.isMobile}
            round
            disableRipple={true}
            className="like"
          >
            <CloudOff />
          </Button>
          </Tooltip>
        </Offline>
        {/* ------------------------------- verkauft/nicht verkauft ------------------------------- */}
        <Button
          id="selenium-local-verkauft"
          justIcon
          isMobile={this.props.isMobile}
          round
          tooltip={ prop.is_verkauft ? 'Auf NICHT verkauft stellen' : 'Auf verkauft stellen'}
          image={ !prop.is_verkauft ? <ShoppingCart /> : <ShoppingCartOff />}
          onClick={() => {
            this.changeProduktValue(prop.id, 'is_verkauft', !prop.is_verkauft)
          }}
          color={ prop.is_verkauft ? 'danger' : 'success'}
          className="like"
        >
        </Button>
        {/* ------------------------------- online/offline ------------------------------- *
        <Button
          justIcon
          isMobile={this.props.isMobile}
          round
          onClick={() => {
            let obj = this.state.dataLocal.find(o => o.id === key);
            this.changeProduktValue(key, 'post_status', !prop.post_status)
            prop.synced = false
            prop.app_synced = false
          }}
          color={ prop.post_status ? 'success' : 'danger'}
          className="like"
        >
          <Tooltip title="online/offline stellen" placement="top">
          { !prop.is_verkauft ? <ShoppingCart /> : <ShoppingCartOff />}
          </Tooltip>
        </Button>*/}
        {/* ------------------------------- ebay ja/nein ------------------------------- */}
        <Button
          id="selenium-local-ebaykl"
          justIcon
          isMobile={this.props.isMobile}
          round
          tooltip="auf Kleinanzeigen setzen ja/nein"
          image={<img src={kleinanzeigenIcon} className={classes.kleinanzeigenIcon} alt="Kleinanzeigen"/>}
          onClick={() => {
            if(this.checkPartnerData('is_ebay_kleinanzeigen')){
              let current_count = this.state && this.state.dataEbay ? this.state.dataEbay.length : 0
              if(!prop.should_ebay && this.getPartnerData('ebay_max_slots') <= current_count){
                this.message(Txt.ebay_no_slots)
              }else{
                this.changeProduktValue(prop.id, 'should_ebay', !prop.should_ebay)
              }
            }else{
              this.message(Txt.kleinanzeigen)
            }
          }}
          color={ prop.should_ebay ? 'success' : 'danger'}
          className="like"
        >
        </Button>
        {/* ------------------------------ markt.de ja/nein ------------------------------ */}
        {this.checkPartnerData('is_marktde') ? (
        <Button
          id="selenium-local-marktde"
          justIcon
          isMobile={this.props.isMobile}
          round
          tooltip="auf Markt.de setzen ja/nein"
          image={<img src={marktdeIcon} className={classes.marktdeIcon} alt="markt.de"/>}
          onClick={() => {
            if(this.checkPartnerData('is_marktde')){
              this.changeProduktValue(prop.id, 'should_marktde', !prop.should_marktde)
            }else{
              this.message(Txt.kleinanzeigen)
            }
          }}
          color={ prop.should_marktde ? 'success' : 'danger'}
          className="like"
        >
        </Button>) : ("")}
        {/* ------------------------------- duplizieren ------------------------------- */}
        <Button
            id="selenium-local-duplicate"
            justIcon
            isMobile={this.props.isMobile}
            round
            tooltip="Duplizieren"
            image={<Copy />}
            onClick={async () => {
              let _this = this;
              let newId = this.guid();
              DB.db.get(prop.id, { attachments: true }).then(function (doc) {
                let product = { ...doc }

                delete product._id;
                delete product._rev;

                const name = product.name + " - Kopie";
                delete product.name;

                const date = new Date();
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, "0");
                const day = date.getDate().toString().padStart(2, "0");
                const hours = date.getHours().toString().padStart(2, "0");
                const minutes = date.getMinutes().toString().padStart(2, "0");

                product.startdatum = `${year}-${month}-${day}T${hours}:${minutes}`;
                product.is_verkauft = false;

                DB.db.put({ ...{ _id: newId, name: name }, ...product }).then(function (response) {
                  _this.state.props.history.push('/productEdit/' + newId)
                }).catch(function (err) {
                  console.log('Error on saving doc:', err);
                });
              }).catch(function (err) {
                console.log(err);
              });
            }}
            color="rose"
            className="duplicate"
        >
        </Button>{" "}
        {/* ------------------------------- bearbeiten ------------------------------- */}
        <Button
          id="selenium-local-edit"
          justIcon
          isMobile={this.props.isMobile}
          round
          tooltip="bearbeiten"
          image={<Edit />}
          onClick={() => {
            //let obj = this.state.dataLocal.find(o => o.id === prop.id);
            // Load productForm Component
            this.state.props.history.push('/productEdit/'+prop.id)
          }}
          color="warning"
          className="edit"
        >
        </Button>{" "}
        {/* ------------------------------- löschen -------------------------------*/}
        <Button
          id="selenium-local-delete"
          justIcon
          isMobile={this.props.isMobile}
          round
          tooltip="löschen"
          image={<Delete />}
          onClick={ () => {
            this.handleClickOpenDialog('delete', prop)
          }}
          color="primary"
          className="remove"
        >
        </Button>{" "}
      </div>
    )
  }

  imageUrlToBase64 = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64 = reader.result;

        const yourBase64String = base64.substring(base64.indexOf(',') + 1);
        const bits = yourBase64String.length * 6; // 567146
        const bytes = bits / 8;
        // const kb = Math.ceil(bytes / 1000);
        resolve({ base64, fileSize: Number(bytes) });
      };
      reader.onerror = reject;
    });
  };

  convertUploadedImageToFileStructure = async (imageUrl, product) => {
    const upload_energiepass = {};
    const imageData = await this.imageUrlToBase64(imageUrl);

    upload_energiepass[product.filename] = {
      contentType: product.mime_type,
      // data: String(imageBase64).replace(`data:${remoteProduct.upload_energiepass.mime_type};base64,`, ""),
      data: String(imageData.base64).replace(`data:${product.mime_type};base64,`, ""),
      imagePreviewUrl: imageData.base64,
      lastChanged: Date.now(),
      name: product.filename,
      title: "",
      type: product.mime_type,
      size: imageData.fileSize
    };

    return upload_energiepass;
  }

  buildRemoteActions = (prop, key) => {
    const { classes } = this.props;
    const _this = this;

    if (prop.original !== undefined) {
      prop = prop.original
    }

    return (
      // we've added some custom button actions
      <div className="actions-right" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {/* ------------------------------- Artikel-Seite ansehen ------------------------------- */}
        <Button
          id="selenium-remote-public"
          justIcon
          round
          isMobile={this.props.isMobile}
          tooltip={prop.post_status === 'publish' ? Txt.show_page : Txt.article_not_public}
          image={<OpenInNew />}
          onClick={() => {
            let status_text = prop.post_status === 'future' ? Txt.status_text_future : Txt.status_text_draft;
            if(prop.post_status === 'publish'){
              window.open(this.buildLink(prop))
            }else{
              this.props.enqueueSnackbar(status_text, { variant: 'info' })
            }
          }}
          color={prop.post_status === 'publish' ? 'info' : 'gray'}
          className="like"
        >
        </Button>
        {/* ------------------------------- Verkaufsschild-PDF ansehen ------------------------------- */}
        <Button
          id="selenium-remote-pdf"
          justIcon
          round
          image={<img src={pdfIcon} className={classes.pdfIcon} alt="PDF"/>}
          tooltip={Txt.show_pdf}
          isMobile={this.props.isMobile}
          onClick={() => {
            let status_text = prop.post_status === 'future' ? Txt.status_text_future : Txt.status_text_draft;
            if(prop.post_status === 'publish'){
              window.open(this.buildPdfLink(prop))
            }else{
              this.props.enqueueSnackbar(status_text, { variant: 'info' })
            }
          }}
          color={prop.post_status === 'publish' ? 'info' : 'gray'}
          className="like"
        >
        </Button>
        {/* ------------------------------- verkauft/nicht verkauft ------------------------------- */}
        <Button
          id="selenium-remote-verkauft"
          justIcon
          round
          isMobile={this.props.isMobile}
          image={!prop.is_verkauft ? <ShoppingCart /> : <ShoppingCartOff />}
          tooltip={ prop.is_verkauft ? 'Auf NICHT verkauft stellen' : 'Auf verkauft stellen'}
          onClick={() => {
            if(!prop.is_verkauft){
              // verkauf einstellen
              console.log('handleClickOpenDialog sell');
              this.handleClickOpenDialog('sell', prop)
            }else{
              // ausverkauf rückgängig machen
              this.changeProductRemoteValue(prop.id, 'is_verkauft', !prop.is_verkauft)
            }
          }}
          color={ prop.is_verkauft ? 'danger' : 'success'}
          className="like"
        >
        </Button>
        {/* ------------------------------- online/offline ------------------------------- *
        <Button
          justIcon
          isMobile={this.props.isMobile}
          round
          onClick={() => {
            let obj = this.state.dataLocal.find(o => o.id === key);
            this.changeProduktValue(key, 'post_status', !prop.post_status)
            prop.synced = false
            prop.app_synced = false
          }}
          color={ prop.post_status ? 'success' : 'danger'}
          className="like"
        >
          <Tooltip title="online/offline stellen" placement="top">
          { !prop.is_verkauft ? <ShoppingCart /> : <ShoppingCartOff />}
          </Tooltip>
        </Button>
        {/* ------------------------------- ebay ja/nein ------------------------------- */}
        <div className={classes.wrapper}>
          <Button
            id="selenium-remote-ebaykl"
            justIcon
            isMobile={this.props.isMobile}
            disabled={this.state.kleinanzeigenQueue.find((element) => element === prop.id)}
            round
            tooltip={prop.has_ebay_error ? prop.ebay_error_info : 'auf Kleinanzeigen setzen ja/nein'}
            image={<img src={kleinanzeigenIcon} className={classes.kleinanzeigenIcon} alt="Kleinanzeigen"/>}
            onClick={() => {
              if(this.checkPartnerData('is_ebay_kleinanzeigen')){
                let current_count = this.state && this.state.dataEbay ? this.state.dataEbay.length : 0
                if(!prop.should_ebay && this.getPartnerData('ebay_max_slots') <= current_count){
                  this.message(Txt.ebay_no_slots)
                } else {
                  const kleinanzeigenQueue = [...this.state.kleinanzeigenQueue];
                  kleinanzeigenQueue.push(String(prop.id));
                  this.setState({ kleinanzeigenQueue: [...kleinanzeigenQueue] });

                  this.changeProductRemoteValuePromiseBased(prop.id, 'should_ebay', !prop.should_ebay, false, false).then(async () => {
                    let kleinanzeigenQueue = [...this.state.kleinanzeigenQueue];
                    kleinanzeigenQueue.splice(kleinanzeigenQueue.indexOf(String(prop.id)), 1);

                    this.setState({ kleinanzeigenQueue: kleinanzeigenQueue });
                    this.loadRemoteData();
                  });
                }
              } else {
                this.message(Txt.kleinanzeigen)
              }
            }}
            color={prop.should_ebay ? (prop.has_ebay_error ? 'warning' : 'success') : 'danger'}
            className="like"
          >
          </Button>{" "}
          {this.state.kleinanzeigenQueue.find((element) => element === prop.id) && <CircularProgress size={40} className={classes.buttonProgress} />}
        </div>
        {/* ------------------------------ markt.de ja/nein ------------------------------ */}
        {this.checkPartnerData('is_marktde') ? (
        <Button
          id="selenium-remote-marktde"
          justIcon
          isMobile={this.props.isMobile}
          round
          tooltip="auf Markt.de setzen ja/nein"
          image={<img src={marktdeIcon} className={classes.marktdeIcon} alt="markt.de"/>}
          onClick={() => {
            if(this.checkPartnerData('is_marktde')){
              this.changeProductRemoteValue(prop.id, 'should_marktde', !prop.should_marktde)
            }else{
              this.message(Txt.kleinanzeigen)
            }
          }}
          color={ prop.should_marktde ? 'success' : 'danger'}
          className="like"
        >
        </Button>) : ("")}
        {/* ------------------------------ Facebook ------------------------------ */}
        {this.checkPartnerData('is_facebook') ? (
          <>{this.getPartnerData('fb_page_token') ? (
            <FacebookPost
              isMobile={this.props.isMobile}
              pageToken={this.getPartnerData('fb_page_token')}
              pageID={this.getPartnerData('fb_page_id')}
              postData={{
                name: prop.name,
                preis: prop.preis,
                images: prop.images,
                link: this.buildDeeplink(prop)
              }}
              onError={this.handleFBError}
              onSuccess={this.handleFBSuccess}
            />
          ) :
          <>
            <Button
              justIcon
              isMobile={this.props.isMobile}
              round
              tooltip="facebook-Post"
              onClick={() => {
                this.props.enqueueSnackbar("Bitte verbinden Sie sich erst mit Facebook unter Einstellungen.", { variant: 'info' })
              }}
              color="gray"
              className={classes.facebookDeactivated}
            >f
            </Button>{" "}
          </> } </>
          ) : (
          <>
            <Button
              justIcon
              isMobile={this.props.isMobile}
              round
              tooltip="facebook-Post"
              onClick={() => {
                this.props.enqueueSnackbar("Die Funktion 'Facebook-Veröffentlichung' ist nicht freigeschaltet! Um diese Funktion nutzen zu können, wenden Sie sich bitte an Ihren Sellanizer-Ansprechpartner, rufen Sie unter 0261/1349640 an oder schreiben eine E-Mail an info@trends4markets.de!", { persist:true, variant: 'info', action: (<SnackbarClose/>)})
              }}
              color="gray"
              className={classes.facebookDeactivated}
            >f
            </Button>{" "}
          </>
        )}
        {/* ------------------------------- duplizieren ------------------------------- */}
          <div className={classes.wrapper}>
            <Button
                id="selenium-remote-duplicate"
                justIcon
                isMobile={this.props.isMobile}
                disabled={this.state.duplicationQueue.find((element) => element === prop.id) || this.state.duplicationQueue.length > 0}
                round
                tooltip="Duplizieren"
                image={<Copy />}
                onClick={async () => {
                  if (prop.id === undefined || prop.id === 'undefined') {
                    this.props.enqueueSnackbar('Beim Duplizieren ist ein Fehler aufgetreten!', { variant: 'error', autoHideDuration: 5000 })
                    return;
                  }

                  const duplicationQueue = [...this.state.duplicationQueue];
                  duplicationQueue.push(String(prop.id));

                  this.setState({ duplicationQueue: duplicationQueue });

                  API.getProduct(prop.id).then(async (data) => {
                    let remoteProduct = { ...data[0] };

                    const name = remoteProduct.name + " - Kopie";

                    delete remoteProduct.name;
                    delete remoteProduct.actions;
                    delete remoteProduct.post_id;
                    delete remoteProduct.startdatum;
                    remoteProduct.name = name;
                    remoteProduct.post_status = 'draft';
                    remoteProduct.should_ebay = false;
                    remoteProduct.should_marktde = false;
                    remoteProduct.should_traktorpool = false;
                    remoteProduct.ebay_ad_id = 0;
                    remoteProduct.ebay_error_info = "";
                    remoteProduct.has_ebay_error = false;
                    remoteProduct.is_on_ebay = false;
                    remoteProduct.marktde_ad_id = 0;
                    remoteProduct.is_on_marktde = false;
                    remoteProduct.traktorpool_ad_id = 0;
                    remoteProduct.traktorpool_error_info = "";
                    remoteProduct.has_traktorpool_error = false;
                    remoteProduct.is_on_traktorpool = false;
                    remoteProduct.is_verkauft = false;

                    if (remoteProduct.versand_moeglich === undefined || remoteProduct.versand_moeglich === false) {
                      remoteProduct.versand_moeglich = 0;
                    }

                    if (remoteProduct.dunstabzug_upload_energiepass !== undefined && remoteProduct.dunstabzug_upload_energiepass !== false) {
                      remoteProduct.dunstabzug_upload_energiepass = await this.convertUploadedImageToFileStructure(remoteProduct.dunstabzug_upload_energiepass.url, remoteProduct.dunstabzug_upload_energiepass);
                    }

                    if (remoteProduct.gefrierschrank_upload_energiepass !== undefined && remoteProduct.gefrierschrank_upload_energiepass !== false) {
                      remoteProduct.gefrierschrank_upload_energiepass = await this.convertUploadedImageToFileStructure(remoteProduct.gefrierschrank_upload_energiepass.url, remoteProduct.gefrierschrank_upload_energiepass);
                    }

                    if (remoteProduct.geschirrspueler_upload_energiepass !== undefined && remoteProduct.geschirrspueler_upload_energiepass !== false) {
                      remoteProduct.geschirrspueler_upload_energiepass = await this.convertUploadedImageToFileStructure(remoteProduct.geschirrspueler_upload_energiepass.url, remoteProduct.geschirrspueler_upload_energiepass);
                    }

                    if (remoteProduct.herd_backofen_upload_energiepass !== undefined && remoteProduct.herd_backofen_upload_energiepass !== false) {
                      remoteProduct.herd_backofen_upload_energiepass = await this.convertUploadedImageToFileStructure(remoteProduct.herd_backofen_upload_energiepass.url, remoteProduct.herd_backofen_upload_energiepass);
                    }

                    if (remoteProduct.kochfeld_upload_energiepass !== undefined && remoteProduct.kochfeld_upload_energiepass !== false) {
                      remoteProduct.kochfeld_upload_energiepass = await this.convertUploadedImageToFileStructure(remoteProduct.kochfeld_upload_energiepass.url, remoteProduct.kochfeld_upload_energiepass);
                    }

                    if (remoteProduct['kue-egeraet_upload'] !== undefined && remoteProduct['kue-egeraet_upload'] !== false) {
                      remoteProduct['kue-egeraet_upload'] = await this.convertUploadedImageToFileStructure(remoteProduct['kue-egeraet_upload'].url, remoteProduct['kue-egeraet_upload']);
                    }

                    if (remoteProduct['kuehl-gefrier-kombi_upload_energiepass'] !== undefined && remoteProduct['kuehl-gefrier-kombi_upload_energiepass'] !== false) {
                      remoteProduct['kuehl-gefrier-kombi_upload_energiepass'] = await this.convertUploadedImageToFileStructure(remoteProduct['kuehl-gefrier-kombi_upload_energiepass'].url, remoteProduct['kuehl-gefrier-kombi_upload_energiepass']);
                    }

                    if (remoteProduct.kuehlschrank_upload_energiepass !== undefined && remoteProduct.kuehlschrank_upload_energiepass !== false) {
                      remoteProduct.kuehlschrank_upload_energiepass = await this.convertUploadedImageToFileStructure(remoteProduct.kuehlschrank_upload_energiepass.url, remoteProduct.kuehlschrank_upload_energiepass);
                    }

                    if (remoteProduct.upload_energiepass !== undefined && remoteProduct.upload_energiepass !== false) {
                      remoteProduct.upload_energiepass = await this.convertUploadedImageToFileStructure(remoteProduct.upload_energiepass.url, remoteProduct.upload_energiepass);
                    }

                    if (remoteProduct.images !== undefined && remoteProduct.images !== false) {
                      const upload_energiepass = [];

                      for (const image of remoteProduct.images) {
                        const imageData = await this.imageUrlToBase64(image.url);

                        upload_energiepass.push({
                          contentType: image.type,
                          data: String(imageData.base64).replace(`data:${image.type};base64,`, ""),
                          imagePreviewUrl: imageData.base64,
                          lastChanged: Date.now(),
                          name: image.title,
                          title: "",
                          type: image.type,
                          // size: imageData.fileSize
                        })
                      }

                      remoteProduct.images = upload_energiepass;
                    }

                    API.syncRequest("", { ...remoteProduct }).then((json) => {
                      duplicationQueue.splice(duplicationQueue.indexOf(String(prop.id)), 1);

                      this.setState({ duplicationQueue: duplicationQueue });

                      _this.loadRemoteData();

                      _this.state.props.history.push('/productEditRemote/' + json.post_id)
                    }, (error => {
                      alert('Fehler beim Update-Sync! '+error)
                    })).catch(function (err) {
                      _this.setState({loading: false})
                      console.debug('Fehler beim Update-Sync!', err);
                      //_this.changeProduktValues(key, { app_synced: false, app_sync_error: true }, false)
                    });
                  }).catch(function (err) {
                    console.log('Fehler', err);
                  });
                }}
                color="rose"
                className="duplicate"
            >
            </Button>{" "}
            {this.state.duplicationQueue.find((element) => element === prop.id) && <CircularProgress size={40} className={classes.buttonProgress} />}
          </div>
        {/*</div>*/}
        {/* ------------------------------- bearbeiten ------------------------------- */}
        <div className={classes.wrapper}>
          <Button
            id="selenium-remote-edit"
            justIcon
            isMobile={this.props.isMobile}
            round
            tooltip="bearbeiten"
            image={<Edit />}
            onClick={() => {
              //let obj = this.state.dataLocal.find(o => o.id === prop.id);
              // Load productForm Component
              this.state.props.history.push('/productEditRemote/'+prop.id)
            }}
            color="warning"
            className="edit"
          >
          </Button>{" "}
        </div>
        {/* ------------------------------- löschen -------------------------------*/}
        <Button
          id="selenium-remote-delete"
          justIcon
          isMobile={this.props.isMobile}
          round
          tooltip="löschen"
          image={<Delete/>}
          onClick={ () => {
            this.handleClickOpenDialog('delete', prop, true)
          }}
          color="primary"
          className="remove"
        >
        </Button>{" "}
      </div>
    )
  }


  buildEbayActions = (prop, key) => {
    const { classes } = this.props;
    return (
      // we've added some custom button actions
      <div className="actions-right">
        {/* ------------------------------- Artikel-Seite ansehen ------------------------------- */}
        {this.buildEbayLink(prop) ? (
        <Button
          justIcon
          round
          isMobile={this.props.isMobile}
          tooltip="Seite ansehen"
          image={<OpenInNew/>}
          onClick={() => {
            //let obj = this.state.dataLocal.find(o => o.id === prop.id);
            window.open(this.buildEbayLink(prop))
          }}
          color={'info'}
          className="like"
        >
        </Button>) :
        <Button
          justIcon
          round
          isMobile={this.props.isMobile}
          tooltip={prop.has_ebay_error ? prop.ebay_error_info : "Artikel ist noch nicht zu Kleinanzeigen synchronisiert. Bitte warten Sie noch."}
          image={prop.has_ebay_error ? <Feedback /> : <HourglassEmpty />}
          onClick={() => this.infoMessage(prop.has_ebay_error ? prop.ebay_error_info : 'Artikel ist noch nicht zu Kleinanzeigen synchronisiert. Bitte warten Sie noch.')}
          color={prop.has_ebay_error ? 'danger' : 'warning'}
          className="like"
        >
        </Button>}
        {/* ------------------------------- verkauft/nicht verkauft ------------------------------- */}
        <Button
          justIcon
          round
          tooltip={ prop.is_verkauft ? 'Auf NICHT verkauft stellen' : 'Auf verkauft stellen'}
          image={!prop.is_verkauft ? <ShoppingCart /> : <ShoppingCartOff />}
          isMobile={this.props.isMobile}
          onClick={() => {
            if(!prop.is_verkauft){
              // verkauf einstellen
              this.handleClickOpenDialog('sell', prop)
            }else{
              // ausverkauf rückgängig machen
              this.changeProductRemoteValue(prop.id, 'is_verkauft', !prop.is_verkauft)
            }
          }}
          color={ prop.is_verkauft ? 'danger' : 'success'}
          className="like"
        >
        </Button>
        {/* ------------------------------- online/offline ------------------------------- *
        <Button
          justIcon
          isMobile={this.props.isMobile}
          round
          onClick={() => {
            let obj = this.state.dataLocal.find(o => o.id === key);
            this.changeProduktValue(key, 'post_status', !prop.post_status)
            prop.synced = false
            prop.app_synced = false
          }}
          color={ prop.post_status ? 'success' : 'danger'}
          className="like"
        >
          <Tooltip title="online/offline stellen" placement="top">
          { !prop.is_verkauft ? <ShoppingCart /> : <ShoppingCartOff />}
          </Tooltip>
        </Button>
        {/* ------------------------------- ebay ja/nein ------------------------------- */}
        <Button
          justIcon
          isMobile={this.props.isMobile}
          round
          tooltip="auf Kleinanzeigen setzen ja/nein"
          image={<img src={kleinanzeigenIcon} className={classes.kleinanzeigenIcon} alt="Kleinanzeigen"/>}
          onClick={() => {
            if(this.checkPartnerData('is_ebay_kleinanzeigen')){
              let current_count = this.state && this.state.dataEbay ? this.state.dataEbay.length : 0
              if(!prop.should_ebay && this.getPartnerData('ebay_max_slots') <= current_count){
                this.message(Txt.ebay_no_slots)
              }else{
                this.changeProductRemoteValue(prop.id, 'should_ebay', !prop.should_ebay)
              }

            }else{
              this.message(Txt.kleinanzeigen)
            }
          }}
          color={ prop.should_ebay ? 'success' : 'danger'}
          className="like"
        >
        </Button>{" "}
        {/* ------------------------------- bearbeiten ------------------------------- */}
        <Button
          justIcon
          isMobile={this.props.isMobile}
          round
          tooltip="bearbeiten"
          image={<Edit />}
          onClick={() => {
            //let obj = this.state.dataLocal.find(o => o.id === prop.id);
            // Load productForm Component
            this.state.props.history.push('/productEditRemote/'+prop.id)
          }}
          color="warning"
          className="edit"
        >
        </Button>{" "}
        {/* ------------------------------- löschen -------------------------------*/}
        <Button
          justIcon
          isMobile={this.props.isMobile}
          round
          tooltip="löschen"
          image={<Delete />}
          onClick={ () => {
            this.handleClickOpenDialog('delete', prop, true)
          }}
          color="primary"
          className="remove"
        >
        </Button>{" "}
      </div>
    )
  }

  buildMarktdeActions = (prop, key) => {
      const { classes } = this.props;
      return (
        // we've added some custom button actions
        <div className="actions-right">
          {/* ------------------------------- Artikel-Seite ansehen ------------------------------- */}
          {this.buildMarktdeLink(prop) ? (
          <Button
            justIcon
            round
            isMobile={this.props.isMobile}
            onClick={() => {
              //let obj = this.state.dataLocal.find(o => o.id === prop.id);
              window.open(this.buildMarktdeLink(prop))
            }}
            color={'info'}
            className="like"
          >
            <Tooltip title="Seite ansehen" placement="top">
              <OpenInNew />
            </Tooltip>
          </Button>) :
          <Button
            justIcon
            round
            isMobile={this.props.isMobile}
            tooltip="Artikel ist noch nicht zu Markt.de synchronisiert. Bitte warten Sie noch."
            image={<HourglassEmpty />}
            onClick={() => this.infoMessage('Artikel ist noch nicht zu Markt.de synchronisiert. Bitte warten Sie noch.')}
            color={'warning'}
            className="like"
          >
          </Button>}
          {/* ------------------------------- verkauft/nicht verkauft ------------------------------- */}
          <Button
            justIcon
            round
            tooltip={ prop.is_verkauft ? 'Auf NICHT verkauft stellen' : 'Auf verkauft stellen'}
            image={ !prop.is_verkauft ? <ShoppingCart /> : <ShoppingCartOff />}
            isMobile={this.props.isMobile}
            onClick={() => {
              if(!prop.is_verkauft){
                // verkauf einstellen
                this.handleClickOpenDialog('sell', prop)
              }else{
                // ausverkauf rückgängig machen
                this.changeProductRemoteValue(prop.id, 'is_verkauft', !prop.is_verkauft)
              }
            }}
            color={ prop.is_verkauft ? 'danger' : 'success'}
            className="like"
          >
          </Button>
          {/* ------------------------------- online/offline ------------------------------- *
          <Button
            justIcon
            isMobile={this.props.isMobile}
            round
            onClick={() => {
              let obj = this.state.dataLocal.find(o => o.id === key);
              this.changeProduktValue(key, 'post_status', !prop.post_status)
              prop.synced = false
              prop.app_synced = false
            }}
            color={ prop.post_status ? 'success' : 'danger'}
            className="like"
          >
            <Tooltip title="online/offline stellen" placement="top">
            { !prop.is_verkauft ? <ShoppingCart /> : <ShoppingCartOff />}
            </Tooltip>
          </Button>
          {/* ------------------------------- markt.de ja/nein ------------------------------- */}
          <Button
            justIcon
            isMobile={this.props.isMobile}
            round
            tooltip="auf Markt.de setzen ja/nein"
            image={<img src={marktdeIcon} className={classes.marktdeIcon} alt="markt.de"/>}
            onClick={() => {
              if(this.checkPartnerData('is_marktde')){
                this.changeProductRemoteValue(prop.id, 'should_marktde', !prop.should_marktde)
              }else{
                this.message(Txt.kleinanzeigen)
              }
            }}
            color={ prop.should_marktde ? 'success' : 'danger'}
            className="like"
          >
          </Button>{" "}
          {/* ------------------------------- bearbeiten ------------------------------- */}
          <Button
            justIcon
            isMobile={this.props.isMobile}
            round
            image={<Edit />}
            tooltip={"bearbeiten"}
            onClick={() => {
              //let obj = this.state.dataLocal.find(o => o.id === prop.id);
              // Load productForm Component
              this.state.props.history.push('/productEditRemote/'+prop.id)
            }}
            color="warning"
            className="edit"
          >
          </Button>{" "}
          {/* ------------------------------- löschen -------------------------------*/}
          <Button
            justIcon
            isMobile={this.props.isMobile}
            round
            tooltip="löschen"
            image={<Delete />}
            onClick={ () => {
              this.handleClickOpenDialog('delete', prop, true)
            }}
            color="primary"
            className="remove"
          >
          </Button>{" "}
        </div>
      )
    }

  buildTraktorpoolActions = (prop, key) => {
    const { classes } = this.props;
    return (
      // we've added some custom button actions
      <div className="actions-right">
        {/* ------------------------------- Artikel-Seite ansehen ------------------------------- */}
        {this.buildTraktorpoolLink(prop) ? (
            <Button
              justIcon
              round
              isMobile={this.props.isMobile}
              onClick={() => {
                window.open(this.buildTraktorpoolLink(prop))
              }}
              color={'info'}
              className="like"
            >
              <Tooltip title="Seite ansehen" placement="top">
                <OpenInNew />
              </Tooltip>
            </Button>) :
          (<Button
            justIcon
            round
            isMobile={this.props.isMobile}
            tooltip={prop.has_traktorpool_error ? prop.traktorpool_error_info : "Artikel ist noch nicht zu Traktorpool synchronisiert. Bitte warten Sie noch."}
            image={prop.has_traktorpool_error ? <Feedback /> : <HourglassEmpty />}
            onClick={() => this.infoMessage(prop.has_traktorpool_error ? prop.traktorpool_error_info : 'Artikel ist noch nicht zu Kleinanzeigen synchronisiert. Bitte warten Sie noch.')}
            color={prop.has_traktorpool_error ? 'danger' : 'warning'}
            className="like">
          </Button>)}
        {/* ------------------------------- verkauft/nicht verkauft ------------------------------- */}
        <Button
          justIcon
          round
          tooltip={ prop.is_verkauft ? 'Auf NICHT verkauft stellen' : 'Auf verkauft stellen'}
          image={ !prop.is_verkauft ? <ShoppingCart /> : <ShoppingCartOff />}
          isMobile={this.props.isMobile}
          onClick={() => {
            if(!prop.is_verkauft){
              // verkauf einstellen
              this.handleClickOpenDialog('sell', prop)
            }else{
              // ausverkauf rückgängig machen
              this.changeProductRemoteValue(prop.id, 'is_verkauft', !prop.is_verkauft)
            }
          }}
          color={ prop.is_verkauft ? 'danger' : 'success'}
          className="like"
        >
        </Button>
        {/* ------------------------------- Traktorpool ja/nein ------------------------------- */}
        <Button
          justIcon
          isMobile={this.props.isMobile}
          round
          tooltip="auf Traktorpool setzen ja/nein"
          image={<img src={traktorpoolIcon} className={classes.traktorpoolIcon} alt="Traktorpool" />}
          onClick={() => {
            if(this.checkPartnerData('is_traktorpool')){
              this.changeProductRemoteValue(prop.id, 'should_traktorpool', !prop.should_traktorpool)
            }else{
              this.message(Txt.kleinanzeigen)
            }
          }}
          color={ prop.should_traktorpool ? 'success' : 'danger'}
          className="like"
        >
        </Button>{" "}
        {/* ------------------------------- bearbeiten ------------------------------- */}
        <Button
          justIcon
          isMobile={this.props.isMobile}
          round
          image={<><Edit /></>}
          tooltip={"bearbeiten"}
          onClick={() => {
            // Load productForm Component
            this.state.props.history.push('/productEditRemote/'+prop.id)
          }}
          color="warning"
          className="edit"
        >
        </Button>{" "}
        {/* ------------------------------- löschen -------------------------------*/}
        <Button
          justIcon
          isMobile={this.props.isMobile}
          round
          tooltip="löschen"
          image={<Delete />}
          onClick={ () => {
            this.handleClickOpenDialog('delete', prop, true)
          }}
          color="primary"
          className="remove"
        >
        </Button>{" "}
      </div>
    )
  }

  textFilter(filter, row){
    let result = -1
    if(typeof row[filter.id] !== 'undefined'){
      result = parseInt( String(row[filter.id]).toUpperCase().indexOf(filter.value.toUpperCase()), 10);
    }
    if(result < 0){
      return false;
    }else{
      return true;
    }
  }

  priceFilter(a, b){
    return parseFloat(a.replace(' €', '')) - parseFloat(b.replace(' €', ''))
  }

  handleDraftSwitch = (event, checked, dataRemote) => {
      if (checked) {
        if(typeof dataRemote === 'undefined'){
          return null;
        }

        const products = this.state.publishedToggle ? this.state.tempRemote : dataRemote;

        let filteredRemoteData = products.filter( obj => {
            return checked ? obj.post_status !== 'publish' : null ;
        });

        this.setState({
            tempRemote: products,
            dataRemote: filteredRemoteData,
            draftToggle: !this.state.draftToggle,
            publishedToggle: false
        })
      } else {
          this.setState({
              dataRemote: this.state.tempRemote,
              draftToggle: !this.state.draftToggle,
              publishedToggle: false
          })
      }
  }

  handlePublishedSwitch = (event, checked, dataRemote) => {
    if (checked) {
      if(typeof dataRemote === 'undefined'){
        return null;
      }

      const products = this.state.draftToggle ? this.state.tempRemote : dataRemote;

      let filteredRemoteData = products.filter( obj => {
        return checked ? obj.post_status === 'publish' : null;
      });

      this.setState({
        tempRemote: products,
        dataRemote: filteredRemoteData,
        draftToggle: false,
        publishedToggle: !this.state.publishedToggle
      })
    } else {
      this.setState({
        dataRemote: this.state.tempRemote,
        draftToggle: false,
        publishedToggle: !this.state.publishedToggle
      })
    }
  }

  handleFBError = (error) => {
    this.props.enqueueSnackbar(error.message, { variant: 'error'})
  }

  handleFBSuccess = (message) => {
    this.props.enqueueSnackbar(message, { variant: 'success'})
  }

  handleBulkEditDeleteDialogOpen = () => {
    this.setState({ bulkEditDeleteDialog: true })
  }

  handleBulkEditDeleteDialogClose = () => {
    this.setState({ bulkEditDeleteDialog: false })
  }

  handleBulkEditSellDialogOpen = () => {
    this.setState({ bulkEditSellDialog: true })
  }

  handleBulkEditSellDialogClose = () => {
    this.setState({ bulkEditSellDialog: false })
  }

  handleTabChange = () => {
    this.resetCheckedProductsList();
    this.resetBulkActionSelect();
  }

  render() {
    // eslint-disable-next-line
    const { dataLocal, dataRemote, dataEbay, dataMarktde, dataTraktorpool, loading } = this.state;
    const { classes } = this.props;
    let activeTab = (this.props.match.params.tab || this.state.activeTab);
    let activeTabIndex = 0;
    const bulkEditType = this.getBulkEditType();
    const checkedProducts = this.getCheckedProducts().length;
    const disableBulkButton = this.state.selectBulkAction === '' || checkedProducts <= 0;
    const remote = activeTab !== 'local';
    const productsGettingSold = dataRemote === undefined ? [] : dataRemote?.filter(product => product.is_verkauft === false && this.getCheckedProducts().includes(String(product.id)));

    switch (activeTab) {
      case 'local':
        activeTabIndex = 0
        break;
      case 'cloud':
        activeTabIndex = 1
        break;
      case 'ebaykl':
        activeTabIndex = 2
        break;
      case 'marktde':
        activeTabIndex = 3
        break;
      case 'traktorpool':
        activeTabIndex = 4
        break;
      default:
        activeTabIndex = 0
        break;
    }

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <BulkEditDeleteDialog
            remote={remote}
            productsGettingSold={productsGettingSold}
            amount={this.getCheckedProducts().length}
            open={this.state.bulkEditDeleteDialog}
            onClose={this.handleBulkEditDeleteDialogClose}
            onConfirm={(reason) => {
              console.log("deleteType", reason)
              this.handleBulkDialogOpen();
              // setTimeout(() => {
                this.bulkEditDelete(remote, reason);
              // }, 1000);
            }}
          />

          <BulkEditSellDialog
            remote={remote}
            productsGettingSold={productsGettingSold}
            checkedProducts={this.getCheckedProducts()}
            amount={this.getCheckedProducts().length}
            productsWithAmount={this.state.bulkEditSellProductsWithAmount}
            open={this.state.bulkEditSellDialog}
            onClose={this.handleBulkEditSellDialogClose}
            onConfirm={(reason) => {
              this.handleBulkDialogOpen();
              this.bulkEditSetSold(remote, reason);
            }}
          />
          <GridContainer data={dataLocal} className={classes.isMobile}>
            <GridItem xs={12}>
              <CustomTabs
                onTabChange={this.handleTabChange}
                history={this.state.props.history}
                title=""
                headerColor="primary"
                activeTabIndex={activeTabIndex}
                activeTab={activeTab}
                tabs={[
                  {
                    tabName: "Lokal",
                    tabLink: "/products/local",
                    tabIcon: LokalIcon,
                    tabContent: (
                      <div className={this.props.isMobile ? "lokal-table mobile" : "lokal-table"}>
                        <ReactTable
                          data={dataLocal}
                          filterable
                          columns={[
                            {
                              accessor: "checkboxes",
                              Header: (props) => {
                                return (
                                  <div>
                                    <Checkbox
                                      tabIndex={-1}
                                      onClick={() => this.handleCheckboxToggleAll(props.data)}
                                      checked={this.state.selectAll}
                                      checkedIcon={<Check className={classes.checkedIcon} />}
                                      icon={<Check className={classes.uncheckedIcon} />}
                                      classes={{
                                        checked: classes.checked
                                      }}
                                    />
                                  </div>
                                );
                              },
                              Cell: (row) => {
                                return (
                                  <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleCheckboxToggle(row.original.id)}
                                    checked={this.state.checked[row.original.id] || false}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                      checked: classes.checked
                                    }}
                                  />
                                )
                              },
                              sortable: false,
                              filterable: false
                            },
                            {
                              Header: "Vorschau",
                              Cell: (row) => {
                                var imageObj = typeof(row.original.images) !== 'undefined' ? Object.values(row.original.images) : undefined; //Object.values(row.original.images)[0]?.imagePreviewUrl;
                                var image = imageObj?.[0]?.imagePreviewUrl;

                                return <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <img loading="lazy" height="50px" src={image === false || image === undefined ? PlaceholderImage : image} alt="vorschau" />
                                </div>
                              },
                            },
                            {
                              Header: "Name",
                              accessor: "name",
                              filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                              Filter: ({filter, onChange}) => (
                                <input type='text'
                                       placeholder="Textsuche ..."
                                       style={{width:'100%'}}
                                       value={filter ? filter.value : ''}
                                       onChange={event => onChange(event.target.value)}
                                />
                              )
                            },
                            {
                              Header: "Artikel-Nr.",
                              accessor: "artikelnr",
                              filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                            },
                            /*{
                              Header: "Artikelart",
                              accessor: "artikelart",
                              width: 140,
                            },*/
                            {
                              Header: "Preis",
                              accessor: "preis",
                              filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                              sortMethod: (a, b) => {return this.priceFilter(a, b)},
                            },
                            {
                              Header: "Kategorie",
                              accessor: "category",
                              filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                              Cell: (row) => {
                                return <span title={row.original.category}>{row.original.category}</span>
                              },
                            },
                            {
                              Header: "Aktionen",
                              accessor: "actions",
                              sortable: false,
                              filterable: false
                            }
                          ]}
                          defaultPageSize={10}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                          previousText={<NavigateBefore fontSize={'inherit'}/>}
                          nextText={<NavigateNext fontSize={'inherit'}/>}
                          loadingText='Wird geladen...'
                          noDataText='Keine Artikel gefunden'
                          pageText='Seite'
                          ofText='von'
                          rowsText='Artikel'
                        />
												<div>
                          <BulkEditActionSelect selectBulkAction={this.state.selectBulkAction} onChange={(e) => this.handleSelectBulkAction(e)} />
                          <CustomButton icon="save" size="sm" color="primary" onClick={() => this.handleBulkActionConfirmation(false)} disabled={this.state.selectBulkAction !== 'CREATE' && disableBulkButton}>Übernehmen</CustomButton>
													<div>Sie haben &nbsp;<strong>{dataLocal ? dataLocal.length : 0} Artikel</strong> auf dem lokalen Gerät gespeichert</div>
                          <div>Sie haben &nbsp;<strong>{checkedProducts} Artikel</strong> ausgewählt</div>
                        </div>
                      </div>
                    )
                  },
                  {
                    tabName: "Cloud",
                    tabLink: "/products/cloud",
                    tabIcon: Online ? Cloud : CloudOff,
                    tabContent: (
                      <div className={(this.props.isMobile ? "cloud-table mobile" : "cloud-table") + (this.checkPartnerData('is_marktde') ? " withMarktDe" : "")}>
                        <Online>
                          <ReactTable
                              data={dataRemote}
                              filterable
                              columns={[
                                {
                                  accessor: "checkboxes",
                                  Header: (props) => {
                                    return (
                                        <div>
                                          <Checkbox
                                              tabIndex={-1}
                                              onClick={() => this.handleCheckboxToggleAll(props.data)}
                                              checked={this.state.selectAll}
                                              checkedIcon={<Check className={classes.checkedIcon}/>}
                                              icon={<Check className={classes.uncheckedIcon}/>}
                                              classes={{
                                                checked: classes.checked
                                              }}
                                          />
                                        </div>
                                    );
                                  },
                                  Cell: (row) => {
                                    return (
                                        <Checkbox
                                            tabIndex={-1}
                                            onClick={() => this.handleCheckboxToggle(row.original.id)}
                                            checked={this.state.checked[row.original.id] || false}
                                            checkedIcon={<Check className={classes.checkedIcon}/>}
                                            icon={<Check className={classes.uncheckedIcon}/>}
                                            classes={{
                                              checked: classes.checked
                                            }}
                                        />
                                    )
                                  },
                                  sortable: false,
                                  filterable: false
                                },
                                {
                                  Header: "Vorschau",
                                  className: "vorschau",
                                  Cell: (row) => {
                                    var image = row.original.images[0]?.thumbnail_url;
                                    return <div style={{display: 'flex', justifyContent: 'center'}}>
                                      <img loading="lazy" height="50px"
                                           src={image === false || image === undefined ? PlaceholderImage : image}
                                           alt="vorschau"/>
                                    </div>
                                  },
                                },
                                {
                                  Header: "Name",
                                  accessor: "name",
                                  className: "name",
                                  filterMethod: (filter, row) => {
                                    return this.textFilter(filter, row)
                                  },
                                  Filter: ({filter, onChange}) => (
                                      <input type='text'
                                             placeholder="Textsuche ..."
                                             style={{width: '100%'}}
                                             value={filter ? filter.value : ''}
                                             onChange={event => onChange(event.target.value)}
                                      />
                                  )
                                },
                                {
                                  Header: "Artikel-Nr.",
                                  accessor: "artikelnr",
                                  className: "artikelnr",
                                  filterMethod: (filter, row) => {
                                    return this.textFilter(filter, row)
                                  },
                                },
                                /*{
                                  Header: "Artikelart",
                                  accessor: "artikelart",
                                  width: 140,
                                },*/
                                {
                                  Header: "Preis",
                                  accessor: "preis",
                                  className: "preis",
                                  filterMethod: (filter, row) => {
                                    return this.textFilter(filter, row)
                                  },
                                  sortMethod: (a, b) => {
                                    return this.priceFilter(a, b)
                                  },
                                },
                                {
                                  Header: "Kategorie",
                                  accessor: "category",
                                  className: "category",
                                  filterMethod: (filter, row) => {
                                    return this.textFilter(filter, row)
                                  },
                                  Cell: (row) => {
                                    return <span title={row.original.category}>{row.original.category}</span>
                                  },
                                },
                                {
                                  Header: "Aktionen",
                                  accessor: "actions",
                                  className: "actions",
                                  filterMethod: (filter, row) => {
                                    return this.textFilter(filter, row)
                                  },
                                  sortable: false,
                                  filterable: false,
                                  Cell: (row) => {
                                    return this.buildRemoteActions(row, "")
                                  },
                                }
                              ]}
                              defaultPageSize={10}
                              showPaginationTop
                              showPaginationBottom={false}
                              className="-striped -highlight"
                              previousText={<NavigateBefore fontSize={'inherit'}/>}
                              nextText={<NavigateNext fontSize={'inherit'}/>}
                              loading={loading}
                              loadingText={
                                <LoadingText
                                    loading={true}
                                >
                                  Artikel werden geladen...
                                </LoadingText>
                              }
                              noDataText='Keine Artikel gefunden'
                              pageText='Seite'
                              ofText='von'
                              rowsText='Artikel'
                          />
                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>
                              <BulkEditActionSelect disableCloud selectBulkAction={this.state.selectBulkAction}
                                                    onChange={(e) => this.handleSelectBulkAction(e)}/>
                              <CustomButton icon="save" size="sm" color="primary"
                                            onClick={() => this.handleBulkActionConfirmation()}
                                            disabled={disableBulkButton}>Übernehmen</CustomButton>
                              {this.state.draftToggle && (
                                  <div>Diese Artikel sind noch im <strong>Entwurfsmodus</strong></div>
                              )}
                              {this.state.publishedToggle && (
                                  <div>Diese Artikel sind <strong>veröffentlicht</strong></div>
                              )}
                              {(!this.state.publishedToggle && !this.state.draftToggle) && (
                                  <div>Sie haben &nbsp;
                                    <strong>{dataRemote ? dataRemote.length : 0} Artikel</strong> in der Cloud
                                    gespeichert</div>
                              )}
                              <div>Sie haben &nbsp;<strong>{checkedProducts} Artikel</strong> ausgewählt</div>
                            </div>
                            <div>
                            <div style={{ marginBottom: '-15px' }}>
                                nur Entwürfe & Geplante zeigen
                                <Switch checked={this.state.draftToggle}
                                        onChange={(event, checked) => this.handleDraftSwitch(event, checked, dataRemote)} />
                              </div>
                              <div>
                                nur veröffentliche Artikel zeigen
                                <Switch checked={this.state.publishedToggle}
                                        onChange={(event, checked) => this.handlePublishedSwitch(event, checked, dataRemote)} />
                              </div>
                            </div>
                          </div>
                        </Online>
                        <Offline>
                          <div style={{display: 'flex', alignItems: 'center', padding: 5}}>
                            <CloudOff/><span style={{padding: 15, fontSize: '1.1em'}}>Bitte stellen Sie eine Internetverbindung her!</span>
                          </div>
                        </Offline>
                      </div>
                    )
                  },
                  {
                    tabName: "Kleinanzeigen",
                    tabLink: "/products/ebaykl",
                    tabIcon: KleinanzeigenIcon,
                    tabContent: this.checkPartnerData('is_ebay_kleinanzeigen') ? (
                      <div className={this.props.isMobile ? "ebay-table mobile" : "ebay-table"}>
                        <Online>
                        <ReactTable
                          data={dataEbay}
                          filterable
                          columns={[
                            {
                              accessor: "checkboxes",
                              Header: (props) => {
                                return (
                                  <div>
                                    <Checkbox
                                      tabIndex={-1}
                                      onClick={() => this.handleCheckboxToggleAll(props.data)}
                                      checked={this.state.selectAll}
                                      checkedIcon={<Check className={classes.checkedIcon} />}
                                      icon={<Check className={classes.uncheckedIcon} />}
                                      classes={{
                                        checked: classes.checked
                                      }}
                                    />
                                  </div>
                                );
                              },
                              Cell: (row) => {
                                return (
                                  <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleCheckboxToggle(row.original.id)}
                                    checked={this.state.checked[row.original.id] || false}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                      checked: classes.checked
                                    }}
                                  />
                                )
                              },
                              sortable: false,
                              filterable: false
                            },
                            {
                              Header: "Vorschau",
                              className: "vorschau",
                              Cell: (row) => {
                                var image = row.original.images[0]?.thumbnail_url;
                                return <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <img loading="lazy" height="50px" src={image === false || image === undefined ? PlaceholderImage : image} alt="vorschau" />
                                </div>
                              },
                            },
                            {
                              Header: "Name",
                              accessor: "name",
                              filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                              Filter: ({filter, onChange}) => (
                                <input type='text'
                                       placeholder="Textsuche ..."
                                       style={{width:'100%'}}
                                       value={filter ? filter.value : ''}
                                       onChange={event => onChange(event.target.value)}
                                />
                              )
                            },
                            {
                              Header: "Artikel-Nr.",
                              accessor: "artikelnr",
                              filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                            },
                            /*{
                              Header: "Artikelart",
                              accessor: "artikelart",
                              width: 140,
                            },*/
                            {
                              Header: "ebay-Ad-Id",
                              accessor: "ebay_ad_id",
                              filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                            },
                            {
                              Header: "Kategorie",
                              accessor: "category",
                              filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                              Cell: (row) => {
                                return <span title={row.original.category}>{row.original.category}</span>
                              },
                            },
                            {
                              Header: "Aktionen",
                              accessor: "actions",
                              filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                              sortable: false,
                              filterable: false
                            }
                          ]}
                          defaultPageSize={10}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                          previousText={<NavigateBefore fontSize={'inherit'}/>}
                          nextText={<NavigateNext fontSize={'inherit'}/>}
                          loading={loading}
                          loadingText={
                            <LoadingText
                                loading={true}
                            >
                              Artikel werden geladen...
                            </LoadingText>
                          }
                          noDataText='Keine Artikel gefunden'
                          pageText='Seite'
                          ofText='von'
                          rowsText='Artikel'
                        />
													<div>
                            <BulkEditActionSelect disableCloud disableMarktde selectBulkAction={this.state.selectBulkAction} onChange={(e) => this.handleSelectBulkAction(e)} />
                            <CustomButton icon="save" size="sm" color="primary" onClick={() => this.handleBulkActionConfirmation()} disabled={disableBulkButton}>Übernehmen</CustomButton>
														<div>Sie haben &nbsp;<strong>{dataEbay ? dataEbay.length : 0} Artikel</strong> auf der eBay-Liste</div>
                            <div>Sie haben &nbsp;<strong>{checkedProducts} Artikel</strong> ausgewählt</div>
                          </div>
                        </Online>
                        <Offline>
                          <div style={{display: 'flex', alignItems: 'center', padding: 5}}>
                            <CloudOff/><span style={{padding: 15, fontSize:'1.1em'}}>Bitte stellen Sie eine Internetverbindung her!</span>
                          </div>
                        </Offline>
                      </div>
                    ) : (<div style={{display: 'flex', alignItems: 'center', padding: 5}}>
                          <span style={{padding: 15, fontSize:'1.1em'}}>{this.message('kleinanzeigen_manager', false)}</span>
                        </div>)
                  },
                  {
                    tabName: "Markt.de",
                    tabLink: "/products/marktde",
                    tabIcon: MarktdeIcon,
                    tabContent: this.checkPartnerData('is_marktde') ? (
                      <div className={this.props.isMobile ? "ebay-table mobile" : "ebay-table"}>
                        <Online>
                        <ReactTable
                          data={dataMarktde}
                          filterable
                          columns={[
                            {
                              accessor: "checkboxes",
                              Header: (props) => {
                                return (
                                  <div>
                                    <Checkbox
                                      tabIndex={-1}
                                      onClick={() => this.handleCheckboxToggleAll(props.data)}
                                      checked={this.state.selectAll}
                                      checkedIcon={<Check className={classes.checkedIcon} />}
                                      icon={<Check className={classes.uncheckedIcon} />}
                                      classes={{
                                        checked: classes.checked
                                      }}
                                    />
                                  </div>
                                );
                              },
                              Cell: (row) => {
                                return (
                                  <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleCheckboxToggle(row.original.id)}
                                    checked={this.state.checked[row.original.id] || false}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                      checked: classes.checked
                                    }}
                                  />
                                )
                              },
                              sortable: false,
                              filterable: false
                            },
                            {
                              Header: "Vorschau",
                              className: "vorschau",
                              Cell: (row) => {
                                var image = row.original.images[0]?.thumbnail_url;
                                return <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <img loading="lazy" height="50px" src={image === false || image === undefined ? PlaceholderImage : image} alt="vorschau" />
                                </div>
                              },
                            },
                            {
                              Header: "Name",
                              accessor: "name",
                              filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                              Filter: ({filter, onChange}) => (
                                <input type='text'
                                       placeholder="Textsuche ..."
                                       style={{width:'100%'}}
                                       value={filter ? filter.value : ''}
                                       onChange={event => onChange(event.target.value)}
                                />
                              )
                            },
                            {
                              Header: "Artikel-Nr.",
                              accessor: "artikelnr",
                              filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                            },
                            /*{
                              Header: "Artikelart",
                              accessor: "artikelart",
                              width: 140,
                            },*/
                            {
                              Header: "Markt.de-Ad-Id",
                              accessor: "marktde_ad_id",
                              filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                            },
                            {
                              Header: "Kategorie",
                              accessor: "category",
                              filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                              Cell: (row) => {
                                return <span title={row.original.category}>{row.original.category}</span>
                              },
                            },
                            {
                              Header: "Aktionen",
                              accessor: "actions",
                              filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                              sortable: false,
                              filterable: false
                            }
                          ]}
                          defaultPageSize={10}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                          previousText={<NavigateBefore fontSize={'inherit'}/>}
                          nextText={<NavigateNext fontSize={'inherit'}/>}
                          loading={loading}
                          loadingText={
                            <LoadingText
                                loading={true}
                            >
                              Artikel werden geladen...
                            </LoadingText>
                          }
                          noDataText='Keine Artikel gefunden'
                          pageText='Seite'
                          ofText='von'
                          rowsText='Artikel'
                        />
                          <div>
                            <BulkEditActionSelect disableCloud disableEbay selectBulkAction={this.state.selectBulkAction} onChange={(e) => this.handleSelectBulkAction(e)} />
                            <CustomButton icon="save" size="sm" color="primary" onClick={() => this.handleBulkActionConfirmation()} disabled={disableBulkButton}>Übernehmen</CustomButton>
                            <div>Sie haben &nbsp;<strong>{dataMarktde ? dataMarktde.length : 0} Artikel</strong> auf der Markt.de-Liste</div>
                            <div>Sie haben &nbsp;<strong>{checkedProducts} Artikel</strong> ausgewählt</div>
                          </div>
                        </Online>
                        <Offline>
                          <div style={{display: 'flex', alignItems: 'center', padding: 5}}>
                            <CloudOff/><span style={{padding: 15, fontSize:'1.1em'}}>Bitte stellen Sie eine Internetverbindung her!</span>
                          </div>
                        </Offline>
                      </div>
                    ) : (<div style={{display: 'flex', alignItems: 'center', padding: 5}}>
                          <span style={{padding: 15, fontSize:'1.1em'}}>{this.message('kleinanzeigen_manager', false)}</span>
                        </div>)
                  },
                  {
                    tabName: "Traktorpool",
                    tabLink: "/products/traktorpool",
                    tabIcon: TraktorpoolIcon,
                    active: this.checkPartnerData('is_traktorpool'),
                    tabContent: this.checkPartnerData('is_traktorpool') ? (
                      <div className={this.props.isMobile ? "ebay-table mobile" : "ebay-table"}>
                        <Online>
                          <ReactTable
                            data={dataTraktorpool}
                            filterable
                            columns={[
                              {
                                accessor: "checkboxes",
                                Header: (props) => {
                                  return (
                                    <div>
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={() => this.handleCheckboxToggleAll(props.data)}
                                        checked={this.state.selectAll}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{
                                          checked: classes.checked
                                        }}
                                      />
                                    </div>
                                  );
                                },
                                Cell: (row) => {
                                  return (
                                    <Checkbox
                                      tabIndex={-1}
                                      onClick={() => this.handleCheckboxToggle(row.original.id)}
                                      checked={this.state.checked[row.original.id] || false}
                                      checkedIcon={<Check className={classes.checkedIcon} />}
                                      icon={<Check className={classes.uncheckedIcon} />}
                                      classes={{
                                        checked: classes.checked
                                      }}
                                    />
                                  )
                                },
                                sortable: false,
                                filterable: false
                              },
                              {
                                Header: "Vorschau",
                                className: "vorschau",
                                Cell: (row) => {
                                  var image = row.original.images[0]?.thumbnail_url;
                                  return <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img loading="lazy" height="50px" src={image === false || image === undefined ? PlaceholderImage : image} alt="vorschau" />
                                  </div>
                                },
                              },
                              {
                                Header: "Name",
                                accessor: "name",
                                filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                                Filter: ({filter, onChange}) => (
                                  <input type='text'
                                         placeholder="Textsuche ..."
                                         style={{width:'100%'}}
                                         value={filter ? filter.value : ''}
                                         onChange={event => onChange(event.target.value)}
                                  />
                                )
                              },
                              {
                                Header: "Artikel-Nr.",
                                accessor: "artikelnr",
                                filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                              },
                              {
                                Header: "Traktorpool-Ad-Id",
                                accessor: "traktorpool_ad_id",
                                filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                              },
                              {
                                Header: "Kategorie",
                                accessor: "category",
                                filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                                Cell: (row) => {
                                  return <span title={row.original.category}>{row.original.category}</span>
                                },
                              },
                              {
                                Header: "Aktionen",
                                accessor: "actions",
                                filterMethod: (filter,row) => {return this.textFilter(filter,row)},
                                sortable: false,
                                filterable: false
                              }
                            ]}
                            defaultPageSize={10}
                            showPaginationTop
                            showPaginationBottom={false}
                            className="-striped -highlight"
                            previousText={<NavigateBefore fontSize={'inherit'}/>}
                            nextText={<NavigateNext fontSize={'inherit'}/>}
                            loading={loading}
                            loadingText={
                              <LoadingText
                                  loading={true}
                              >
                                Artikel werden geladen...
                              </LoadingText>
                            }
                            noDataText='Keine Artikel gefunden'
                            pageText='Seite'
                            ofText='von'
                            rowsText='Artikel'
                          />
                          <div>
                            <BulkEditActionSelect disableCloud disableEbay disableMarktde selectBulkAction={this.state.selectBulkAction} onChange={(e) => this.handleSelectBulkAction(e)} />
                            <CustomButton icon="save" size="sm" color="primary" onClick={() => this.handleBulkActionConfirmation()} disabled={disableBulkButton}>Übernehmen</CustomButton>
                            <div>Sie haben &nbsp;<strong>{dataTraktorpool ? dataTraktorpool.length : 0} Artikel</strong> auf der Traktorpool-Liste</div>
                            <div>Sie haben &nbsp;<strong>{checkedProducts} Artikel</strong> ausgewählt</div>
                          </div>
                        </Online>
                        <Offline>
                          <div style={{display: 'flex', alignItems: 'center', padding: 5}}>
                            <CloudOff/><span style={{padding: 15, fontSize:'1.1em'}}>Bitte stellen Sie eine Internetverbindung her!</span>
                          </div>
                        </Offline>
                      </div>
                    ) : (<div style={{display: 'flex', alignItems: 'center', padding: 5}}>
                      <span style={{padding: 15, fontSize:'1.1em'}}>{this.message('kleinanzeigen_manager', false)}</span>
                    </div>)
                  }
                ]}
              />
              <Dialog
                open={this.state.dialogOpen}
                onClose={this.handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{this.state.dialogProductName || ''}</DialogTitle>
                {(this.state.productIsRemote) ? (
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Warum möchtest du den Artikel löschen?
                    </DialogContentText>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="delete-reason">Pflichtfeld</InputLabel>
                      <Select
                        id="delete-reason"
                        autoFocus
                        value={this.state.toDeleteReason}
                        onChange={(e) => this.handleDeleteReason(e)}
                        required={true}
                      >
                        <MenuItem value="-">- bitte wählen -</MenuItem>
                        <MenuItem value="verkauft_online">Artikel wurde durch Online-Anfrage verkauft</MenuItem>
                        <MenuItem value="verkauft_offline">Artikel wurde stationär verkauft</MenuItem>
                        <MenuItem value="test_fehler_duplikat">Artikel ist ein Duplikat, Test oder Fehlerhaft</MenuItem>
                        <MenuItem value="sonstiges">Artikel soll aus anderen Gründen nicht mehr angeboten werden.</MenuItem>
                      </Select>
                    </FormControl>
                    {(this.state.dialogText && this.state.dialogText.length > 1) ? (
                    <p className="alert-dialog-error">{this.state.dialogText}</p>
                    ) : '' }
                  </DialogContent>
                ) : (
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Möchten Sie diesen Artikel wirklich löschen?
                    </DialogContentText>
                  </DialogContent>
                )}
                <DialogActions>
                  <Button onClick={this.handleDeleteAndCloseDialog} color="primary" disabled={this.state.dialogDisableActionButton}>
                    Löschen bestätigen
                  </Button>
                  <Button onClick={this.handleCloseDialog} autoFocus>
                    Abbrechen
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.dialogSellOpen}
                onClose={this.handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{this.state.dialogProductName || ''}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Um welche Art des Verkaufs handelt es sich?
                    </DialogContentText>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="sell-reason">Pflichtfeld</InputLabel>
                      <Select
                        id="sell-reason"
                        autoFocus
                        value={this.state.sellType}
                        onChange={(e) => this.handleSellType(e)}
                        required={true}
                      >
                        <MenuItem value="-">- bitte wählen -</MenuItem>
                        <MenuItem value="verkauft_online">Artikel wurde vollständig durch Online-Anfrage verkauft.</MenuItem>
                        <MenuItem value="verkauft_offline">Artikel wurde vollständig stationär verkauft.</MenuItem>
                        {(this.state.dialogProductQuantity > 1) ? (<MenuItem value="teilverkauf_online">Teilverkauf durch Online-Anfrage</MenuItem>) : ''}
                        {(this.state.dialogProductQuantity > 1) ? (<MenuItem value="teilverkauf_offline">Teilverkauf durch stationären Handel</MenuItem>) : ''}
                        <MenuItem value="sonstiges">Artikel aus sonstigen Gründen auf verkauft stellen</MenuItem>
                      </Select>
                      {(this.state.sellType.includes('teilverkauf')) ? (
                      <p>Aktuelle Menge ({this.state.dialogProductQuantity || '0'}) reduzieren um <input id="sales-quantity" type="number" className="dialog-number-input" min={0} max={this.state.dialogProductQuantity} onChange={(e) => this.setState({salesQuantity: e.target.value})} value={this.state.salesQuantity} required={true} /> !</p>
                      ) : '' }
                    </FormControl>
                    {(this.state.dialogText && this.state.dialogText.length > 1) ? (
                    <p className="alert-dialog-error">{this.state.dialogText}</p>
                    ) : '' }
                  </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleSellAndCloseDialog} color="primary" disabled={this.state.dialogDisableActionButton}>
                    Verkauf bestätigen
                  </Button>
                  <Button onClick={this.handleCloseDialog} autoFocus>
                    Abbrechen
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                disableBackdropClick={this.state.productBulkUploaded.length !== this.state.productBulk.length}
                disableEscapeKeyDown={this.state.productBulkUploaded.length !== this.state.productBulk.length}
                open={this.state.bulkEditDialog}
                onClose={this.handleBulkDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title" style={{ paddingBottom: '15px' }}>Mehrfachaktion durchführen</DialogTitle>
                <DialogContent style={{ paddingBottom: 0 }}>
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                    <CircularProgress
                      className={classes.progress}
                      variant="static"
                      value={(this.state.productBulkUploaded.length / this.state.productBulk.length) * 100}
                      color="secondary"
                    />
                  </div>
                  <Typography component="span" style={{ textAlign: 'center' }}>
                    Aktion: {bulkEditType}
                  </Typography>
                  <Typography component="span" style={{ textAlign: 'center' }}>
                    {this.state.productBulkUploaded.length - this.state.productBulkFailed.length} von {this.state.productBulk.length} Produkte erfolgreich
                  </Typography>
                  <Typography component="span" style={{ textAlign: 'center' }}>
                    {this.state.productBulkFailed.length} Produkte fehlgeschlagen
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button color="info" onClick={this.handleBulkDialogClose} disabled={this.state.productBulkUploaded.length !== this.state.productBulk.length}>
                    Bestätigen
                  </Button>
                </DialogActions>
              </Dialog>

            </GridItem>
          </GridContainer>
          <Fab className={classes.fab} color="primary" onClick={ () => this.handleClickNewProduct() }>
            <AddIcon />
          </Fab>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withSnackbar(withStyles(SellanizerProductTablesStyle)(ProductTables));
