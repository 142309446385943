import React from 'react';
import Check from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import Close from "@material-ui/icons/Close";

const styles = () => ({
    loadingContainer: {
      position: 'relative'
    },
    circularProgress: {
        position: 'absolute',
        top: '-7px',
        height: 'unset !important',
        '& svg': {
            marginRight: 0
        }
    }
});

const LoadingText = (props) => {
    const { classes, loading, done, failed, children, ...other } = props;

    if (done) {
        return (
            <div className={classes.loadingContainer} {...other}>
                {children} &nbsp; <Check />
            </div>
        );
    } else if (failed) {
        return (
            <div className={classes.loadingContainer} {...other}>
                {children} &nbsp; <Close />
            </div>
        );
    } else if (loading) {
        return (
            <div className={classes.loadingContainer} {...other}>
                {children} &nbsp; <CircularProgress className={classes.circularProgress} />
            </div>
        );
    } else {
        return (
            <div className={classes.loadingContainer} {...other}>
                {children}
            </div>
        );
    }
}

export default withStyles(styles)(LoadingText);