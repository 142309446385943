/*global FB*/
import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// 44 components
import API from 'components/44/ApiPlanungswelten';
import DB from 'components/44/Database';
import Button from "components/CustomButtons/Button.jsx";
import FacebookConnect from 'components/44/FacebookConnect/FacebookConnect';
import FBinit from 'components/44/FBinit';

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import { withSnackbar } from 'notistack';
import CardHeader from "../Card/CardHeader";
import SnackbarClose from "components/Snackbar/SnackbarClose";

class Settings extends React.Component {

  FB_APP_ID = process.env.REACT_APP_FB_APP_ID;
  FB_APP_SECRET = process.env.REACT_APP_FB_APP_SECRET;

  state = {
    data: {
      pageToken: null,
      pageID: null,
      userID: null
    },
    appData: {
      appID: this.FB_APP_ID,
      appSecret: this.FB_APP_SECRET,
    },
    selectedPartnerData: DB.selectedPartnerData()
  }

  componentDidMount(){
    if (!window.fbAsyncInit) {
      // init fb-sdk
      FBinit(this);
    } else {
      // check wether token is valid
      this.checkToken();
    }
  }

  checkToken() {
    //console.debug('Check wether pageToken is valid ...');
    let _this = this;
    //console.debug('change fb_page_token', DB.changePartnerData('fb_page_token', 'TEST'));

    let storedPageToken = this.getPartnerDataFB('fb_page_token')
    let accessToken = this.FB_APP_ID+'|'+this.FB_APP_SECRET
    //console.debug('accessToken', accessToken)

    if (storedPageToken && accessToken) {
      //console.debug('pageToken: ', storedPageToken)
      //console.debug('access_token', accessToken)
      //console.debug('FB: debug_token')
      FB.api(
        '/debug_token',
        {
          input_token: storedPageToken,
          access_token: accessToken
        },
        function(response){
          if (response && !response.error) {
            if (response.data.is_valid) {
              console.debug('pageToken is valid. Set state.')
              _this.setState({
                data: {
                  pageToken: storedPageToken,
                  pageID: _this.getPartnerDataFB('fb_page_id'),
                  userID: _this.getPartnerDataFB('fb_user_id')
                }
              })
              //_this.setPartnerDataFB(values, _this)
            } else {
              console.debug('pageToken is NOT valid. Set state to NULL.')
              let values = {
                fb_page_token: '',
                fb_page_id: '',
                fb_user_id: ''
              }
              _this.setPartnerDataFB(values, _this)
            }
          } else {
            _this.handleFbError(response.error);
          }
        }
      )
    } else {
      console.debug('No pageToken and/or accessToken available!')
    }
  }

  setPartnerDataFB(values, _this){
    API.updatePartner(values)
      .then(
        (result) => {
          console.debug('updatePartner Result', result)
          console.debug('updateSelectedPartnerData Values', values)

          DB.updateSelectedPartnerData(values)
          /*for (let [key, value] of Object.entries(values)) {
            //localStorage.setItem(key,value)
            DB.changePartnerData(key,value)
          }*/
          _this.setState({
            data: {
              pageToken: values.fb_page_token,
              pageID: values.fb_page_id,
              userID: values.fb_user_id
            }
          })
        },
          (error) => {
            // console.log('Error beim Request1:', error);
            this.props.enqueueSnackbar("Fehler bei dem Verabeiten der Parter-Daten!", { persist: true, variant: 'error', action: (<SnackbarClose/>)})
          }
      )
      .catch(response => () => {
        // console.log('Error beim Request2:', response);
        this.props.enqueueSnackbar("Fehler bei dem Verabeiten der Parter-Daten!", { persist: true, variant: 'error', action: (<SnackbarClose/>)})
      }
    ); // End API.catch
  }

  getPartnerDataFB(key){
    //const val = DB.getPartnerData(key)
    //console.log('getPartnerDataFB: '+key, val)
    return DB.getPartnerData(key)
  }


  handleFbDataChange = (data) => {
    console.debug('handleDataChange')
    if (data) {
      console.debug('store received data (fb-connect)')
      let values = {
        fb_page_token: data.pageToken,
        fb_page_id: data.pageID,
        fb_user_id: data.userID
      }
      this.setPartnerDataFB(values, this)
    } else {
      console.debug('remove all data (fb-disconnect)')
      let values = {
        fb_page_token: '',
        fb_page_id: '',
        fb_user_id: ''
      }
      this.setPartnerDataFB(values, this)
    }

  }

  handleFbError = (error) => {
    //console.log(error)
    this.props.enqueueSnackbar('Facebook: ' + error.message, { variant: 'error'})
  }

  clearDB(){
    DB.clearDB();
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer>

            <GridItem xs={12} sm={12} lg={6}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Facebook-Integration</h4>
                  <p className={classes.cardCategoryWhite}>
                    Ermöglicht das veröffentlichen eines Artikels als Beitrag auf ihrer Facebook-Seite
                  </p>
                </CardHeader>
                <CardBody>
                  {DB.checkPartnerData('is_facebook') ?
                    <FacebookConnect
                      data={{
                        ...this.state.appData,
                        ...this.state.data
                      }}
                      onChange={this.handleFbDataChange}
                      onError={this.handleFbError}
                    />
                    :
                    <p>Nicht freigeschaltet! - Um diese Funktion nutzen zu können, wenden Sie sich bitte an Ihren Sellanizer-Ansprechpartner, rufen Sie unter 0261/1349640 an oder schreiben eine E-Mail an info@trends4markets.de!</p>
                  }
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} lg={6}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Datenbank bereinigen</h4>
                  <p className={classes.cardCategoryWhite}>
                    Bereinigt die lokale Datenbank des Browsers (localStorage/IndexedDB) von überbliebenen Datenleichen.
                  </p>
                </CardHeader>
                <CardBody style={{textAlign: 'center'}}>
                  <Button color="success" size="lg" onClick={() => this.clearDB()}>Jetzt bereinigen</Button>
                </CardBody>
              </Card>
            </GridItem>

          </GridContainer>
        </div>
      </div>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(dashboardStyle)(Settings));
