import React from "react";
//import ReactDOM from 'react-dom';
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';

// @material-ui/icons
// TODO: eigene Styles für Accordion importen
//import accordionStyle from "assets/jss/material-dashboard-pro-react/components/accordionStyle.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomButton from "components/CustomButtons/Button.jsx";
//import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

//import Form from 'material-ui-form-builder';
import FormBuilder from 'components/44/formbuilder';

import _ from 'lodash';

// 44 components
import DB from 'components/44/Database';
import Txt from 'variables/texts';
import API from 'components/44/ApiPlanungswelten';
import { theme } from "components/44/muiTheme"
import combineStyles from 'components/44/combineStyles';
import { withSnackbar } from 'notistack';

import defaultImage from "assets/img/default-picture.png";

// import Styles
import productFormStyle from "assets/jss/44/productFormStyle.jsx";
import SnackbarClose from "../../components/Snackbar/SnackbarClose";
// import {datetime} from "locutus/php"; // Wird nur zu Zeitmessungen bei Testzwecken verwendet

// JSON data
const formData = require("../../json/produkte.json");
const additionalProductData = require("../../json/produkte_additional.json");

// Einfügen von Title, Start-Datum und Online-Anzeigen da diese Felder nicht im ACF-Export enthalten sind
formData.fields = addAfter(formData.fields, 1, additionalProductData);
let formDataFields = _.clone(formData.fields)

// Felder die für den Formbuilder gelöscht werden sollen - hide_for_customer kann bei Tabs leider nicht im ACF hinterlegt werden, der Formbuilder kann aber damit umgehen.
let toDelete = [
  'field_5bdc50692197b', 'field_5cdaef45388e3', 'field_62433ff39d1a9', // tabs: ebay, markt.de, traktorpool
  'is_on_ebay', 'should_be_update_on_ebay', 'ebay_ad_id', 'has_ebay_error', 'ebay_error_info',
  'is_on_marktde', 'should_be_update_on_marktde', 'marktde_ad_id', 'partner'
]

// Felder die für den Formbuilder ausgeblendet werden sollen
let toHide = ['field_5964f919f2278', 'strasse', 'plz', 'ort', 'land'] // field_5964f919f2278 = Artikel-Standort (Tab)

if(toDelete.length || toHide.length) {

  formDataFields.reduceRight( function(acc, field, index, formDataFields) {
    //console.log('Check if is to delete: ' + field.name)
    if (toDelete.length) {
      toDelete.forEach(field_to_delete => {
        if (field.key === field_to_delete || field.name === field_to_delete) {
          //console.log('Delete field: ' + field_to_delete)
          formDataFields.splice(index, 1);
        }
      })
    }
    return true
  })

  formDataFields.forEach( function(field, index, formDataFields) {
    if(toHide.length) {
      toHide.forEach(field_to_hide => {
        if (field.key === field_to_hide || field.name === field_to_hide) {
          //console.debug('Hide field: '+field.key+' ('+field.name+')')
          field.disabled = true
          field.readonly = true
        }
      })
    }

  })

  formData.fields = formDataFields
}

const formDataWpFields = require("../../json/produkte_wp-fields.json");
const formFields = [ ...formDataWpFields.fields, ...formData.fields ];

const ansprechpartnerFallback = require("../../json/ansprechpartner.json");
const herstellerFallback = require("../../json/hersteller_production.json");
const kategorienFallback = process.env.NODE_ENV === 'production' ? require("../../json/produkt_kategorien_production.json") : require("../../json/produkt_kategorien.json");

/* GUID-Generator => 4d4a81af-7ba2-9c98-90f8-fa65054008e2 */
function guid() {
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

function addAfter(array, index=0, newItem) {
  if (Array.isArray(newItem)){
    newItem.reverse().forEach(item => {
      array = addAfter(array, index, item)
    })
    return array
  }else{
    return [
      ...array.slice(0, index),
      newItem,
      ...array.slice(index)
    ];
  }
}

const styles = theme => ({

});

class ProductForm extends React.Component {

  constructor(props) {
    super(props);

    let selectedPartnerData = DB.selectedPartnerData()
    //console.debug('selectedPartnerData', selectedPartnerData)

		let publishDefault = 'publish';
		if (API.getUserRole() === 'sellanizer_creator') {
			publishDefault = 'draft';
		}

    this.state = {
      props: props,
      formFields: formFields,
      ai_text_generation_enable: API.userHasGenerateAiTexts(), /* API.userIs44OrT4M() */
      editValues: {
        images: [],
        app_synced: false,
        post_id: false,
        post_status: publishDefault,
        produkt_typ: 'moebel',
        menge: 1,
        startdatum: new Date().toISOString().slice(0, 16),
        field_wordpress_date: new Date().toISOString().slice(0, 16),
        partner: selectedPartnerData.partner_id,
        zahlungsbedingungen: selectedPartnerData.partner_zahlungsbedingungen || '',
        lieferbedingungen: selectedPartnerData.partner_lieferbedingungen || '',
        finanzierung: selectedPartnerData.partner_finanzierung || '',
        strasse: selectedPartnerData.partner_street || '',
        plz: selectedPartnerData.partner_zip || '',
        ort: selectedPartnerData.partner_city || '',
        land: selectedPartnerData.partner_country || '',
        cat_level1: 0,
        cat_level2: 0,
        cat_level3: 0
      },
      jsonData: {
        "ansprechpartner": ansprechpartnerFallback,
        "hersteller": herstellerFallback,
        "kochfeld_hersteller": herstellerFallback,
        "produkt_kategorien": kategorienFallback
      },
      validateValues: {},
      expanded: null,
      loading: true
    };
    this.formBuilder = React.createRef();
    this.pictureUpload = React.createRef();
  }

  componentDidUpdate(){
    //console.log('componentWillUpdate');

    // "Online anzeigen?" für Ersteller sperren
    this.state.formFields.forEach( field => {
      if (field.name === 'post_status') {
        if (API.getUserRole() === 'sellanizer_creator') {
          field.disabled = true
          field.readonly = true
        }else{
          field.disabled = false
          field.readonly = false
        }
      }
    })

    // Extra-Feature prüfen / sperren
    this.state.formFields.forEach( field => {
      if (field.name === 'should_ebay' || field.name === 'should_marktde') {
        let search = new RegExp(' - ' + Txt.kleinanzeigen,"g");
        if(!DB.checkPartnerData('is_ebay_kleinanzeigen')) {
          // Um zu gewährleisten das der Text nicht mehrfach angehangen wird, Text erst einmal löschen.
          field.label = field.label.replace(search, '')
          field.label = field.label + ' - ' + Txt.kleinanzeigen
          field.disabled = true
          field.readonly = true
        }else{
          field.label = field.label.replace(search, '')
          field.disabled = false
          field.readonly = false
        }
      }
    })
  }

  componentDidMount(){
    this.setState({ db: DB })
    let _this = this

    /* JSON-Data (ua. Kategorien, Ansprechpartner, Hersteller-Daten usw) neu laden */
    let kategorien = typeof(localStorage['kategorienData']) !== 'undefined' ? JSON.parse(localStorage['kategorienData']) : kategorienFallback;
    let ansprechpartner = typeof(localStorage['ansprechpartnerData']) !== 'undefined' ? JSON.parse(localStorage['ansprechpartnerData']) : ansprechpartnerFallback;
    let herstellerLieferprogramm = typeof(localStorage['herstellerData']) !== 'undefined' ? JSON.parse(localStorage['herstellerData']) : herstellerFallback;
    let herstellerCategory = typeof(localStorage['herstellerCategoryData']) !== 'undefined' ? JSON.parse(localStorage['herstellerCategoryData']) : herstellerFallback;

    let jsonData = {
      "ansprechpartner": ansprechpartner,
      "hersteller": DB.checkPartnerData('hersteller_lieferprogramm', false) ? herstellerLieferprogramm : herstellerCategory,
      "kochfeld_hersteller": herstellerCategory,
      "herd_backofen_hersteller": herstellerCategory,
      "mikrowelle_hersteller": herstellerCategory,
      "dunstabzug_hersteller": herstellerCategory,
      "kuehlschrank_hersteller": herstellerCategory,
      "gefrierschrank_hersteller": herstellerCategory,
      "geschirrspueler_hersteller": herstellerCategory,
      "kuehl-gefrier-kombi_hersteller": herstellerCategory,
      "produkt_kategorien": kategorien
    }

    // "Online anzeigen? | Kleinanzeigen | Markt.de" für Ersteller sperren
    if (API.getUserRole() === 'sellanizer_creator') {
      this.state.formFields.forEach( field => {
    		if (field.name === 'post_status' || field.name === 'should_ebay' || field.name === 'should_marktde') {
    			field.disabled = true
          field.readonly = true
    		}
    	})
    }

    // Extra-Feature prüfen / sperren
    if(!DB.checkPartnerData('is_ebay_kleinanzeigen')) {
      let search = new RegExp(' - ' + Txt.kleinanzeigen,"g");
      this.state.formFields.forEach( field => {
        if (field.name === 'should_ebay' || field.name === 'should_marktde') {
          field.label = field.label.replace(search, '')
          field.label = field.label + ' - ' + Txt.kleinanzeigen
          field.disabled = true
          field.readonly = true
        }
      })
    }

    this.setState({ jsonData: jsonData })

    if(this.props.match.params.id){
      //console.log('E D I T');

      // Load Product-Data from DB
      DB.db.get(this.props.match.params.id, {attachments: true}).then(function (doc) {
        //console.log('%cREADY loaded doc:', 'color:red; font-size: 24px', doc);
        let docValues = {}
        if(doc.values){
          docValues = doc.values
        }else if(doc.name){
          docValues = doc
        }

        console.log("rofl", docValues)

        _this.setState({editValues: docValues}, function () {
          _this.setState({loading: false})
        })

        //console.warn('%cTime:', 'color:red; font-size: 24px', datetime.time());
        if(typeof _this.formBuilder.current !== 'undefined' && _this.formBuilder.current !== null ){
          _this.formBuilder.current.setDefaultFields(docValues);
          _this.state.formFields.forEach( (field) => {
            // Alle Felder welche als Entscheidungsträger markiert sind, auf Conditions prüfen!
            if(typeof(field.wrapper) !== 'undefined' && field.wrapper.class === 'check_cl_on_startup') {
              //console.debug('handleConditions OF ', field.name);
              let val = docValues[field.name] ?? field.default_value;
              _this.formBuilder.current.handleCondition(field.key, field.name, val)
            }
          })
          //console.warn('%cTime:', 'color:red; font-size: 24px', datetime.time());
        }

      }).catch(function (err) {
        console.log(err);
      });

    }else{
      setTimeout(
        function() {
          if(typeof _this.formBuilder.current !== 'undefined' && _this.formBuilder.current !== null ){
            _this.formBuilder.current.setDefaultFields();
          }
        },
        50
      );
      //_this.formBuilder.current.setDefaultFields();
      _this.setState({loading: false})
    }
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  _saveFormAndBack = () => {
    let _this = this
    this.saveForm().then( (message) => {
      _this.state.props.history.push('/products/local')
    }, (error) => {
      console.log('Error on saveFormAndBack:', error);
      /*_this.props.enqueueSnackbar('Beim Speichern ist ein Fehler aufgetreten! '+error, { variant: 'error'})*/
    })
  }

  _abortFormAndBack = () => {
    //this.state.props.history.back
    this.state.props.history.push('/products/local')
  }

  /*
   * Bilderupload
   * */

  onFileLoad = (e, file) => console.log(e.target.result, file.name);

  onChange = (pictures) => this.setState({pictures});

  convertImgToBlob(img, callback) {
     var canvas = document.createElement('canvas');
     var context = canvas.getContext('2d');
     context.drawImage(img, 0, 0);

      // Warning: toBlob() isn't supported by every browser.
      // You may want to use blob-util.
     canvas.toBlob(callback, 'image/png');
  }

  setStateFromChildren(state){
    console.log('setStateFromChildren:', state);
    this.setState(...state)
  }

  secureFilename(key, i, file, productName){
    let filename = file.name || key
    // image.jpg is a default iOS Name, we have to rename it
    if(filename === 'image.jpg') {
      filename = this.filenameByName(key, i, file, productName)
    }
    // Remove invalid trailing _
    if(filename.charAt(0)==='_'){
      filename = filename.substr(1);
    }
    //console.log('secureFilename', filename)
    return filename
  }

  filenameByName(key, i, file, productName){
    let type = (file.type || file.content_type).replace('jpeg', 'jpg')

    // Originalfilename und Fallback Filename mit Timestamp
    let filename = file.name || key || ('img-'+Date.now()+'.'+type.split('/').pop())

    // Filename by Artikelname deaktviert, da in der API aktuell nicht überprüft wird ob unter diesem Namen schon ein Bild existiert, wenn ja wird dieser genommen und überschrieben?!
    if(false || productName.length > 2){
      // Filename by Artikelname
      let secName = productName.replace(' ', '-').replace(/(?!\.[^.]+$)\.|[^\w-]+/g, '')
      filename = 'img'+i+'-'+secName+'.'+type.split('/').pop()
    }else{
      // Uniq Filename by Timestamp
      filename = 'img'+i+'-'+Date.now()+'.'+type.split('/').pop()
    }
    if(filename.charAt(0)==='_'){
      filename = filename.substr(1);
    }
    return filename
  }


  validateForm() {
    const _this = this
    let errors = this.formBuilder.current.state.errors;
    let errorText = ''

    const formFields = _this.state.formFields

    Object.keys(errors).forEach(function(k){
      let f = errors[k]
      //console.debug('errors.forEach '+k, f);
      if(f.hide){
        // remove hidden fields from validation list
        delete errors[k]
        //console.debug('DELETE '+f.label+' from Validations, coz its hidden!');
      }else{
        let found = false
        // eslint-disable-next-line
        let current_ff = {}
        // search for label and instructions in formFields for a better validation message
        formFields.forEach(function(ff){
          if(ff.key === k){
            current_ff = ff
            found = true
            // add error to validation message if its required
            if(ff.required){
              //console.debug('Field '+ff.key+' is required, but missing. Add instructions to validation message!', ff)
              let msg = typeof(ff.instructions) !== 'undefined' ? ff.instructions : ( errors[ff.key].text.length ? errors[ff.key].text : "Dies ist ein Pflichtfeld!");
              //toValidateLabels[f.key] = {label: f.label, text: msg}
              errorText+= '-'+ff.label+': '+msg+"\n\r"
            }else{
              delete errors[k]
            }
          }
        })

        // if field is not in formFields, then add it with default texts
        if(!found){
          //console.debug('Field '+k+' konnte nicht in den formFields gefunden werden und ist '+(current_ff.required ? '' : 'nicht ')+'required', f)
          errorText+= '-'+f.label+': '+f.text+"\n\r"
        }else{
          //console.debug('Field '+k+' konnte in den formFields gefunden werden und ist '+(current_ff.required ? '' : 'nicht ')+'required', formFields)
        }
      }
    })

    if(Object.keys(errors).length > 0){
      console.warn('errors', errors);
      const msg = 'Bitte überprüfen Sie die rot markierten Felder: \n\n'+errorText
      //alert(msg);
      this.props.enqueueSnackbar(msg, {
        variant: 'error',
        action: <SnackbarClose/>,
        autoHideDuration: 8000,
        style: {whiteSpace: 'pre-wrap', marginTop: 50},
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        },
      });
      return false
    }else{
      return true
    }
  }

  formatFilesObject(files_ary, name) {
    let attachments = {}
    let file
    let _this = this

    if(typeof files_ary !== 'undefined' && Object.keys(files_ary).length){
      let i = 0
      Object.keys(files_ary).forEach(function(key) {
        file = files_ary[key]
        //console.log('images.forEach file=', file);

        if(typeof file !== "undefined"){
          let filename = _this.secureFilename(key, i, file, name)
          file.name = filename

          if(attachments[filename]){
            let filename = _this.filenameByName(key, i, file, name)
            file.name = filename
            // Index existiert schon, ändern
            //console.log('Attachment-Index exitiert schon, rename to:', filename)
            attachments[filename] = file
          }else{
            attachments[filename] = file
          }

          /*attachments[filename] = {
            name: filename,
            size: size,
            content_type: file.type,
            type: file.type,
            data: file.data
          }*/
        }
        i++;
      });
    }
    return attachments;
  }

  saveForm = (newValues) => {
    return new Promise((resolve, reject) => {
      //const _this = this

      if(!this.validateForm()){
        return reject('Bitte überprüfen Sie die rot markierten Felder!')
      }

      this.setState({loading: true})

      let values = this.formBuilder.current.state.values;
      //let values = newValues || this.state.editValues

      let images = this.formatFilesObject(this.formBuilder.current.state.values.images, this.formBuilder.current.state.values.name);
      //let upload_energiepass = this.formatFilesObject(this.formBuilder.current.state.values.upload_energiepass, this.formBuilder.current.state.values.name);
      //console.log('saveForm.images before loop', images);

      //console.log('images', images);
      let key = this.props.match.params.id

      if(key){
        // Get existing Product-Object from DB and save new Values
        return DB.db.get(key).then(function (doc) {
          //console.log(doc);

          //console.log('saveForm.images (existing doc)', images);

          //console.log('debug _attachments before save:', attachments)

          return DB.db.put(
            { ...{ _id: doc._id, _rev: doc._rev}, ...values, app_synced: false, images: images } //, upload_energiepass: upload_energiepass

          ).then(function (doc) {
            // handle response
            //console.log('Saved existing doc '+(Object.keys(attachments).length > 0 ? 'with' : 'without' )+' attachments:', doc);
            //console.log('attachments', attachments);
            return resolve('Success');
            //return doc;
          })

        }).catch(function (err) {
          console.log(err);
          return false;
        });

      } else {
        // Save Product-Data to DB
        let newId = guid()

        return DB.db.put(
          { ...{_id: newId}, ...values, app_synced: false, images: images } //, upload_energiepass: upload_energiepass

        ).then(function (response) {
          // handle response
          //console.log('Saved new doc:', response);
          /*
          let key = response.id
          _this.state.props.history.push('/productEdit/'+key)*/
          console.log("new product saved", values)
          //console.log('this.state.db.getAllProducts()', this.state.db.getAllProducts());
          return resolve('Success');
        }).catch(function (err) {
          console.log('Error on saving doc:', err);
          return false;
        });
      }
    })
  };

  render() {
    const {classes} = this.props;

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
          {(!!this.state.loading ?
            <GridContainer style={{paddingTop: 20, height:'100%', minHeight: '500px'}}>
              <div style={{position:'absolute', height:'100%', width:'100%', top:'-10%', justifyContent:'center', display:'flex', alignItems:'center'}}>
                <div style={{textAlign:'center'}}>
                  <div style={{display:'block', clear:'both', color:"#ff412f", marginBottom:10 }}>Wird geladen...</div>
                  <CircularProgress className={classes.progress} style={{color:"#ff412f"}} />
                </div>
              </div>
            </GridContainer> :

            <GridContainer style={{paddingTop: 20}}>
              <GridItem xs={12}>
                <FormBuilder ref={this.formBuilder}
                  classes={classes}
                  defaultImage={defaultImage}
                  fieldContainerStyle={{backgroundColor: '#fefefe'}}
                  //onChange={() => this._onChange(this.state.editValues)}
                  saveForm={this.saveForm}
                  setStateFromChildren={(state) => this.setStateFromChildren(state)}
                  fields={this.state.formFields}
                  jsonData={this.state.jsonData}
                  values={this.state.editValues}
                  errors={this.state.validateValues}
                  ai_text_generation_enable={this.state.ai_text_generation_enable}>
                </FormBuilder>
                <br/>
                {process.env.NODE_ENV !== 'production' ?
                  <GridContainer justify="flex-end" style={{paddingTop: 20}}>
                    <GridItem xs={6} sm={4} md={4} dense={1}>
                      <CustomButton icon="save" color="success" onClick={this.saveForm}>Speichern</CustomButton>
                    </GridItem>
                    <GridItem xs={6} sm={4} md={4}>
                      <CustomButton icon="save" color="info" onClick={this._saveFormAndBack}>Speichern & Zurück</CustomButton>
                    </GridItem>
                    <GridItem xs={6} sm={4} md={4} dense={1} style={{textAlign: 'right'}}>
                      <NavLink to="/products/local">
                        <CustomButton icon="clear" color="danger">Abbrechen</CustomButton>{/*onClick={this._abortFormAndBack}*/}
                      </NavLink>
                    </GridItem>
                  </GridContainer>
                  :
                  <GridContainer justify="flex-end" style={{paddingTop: 20,}}>
                    <GridItem xs={6} sm={6} md={6} dense={1}>
                      <CustomButton icon="save" color="info" onClick={this._saveFormAndBack}>Speichern & Zurück</CustomButton>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6} dense={1} style={{textAlign: 'right'}}>
                      <NavLink to="/products/local">
                        <CustomButton icon="clear" color="danger">Abbrechen</CustomButton>{/*onClick={this._abortFormAndBack}*/}
                      </NavLink>
                    </GridItem>
                  </GridContainer>
                }
              </GridItem>
            </GridContainer>
          )}
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withSnackbar(withStyles(combineStyles(styles, productFormStyle))(ProductForm));
