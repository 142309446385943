import React from "react";

import ExifOrientationImg from 'react-exif-orientation-img'
import Compressor from 'compressorjs'
import defaultImage from "assets/img/default-picture.png";
import Delete from "@material-ui/icons/Delete";
import Attachment from "@material-ui/icons/Attachment";
import { ReactSortable } from "react-sortablejs";

const OrientationImg = ExifOrientationImg

const ExifRestorer = require('components/44/ExifRestorer.js').default;

//**dataURL to blob**
function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
}

//**blob to dataURL**
/*function blobToDataURL(blob, callback) {
    var a = new FileReader();
    a.onload = function(e) {callback(e.target.result);}
    a.readAsDataURL(blob);
}*/

class PictureUpload extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pouchDB_imageType: 'base64',
      img: undefined,
      file: undefined,
      images: [],
      label: this.props.label || '',
      helperText: this.props.helperText,
      max: this.props.max || 1,
      imagePreviewUrl: defaultImage,
      lastChanged: Date.now()
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
    let _this = this
    let imagesObj = this.props.images
    let images = []
    let file = undefined

    if(typeof imagesObj !== 'undefined' && Object.keys(imagesObj).length){
      Object.keys(imagesObj).forEach(function(key) {
        file = undefined
        file = imagesObj[key]

        if(typeof file !== 'undefined'){
          let base64 = file.data //this.resizedataURL(file.data)
          let dataUrl = 'data:'+(file.content_type || file.type || 'image/jpeg')+';base64,'+base64

          if(typeof file.thumbnail_url !== 'undefined'){
            file.imagePreviewUrl = file.thumbnail_url
            images.push(file)
            _this.setState({images: images});
          }else{
            file.imagePreviewUrl = dataUrl
            file.name = key
            images.push(file)
            _this.setState({
              images: images
            },function(){
              if (_this.props.onChange) {
                _this.props.onChange(images);
              }
            });
          }

        }else{
          console.log('file not found:', file)
        }

      });
    }
  }

  handleImageDelete(e, key, prop) {
    e.preventDefault();

    let _this = this
    let images = this.state.images

    if(typeof key !== 'undefined'){
      images.splice(key, 1)
      _this.setState({
        images: images
      },function(){
        if (_this.props.onChange) {
          _this.props.onChange(images);
        }
      });
    }else{
    }
  }

  handleImageChange(e, key, prop) {
    e.preventDefault();

    let _this = this
    let images = this.state.images
    let newFiles = e.target.files;

    Object.values(newFiles).forEach(function(origfile) {
      let file = {}
      new Compressor(origfile, {
        quality: 0.6,
        minWidth: 800,
        minHeight: 600,
        maxWidth: 1600,
        maxHeight: 1000,
        success(compressedFile) {
          //console.log('2. sizeCompressedImage', getImageSize(result))
          //console.log('compressedImage', compressedFile)

          /*
           * Handle File Reader START
           */
          let reader = new FileReader();

          reader.onloadend = () => {
            let dataUrl = reader.result
            let data = undefined
            // eslint-disable-next-line
            let dataSmall = undefined
            //let blob = undefined
            // eslint-disable-next-line
            let base64 = undefined

            // Image compression to 70%
            /*
            let imageCompressor = new ImageCompressor({
              onSuccess: response => {
                console.log('1. sizeOriganleImage', getImageSize(response.original))
                console.log('1. sizeCompressedImage', getImageSize(response.compressed))
                console.log('1. compressedImage', response.compressed)
                dataUrl = response.compressed
                /*this.setState({
                  originalImage: response.original,
                  sizeOriganleImage: getImageSize(response.original),
                  compressedImage: response.compressed,
                  sizeCompressedImage: getImageSize(response.compressed)
                });*
              },
              scale: 70,
              quality: 70,
              originalImage: reader.result
            });*/


            if(typeof dataUrl === 'string'){
              //base64 = _this.resizedataURL(data)

              // eslint-disable-next-line
              let dataUrlSmall = _this.resizedataURL(dataUrl, function(dataUrlSmall){

                // lastChanged is used to force update OrientationImg
                file.lastChanged = Date.now()
                file.imagePreviewUrl = dataUrl
                //_this.setState({ imagePreviewUrl: dataUrlSmall, lastChanged: Date.now() })
                //console.log('callback after dataUri shrink', dataUrlSmall)

                data      = dataUrl.split('base64,')[1]
                base64    = data
                dataSmall = dataUrlSmall.split('base64,')[1]

                if(_this.state.pouchDB_imageType==='blob'){
                  data = dataURLtoBlob(dataUrl)
                }

                file.name         = origfile.name
                file.size         = origfile.size
                file.type         = origfile.type
                file.title        = origfile.title || ''
                file.content_type = origfile.type
                file.data = data

                console.debug('Bilddaten', origfile)

                if(typeof key !== 'undefined'){
                  images[key] = file
                }else{
                  images.push(file)
                }

                _this.setState({
                  images: images
                },function(){
                  if (_this.props.onChange) {
                    _this.props.onChange(images);
                  }
                });

              }, 100, 75, key)
            }
          };

          // erzeugt: data:image/jpeg;base64
          reader.readAsDataURL(compressedFile);

          /*
           * Handle File Reader ENDE
           */
        },
        error(err) {
          console.log(err.message);
        },
      });
    });
  }

  arrayMoveElement(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
  };

  handleImageSort(e) {
    if(typeof e.newIndex === 'number' && typeof e.oldIndex === 'number'){
      let images = this.state.images
      images = this.arrayMoveElement(images, e.oldIndex, e.newIndex)

      if (this.props.onChange) {
        this.props.onChange(images);
      }
    }
  }

  handleImageText(e, k, v){
    /*console.debug('handleImageText', e.target.value);*/
    console.log(k,v);
    let images = this.state.images
    images[k].title = e.target.value;
    /*if (this.props.onChange) {
      this.props.onChange(images);
    }*/
    this.setState({
      images: images
    },function(){
      if (this.props.onChange) {
        this.props.onChange(images);
      }
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
    console.log('handleSubmit: this.state.file', this.state.file);
  }

  resizedataURL(datas, callback = undefined, wantedWidth = 100, wantedHeight = 75, key = '') {
    // We create an image to receive the Data URI
    var img = document.createElement('img');

    // When the event "onload" is triggered we can resize the image.
    img.onload = function () {
      // We create a canvas and get its context.
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');

      // debug current size
      var ratio = this.width/this.height

      wantedHeight = wantedWidth/ratio

      // We set the dimensions at the wanted size.
      canvas.width = wantedWidth;
      canvas.height = wantedHeight;

      // We resize the image with the canvas method drawImage();
      ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

      var dataURI = canvas.toDataURL();
      dataURI = ExifRestorer.restore(datas.replace('data:', ''), dataURI);


      if(typeof callback === 'function'){
        callback(dataURI)
      }
    };

    // We put the Data URI in the image's src attribute
    img.src = datas;
  }

  render() {
    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    return (
      <div>
        <p style={{marginTop:10}}>{this.state.helperText}</p>
        <ReactSortable onEnd={(e) => this.handleImageSort(e)} list={this.state.images} setList={(newState) => function(){ console.log('setList', newState); }} >
        {this.state.images.map((prop, key) => {
          if (iOS) {
            return (
              <div key={key+'-wrapper'} className="picture-container ios" style={{display:'inline-block'}}>
                <div className="picture gallery">
                  <img id={key===0 ? 'mainimage' : 'image-'+key}
                    key={'oimg-'+key+'-'+prop.lastChanged}
                    src={prop.imagePreviewUrl}
                    className="picture-src"
                    //crossOrigin="anonymous"
                    alt={prop.name || ''}
                  />
                  <input type="file" accept="image/*" onChange={(e) => this.handleImageChange(e,key,prop)} />
                </div>
                <div className="description"><input type="text" placeholder="Beschreibung" onChange={(e) => this.handleImageText(e,key,prop)} value={prop.title || ''} /></div>
                <h6 onClick={(e) => this.handleImageDelete(e,key,prop)} className="deleteBtn"><Delete /> löschen</h6>
              </div>
            );
          }else{
            return (
              <div key={key+'-wrapper'} className="picture-container" style={{display:'inline-block'}}>
                <div className="picture gallery">
                  <OrientationImg id={Number(key)===0 ? 'mainimage' : 'image-'+key}
                    key={'oimg-'+key+'-'+prop.lastChanged}
                    src={prop.imagePreviewUrl}
                    className="picture-src"
                    //crossOrigin="anonymous"
                    alt={prop.name || ''}
                  />
                  <input type="file" accept="image/*" onChange={(e) => this.handleImageChange(e,key,prop)} />
                </div>
                <div className="description"><input type="text" placeholder="Beschreibung" onChange={(e) => this.handleImageText(e,key,prop)} value={prop.title || ''} /></div>
                <h6 onClick={(e) => this.handleImageDelete(e,key,prop)} className="deleteBtn"><Delete /> löschen</h6>
              </div>
            );
          }
        })}
        </ReactSortable>
        <div key="new-image" className="picture-container" style={{ marginTop: -5, display: this.state.images.length < (this.state.max || 1) ? 'inline-block' : 'none'}}>
          <div className="picture">
            <OrientationImg id="newimage"
              key={'oimg-'+this.state.lastChanged}
              src={defaultImage}
              className="picture-src newimage"
              //crossOrigin="anonymous"
              alt="choose new image"
            />
            <input type="file" accept="image/*" multiple onChange={e => this.handleImageChange(e)} />
          </div>
          <h6 className="deleteBtn"><Attachment /> wählen</h6>
        </div>
      </div>
    );
  }
}

export default PictureUpload;
