/* eslint-disable */
import React, { useContext, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Switch, Redirect } from "react-router-dom"

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import {MuiThemeProvider} from "@material-ui/core"
// 44 components
import { theme } from "components/44/muiTheme"
import API from 'components/44/ApiPlanungswelten'
import DB from 'components/44/Database'

// core components
import Header from "components/Header/Header.jsx"
import Footer from "components/Footer/Footer.jsx"
//import Sidebar from "components/Sidebar/Sidebar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import dashboardRoutes from "routes/dashboard.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-1.png";
import logo from "assets/img/logo_sellanizer-weiss-ohne-sub.svg";

import PropsRoute from "components/44/PropsRoute"

import Loading from "@material-ui/icons/Cached";
import { withSnackbar } from 'notistack';

let logoText = 'Sellanizer';

import sidebarContext from 'components/Sidebar/SidebarContext'
//const sidebarContext=React.createContext();

class App extends React.Component {
  //sidebarContext=React.createContext();
  constructor(props) {
    super(props);

    let selectedPartnerData = DB.selectedPartnerData()

    this.state = {
      mobileOpen: false,
      switchRoutes: this.switchRoutes(),
      sidebar: {
        setSidebar: this.setSidebar,
        dashboardRoutes: {},
        logoText: '',
        logo: '',
        image: '',
        partnerLogo: selectedPartnerData.partner_logo,
        partnerName: 'Loading...',
        handleDrawerToggle: null,
        mobileOpen: null,
      }
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getRoute() {
    return this.props.location.pathname !== "/maps";
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    window.addEventListener("resize", this.resizeFunction);
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }

  switchRoutes() {
    return(
      <Switch>
        {dashboardRoutes.map((prop, key) => {

          // Rechte & Rolle der Route prüfen
          let path = prop.path
          if(typeof prop.role !== 'undefined' && prop.role !== API.getUserRole()){
            // Wenn keine Rechte, dann auf Dashboar umleiten
            path = '/dashboard'
          }
          //let path = (prop.role && prop.role !== API.getUserRole()) ? '/dashboard' : prop.path;

          if (prop.redirect)
            return <Redirect from={path} to={prop.to} key={key} />;
          if (prop.collapse)
            return prop.views.map((prop, key) => {
              return (
                <PropsRoute path={path} component={prop.component} key={key} isMobile={this.props.isMobile} />
              );
            });
          return <PropsRoute path={path} component={prop.component} key={key} isMobile={this.props.isMobile} />;
        })}
      </Switch>
    );
  }

  //This is the method to set the context data.
  setSidebar = (props) => {
    //this.setState({ sidebar: props });
  };

  useSidebar(){
    //let setSidebar = this.setSidebar
    const [sidebar, setSidebar] = useState(null)
    const useSidebar = useMemo(()=> ({sidebar, setSidebar}), [sidebar, setSidebar])
    // {setSidebar, dashboardRoutes, logoText, logo, image, partnerLogo, partnerName, handleDrawerToggle, mobileOpen, ...rest}
  }

  render() {
    const { classes, ...rest } = this.props;
    let selectedPartnerData = DB.selectedPartnerData()
    //console.debug('selectedPartnerData', selectedPartnerData);

    let partnerLogo = selectedPartnerData.partner_logo
    let partnerName = selectedPartnerData.partner_shortname
    let handleDrawerToggle = this.handleDrawerToggle
    let mobileOpen = this.state.mobileOpen

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.wrapper}>
          <sidebarContext.Provider value={this.useSidebar}>
            <Sidebar
              routes={dashboardRoutes}
              logoText={logoText}
              logo={logo}
              image={image}
              partnerLogo={partnerLogo}
              partnerName={partnerName}
              handleDrawerToggle={handleDrawerToggle}
              open={mobileOpen}
              color="red"
              bgColor="black"
              {...rest}
            />
          </sidebarContext.Provider>
          <div className={classes.mainPanel} ref="mainPanel">
            <Header
              routes={dashboardRoutes}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
            />
            {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
            {this.getRoute() ? (
              <div className={classes.content}>
                <div className={classes.container}>{this.state.switchRoutes}</div>
              </div>
            ) : (
              <div className={classes.map}>{this.state.switchRoutes}</div>
            )}
            {this.getRoute() ? <Footer /> : null}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(dashboardStyle)(App));
