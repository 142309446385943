import React from 'react';

//Insert the default value here.
const defaultVal = {
  partnerData: {
    bla: 'test'
  },
  setPartner: (data) => {

  }
  //partners: []
}

const MainContext = React.createContext(defaultVal);

export default MainContext;