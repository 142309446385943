import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {Typography} from "@material-ui/core";

export default class BulkEditSellDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sellType: '-',
      errorText: ''
    }
  }

  handleConfirm = () => {
    if(!this.props.remote || this.props.productsGettingSold.length === 0) {
      this.props.onClose();
      this.props.onConfirm(this.state.sellType);
      this.resetStates();
      return;
    }

    if(this.state.sellType && this.state.sellType.length > 2) {
      this.props.onClose();
      this.props.onConfirm(this.state.sellType);
      this.resetStates();
    } else {
      this.setState({ errorText: 'Bitte Art des Verkaufs für alle ausgewählten Artikel angeben!' })
    }
  }

  resetStates() {
    this.setState({ errorText: '', sellType: '-' });
  }

  handleClose = () => {
    this.props.onClose();
    this.setState({ errorText: '', sellType: '-' })
  }

  handleSellType(e) {
    this.setState({ sellType: e.target.value });

    if(e.target.value !== '') {
      this.setState({ errorText: '' });
    }
  }

  render() {
    const isRemote = this.props.remote;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">{this.props.amount} Artikel verkaufen</DialogTitle>
        <DialogContent>
          {this.props.productsWithAmount.length > 0 ? (
            <div>
              <Typography component="span" variant="h6" style={{ margin: '6px 0' }}>
                Achtung: Folgende Artikel besitzen eine Menge, die größer als 1 ist:
              </Typography>
              <ul>
                {this.props.productsWithAmount.map((item, index) => {
                  return (
                    <li key={item + index}>{item}</li>
                  );
                })}
              </ul>
              <Typography component="span" variant="subheading" style={{ margin: '6px 0' }}>
                Ein Teilverkauf ist in der Bulk-Bearbeitung nicht möglich. Diese Artikel würden komplett verkauft werden. Sind Sie sich sicher?
              </Typography>
            </div>
          ) : ''}
          {isRemote && this.props.productsGettingSold.length > 0 ? (
            <>
              <DialogContentText id="alert-dialog-description">
                Um welche Art des Verkaufs handelt es sich?
              </DialogContentText>
              <FormControl>
                <InputLabel htmlFor="sell-reason">Pflichtfeld</InputLabel>
                <Select
                    id="sell-reason"
                    autoFocus
                    value={this.state.sellType}
                    onChange={(e) => this.handleSellType(e)}
                    required={true}
                >
                  <MenuItem value="-">- bitte wählen -</MenuItem>
                  <MenuItem value="verkauft_online">Artikel wurde vollständig durch Online-Anfrage verkauft.</MenuItem>
                  <MenuItem value="verkauft_offline">Artikel wurde vollständig stationär verkauft.</MenuItem>
                  <MenuItem value="sonstiges">Artikel aus sonstigen Gründen auf verkauft stellen</MenuItem>
                </Select>
                {(this.state.errorText && this.state.errorText.length > 1) ? (
                    <p className="alert-dialog-error">{this.state.errorText}</p>
                ) : '' }
              </FormControl>
            </>
          ) : ''}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleConfirm} color="primary">
            Verkauf bestätigen
          </Button>
          <Button onClick={this.handleClose} autoFocus>
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
