import React from 'react';

let icon = (props) => (
  <svg
    viewBox="120 153 100 120"
    width="26px" height="26px"
    version="1.1"
    id="svg16"
    xmlns="http://www.w3.org/2000/svg">
    <defs
      id="defs20"/>
    <path
      fill-rule="evenodd"
      d="M137.586 176.596c-3.016 2.939-5.238 7.515-5.238 14.098V246.6c0 6.583 2.222 11.159 5.238 14.098 3.069 2.989 7.221 4.537 11.457 4.537 6.424 0 9.996-3.125 16.124-9.343l2.613-2.652c-1.331-4.02-2.041-8.522-2.041-13.417v-49.129c0-6.583-2.222-11.159-5.238-14.098-3.069-2.989-7.221-4.537-11.458-4.537-4.236 0-8.388 1.548-11.457 4.537Zm34.085 84.675-.601.61c-6.027 6.115-11.813 11.825-22.027 11.825-6.197 0-12.48-2.263-17.238-6.898-4.81-4.685-7.805-11.544-7.805-20.208v-55.906c0-8.664 2.995-15.523 7.805-20.208 4.758-4.635 11.041-6.898 17.238-6.898 6.198 0 12.481 2.263 17.239 6.898 4.764 4.64 7.748 11.413 7.804 19.961a24.777 24.777 0 0 1 8.345-1.447c6.39 0 12.788 2.471 17.675 7.409 4.885 4.937 7.368 11.407 7.368 18.003 0 3.469-.574 6.766-2.045 10.058a23.7 23.7 0 0 1-.282.606C213.9 229.037 220 237.94 220 248.294c0 14.033-11.214 25.412-25.043 25.412-8.053 0-15.453-3.227-20.804-9.229-.892-1-1.721-2.07-2.482-3.206Zm28.562-29.056c6.635 2.239 11.419 8.596 11.419 16.079 0 9.355-7.476 16.941-16.695 16.941-5.781 0-10.902-2.279-14.614-6.441a21.556 21.556 0 0 1-2.621-3.663l20.958-21.266a47.85 47.85 0 0 0 1.553-1.65Zm-26.146-32.484a16.494 16.494 0 0 1 8.344-2.261c4.27 0 8.523 1.646 11.784 4.941 3.262 3.297 4.911 7.585 4.911 12.001 0 2.459-.394 4.533-1.3 6.559-.922 2.064-2.47 4.287-5.049 6.904l-18.148 18.416a37.247 37.247 0 0 1-.542-6.468v-40.092Z"
      clip-rule="evenodd"
      id="path14"
      fill="#ffffff"
      fill-opacity="1" />
  </svg>
);
icon.displayName = 'Kleinanzeigen';
icon.muiName = 'SvgIcon';

export default icon;