/*global FB*/
import React, { Component } from 'react';

// import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import styles from './FacebookConnect.module.css';

class FacebookConnect extends Component {
  state = {
    userID: null,
    loading: false
  }

  handleConnectClick = () => {
    let _this = this;
    FB.getLoginStatus(
      function(response) {
        if (response && response.status === 'connected' && _this.state.userID) {
          _this.getLongLivedUserToken(response.authResponse.accessToken);
        } else {
          FB.login(
            (response) => _this.loginToFacebook(response),
            {
              scope: 'pages_manage_posts, pages_read_engagement' // OLD until May'22: manage_pages, publish_pages
            }
          );
        }
      });
  }

  handleDisconnectClick = () => {
    let _this = this
    this.setState({loading: true});
    let facebookConnect = this;
    //console.debug('accessToken',  _this.props.data.appID+'|'+_this.props.data.appSecret)

    FB.api(
      '/'+this.props.data.userID+'/permissions',
      'DELETE',
      {
        access_token: _this.props.data.appID+'|'+_this.props.data.appSecret
      },
      function(response){
        if (response && response.success) {
          facebookConnect.saveData(null)
          facebookConnect.setState({loading: false});
        } else {
          if (response && response.error) {
            facebookConnect.props.onError(response.error)
          } else {
            facebookConnect.props.onError({message: 'Unbekannter Fehler (revoke permissions)'})
          }
        }
      }
    );
  }

  loginToFacebook(response) {
    if (response && response.authResponse) {
      this.setState({userID: response.authResponse.userID})
      this.getLongLivedUserToken(response.authResponse.accessToken);
      FB.logout(function(response){
        // User is logged out
      });
    } else {
      if (response && response.error ) {
        this.props.onError(response.error)
      } else {
        this.props.onError({message: 'Unabkannter Fehler (FBLogin)'})
      }
    }
  }

  getLongLivedUserToken(user_token){
    console.log('getLongLivedUserToken', user_token)
    FB.api(
      '/oauth/access_token?grant_type=fb_exchange_token',
      {
        client_id: this.props.data.appID,
        client_secret: this.props.data.appSecret,
        fb_exchange_token: user_token
      },
      (response) => this.getPageAccessToken(response)
    )
  }

  getPageAccessToken(response){
    let app = this;
    let long_lived_user_token = response.access_token;
    if(app.state.userID){
      FB.api(
        '/'+app.state.userID+'/accounts',
        {
          access_token: long_lived_user_token,
        },
        function(response){
          if (response && response.data && response.data[0]) {
              app.saveData({
                userID: app.state.userID,
                pageToken: response.data[0].access_token,
                pageID: response.data[0].id
              })
          } else {
            if (response && response.error) {
              app.props.onError(response.error)
            } else {
              app.props.onError({message: 'Unbekannter Fehler (pageToken)'})
            }
          }
        }
      );
    }else{
      app.props.onError({message: 'Fehler: userID ist leer!'})
    }
  }

  saveData(data) {
    this.props.onChange(data);
  }

  render() {
    let message, btn;

    if (this.props.data) {
      if (this.props.data.pageToken) {
        message = <div>Sie können jederzeit die Verbindung zwischen dem Sellanizer und Facebook trennen.</div>;
        btn =  <div className={styles.btn} onClick={this.handleDisconnectClick}>Verbindung trennen</div>
      } else {
        message = 'Aktuell ist die Sellanizer App nicht mit ihrer Facebookseite verbunden.';
        btn = <div className={styles.btn} onClick={this.handleConnectClick}>Jetzt verbinden</div>
      }

    } else {
      message = 'Aktuell ist die Sellanizer App nicht mit ihrer Facebookseite verbunden.';
      btn = <div className={styles.btn} onClick={this.handleConnectClick}>Jetzt verbinden</div>
    };

    return (
      <div className={styles.container}>
        {message}
        {!this.state.loading ?
          btn
          :
          <div className={styles.spinner}>
            <div className={styles.doubleBounce1}></div>
            <div className={styles.doubleBounce2}></div>
          </div>
        }
      </div>
    );
  }

}

export default FacebookConnect;
