
const mainStyle = theme => ({
  datepickerStart: {
    [theme.breakpoints.down('lg')]: {
      minWidth: '25%', marginRight: 30, marginTop: 20, marginBottom: 20
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '40%', marginRight: 30, marginTop: 10, marginBottom: 20
    },
  },
  datepickerEnd: {
    [theme.breakpoints.down('lg')]: {
      minWidth: '25%', marginTop: 20, marginBottom: 20
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '40%', marginTop: 10, marginBottom: 20
    },
  }
});

export default mainStyle;
