// ##############################
// // // snackbarNotistack styles
// #############################

import {
  defaultFont,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow
} from "assets/jss/material-dashboard-pro-react.jsx";

const snackbarNotistackStyle = {
  root: {
    ...defaultFont,
    flexWrap: "unset",
    fontSize: "14px",
    borderRadius: "3px",
    /*boxShadow:
      "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255, 0.2)"*/
  },
  top20: {
    top: "20px"
  },
  top40: {
    top: "40px"
  },
  customIcon: {
    marginRight: 8,
  },
  spin: {
    animation: "spinner 60s linear infinite",
    /*animationName: 'spin',
    animationDuration: '4000ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    transform: 'rotate(150deg)',*/
    animationClass: {
      animation: "spinner 60s linear infinite"
    },
  },
  default: {
    backgroundColor: "#999999",
    ...defaultFont,
    fontWeight: 300,
    ...primaryBoxShadow
  },
  loading: {
    backgroundColor: "#333333",
    //backgroundColor: "#00b7ca",
    ...defaultFont,
    fontWeight: 300,
    ...primaryBoxShadow
  },
  info: {
    backgroundColor: "#00d3ee",
    color: "#ffffff",
    ...defaultFont,
    fontWeight: 400,
    ...infoBoxShadow
  },
  success: {
    backgroundColor: "#5cb860",
    color: "#ffffff",
    ...defaultFont,
    fontWeight: 300,
    ...successBoxShadow
  },
  warning: {
    backgroundColor: "#ffa21a",
    color: "#ffffff",
    ...defaultFont,
    fontWeight: 400,
    ...warningBoxShadow
  },
  error: {
    backgroundColor: "#f55a4e",
    color: "#ffffff",
    ...defaultFont,
    fontWeight: 400,
    ...dangerBoxShadow
  },
  message: {
    padding: "0",
    display: "block",
    maxWidth: "89%",
  },
  close: {
    width: "11px",
    height: "11px"
  },
  iconButton: {
    width: "24px",
    height: "24px",
    padding: "0"
  },
  icon: {
    width: "38px",
    height: "38px",
    display: "block",
    left: "15px",
    position: "absolute",
    marginTop: "-39px",
    fontSize: "20px",
    backgroundColor: "#FFFFFF",
    padding: "9px",
    borderRadius: "50%",
    maxWidth: "38px",
    boxShadow:
      "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  action: {
    position: 'absolute',
  },
  svg: {
    position: 'absolute',
  },
  snackClose: {
    position: 'absolute',
  },
  infoIcon: {
    color: "#00d3ee"
  },
  successIcon: {
    color: "#5cb860"
  },
  warningIcon: {
    color: "#ffa21a"
  },
  dangerIcon: {
    color: "#f55a4e"
  },
  primaryIcon: {
    color: "#af2cc5"
  },
  roseIcon: {
    color: "#eb3573"
  },
  iconMessage: {
    paddingLeft: "50px",
    display: "block"
  }
};

export default snackbarNotistackStyle;
