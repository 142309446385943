import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Refresh from "@material-ui/icons/Refresh";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
//import Collapse from "@material-ui/core/Collapse";
// core components
//import HeaderLinks from "components/Header/HeaderLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";

import { unregister } from "../../registerServiceWorker";

import API from 'components/44/ApiPlanungswelten';
let pjson = require("./../../../package.json")


/*function sessionLogout() {
  Session.logout()
}

function handleActions(component, action) {
  //Session.logout()
}*/

const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName, routeName2 = 'xxx') {
    routeName = routeName.replace(/:[\w-]*/, '')
    return props.location ? (props.location.pathname.indexOf(routeName) > -1 || props.location.pathname.indexOf(routeName2) > -1) ? true : false : false
  }

  function refreshApp(){
    unregister()
    //registerServiceWorker()
    window.location.reload(true)
  }

  const { classes, color, logo, image, logoText, partnerLogo, partnerName, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.role){
          //console.log(prop.sidebarName+' needs role '+prop.role, API.getUserRole())
          // Admin Rolle überprüfen
          if (prop.role !== API.getUserRole()) return null;
        }
        if (prop.invisible) return null;
        if (prop.redirect) return null;
        var activePro = " ";
        var listItemClasses;

        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.path, prop.pathLink) || prop.active
        });

        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.path, prop.pathLink)
        });
        return (
          <NavLink
            style={prop.style}
            to={prop.pathLink || prop.path}
            className={activePro + classes.item + " logout " + prop.classNames}
            activeClassName="active"
            onClick={typeof(prop.onClick) !== 'undefined' ? () => prop.onClick() : function(){ }}
            //onClick={prop.action}
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                {typeof prop.icon === "string" ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <prop.icon />
                )}
              </ListItemIcon>
              <ListItemText
                primary={prop.sidebarName}
                className={classes.itemText + whiteFontClasses}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
  let pversion = (
    <div className={classes.version}>
      <small>v{pjson.version}</small>
    </div>
  )
  let refreshButton = (
    <div className={classes.refresh}>
      <Refresh onClick={() => refreshApp()}/>
    </div>
  )
  let brand = (
    <div className={classes.logo}>
      <div className={classes.logoImage}>
        <img src={logo} alt={logoText} className={classes.img} />
      </div>
    </div>
  );
  let user = (
    <div className={classes.user}>
      <div className={classes.userImage}>
        <small style={{color: 'white', display:'none'}}>Sie sind eingeloggt als:</small>
        {partnerLogo && typeof partnerLogo !== 'undefined' ? (
          <img src={partnerLogo} alt={partnerName} className={classes.userImg} />
        ) : null}
        <small style={{color: 'white'}}>{partnerName}</small>
      </div>
    </div>
  );
  let version = (
    <div style={{ position:'absolute', bottom: 0, paddingLeft:33, zIndex:10}}>
      <NavLink to="/pages/versions">
        <small style={{color: 'white'}}>Version: &nbsp;{pjson.version}</small>
      </NavLink>
    </div>
  )
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {pversion}
          {refreshButton}
          {brand}
          {user}
          <div className={classes.sidebarWrapper}>
            {/*<HeaderLinks />*/}
            {links}
            {version}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {pversion}
          {refreshButton}
          {brand}
          {user}
          <div className={classes.sidebarWrapper}>
            {links}
            {version}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
