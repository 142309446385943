import React from 'react';
//import pure from 'recompose/pure';
//import SvgIcon from '@material-ui/core/SvgIcon';
//import ebayIcon from "assets/img/logo_ebay-kl.svg";

let icon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-130 -320 550 380">
    <title>logo_markt.de</title>
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path fill="#fff"
                d="M 302.12571,47.657693 V -154.19706 c 0.0316,-8.61691 -1.63162,-23.51798 -8.74624,-35.08655 -7.22845,-11.52095 -18.5075,-20.85197 -43.37548,-21.09001 -32.79366,0.28564 -54.32251,23.42277 -54.67349,56.17656 V 47.657693 H 92.263344 V -154.19706 c 0.06324,-8.71213 -1.467193,-23.6132 -8.504345,-35.13415 -7.152567,-11.42574 -18.365211,-20.80437 -43.579431,-21.04241 -32.0727161,0.25391 -53.729626,23.42277 -54.042669,56.17656 V 47.657693 H -116.93501 V -300.39893 h 99.234756 v 18.85247 c 20.2339855,-17.0434 45.946229,-27.18374 75.704321,-27.08853 39.462437,-0.0952 73.963603,15.28193 98.362013,41.89438 24.86323,-26.04116 59.40235,-41.98959 100.01262,-41.89438 45.9652,-0.0952 83.5968,15.51997 109.18573,43.08457 25.6411,27.56459 38.99761,66.3169 38.95967,111.35336 V 47.657693"/>
    <g>
      <path fill="#fff"
                  d="M 302.12571,47.657693 V -154.19706 c 0.0316,-8.61691 -1.63162,-23.51798 -8.74624,-35.08655 -7.22845,-11.52095 -18.5075,-20.85197 -43.37548,-21.09001 -32.79366,0.28564 -54.32251,23.42277 -54.67349,56.17656 V 47.657693 H 92.263344 V -154.19706 c 0.06324,-8.71213 -1.467193,-23.6132 -8.504345,-35.13415 -7.152567,-11.42574 -18.365211,-20.80437 -43.579431,-21.04241 -32.0727161,0.25391 -53.729626,23.42277 -54.042669,56.17656 V 47.657693 H -116.93501 V -300.39893 h 99.234756 v 18.85247 c 20.2339855,-17.0434 45.946229,-27.18374 75.704321,-27.08853 39.462437,-0.0952 73.963603,15.28193 98.362013,41.89438 24.86323,-26.04116 59.40235,-41.98959 100.01262,-41.89438 45.9652,-0.0952 83.5968,15.51997 109.18573,43.08457 25.6411,27.56459 38.99761,66.3169 38.95967,111.35336 V 47.657693"/>
    </g>
  </svg>
);
icon.displayName = 'Markt.de';
icon.muiName = 'SvgIcon';

export default icon;