import React from "react";

import Button from "../../components/CustomButtons/Button";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

const _lodash = require('lodash');

class Repeater extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      skeleton: { benutzer: '', passwort: ''},
      key: this.props.name,
      rows: this.props.rows,
      subfields: this.props.subfields,
      pos: this.props.subfields.length-3,
      helperText: this.props.helperText,
      min: this.props.min || 1,
      max: this.props.max || 10,
      button_label: this.props.button_label
    };
    //this.handleChange = this.handleChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
    // let rows = this.props.rows
    // let subfields = this.props.subfields

    //console.log('this.props.rows', this.props.rows)
    //console.log('this.props.subfields', this.props.subfields)
    // console.debug('Repeater this.state', this.state);

    /*if(typeof rows !== 'undefined' && Object.keys(rows).length){
      Object.keys(rows).forEach(function(key) {

      });
    }*/
  }

  handleRowAdd(e, prop) {
    e.preventDefault();
    //console.log('handleRowAdd.key', key)
    //console.log('handleRowAdd.prop', prop)
    let _this = this
    let subfields = this.props.subfields
    let pos = this.state.pos

    if(pos < 0){
      pos = -1
    }
    // pos = pos+1

    this.setState({'pos': pos+1})
    //console.debug('pos', pos+1);
    //console.debug('this.state.rows', this.state.rows);

    let state_new_row = _lodash.cloneDeep(this.state.rows[this.state.rows.length-1])

    state_new_row = state_new_row || [{benutzer: '', passwort: ''}]

    //console.debug('state_new_row', state_new_row);
    state_new_row.benutzer = ''
    state_new_row.passwort = ''

    let rows = this.state.rows
    rows.push(state_new_row);

    this.setState({'rows': rows})
    //console.debug('this.state.rows', this.state.rows)

    //TODO: Wofür benötige ich pos? und wieso crasht es dann beim 5. hinzufügen = pos 10

    let new_row = this.props.subfields[pos]
    new_row.disabled = false


    /*let new_row = _lodash.cloneDeep(this.props.subfields[pos])
    new_row.disabled = false
    //TODO: Ändern des Index in key und name "accounts_ebay_kleinanzeigen[X][benutzer]"
    // => Nützt nichts da die Listener noch auf dem alten Index laufen
    new_row[0].props.children.props.id = "accounts_ebay_kleinanzeigen[x][benutzer]".replace('x', pos)
    new_row[1].props.children.props.id = "accounts_ebay_kleinanzeigen[x][passwort]".replace('x', pos)
    new_row[0].props.children.props.name = "accounts_ebay_kleinanzeigen[x][benutzer]".replace('x', pos)
    new_row[1].props.children.props.name = "accounts_ebay_kleinanzeigen[x][passwort]".replace('x', pos)
    new_row[0].props.children.props.value = ""
    new_row[1].props.children.props.value = ""
    console.log('new id', new_row[0].props.children.props.id);
    console.log('new name', new_row[0].props.children.props.name);
    subfields.splice(pos, 0, new_row);*/
    //console.log('added that:', this.props.subfields[pos-1][0].props.children.props)

    // TypeError: Cannot assign to read only property 'disabled' of object '#<Object>'
    //this.props.subfields[this.props.subfields.length-2][0].props.children.props.disabled = true
    //this.props.subfields[this.props.subfields.length-2][0].props.children.props.readonly = true

    /*console.log('handleRowAdd.subfields', subfields)
    console.log('this.state.rows.length', this.state.rows.length)
    console.log('this.state.rows.length < this.state.max', this.state.rows.length < this.state.max)*/

    this.setState({
      subfields: subfields
    },function(){
      //console.log('images (handleImageDelete)', images);
      if (_this.props.onChange) {
        _this.props.onChange(subfields);
      }
    });
  }

  countOpenRows(){
    // TODO
  }

  deleteDisabledRows(subfields){
    let i = 1
    let subfields_new = _lodash.cloneDeep(subfields);

    subfields.forEach(function(val, key) {
      //console.log(key, val.disabled);
      if(val.disabled===true && i !== subfields.length){ // don't delete the last blueprint entry
        //console.debug('delete pos '+i)
        delete subfields_new[key];
        subfields_new.splice(i,1);
      }else{
        i++;
      }
    });

    //console.log('deleteDisabledRows length before', subfields.length )
    //console.log('deleteDisabledRows length after', subfields_new.length )

    return subfields_new
  }

  handleRowDelete(e, key, prop, index) {
    e.preventDefault();
    //console.debug('handleRowDelete', [key, prop, index])
    //console.log('handleImageDelete.prop', prop)

    let _this = this
    let rows = this.props.rows
    let subfields = this.props.subfields
    let pos = this.state.pos

    pos = pos-1
    if(pos < 0){
      pos = 0
    }

    this.setState({'pos': pos})
    //console.debug('pos', pos);

    if(typeof key !== 'undefined'){
      //console.debug('subfields before_splice', subfields);
      //console.debug('rows before_splice', rows);

      // don't delete the blueprint entry
      if(subfields.length > 1){
        subfields.splice(index, 1)
        rows.splice(index, 1)
        //console.debug('subfields after_splice', subfields);
        //console.debug('rows after_splice', rows);

        subfields = this.deleteDisabledRows(subfields)
      }else{
        rows.splice(index, 1)
        subfields[0].disabled=true
      }

      _this.setState({
        subfields: subfields,
        rows: rows,
      },function(){
        console.log('subfields (handleRowDelete)', subfields);
        /*if (_this.props.onChange) {
          _this.props.onChange(subfields);
        }*/
      });
    }
  }

  handleChange(e, key, prop) {
    e.preventDefault();
    //console.log('handleImageChange.key', key)
    //console.log('handleImageChange.prop', prop)

    // let _this = this
    //let file = e.target.files[0];
    // let rows = this.state.rows
    // let subfields = this.state.subfields

    console.debug('handleChange (currently not used) '+key)

    //console.log('images', this.state.images)
    //console.log('origfile2', e.target.files[1])
  }


  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
    console.log('handleSubmit: this.state.file', this.state.file);
  }

  render() {
    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    //console.log('sublfields', this.props.subfields);
    let key = this.state.key;
    return (
      <div className="repeater-wrapper" style={{marginBottom: '20px', ...this.props.style }}>
        <h5>{this.state.helperText}</h5>
        {this.props.subfields.map((subfield, index) => {
          if (iOS) {
            return (
              <div key={key+'-wrapper-'+index} className="repeater-row ios" style={{padding: '10px', marginBottom: '10px', boxShadow:'0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'}}>
                {subfield}
                <h6 onClick={(e) => this.handleRowDelete(e,key,subfield,index)} className="description"><Delete /> löschen</h6>
              </div>
            );
          }else{
            return (
              <div key={key+'-wrapper-'+index} className="repeater-row" style={{display: subfield.disabled ? 'none' : 'block', padding: '10px', marginBottom: '10px', boxShadow:'0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'}}>
                <GridContainer justify="flex-start" spacing={8}>
                  {subfield}
                  <GridItem xs={2} sm={1} md={1}>
                    <Button color="gray" size="sm" round justIcon onClick={(e) => this.handleRowDelete(e,key,subfield,index)}><Delete /></Button>
                  </GridItem>
                </GridContainer>
              </div>
            );
          }
        })}
        <div key="new-row" className="repeater-container" style={{ marginTop: -5, display: this.state.rows.length < this.state.max ? 'block' : 'none'}}>
          <div>{/*this.state.rows.length*/}</div>
          <Button color="gray" size="sm" round onClick={(e,prop) => this.handleRowAdd(e, this.state.rows)}>
            <Add /> {this.state.button_label}
          </Button>
        </div>
      </div>
    );
  }
}

export default Repeater;
