import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import customTabsStyle from "assets/jss/material-dashboard-react/components/customTabsStyle.jsx";

class CustomTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      props: props,
      value: this.props.activeTabIndex || 0
    }
  }

  componentDidMount() {
    const currentPath = this.state.props.history.location.pathname || ''
    /*console.log('this.props.activeTab', this.props.activeTab)
    console.log('this.props.activeTabIndex', this.props.activeTabIndex)*/

    let i = 0
    this.props.tabs.forEach(tab => {
      if(tab.tabLink === currentPath){
        this.setState({ i })
        this.handleChange(i, i)
      }
      i++;
    })
  }

  handleChange = (event, value) => {
    this.setState({ value })
    this.props.onTabChange();
    //console.log('tabs', this.props.tabs)
    //console.log('active tab: ', value);
    const url = this.props.tabs[value].tabLink || false
    const title = this.props.tabs[value].tabName || false
    if(url){
      document.title = "Sellanizer - "+title
      this.state.props.history.push(url)
    }
  }

  render() {
    const {
      classes,
      headerColor,
      plainTabs,
      tabs,
      title,
      rtlActive
    } = this.props;
    const cardTitle = classNames({
      [classes.cardTitle]: true,
      [classes.cardTitleRTL]: rtlActive
    });
    return (
      <Card plain={plainTabs}>
        <CardHeader color={headerColor} plain={plainTabs}>
          {title !== undefined ? (
            <div className={cardTitle}>{title}</div>
          ) : null}
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.displayNone,
              scrollButtons: classes.displayNonefab
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map((prop, key) => {
              var icon = {};
              if (prop.active === false) {
                return null
              }
              if (prop.tabIcon) {
                icon = {
                  icon: <prop.tabIcon />
                };
              }
              return (
                <Tab
                  classes={{
                    root: classes.tabRootButton,
                    labelContainer: classes.tabLabelContainer,
                    label: classes.tabLabel,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper+" "+classes.tabSmall
                  }}
                  key={key}
                  label={prop.tabName}
                  onClick={prop.tabhandleClick}
                  {...icon}
                />
              );
            })}
          </Tabs>
        </CardHeader>
        <CardBody>
          {tabs.map((prop, key) => {
            if (key === this.state.value) {
              return <div key={key}>{prop.tabContent}</div>;
            }
            return null;
          })}
        </CardBody>
      </Card>
    );
  }
}

CustomTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary"
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabLink: PropTypes.string,
      tabhandleClick: PropTypes.func,
      tabIcon: PropTypes.func,
      active: PropTypes.bool,
      tabContent: PropTypes.node.isRequired
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool
};

export default withStyles(customTabsStyle)(CustomTabs);
